import { useT } from 'apprise-frontend-core/intl/language'
import { Button } from 'apprise-ui/button/button'
import { AiOutlineCopy } from 'react-icons/ai'
import { useFeedback } from './feedback'

export const useCopytoClipboard = (props: {

    title?: string
    text: () => string

}) => {

    const t = useT()
    const fb = useFeedback()

    const { title = t('ui.copy_btn_lbl'), text } = props

    const copy = () => {

        navigator.clipboard.writeText(text())

        fb.showNotification(t('ui.copy_copied'), { duration: 2000 })
    }

    const copyBtn = <Button noReadonly icon={<AiOutlineCopy />} onClick={copy} >
        {title}
    </Button>

    return { copyBtn, copy }

}