import { RecordIdentifier } from '#record/model'
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant'
import { useDeferredRouting, useRouting } from 'apprise-ui/utils/routing'
import { useContext } from 'react'
import { useHistory } from 'react-router'
import { recordTab } from './constants'
import { SubmissionRoutingContext } from './context'
import { Submission, SubmissionRef, useSubmissionModel } from './model'


export const submissionRoute = '/submission'

export const submissiondDetailParam = 'sub-tab'
export const downloadDialogParam = 'download-tab'
export const rejectParam = 'reject'
export const recordDetailParam = 'record'
export const recordTabParam = 'record-tab'

export type SubmissionRoutingState = {

    nextSubmission: Submission
    routeAfterNext: (added: Submission) => any

}

export const useSubmissionRouting = () => {


    const state = useContext(SubmissionRoutingContext)

    const logged = useTenancyOracle()
    const routing = useRouting()

    const submissions = useSubmissionModel()
    const history = useHistory()

    const defer = useDeferredRouting()


    const self = {

        listRoute: () => submissionRoute

        ,

        detailRoute: (id: SubmissionRef) => `${submissionRoute}/${id}`

        ,

        innerDetailRoute: (id: SubmissionRef) => `${self.detailRoute(id)}?${routing.query()}`

        ,

        innerRecordRoute: (record?: RecordIdentifier) => `${routing.routeQueryTo(ps => {

            ps[submissiondDetailParam] = recordTab

            if (record)
                ps[recordDetailParam] = record

        })}`


        ,

        recordsRoute: (id: SubmissionRef) => `${self.detailRoute(id)}?${submissiondDetailParam}=${recordTabParam}`

        ,

        recordRoute: (id: SubmissionRef, record: RecordIdentifier) => `${self.recordsRoute(id)}&${recordDetailParam}=${record}`

        ,


        newDetailRoute: () => self.detailRoute('new')

        ,

        routeToList: () => history.push(self.listRoute())

        ,

        routeToDetail: (id: SubmissionRef) => history.push(self.detailRoute(id))

        ,

        routeToInnerDetail: (id: SubmissionRef) => history.push(self.innerRecordRoute(id))

        ,

        routeToNewDetail: (directives: Partial<SubmissionRoutingState> = {}) => {


            defer.routeAfterRenderTo(self.newDetailRoute())

            state.reset(directives)


            
        }
        ,

        nextSubmission: () => {

            const nextSubmission = state.get().nextSubmission ?? submissions.newSubmission()

            const next: Submission = { ...nextSubmission, tenant: nextSubmission.tenant ?? (logged.isSingleTenantUser() ? logged.tenant() : undefined!) }

            if (next)
                state.setQuietly(s => s.nextSubmission = undefined)

            return next

        }

        ,

        routeOnAdded: (submission: Submission) => state.get().routeAfterNext?.(submission) ?? self.routeToList()

    }

    return self
}