import { defaultConfiguration } from '#config'
import { ExtensionProvider } from '#extension/provider'
import RavGuest from '#guest/rav-guest'
import { IOTCExtension } from '#iotc/extension'
import { Search } from '#search/provider'
import { VersionUpgradeObserver } from '#utils/upgradeobserver'
import { Vessels } from '#vessel/vessels'
import { Application } from 'apprise-frontend-core/application'
import { Client } from 'apprise-frontend-core/client/client'
import { Preload } from 'apprise-frontend-core/client/preload'
import { Configuration } from 'apprise-frontend-core/config/configuration'
import { ConfigLoader } from 'apprise-frontend-core/config/loader'
import { Intl } from 'apprise-frontend-core/intl/intl'
import { useT } from 'apprise-frontend-core/intl/language'
import { useComponentBridge } from 'apprise-frontend-core/utils/bridge'
import { vite } from 'apprise-frontend-core/utils/vitebridge'
import { Events } from 'apprise-frontend-events/events'
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant'
import { Login } from 'apprise-frontend-iam/login/login'
import { Tenants } from 'apprise-frontend-iam/tenant/tenants'
import { Settings } from 'apprise-frontend-streams/settings/settings'
import { Tags } from 'apprise-frontend-tags/tags'
import { UserInterface } from 'apprise-ui/provider'
import { lazy, Suspense } from 'react'


export const Rav = () => {

    return <Application tool={vite}>
        <UserInterface>
            <Configuration config={defaultConfiguration}>
                <Preload>
                    <Intl>
                        <Client>
                            <ConfigLoader>
                                <Login allowGuest>
                                    <Events>
                                        <ExtensionProvider>
                                            <IOTCExtension>
                                                <Settings>
                                                    <Tags>
                                                        <Tenants>
                                                            <Search>
                                                                <Vessels>
                                                                    <VersionUpgradeObserver>
                                                                        <RavRouter />
                                                                    </VersionUpgradeObserver>
                                                                </Vessels>
                                                            </Search>
                                                        </Tenants>
                                                    </Tags>
                                                </Settings>
                                            </IOTCExtension>
                                        </ExtensionProvider>
                                    </Events>
                                </Login>
                            </ConfigLoader>
                        </Client>
                    </Intl>
                </Preload>
            </Configuration>

        </UserInterface>
    </Application >
}

// const RavGuest = lazy(() => import('./guest/rav-guest'))
const RavLogged = lazy(() => import('./rav-logged'))


export const RavRouter = () => {

    const t = useT()

    const oracle = useTenancyOracle()

    const { Indicator } = useComponentBridge()

    return oracle.isGuest() ? 
    
    <RavGuest /> 
    
    : 
    
    <Suspense fallback={<Indicator msg={t('rav.load_ui')} waiting={true} />} >
       <RavLogged />
    </Suspense>
}