import { RecordPlugin, RecordPluginDescriptor, useDefaultRecordPlugin } from '#record/plugin';
import { useT } from 'apprise-frontend-core/intl/language';
import { useDelistingColumns } from './columns';
import { DelistingIcon, delistingPatchedTypes } from './constants';
import { DelistingPatch } from './model';
import { useDelistingParser } from './parser';
import { DelistingPatchCard } from './patch';
import { useDelistingJsonSerializer } from './jsonserializer';
import { useDelistingValidation } from './validator';



export const useDelistingPluginDescriptor = (): RecordPluginDescriptor => {

    const t = useT()
    const defaults = useDefaultRecordPlugin()

    return {

        ...defaults,

        name: t('delisting.type')

        ,

        singular: t('delisting.singular')

        ,

        plural: t('delisting.plural')

        ,


        Icon: DelistingIcon

        ,

        patchedTypes: delistingPatchedTypes
    }

}

export const useDelistingPlugin = (): RecordPlugin<DelistingPatch> => {


    const self = {

        ...useDefaultRecordPlugin()
        
        ,
        
        ...useDelistingPluginDescriptor()

        ,

        parse: useDelistingParser

        ,

        validate: useDelistingValidation

        ,

        serialize: useDelistingJsonSerializer

        ,

        Patch: DelistingPatchCard    

        
        // ,

        // patchFilters: usedelistingFilters

        // ,

        // Form: delistingForm

        ,

        Overview: undefined

        ,

        patchColumns: useDelistingColumns




    }


    return self
}
