import { beneficialOwnerTag, bowTag, carrierKindTag, contactTypeCategory, detailsType, fishingKindTag, loaTag, operatingCompanyTag, operatorTag, ownerTag, portsideTag, ravContext, specificationCategory, starboardTag, supplyKindTag, tonnageTag, totalVolumeTag, vesselGearCategory, vesselKindCategory, vesselRangeCategory, vesselTypeCategory } from '#details/constants'
import { imoscheme, iotcnumberscheme, ircsscheme, regnoscheme } from '#iotc/constants'
import { photographsTypeCategory, photographType } from '#photographs/constants'
import { vidSchemeCategory } from '#vid/model'
import { useIntlMocks } from 'apprise-frontend-core/intl/mocks'
import { useLifecycleMocks } from 'apprise-frontend-core/utils/lifecycle'
import { tenantType } from 'apprise-frontend-iam/tenant/constants'
import { userType } from 'apprise-frontend-iam/user/constants'
import { useCategoryMocks } from 'apprise-frontend-tags/category/mockery'
import { cardinalities, CategoryLifecycleState, TagCategory, useCategoryModel } from 'apprise-frontend-tags/category/model'
import { CategoryCacheContext } from 'apprise-frontend-tags/category/provider'
import { systemModule } from 'apprise-frontend-tags/constants'
import { useTagMocks } from 'apprise-frontend-tags/tag/mockery'
import { Tag, TagLifecycleState, useTagModel } from 'apprise-frontend-tags/tag/model'
import { TagCacheContext } from 'apprise-frontend-tags/tag/provider'
import { useContext } from 'react'


export const useRavTags = () => {

    const catstore = useContext(CategoryCacheContext)?.get()
    const tagstore = useContext(TagCacheContext)?.get()
    
    const tags = useTagModel()
    const cats = useCategoryModel()
    const tagmocks = useTagMocks()
    const catmocks = useCategoryMocks()

    const { mockMultilang } = useIntlMocks()
    const { pastLifecycle } = useLifecycleMocks()

    const context = catmocks.mockCategories().context

    const ravctx = {

        ...tags.newTag(systemModule.type),
        category: context.id,
        id: ravContext,
        predefined: true,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('RAV Record'),
        description: mockMultilang('An entry in a the Record of Authorized Vessels.')
    }

    const activelistctx = {

        ...tags.newTag(systemModule.type),
        category: context.id,
        id: "TG-actielist-context",
        predefined: true,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Active List Record'),
        description: mockMultilang('An active vessel without an authorisation in the RAV.')
    }

    const scheme: TagCategory = {

        ...cats.newCategory(detailsType),
        id: vidSchemeCategory,

        predefined: true,
        lifecycle: pastLifecycle<CategoryLifecycleState>('active'),

        cardinality: cardinalities.one,

        name: mockMultilang("Identification Scheme"),
        description: mockMultilang("Identifiation Scheme Description"),

        properties: {
            field: {
                enabled: false,
            }
        }

    }

    const imo: Tag = {

        ...tags.newTag(detailsType),
        category: scheme.id,
        id: imoscheme,
        predefined: true,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('IMO'),
        description: mockMultilang('The International Maritime Organisation (IMO) UVI Scheme.')
    }


    const tuvi: Tag = {

        ...tags.newTag(detailsType),
        category: scheme.id,
        id: "TG-tuvi-scheme",
        predefined: true,
        lifecycle: pastLifecycle<TagLifecycleState>('inactive'),
        name: mockMultilang('TUVI'),
        description: mockMultilang('Tuna UVI.')
    }

    const iotcnumber: Tag = {

        ...tags.newTag(detailsType),
        category: scheme.id,
        id: iotcnumberscheme,
        predefined: true,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('IOTC Number'),
        description: mockMultilang('The Indian Ocean Tuna Commission Vessel Number Scheme.')
    }

    // const nrn: Tag = {

    //     ...tags.newTag(detailsType),
    //     category: scheme.id,
    //     id: "TG-nrn-scheme",
    //     predefined: true,
    //     lifecycle: pastLifecycle<TagLifecycleState>('active'),
    //     name: mockMultilang('NRN'),
    //     description: mockMultilang('National Registration Number.')
    // }


    // const cfr: Tag = {

    //     ...tags.newTag(detailsType),
    //     category: scheme.id,
    //     id: "TG-cfr-scheme",
    //     predefined: true,
    //     lifecycle: pastLifecycle<TagLifecycleState>('active'),
    //     name: mockMultilang('CFR'),
    //     description: mockMultilang('Community Fleet Register.')
    // }

    const regno: Tag = {

        ...tags.newTag(detailsType),
        category: scheme.id,
        id: regnoscheme,
        predefined: true,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('REGNO'),
        description: mockMultilang('National or EU registration number (NRN/CRF).')
    }



    const ircs: Tag = {

        ...tags.newTag(detailsType),
        category: scheme.id,
        id: ircsscheme,
        predefined: true,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('IRCS'),
        description: mockMultilang('International Radio Call Sign.')
    }

    // const iccat: Tag = {

    //     ...tags.newTag(detailsType),
    //     category: scheme.id,
    //     id: "TG-iccat-scheme",
    //     predefined: true,
    //     lifecycle: pastLifecycle<TagLifecycleState>('active'),
    //     name: mockMultilang('ICCAT'),
    //     description: mockMultilang('International Commission for the Conservation of Atlantic Tunas.')
    // }

    // const wcpfc: Tag = {

    //     ...tags.newTag(detailsType),
    //     category: scheme.id,
    //     id: "TG-wcpfc-scheme",
    //     predefined: true,
    //     lifecycle: pastLifecycle<TagLifecycleState>('active'),
    //     name: mockMultilang('WCPFC'),
    //     description: mockMultilang('Western and Central Pacific Fisheries Commission.')
    // }

    

    const photographs: TagCategory = {

        ...cats.newCategory(photographType),
        id: photographsTypeCategory,

        predefined: true,
        lifecycle: pastLifecycle<CategoryLifecycleState>('active'),

        cardinality: cardinalities.one,

        name: mockMultilang("Photographs"),
        description: mockMultilang("Photograps of the Vessel."),

        properties: {
            field: {
                enabled: false
            }
        }

    }

    const starboard: Tag = {

        ...tags.newTag(detailsType),
        category: photographs.id,
        id: starboardTag,
        predefined: true,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Starboard'),
        description: mockMultilang('A photograph of the starboard side of the vessel.')
    }

    const portside: Tag = {

        ...tags.newTag(detailsType),
        category: photographs.id,
        id: portsideTag,
        predefined: true,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Portside'),
        description: mockMultilang('pA photograph of the ortside of the vessel.')
    }

    const bow: Tag = {

        ...tags.newTag(detailsType),
        category: photographs.id,
        id: bowTag,
        predefined: true,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Bow'),
        description: mockMultilang('A photograph of the bow of the vessel.')
    }


    const kind: TagCategory = {

        ...cats.newCategory(detailsType),
        id: vesselKindCategory,

        predefined: true,
        lifecycle: pastLifecycle<CategoryLifecycleState>('active'),

        cardinality: cardinalities.one,

        name: mockMultilang("Kind"),
        description: mockMultilang("A broad classification of vessel purpose and role in Fisheries operations."),

        properties: {
            field: {
                ordinal: 10,
                enabled: true,
            }
        }

    }

    const fishingVessel: Tag = {

        ...tags.newTag(detailsType),
        category: kind.id,
        id: fishingKindTag,
        predefined: true,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Fishing Vessel'),
        description: mockMultilang('A vessel equipped with fishing gear.')
    }



    const carrierVessel: Tag = {

        ...tags.newTag(detailsType),
        category: kind.id,
        id: carrierKindTag,
        predefined: true,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Carrier Vessel'),
        description: mockMultilang('A vessel that transports catch.')
    }


    const supplyVessel: Tag = {

        ...tags.newTag(detailsType),
        category: kind.id,
        id: supplyKindTag,
        predefined: true,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Supply Vessel'),
        description: mockMultilang('A vessel that provids logistic support.')
    }


    const type: TagCategory = {

        ...cats.newCategory(detailsType),
        id: vesselTypeCategory,

        predefined: true,
        lifecycle: pastLifecycle<CategoryLifecycleState>('active'),

        cardinality: cardinalities.one,

        name: mockMultilang("Vessel Type"),
        description: mockMultilang("The charateristics and purpose of the vessel."),

        properties: {
            includeCondition: {

                terms: [{ category: kind.id, tags: [fishingVessel.id], op: 'anyOf' }]

            },
            field: {
                ordinal: 20,
                enabled: true,
            }
        }

    }

    const poleandline: Tag = {

        ...tags.newTag(detailsType),
        category: type.id,
        id: 'TG-poleandline-type',
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Pole and Line Vessel'),
        description: mockMultilang('Pole and Line Vessel'),
        code:'BB',
        properties: {

            includeCondition: {

                terms: [{ category: kind.id, tags: [fishingVessel.id], op: 'anyOf' }]
            }
        }
    }


    const cargofreezer: Tag = {

        ...tags.newTag(detailsType),
        category: type.id,
        id: 'TG-cargofreezer-type',
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Cargo Freezer'),
        description: mockMultilang('Cargo Freezer'),
        code:'CF',
        properties: {

            includeCondition: {

                terms: [{ category: kind.id, tags: [carrierVessel.id], op: 'anyOf' }]
            }
        }
    }


    const gillnetter: Tag = {

        ...tags.newTag(detailsType),
        category: type.id,
        id: 'TG-gillnetter-type',
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Gill Netters'),
        description: mockMultilang('Gill Netters'),
        code:'GI',
        properties: {

            includeCondition: {

                terms: [{ category: kind.id, tags: [fishingVessel.id], op: 'anyOf' }]
            }
        }
    }

    const longlinerpolenadline: Tag = {

        ...tags.newTag(detailsType),
        category: type.id,
        id: 'TG-longlinerpolenadline-type',
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Longliner-Pole and Line vessel'),
        description: mockMultilang('Longliner-Pole and Line vessel'),
        code:'LB',
        properties: {

            includeCondition: {

                terms: [{ category: kind.id, tags: [fishingVessel.id], op: 'anyOf' }]
            }
        }
    }

    const longlinercarrier: Tag = {

        ...tags.newTag(detailsType),
        category: type.id,
        id: 'TG-longlinercarrier-type',
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Longliner and Carrier vessel'),
        description: mockMultilang('Longliner and Carrier vessel'),
        code:'LC',
        properties: {

            includeCondition: {

                terms: [{ category: kind.id, tags: [fishingVessel.id], op: 'anyOf' }]
            }
        }
    }

    const longliner: Tag = {

        ...tags.newTag(detailsType),
        category: type.id,
        id: 'TG-longliner-type',
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Longliner'),
        description: mockMultilang('Longliner'),
        code:'LL',
        properties: {

            includeCondition: {

                terms: [{ category: kind.id, tags: [fishingVessel.id], op: 'anyOf' }]
            }
        }
    }

    const liner: Tag = {

        ...tags.newTag(detailsType),
        category: type.id,
        id: 'TG-liner-type',
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Line Vessel'),
        description: mockMultilang('Line Vessel'),
        code:'LI',
        properties: {

            includeCondition: {

                terms: [{ category: kind.id, tags: [fishingVessel.id], op: 'anyOf' }]
            }
        }
    }

    const multipurpose: Tag = {

        ...tags.newTag(detailsType),
        category: type.id,
        id: 'TG-multipurpose-type',
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Multipurpose'),
        description: mockMultilang('Multipurpose'),
        code:'MU',
        properties: {

            includeCondition: {

                terms: [{ category: kind.id, tags: [fishingVessel.id], op: 'anyOf' }]
            }
        }
    }

    const purseseiner: Tag = {

        ...tags.newTag(detailsType),
        category: type.id,
        id: 'TG-purseseiner-type',
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Purse Seiner'),
        description: mockMultilang('Purse Seiner'),
        code:'PS',
        properties: {

            includeCondition: {

                terms: [{ category: kind.id, tags: [fishingVessel.id], op: 'anyOf' }]
            }
        }
    }

    const researchtraining: Tag = {

        ...tags.newTag(detailsType),
        category: type.id,
        id: 'TG-researchtraining-type',
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Research-Training'),
        description: mockMultilang('Research-Training'),
        code:'RT',
        properties: {

            includeCondition: {

                terms: [{ category: kind.id, tags: [fishingVessel.id], op: 'anyOf' }]
            }
        }
    }

    const supply: Tag = {

        ...tags.newTag(detailsType),
        category: type.id,
        id: 'TG-supply-type',
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Supply Vessel (Purse Seiner)'),
        description: mockMultilang('Supply Vessel (Purse Seiner)'),
        code:'SP',
        properties: {

            includeCondition: {

                terms: [{ category: kind.id, tags: [supplyVessel.id], op: 'anyOf' }]
            }
        }
    }

    const trawler: Tag = {

        ...tags.newTag(detailsType),
        category: type.id,
        id: 'TG-trawler-type',
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Trawler'),
        description: mockMultilang('Trawler'),
        code:'TW',
        properties: {

            includeCondition: {

                terms: [{ category: kind.id, tags: [fishingVessel.id], op: 'anyOf' }]
            }
        }
    }

    const unknowntype: Tag = {

        ...tags.newTag(detailsType),
        category: type.id,
        id: 'TG-unknown-type',
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Unknown'),
        description: mockMultilang('Unknown'),
        code:'UN',
        properties: {

            includeCondition: {

                terms: [{ category: kind.id, tags: [fishingVessel.id], op: 'anyOf' }]
            }
        }
    }


    const operatingRange: TagCategory = {

        ...cats.newCategory(detailsType),
        id: vesselRangeCategory,

        predefined: true,
        lifecycle: pastLifecycle<CategoryLifecycleState>('active'),

        cardinality: cardinalities.one,

        name: mockMultilang("Operating Range"),
        description: mockMultilang("The operating range of the vessel."),

        properties: {
            field: {
                ordinal: 0,
                enabled: true,
            }
        }

    }

    const eez: Tag = {

        ...tags.newTag(detailsType),
        category: operatingRange.id,
        id: 'TG-eez-range',
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('EEZ'),
        code: 'EEZ'
    }

    const highseas: Tag = {

        ...tags.newTag(detailsType),
        category: operatingRange.id,
        id: 'TG-highseas-range',
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('High Seas'),
        code: 'High Seas',
    }

    const eezAndhighseas: Tag = {

        ...tags.newTag(detailsType),
        category: operatingRange.id,
        id: 'TG-eezhighseas-range',
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('EEZ and High Seas'),
        code: 'EEZ and High Seas',
    }

    const gear: TagCategory = {

        ...cats.newCategory(detailsType),
        id: vesselGearCategory,

        predefined: true,
        lifecycle: pastLifecycle<CategoryLifecycleState>('active'),

        cardinality: cardinalities.atLeastOne,

        name: mockMultilang("Fishing Gear"),
        description: mockMultilang("A classification of fishing gear types."),

        properties: {
            includeCondition: {

                terms: [{ category: kind.id, tags: [fishingVessel.id], op: 'anyOf' }]
            },
            field: {
                ordinal: 30,
                enabled: true,
            }
        }

    }


    const longline: Tag = {

        ...tags.newTag(detailsType),
        category: gear.id,
        id: 'TG-longline-gear',
        code:"LL",
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Long Line'),
        description: mockMultilang('Long Line.')
    }

    const gillnet: Tag = {

        ...tags.newTag(detailsType),
        category: gear.id,
        id: 'TG-gillnet-gear',
        code:"GI",
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Gillnet'),
        description: mockMultilang('Gillnet.')
    }

    const driftnet: Tag = {

        ...tags.newTag(detailsType),
        category: gear.id,
        id: 'TG-driftnet-gear',
        code:"DR",
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Driftnet'),
        description: mockMultilang('Driftnet.')
    }

    const handline: Tag = {

        ...tags.newTag(detailsType),
        category: gear.id,
        id: 'TG-handpoleline-gear',
        code:"HAND",
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Handline'),
        description: mockMultilang('Handline.')
    }


    const polenadline: Tag = {

        ...tags.newTag(detailsType),
        category: gear.id,
        id: 'TG-polenadline-gear',
        code:"PL",
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Pole and line'),
        description: mockMultilang('Pole and line.')
    }

    

    const purseeines: Tag = {

        ...tags.newTag(detailsType),
        category: gear.id,
        id: 'TG-purseseines-gear',
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        code:"PS",
        name: mockMultilang('Purse seine'),
        properties: {

            includeCondition: {

                terms: [{ category: type.id, tags: [purseseiner.id], op: 'anyOf' }]
            }
        }
    }

    const midwatertrawl: Tag = {

        ...tags.newTag(detailsType),
        category: gear.id,
        id: 'TG-midwatertrawl-gear',
        code:"MWT",
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Mid-water trawl'),
        description: mockMultilang('Mid-water trawl.')
    }

    const rodandreel: Tag = {

        ...tags.newTag(detailsType),
        category: gear.id,
        id: 'TG-rodandreel-gear',
        code:"RR",
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Rod & reel'),
        description: mockMultilang('Rod & reel.')
    }

    const troll: Tag = {

        ...tags.newTag(detailsType),
        category: gear.id,
        id: 'TG-troll-gear',
        code:"TROL",
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Troll'),
        description: mockMultilang('Troll.')
    }

    const othergear: Tag = {

        ...tags.newTag(detailsType),
        category: gear.id,
        id: 'TG-other-gear',
        code:"TROL",
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Other'),
        description: mockMultilang('Other.')
    }

    const specification: TagCategory = {

        ...cats.newCategory(detailsType),
        id: specificationCategory,

        predefined: true,
        lifecycle: pastLifecycle<CategoryLifecycleState>('active'),

        cardinality: cardinalities.atLeastOne,

        name: mockMultilang("Specifications"),
        description: mockMultilang("The physical measures or characteristics of the vessel."),

        properties: {
            field: {
                ordinal:40,
                enabled: true,
            }
        }

    }

    const loa: Tag = {

        ...tags.newTag(detailsType),
        category: specification.id,
        id: loaTag,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('LOA'),
        description: mockMultilang('The length overall of the vessel (boa to aft), in metres.')
    }

    const tonnage: Tag = {

        ...tags.newTag(detailsType),
        category: specification.id,
        id: tonnageTag,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('GT'),
        description: mockMultilang('Weight of the vessel, in gross tons.')
    }

    const totalVolume: Tag = {

        ...tags.newTag(detailsType),
        category: specification.id,
        id: totalVolumeTag,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Total Volume'),
        description: mockMultilang('Total volume of fish hold/s, in cubic metres.')
    }


    const contactType: TagCategory = {

        ...cats.newCategory(detailsType),
        id: contactTypeCategory,

        predefined: true,
        lifecycle: pastLifecycle<CategoryLifecycleState>('active'),

        cardinality: cardinalities.atLeastOne,

        name: mockMultilang("Contact"),
        description: mockMultilang("A classification of vessel contact roles."),

        properties: {
            field: {
                enabled: false,
            }
        }

    }


    const owner: Tag = {

        ...tags.newTag(detailsType),
        category: contactType.id,
        id: ownerTag,
        predefined: true,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Owner'),
        description: mockMultilang('The owner of the Vessel.')

    }

    const beneficialOwner: Tag = {

        ...tags.newTag(detailsType),
        category: contactType.id,
        id: beneficialOwnerTag,
        predefined: true,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Beneficial Owner'),
        description: mockMultilang('The beneficial owner of the Vessel.')
    }


    const operator: Tag = {

        ...tags.newTag(detailsType),
        category: contactType.id,
        id: operatorTag,
        predefined: true,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Operator'),
        description: mockMultilang('The operator of the Vessel.')
    }


    const operatingcompany: Tag = {

        ...tags.newTag(detailsType),
        category: contactType.id,
        id: operatingCompanyTag,
        predefined: true,
        lifecycle: pastLifecycle<TagLifecycleState>('active'),
        name: mockMultilang('Operating Company'),
        description: mockMultilang('The operating company of the Vessel.')
    }

    // const registry: TagCategory = {

    //     ...cats.newCategory(delistingType),
    //     id: registryCategory,

    //     predefined: true,
    //     lifecycle: pastLifecycle<CategoryLifecycleState>('active'),

    //     cardinality: cardinalities.one,

    //     name: mockMultilang("Registry"),
    //     description: mockMultilang("Known vessel registries."),

    //     properties: {
    //         color: mocks.randomColor(),
    //         field: {
    //             enabled: true,
    //         }
    //     }

    // }


    // const deletionReason: TagCategory = {

    //     ...cats.newCategory(delistingType),
    //     id: deletionReasonCategory,

    //     predefined: true,
    //     lifecycle: pastLifecycle<CategoryLifecycleState>('active'),

    //     cardinality: cardinalities.one,

    //     name: mockMultilang("Deletion Reason"),
    //     description: mockMultilang("Reasons for removal from registries."),

    //     properties: {
    //         color: mocks.randomColor(),
    //         field: {
    //             enabled: true,
    //         }
    //     }

    // }

    
    const tenantype : TagCategory = catmocks.mockCategory('tenant-type', tenantType)

    const tenanttypetags = {

        coastal: tagmocks.mockTag('Coastal State', tenantype.id, tenantType)
        ,
        port: tagmocks.mockTag('Port State', tenantype.id, tenantType)
        ,
        market: tagmocks.mockTag('Market State', tenantype.id, tenantType)
        ,
        flag: tagmocks.mockTag('Flag State', tenantype.id, tenantType)
    }

    const userprofile =catmocks.mockCategory('user-profile', userType)
    const usergroup = catmocks.mockCategory('user-group', userType)


    const userprofiletags = {

        operations: tagmocks.mockTag('Operations', userprofile.id, userType),
        marketing: tagmocks.mockTag('Marketing', userprofile.id, userType),
        research: tagmocks.mockTag('Research', userprofile.id, userType),
        admin: tagmocks.mockTag('Admin', userprofile.id, userType)

    }

    const usergrouptags = {
        field: tagmocks.mockTag('Field', usergroup.id, userType),
        hq: tagmocks.mockTag('HQ', usergroup.id, userType),
        roaming: tagmocks.mockTag('Roaming', usergroup.id, userType)
    }



    const self = {

       
        categories: {

            context,
            scheme,
            kind, type, gear, operatingRange,
            contactType,
            specification,
            photographs,
            tenantype,
            userprofile,
            usergroup
            //registry,
            //deletionReason

        }

        ,

        tags: {
            
            ravctx, activelistctx,
            imo, tuvi, iotcnumber,  ircs, regno,
            fishingVessel, carrierVessel, supplyVessel,
            eez, highseas, eezAndhighseas,
            longliner, purseseiner, liner, poleandline, cargofreezer, gillnetter, longlinerpolenadline
            ,longlinercarrier, multipurpose,researchtraining, supply, trawler,unknowntype,
            longline, handline, purseeines, gillnet,driftnet,polenadline,midwatertrawl, rodandreel, troll, othergear,
            loa, tonnage, totalVolume,
            owner, beneficialOwner, operator, operatingcompany,
            starboard, portside, bow,
            ...tenanttypetags,
            ...userprofiletags,
            ...usergrouptags
        }

        ,

        stage: () => {

            catstore || console.log('staging tags and categories...')

            catmocks.categoryStore().addMany(...catstore?.all ?? Object.values(self.categories))

            
            tagmocks.tagStore().addMany(...tagstore?.all ?? Object.values(self.tags))
        }

    }
    return self

}

