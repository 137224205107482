import { Language, languageOf, useLanguage, useT } from 'apprise-frontend-core/intl/language';
import { Label, LabelProps } from 'apprise-ui/label/label';
import { LanguageIcon } from 'apprise-ui/utils/icons';

export type LanguageLabelProps = LabelProps & Partial<{

    language: Language
    displayMode: 'text' | 'code'
}>



export const LanguageLabel = (props:LanguageLabelProps) => {

    const t = useT()
    const lang = useLanguage()

    const {language = lang.default(), displayMode = 'text', ...rest} = props

    const title = displayMode === 'text' ? t(languageOf(language)) : language
    const modeDefaulted =displayMode === 'code' ? 'tag' : undefined

    return <Label icon={<LanguageIcon/>} title={title}  mode={modeDefaulted} {...rest} />

}