import { AuthorizationJsonSerialization, useAuthorizationJsonSerializer } from '#authorization/jsonserializer';
import { DetailsJsonSerialization, useDetailsJsonSerializer } from '#details/jsonserializer';
import { usePhotographJsonSerializer } from '#photographs/jsonserializer';
import { JsonSerializationProps, useGenericJsonSerializer } from '#record/jsonserializer';
import { BaseComboPatch } from './mode';



// types the json row produced by a parse with the keys specified in config.
export type BaseComboJsonSerialization = DetailsJsonSerialization & AuthorizationJsonSerialization


export const useBaseComboJsonSerializer = () => {

    const detailJsonSerializer = useDetailsJsonSerializer()
    const authorizationJsonSerializer = useAuthorizationJsonSerializer()
    const photoJsonSerializer = usePhotographJsonSerializer()

    const serializer = (props: JsonSerializationProps<BaseComboPatch>): BaseComboJsonSerialization => ({
 
        ...detailJsonSerializer(props),
        ...authorizationJsonSerializer(props),
        ...photoJsonSerializer(props)
         
    })

    return useGenericJsonSerializer(serializer)
}