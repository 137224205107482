import { RecordPatch, RecordSlot } from '#record/model'
import { DeepPartial } from 'apprise-frontend-core/utils/common'
import { TenantReference } from 'apprise-frontend-iam/tenant/model'
import { TagReference } from 'apprise-frontend-tags/tag/model'
import shortid from 'shortid'
import { trxauthzType } from './constants'

export type TrxAuthPatch = RecordPatch<typeof trxauthzType> 

export type TrxAuthzs = RecordSlot & {

    authzs: Record<TenantReference, TrxAuthz[]>

  
}

export type TrxAuthz = {

    timestamp: string
    type: TagReference
    from: string
    to: string

    revocation?: boolean
}

export type TrxAuthzPeriod = {

}


// minimal skeleton for missing slots.
export const newTrxAuthzSlot = () => {

    const partials : DeepPartial<TrxAuthzs> = {

        id: `TRX-${shortid()}`,
        authzs: {}

    } 

    return partials as TrxAuthz
}