import { createContext, PropsWithChildren, useMemo } from "react";
import { useFocusMode } from './focusmode';


export type FocusModeState = {
    toggleFocusMode: () => void
    focusModeEnabled: boolean
}

export const FocusModeContext = createContext<FocusModeState>(undefined!);


export type FocusModeProps = PropsWithChildren<Partial<{
    hackAutoResize: boolean
    className: string
}>>

export const FocusModeProvider = (props: FocusModeProps) => {

    const { children, className } = props

    const { elementRef, toggleFocusMode, focusModeEnabled } = useFocusMode();

    const value: FocusModeState = useMemo(() => ({

        toggleFocusMode,
        focusModeEnabled

	// eslint-disable-next-line
    }), [ focusModeEnabled ]);

    return  <div ref={ elementRef } className={ className } style={{ height: '100%' }}>
        <FocusModeContext.Provider value={ value }>
            { children }
        </FocusModeContext.Provider>
    </div>
}


