import { RecordIssue } from '#submission/validator';
import { useVID } from '#vid/model';
import { GenericRecord, RavRecord } from './model';


export type JsonSerializationMode = 'summary' | 'full'


export type JsonSerializationProps <T extends GenericRecord>  = {

    patch: T, 
    current: RavRecord | undefined, 
    issues: RecordIssue[]
    mode: JsonSerializationMode
}

export type JsonRecordSerializer <T extends GenericRecord> = (props: JsonSerializationProps<T>) => any



export const useGenericJsonSerializer = <T extends GenericRecord> (customSerializer: JsonRecordSerializer<T>) => {

    const vid = useVID()

    return (props: JsonSerializationProps<T>)  => {

        const { patch, current } = props 

        return { 
            
            uvi : vid.valueOf(patch?.uvi), // using current.uvi is sensical, but at dev time we can work with real patches without their current records.
            name: current?.details?.name,
            ...customSerializer(props),
            issues: props.issues
        }   

    }

}