
import { useIOTCVid } from '#iotc/vid'
import { RecordSearch } from '#search/search'
import { NoSuchRoute } from 'apprise-ui/link/nosuchroute'
import { Redirect, Route, Switch } from 'react-router'
import { NewVessel, VesselDetail } from './detail'
import { recordRoute, useVesselRouting } from './routing'




export const RecordRouter = () => {

    const routing = useVesselRouting()
    const helper = useIOTCVid()

    return <Switch>

        <Route exact path={routing.searchRoute()}>
            <RecordSearch />
        </Route>

        <Route path={routing.newRoute()}>
            <NewVessel />
        </Route>

        <Route exact path={`${routing.detailRoute()}(\\d+)`} render={({ match }) => <Redirect to={`${recordRoute}/${helper.formatUvi(match.params['uvi'])}`} />} ></Route>

        <Route path={routing.detailRoute()}>
            <VesselDetail uvi={routing.uviInRoute()} historySlot={routing.historyInRoute()} age={routing.ageInRoute()} />
        </Route>

        <NoSuchRoute />

    </Switch>
}