import { EventSubscription } from "apprise-frontend-events/subscriptions"
import { useCurrentLocation } from 'apprise-ui/link/currentlocation'
import { useHistory } from 'react-router-dom'
import { tenantChangeTopic } from './constants'
import { Tenant } from './model'
import { useTenantStore } from './store'



export type TenantChangeEvent = {
    tenant: Tenant
    type: 'add' | 'remove' | 'change'
}

export const useTenantChangeListener = (): EventSubscription<TenantChangeEvent> => {

    const tenantstore = useTenantStore()

    const location = useCurrentLocation()

    const history = useHistory()
   
    return {

        topic: tenantChangeTopic

        ,

        onEvent: (event: TenantChangeEvent) => {


            tenantstore.push(event)

            history.replace(location.current(), `${event.tenant.id}-${event.type}`)
                

        }

    }

}