import { useRavTags } from '#stage/tags'
import { useVIDUtils } from '#vid/model'
import { useMocks } from 'apprise-frontend-core/client/mocks'
import { TenantReference } from 'apprise-frontend-iam/tenant/model'
import { useTagMocks } from 'apprise-frontend-tags/tag/mockery'
import shortid from 'shortid'
import { beneficialOwnerTag, vesselGearCategory, fishingKindTag, loaTag, operatingCompanyTag, vesselRangeCategory, operatorTag, ownerTag, tonnageTag, totalVolumeTag, vesselTypeCategory } from './constants'
import { Contact, Details } from './model'

export const useDetailsMocks = () => {

    const mocks = useMocks()
    const tags = useTagMocks()
    const ravTags = useRavTags()
    const vids = useVIDUtils()


    const getRandomIMO = () => {
        const id = `${ mocks.randomNumberBetween(100001, 999999) }`
        const sum = `${ Array.from({ length: 6 }).map((_, i) => i).reduce((s, i) => s + ( parseInt(id.charAt(i)) * (7 - i)), 0) }`

        return `${ id }${ sum.charAt(sum.length-1) }`
    }

    const self = {

        store: () => mocks.getOrCreateStore<Details>('details', { id: m => m.id })

        ,

        mockSlot: (props: { current?: Details, tenant: TenantReference, timestamp?: string, name: string }): Details => {

            const { current, timestamp, tenant, name } = props

            const tt = ravTags.tags

            const identifiers = [tt.imo, tt.regno, tt.ircs].map((t, i) => vids.stringify(t.id,
                i ? `${t.name.en}-${mocks.randomNumberBetween(1, 50000)}` : getRandomIMO()
            ))

            return {

                id: shortid(),
                timestamp: timestamp ?? mocks.randomDateSince(new Date(2017, 0, 1)).toISOString(),
                name,
                flagstate: tenant,
                identifiers: current?.identifiers ?? identifiers,
                port:  current?.port && mocks.randomBoolean(.7)  ? current.port : {name: `Port-${shortid()}`, code: mocks.randomBoolean(.7) ? undefined : `PortCode-${shortid()}`, flag: tenant},
                vesselKind: fishingKindTag,
                vesselType: current?.vesselType && mocks.randomBoolean(.5) ? current.vesselType : mocks.randomIn(tags.tagStore().all().filter(tag => tag.category === vesselTypeCategory))?.id,
                contacts: [{
                    id: `C-${shortid()}`,
                    type: ownerTag,
                    name: mocks.randomBoolean(.7) ?  "Captain Jerico" : "Bluebeard",
                    address: mocks.randomBoolean(.7) ? "101 Desolation Row" : "66 Marlboro Street"
                   
                },
                {
                    id: `C-${shortid()}`,
                    type: beneficialOwnerTag,
                    name: mocks.randomBoolean(.7) ?  "Sue Nelson" : "Alexander Volt",
                    address: mocks.randomBoolean(.7) ? "89 Abbey Road" : "5 Sunset Boulevard"
                   
                },
                {
                    id: `C-${shortid()}`,
                    type: operatorTag,
                    name: mocks.randomBoolean(.7) ? "Baron Ephelius" : "Jetsy Blooms",
                    address: mocks.randomBoolean(.7) ? "105 Miracle Street" : "11 Elm's End"
                   
                },           
                {
                    id: `C-${shortid()}`,
                    type: operatingCompanyTag,
                    name: mocks.randomBoolean(.7) ?  "Ocean Ways" : "Hashicorp",
                    address: mocks.randomBoolean(.7) ? "45 Slippery Road" : "9 Hell's Kitchen"
                   
                } as Contact] ,
                state: 'active',
                range: current && mocks.randomBoolean(.7) ? current.range : mocks.randomIn(tags.tagStore().all().filter(tag => tag.category === vesselRangeCategory))?.id,
                gears: current && mocks.randomBoolean(.7) ? current.gears : mocks.randomSlice(tags.tagStore().all().filter(tag => tag.category === vesselGearCategory).map(g => g.id), 1, 2),
                specifications: {
                    [loaTag]: current && mocks.randomBoolean(.7) ? current?.specifications[loaTag]! : `${mocks.randomNumberBetween(10, 199)}`,
                    [tonnageTag]: current && mocks.randomBoolean(.7) ? current?.specifications[tonnageTag]! : `${mocks.randomNumberBetween(100, 9999)}`,
                    [totalVolumeTag]: current && mocks.randomBoolean(.7) ? current?.specifications[totalVolumeTag]! :`${mocks.randomNumberBetween(50, 999)}`
                }

                ,

                priorHistory:{
                    name: "PriorName",
                    flags: "",
                    deletions:""
                }
            }

        }


    }

    return self
}