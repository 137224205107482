
import { useT } from 'apprise-frontend-core/intl/language'
import { Lifecycled } from 'apprise-frontend-core/utils/lifecycle'
import { Label } from 'apprise-ui/label/label'
import * as React from 'react'
import { AiOutlineFilter } from 'react-icons/ai'
import { OptionMenu } from '../optionmenu/optionmenu'
import { ContextualFilterProps, useFilterState } from './filter'

type BaseProps<T> = ContextualFilterProps & {

    data: T[]
    isReadonly: (_: T) => boolean
}

const defaultActive = [0, 1]

export const useBaseFilter = <T extends Lifecycled>(props: BaseProps<T>) => {

    const t = useT()

    const { context, contextKey = 'base', data = [], isReadonly } = props

    const { get, set } = useFilterState<number[]>(context)

    const active = get(contextKey) ?? defaultActive
    const setActive = set(contextKey)

    const BaseFilter = <OptionMenu active={active} setActive={setActive} noSelectAll label={<Label icon={<AiOutlineFilter />} title={t("ui.menu.base_title")} />} >
        <OptionMenu.Option key={0} value={0} title={t("ui.menu.base_inactive")} />
        <OptionMenu.Option key={1} value={1} title={t("ui.menu.base_readonly")} />
    </OptionMenu>

    const isActive = (e: T) => e.lifecycle.state === 'active' as any

    const baseFilter = React.useCallback(

        (e: T) => (active.includes(0) || isActive(e)) && (active.includes(1) || !isReadonly(e))

        // eslint-disable-next-line     
        , [active])                   // recompute on filter change


    const baseFilteredData = React.useMemo(() => data.filter(baseFilter), [data,baseFilter])


    return { BaseFilter, baseFilter, baseFilteredData, active, setActive }
}