import { iotcnumberscheme } from '#iotc/constants';
import { Card } from '#record/card';
import { PatchCardProps } from '#submission/patchdetail';
import { useValidationIssueIndex } from '#submission/validator';
import { DateLabel } from '#utils/datelabel';
import { useVID } from '#vid/model';
import { useT } from 'apprise-frontend-core/intl/language';
import { TagLabel } from 'apprise-frontend-tags/tag/label';
import { useTagStore } from 'apprise-frontend-tags/tag/store';
import { classname } from 'apprise-ui/component/model';
import { Grid } from 'apprise-ui/grid/grid';
import { Label } from 'apprise-ui/label/label';
import { NamedProperty } from 'apprise-ui/utils/namedproperty';
import { trxAuthzTypeCategory } from './constants';
import { TrxAuthPatch } from './model';
import './styles.scss';


export const TrxAuthzPatchCard = (props: PatchCardProps<TrxAuthPatch>) => {

    const t = useT()

    const { current, record } = props

    const vid = useVID()
    const issues = useValidationIssueIndex()

    const authz = Object.values(record.trxauthz.authzs).flat()[0]

    const revokedClass = classname(authz?.revocation && 'trxauthz-revoked')

    const tags = useTagStore()

    return <Card minWidth={600}>
        <Grid rowGap={8} columnGap={10} >

            <Grid.Row>
                <NamedProperty status={issues.uvi} name={<TagLabel bare noIcon tag={tags.allTags().find(t => t.id === iotcnumberscheme)} />}>
                    <Label noIcon title={vid.valueOf(record.uvi)} />
                </NamedProperty>
            </Grid.Row>

            <Grid.Row>
                <NamedProperty className={revokedClass} status={issues[trxAuthzTypeCategory]} name={t('trxauthz.type_col')}>
                    <TagLabel noIcon bare tag={authz?.type} />
                </NamedProperty>
            </Grid.Row>

            <Grid.Row>
                <NamedProperty className={revokedClass} status={issues.range ?? issues.from} name={t('trxauthz.from_col')}>
                    <DateLabel noIcon bare date={authz?.from} />
                </NamedProperty>
            </Grid.Row>

            <Grid.Row>
                <NamedProperty className={revokedClass} status={issues.range ?? issues.to} name={t('trxauthz.to_col')}>
                    <DateLabel displayMode={current ? 'expiry' : 'normal'} noIcon date={authz?.to} />
                </NamedProperty>
            </Grid.Row>

        </Grid >
    </Card>
}
