import { RavConfig } from '#config';
import { bowTag, portsideTag, starboardTag } from '#details/constants';
import { SubmissionParseContext, useGenericPatchParser } from '#record/parser';
import { useConfig } from 'apprise-frontend-core/config/api';
import { ModelParser, ParseContext } from 'apprise-frontend-parse/model';
import { useDefaultModelParser, useDefaultParser } from 'apprise-frontend-parse/resource';
import { useDefaultBookParser } from 'apprise-frontend-parse/workbook';
import { photographType } from './constants';
import { newPhotoSlot, Photo, PhotographsPatch } from './model';
import { PhotographsJsonSerialization } from './jsonserializer';

export const usePhotographsParser = () => {

  const config = useConfig<RavConfig>()

  const modelparser: ModelParser<PhotographsPatch, ParseContext<SubmissionParseContext>> = useDefaultModelParser(usePhotographsItemParser())

  const bookparser = useDefaultBookParser(modelparser, () => config, photographType)

  return useDefaultParser({ modelparser, bookparser })

}


export const usePhotographsItemParser = () => {

  const parser = (row: PhotographsJsonSerialization) => {

    const { starboard, portside, bow } = row

    const photos: Photo[] = []

    starboard && photos.push({
      type: starboardTag,
      ref: starboard
    })

    portside && photos.push({
      type: portsideTag,
      ref: portside
    })

    bow && photos.push({
      type: bowTag,
      ref: bow
    })

    const patch: Partial<PhotographsPatch> = {

      patchedSlots: [photographType],

      [photographType]: {...newPhotoSlot(), photos}
    }

    return patch as PhotographsPatch
  }

  return useGenericPatchParser(parser)

}