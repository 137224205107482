import { authorizationType } from '#authorization/constants';
import { useAuthorizationRowParser } from '#authorization/parser';
import { RavConfig } from '#config';
import { detailsType } from '#details/constants';
import { useDetailsRowParser } from '#details/parser';
import { photographType } from '#photographs/constants';
import { usePhotographsItemParser } from '#photographs/parser';
import { SubmissionParseContext, useGenericPatchParser } from '#record/parser';
import { useConfig } from 'apprise-frontend-core/config/api';
import { ParseContext } from 'apprise-frontend-parse/model';
import { useDefaultModelParser, useDefaultParser } from 'apprise-frontend-parse/resource';
import { useDefaultBookParser } from 'apprise-frontend-parse/workbook';
import { BaseComboPatch } from './mode';
import { BaseComboJsonSerialization } from './jsonserializer';



export const useBaseComboParser = () => {

  const config = useConfig<RavConfig>()

  const modelparser = useDefaultModelParser(useBaseComboRowParser())

  const bookparser = useDefaultBookParser(modelparser, () => config, detailsType, authorizationType, photographType)
 
  return useDefaultParser({ modelparser, bookparser })

}


const useBaseComboRowParser = () => {

  const detailParser = useDetailsRowParser()
  const authorizationParser = useAuthorizationRowParser()
  const photoParser = usePhotographsItemParser()

  // we do our best to construct patchrd from rowd.
  // where we don't manage, we force a value (undefined, empty array, empty objects, etc)
  // and rely on validation to catch the errors.

  const parser = (row: BaseComboJsonSerialization, ctx: ParseContext<SubmissionParseContext>) => {

    const details = detailParser(row, ctx)[detailsType]
    const authorization = authorizationParser(row, ctx)[authorizationType]
    const photographs = photoParser(row, ctx)[photographType]

    const patch: Partial<BaseComboPatch> = {

      patchedSlots: [detailsType, authorizationType, photographType],

      [detailsType]: details,
      [authorizationType]: authorization,
      [photographType]: photographs

    }

    return patch

  }

  return useGenericPatchParser(parser)

}

