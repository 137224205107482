
import { useT } from 'apprise-frontend-core/intl/language'
import { Button } from 'apprise-ui/button/button'
import { Label } from 'apprise-ui/label/label'
import { SidebarContent } from 'apprise-ui/page/sidebar'
import { Table } from 'apprise-ui/table/table'
import { useTableUtils } from 'apprise-ui/table/utils'
import { RemoveItemIcon, SearchIcon, TextIcon } from 'apprise-ui/utils/icons'
import React from 'react'
import { Query, SavedQuery } from './model'


export type SavedQueryRow = SavedQuery & {

    disabled: boolean
    highlighted: boolean
}

export type SavedQuerySiderProps = {

    queries: SavedQueryRow[]

    onSelect: (_: Query) => any
    onEdit: (_: SavedQuery) => any
    onRemove: (_: SavedQuery) => any
}

export const SavedQuerySider = (props: SavedQuerySiderProps) => {

    const { queries, onSelect, onEdit, onRemove } = props

    const t = useT()

    const { Column } = useTableUtils<SavedQueryRow>()

    return <div style={{ height: 'calc(50% - 34px)' }}>

        <SidebarContent.Title>{t('search.query_save_title')}</SidebarContent.Title>

        <Table.Sider<SavedQuery> name='queries' fixedHeight noFilter emptyPlaceholder={t("search.query_placeholder")} rowId={i => i.name} data={queries} mountDelay={0} >

            <Column render={row => {

                const { name, query, disabled, highlighted } = row

                const content = <Label icon={<SearchIcon />} title={name} highlighted={highlighted} />

                return highlighted ? content : <Button tip={t('search.query_load_tip')} disabled={disabled} readonly={highlighted} onClick={() => onSelect(query)}>{content}</Button>

            }} />

            <Column width={40} align='right' render={query =>

                <div className='apprise-row'>
                    <Button tip={t('search.query_edit_tip')} className='query-action' disabled={query.disabled} onClick={() => onEdit(query)}>{<TextIcon color={query.disabled ? 'inherit' : 'lightseagreen'} />}</Button>
                    &nbsp;
                    <Button tip={t('search.query_remove_tip')} className='query-action' disabled={query.disabled} onClick={() => onRemove(query)}>{<RemoveItemIcon color={query.disabled ? 'inherit' : 'orange'} />}</Button>
                </div>

            } />

        </Table.Sider>

    </div>


}