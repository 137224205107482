import { DateLabel } from '#utils/datelabel';
import { SlotCardProps } from '#vessel/overview';
import { useT } from 'apprise-frontend-core/intl/language';
import { utils } from 'apprise-frontend-core/utils/common';
import { TenantLabel } from 'apprise-frontend-iam/tenant/label';
import { useTenantUtils } from 'apprise-frontend-iam/tenant/utils';
import { TagLabel } from 'apprise-frontend-tags/tag/label';
import { classname } from 'apprise-ui/component/model';
import { Grid } from 'apprise-ui/grid/grid';
import { NamedProperty } from 'apprise-ui/utils/namedproperty';
import isAfter from 'date-fns/isAfter';
import { Fragment } from 'react';
import { TrxAuthPatch } from './model';

export const TrxAuthzOverview = (props: SlotCardProps<TrxAuthPatch>) => {

    const t = useT()

    const { isCurrent, record } = props

    const tenants = useTenantUtils()


    const authzs = record.trxauthz.authzs
    const grantedBy = Object.keys(authzs).sort(tenants.refComparator)
    const mostRecent = Object.values(authzs).flat().map(a => a.timestamp).reduce((prev, next) => (!prev || isAfter(Date.parse(next), Date.parse(prev))) ? next : prev ,undefined! as string)

    const now = Date.now()
    
    return <Grid rowGap={8} columnGap={10} >

        {grantedBy.map(tid => {

            const authz = [...authzs[tid]].sort(utils().compareDatesOf( a=> a.timestamp)).filter( a => isCurrent ? isAfter(Date.parse(a.to), now) : a).reverse()

            return <Fragment key={tid}>
                
                {authz.map( (authz,i) =>{

                    const classes = classname( authz.timestamp===mostRecent && 'trxauthz-latest', isAfter(now, Date.parse(authz.to)) && 'trxauthz-expired')

                    return <Grid.Row key={i} height='auto' className={classes} >
                        <Grid.Col width={90}>
                            <NamedProperty valueAlign='right' >
                                <DateLabel titleStyle={{color:'gray'}} noIcon  date={authz.timestamp} />
                            </NamedProperty>
                        </Grid.Col>
                        <Grid.Col width='4fr'>
                            <NamedProperty>
                                <TenantLabel bare noIcon tenant={tid} />
                            </NamedProperty>
                        </Grid.Col>
                        <Grid.Col>
                            <NamedProperty name={t('trxauthz.type_col')}>
                                <TagLabel bare noIcon tag={authz.type} />
                            </NamedProperty>
                        </Grid.Col>
                        <Grid.Col>
                            <NamedProperty name={t('trxauthz.from_col')}>
                                <DateLabel noIcon date={authz.from} />
                            </NamedProperty>
                        </Grid.Col>
                        <Grid.Col>
                            <NamedProperty name={t('trxauthz.to_col')}>
                                <DateLabel displayMode={isCurrent ? 'expiry' : 'normal'} noIcon date={authz.to} />
                            </NamedProperty>
                        </Grid.Col>
                    </Grid.Row>
            })}

            </Fragment>
        })}
    </Grid>
}
