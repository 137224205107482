import { utils } from 'apprise-frontend-core/utils/common'
import { Component, useComponentProps } from 'apprise-ui/component/component'
import { Debugged, Styled, Tall, Wide } from 'apprise-ui/component/model'
import partition from 'lodash/partition'
import React, { CSSProperties, PropsWithChildren } from 'react'
import './styles.scss'



type BaseProps<T> = Debugged & Styled & PropsWithChildren<Partial< Gapped & T>>

export type GridProps = BaseProps<Aligned & Tall & Wide >


export const Grid = (clientrops: GridProps) => {

    const props = useComponentProps(clientrops)

    const { debug, style, align, justify, children, height, width, rowGap, columnGap, ...rest } = props

    const [rows, other] = partition<JSX.Element>(utils().elementsIn(children), utils().isElementOf(Grid.Row))

    const otherRow = <Grid.Row>
        {other}
    </Grid.Row>

    const allrows = other.length > 0 ? [...rows, otherRow] : rows

    const gridTemplateRows = allrows.map(r => widthOf(r.props.height) ?? 'max-content').join(' ')

    const sstyle: React.CSSProperties = {
        height, width, rowGap, gridTemplateColumns: '1fr', gridTemplateRows, ...style
    }

    debug && console.log({ children, rows, gridTemplateRows })

    const inherited = { rowGap, columnGap, align,justify }

    return <Component name='grid' style={sstyle} {...rest}>
        {allrows.map((r, key) => React.cloneElement(r, { key, ...inherited, ...r.props}))}
    </Component>
}


export type RowProps = BaseProps<Aligned & Tall>


Grid.Row = function Row(clientprops: RowProps) {

    // note: width is extracted by Grid and must NOT be applied again here. so ignore.
    const { debug, align, justify, style, height, children, rowGap, columnGap, ...rest } = clientprops

    const [columns, other] = partition<JSX.Element>(utils().elementsIn(children), utils().isElementOf(Grid.Col))

    const otherCol = <Grid.Col>
        {other}
    </Grid.Col>

    const cols = other.length > 0 ? [...columns, otherCol] : columns

    const gridTemplateColumns = cols.map(c => widthOf(c.props.width) ?? '1fr').join(' ')

    const sstyle: React.CSSProperties = { columnGap, gridTemplateColumns, gridTemplateRows: '1fr', ...style }

    const inherited = { rowGap, columnGap, align,justify }

    debug && console.log({ children, cols, gridTemplateColumns })

    return <Component style={sstyle} name='gridrow' {...rest}>
        {cols.map((c, key) => React.cloneElement(c, { key, ...inherited, ...c.props}))}
    </Component>
}



export type ColpProps = BaseProps<Aligned & Wide>

Grid.Col = function Col(clientrops: ColpProps) {

    const props = useComponentProps(clientrops)

    const { children, align='start', width, justify, className, style: clientstyle, rowGap, columnGap, ...rest } = props

    const includeRows = utils().elementsIn(children).find(utils().isElementOf(Grid.Row))

    const style = {

        alignSelf: align,
        justifySelf: justify,
        ...clientstyle
    }

    return includeRows ?

        <Component name='gridcol' style={style} className={className} >
            <Grid style={{ height: '100%', width: '100%' }} align={align} justify={justify} rowGap={rowGap} columnGap={columnGap} {...rest} >
                {children}
            </Grid>
        </Component>

        :

        <Component name='gridcol' style={style} className={className}  {...rest}>
            {children}
        </Component>

}

const widthOf = width => typeof width === 'number' ? `${width}px` : width


export type Aligned = {

    align: CSSProperties['alignSelf']
    justify: CSSProperties['justifySelf']
}

export type Gapped = {

    rowGap: CSSProperties['rowGap']
    columnGap: CSSProperties['columnGap']
    
}