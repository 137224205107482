import { useT } from "apprise-frontend-core/intl/language"
import { useL } from "apprise-frontend-core/intl/multilang"
import { noTenant } from "apprise-frontend-iam/tenant/model"
import { useTenantStore } from "apprise-frontend-iam/tenant/store"
import { SheetWriter, useWorkbookWriter } from "apprise-frontend-parse/workbookWriter"
import { TagCategory } from "apprise-frontend-tags/category/model"
import { useCategoryStore } from "apprise-frontend-tags/category/store"
import { useTagStore } from "apprise-frontend-tags/tag/store"
import { Button } from "apprise-ui/button/button"
import { ChoiceBox } from "apprise-ui/choicebox/choicebox"
import { useRoutableDrawer } from "apprise-ui/drawer/drawer"
import { Form } from "apprise-ui/form/form"
import { useTableData } from "apprise-ui/table/provider"
import { Explainer, ExplainerPanel } from "apprise-ui/utils/explainer"
import { DownloadIcon } from "apprise-ui/utils/icons"
import { Fragment, useState } from "react"
import { ImDownload } from 'react-icons/im'
import { UserIcon, userPlural, userType } from "./constants"
import { userTableName } from "./list"
import { User } from "./model"
import { useUserPreferencesModules } from "./modules"



type DownloadMode = 'all' | 'names' | 'codes'

export type DownloadOptions = {

    mode: DownloadMode
}


type Row = Record<string, any>


export const useUserDownload = () => {

    const t = useT()
    const l = useL()

    const module = useUserPreferencesModules().all()

    const { Drawer, openSet } = useRoutableDrawer('user-download')

    const tabledata = useTableData<User>(userTableName)
    const write = useWorkbookWriter()

    const categoryStore = useCategoryStore()

    const categories = categoryStore.allCategoriesOf(userType)
    const categoriesToExcel = useCategoriesToExcel<User>(categories)

    const tenants = useTenantStore()

    const filteredOrSelected = tabledata.selected.length ? tabledata.selected : tabledata.data

    const populationSize = filteredOrSelected.length

    const userDownloadBtn = <Button
        disabled={populationSize < 1}
        icon={<DownloadIcon />}
        iconPlacement='right'
        onClick={() => openSet(true)} >
        {t('user.download.download_btn', { count: populationSize })}
    </Button>

    const renderTenantLbl = (user: User, mode: DownloadMode) => user.tenant !== noTenant ? mode === 'names' ? tenantToName(user.tenant) : tenantToCode(user.tenant) : ''
    const tenantToName = (tenant: string) => l(tenants.safeLookup(tenant).name)
    const tenantToCode = (tenant: string) => tenants.safeLookup(tenant).code
    
    const moduleColumns = module.flatMap(m => m.download ?? [])

    const download = (options: DownloadOptions) => {

        const writer = write.sheetOf(filteredOrSelected)
            .text(t('user.download.username')).render(u => u.username)

        if (options.mode === 'all') {
            writer.text(t('user.download.tenant_name')).render(u => renderTenantLbl(u, 'names'))
                  .text(t('user.download.tenant_code')).render(u => renderTenantLbl(u, 'codes'))
        } else {
            writer.text(t('user.download.tenant')).render(u => renderTenantLbl(u, options.mode))
        }

        writer.text(t('user.download.email')).render(u => u.details.email)
            .text(t('user.download.firstname')).render(u => u.details.firstname)
            .text(t('user.download.lastname')).render(u => u.details.lastname)
            .text(t('user.download.address')).render(u => u.details.preferences.address)
            .text(t('user.download.phone')).render(u => u.details.preferences.phone)
            .text(t('user.download.state')).render(u => u.lifecycle.state)
            .text(t('user.download.account_state')).render(u => u.details.preferences.accountState && t(`account.state_${u.details.preferences.accountState}_label`).toLocaleLowerCase())

        categoriesToExcel(options.mode).addColumnsToWriter(writer)

        moduleColumns.forEach(col => col(writer, options))

        writer.as(t(userPlural)).save(`${t(userPlural).toLocaleLowerCase()}_${new Date().toISOString()}.xlsx`)

    }

    const explainer = <Fragment>
        {t("user.download.explainer1", { count: populationSize })}
        <br/><br/>
        {t("user.download.explainer2")}
    </Fragment>

    const drawerTitle = t("user.download.download_title", { count: populationSize })

    const UserDownloadDrawer = () => <Drawer width={450} title={drawerTitle} icon={<UserIcon />}>
        <DownloadInnerDrawer
            download={download} 
            explainer={explainer}  
        />
    </Drawer>

    return { userDownloadBtn, UserDownloadDrawer }


}



export const useCategoriesToExcel = <T extends Row>(categories: TagCategory[]) => {

    const tagStore = useTagStore()
    const l = useL()
    const t = useT()

    const self = (mode: DownloadMode) => ({

        addColumnsToWriter: (writer: SheetWriter<T>) => {
            categories.forEach(category => {

                const tags = tagStore.allTagsOfCategory(category.id)

                const name = l(category.name)

                if (mode === 'all') {
                    writer.text(t('download.col_name', { name })).render(t => tags.filter(tag => t.tags.includes(tag.id)).map(tag => l(tag.name)).filter(tag => tag && tag.trim() !== '').join(','))
                    writer.text(t('download.col_code', { name })).render(t => tags.filter(tag => t.tags.includes(tag.id)).map(tag => tag.code).filter(tag => tag && tag.trim() !== '').join(','))
                } else {
                    writer.text(l(category.name)).render(t => tags.filter(tag => t.tags.includes(tag.id)).map(tag => mode === 'codes' ? tag.code : l(tag.name)).filter(tag => tag && tag.trim() !== '').join(','))
                }

            })
        }

    })

    return self

}

type DownloadInnerDrawerProps = {
    download: (mode: DownloadOptions) => void, 
    explainer?: JSX.Element | string, 
    disableModeChooser?: boolean
}


export const DownloadInnerDrawer = (props: DownloadInnerDrawerProps) => {

    const { download, explainer, disableModeChooser = false } = props

    const [mode, modeSet] = useState<DownloadMode>('names')

    const t = useT()

    return <ExplainerPanel>
        <Explainer icon={ImDownload}>{explainer && explainer}</Explainer>
        <Form centered>
            <ChoiceBox radio value={mode} onChange={v => modeSet(v ?? 'names')} disabled={disableModeChooser}>
                <ChoiceBox.Option value='names' title={t('download.download_names')} />
                <ChoiceBox.Option value='codes' title={t('download.download_codes')} />
                <ChoiceBox.Option value='all' title={t('download.download_names_codes')} />
            </ChoiceBox>
        </Form>
        <Button type='primary' className='download-btn' icon={<DownloadIcon />} onClick={() => download({mode})}>{t('download.download_now')}</Button>
    </ExplainerPanel>



}