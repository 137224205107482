import { useSearch } from '#search/api'
import { useVID, Vid } from '#vid/model'
import { useT } from 'apprise-frontend-core/intl/language'
import { EventSubscription } from 'apprise-frontend-events/subscriptions'
import { useReloadOnEvent } from 'apprise-frontend-events/utils'
import { useLazyRouting } from 'apprise-ui/utils/routing'
import { useVesselCache } from './cache'
import { useVesselCalls } from './calls'
import { vesselType } from './constants'

export type VesselChangeEvent = {
    uvis: Vid[]
}


export const useVesselChangeListener = (): EventSubscription<VesselChangeEvent> => {

    const vid = useVID()
    const cache = useVesselCache()
    const vessels = useVesselCalls()
    const search = useSearch()

    const t = useT()

    const inform = useReloadOnEvent({ singular: t('vessel.singular'), allowCancel: false })

    const routing = useLazyRouting()

    return {

        topic: vesselType

        ,

        onEvent: (event: VesselChangeEvent) => {

            const { uvis } = event

            console.log("updating vessel cache on notification...", { uvis })

            const route = routing.get()

            // if user is looking at one of the changed vessels, inform it's going to be refetched.
            const uvi = uvis.find(uvi => route.location.pathname.includes(vid.valueOf(uvi) ?? '_nouvi_'))

            if (uvi)
                inform.thenRun(() => vessels.fetchVessel(uvi))
            
            else
                cache.resetForUvis(uvis)


            // if change has impacted results, refresh the results.
            if (search.get().results?.some(r => uvis.includes(r.uvi)))
                search.runQueryQuietly()

          
        }
    }
}



