import * as React from 'react'
import { CategoryProvider } from './category/provider'
import { TagInitializer } from './initializer'
import { TagLoader } from './loader'
import "./styles.scss"
import { TagProvider } from './tag/provider'


export type TagProps = React.PropsWithChildren<Partial<{

    mock: boolean

}>>

export const Tags = (props: TagProps) => {

    return <TagProvider>
        <CategoryProvider>
            <TagInitializer />
            <TagLoader {...props} />
        </CategoryProvider>
    </TagProvider>


}
