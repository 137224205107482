import { Item } from 'apprise-ui/dnd/model'
import { NativeTypes } from 'react-dnd-html5-backend'
import { Droppable, DroppableProps } from '../dnd/droppable'

type FileProps = Omit<DroppableProps<File[]>, 'types'> & Partial<{

    onDrop: (files: File[]) => void

}>


export const FileDroppable = (props: FileProps) => {

    const { onDrop, children, ...rest } = props

    return <Droppable<Item & { files: File[] }> accept={[NativeTypes.FILE]} path='none' onDrop={({ files }) => onDrop?.(files)} {...rest} >
        {props.children}
    </Droppable>

}