
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import endOfDay from 'date-fns/endOfDay'


export const defaultTimezone = 'Indian/Mahe'

export const useDateHelper = () => {


    const self = {

        timezoned: (date:Date|number) => {

            const timezone = defaultTimezone

            return utcToZonedTime(date,timezone)
           
        }
        
        ,

        expired: (date: Date|number) => {

            // this could go on to become a setting, but overkill for now.
            const expired = endOfDay(self.timezoned(date)).getTime() < self.timezoned(new Date()).getTime()

            //console.log({date:new Date(date).getTime(), timezonedDate: timezonedDate.getTime(), now: Date.now(), expired})
        
            return expired
            
        }

    }

    return self
}