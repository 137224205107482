import { DateIcon, EditIcon, UploadIcon } from 'apprise-ui/utils/icons';
import { CSSProperties } from 'react';
import { IconBaseProps } from 'react-icons';
import { AiFillCheckSquare, AiOutlineCheck, AiOutlineNumber, AiOutlinePause } from 'react-icons/ai';
import { BsFillFileZipFill, BsIntersect, BsLayerBackward, BsLayerForward } from 'react-icons/bs';
import { FaExternalLinkSquareAlt, FaRulerVertical } from 'react-icons/fa';
import { FiArrowRightCircle } from 'react-icons/fi';
import { IoEarthOutline } from 'react-icons/io5';
import { MdPhotoCamera } from 'react-icons/md';
import { RiForbid2Line } from 'react-icons/ri';
import { IoIosUndo } from "react-icons/io";


export const submissionType = 'submission'
export const submissionSingular = "sub.singular"
export const submissionPlural = "sub.plural"

export const generalTab= 'general'
export const recordTab= 'records'
export const assetTab= 'assets'

export const assetExtensions = ['.jpg','.png', 'webp', 'heic', '.rar','.zip','.gzip']

export const SubmissionTypeIcon = BsIntersect
export const SubmissionStateIcon = FiArrowRightCircle
export const SubmissionDateIcon = DateIcon
export const SubmissionIcon = UploadIcon

export const SubmissionDraftIcon = EditIcon
export const SubmissionPendingIcon = AiOutlinePause
export const SubmissionSubmittedIcon = (props: IconBaseProps  ) => <SubmissionIcon  color={submittedColor} {...props} />
export const SubmissionPublishedIcon = (props: IconBaseProps  ) => <IoEarthOutline  color={publishedColor} {...props} />
export const SubmissionRejectedIcon = (props: IconBaseProps  ) => <RiForbid2Line  color={rejectedColor} {...props} />
export const SubmissionRestoreIcon = IoIosUndo

export const SubmissionStatsIcon = AiOutlineNumber
export const AssetIcon = MdPhotoCamera
export const AssetReferenceIcon = FaExternalLinkSquareAlt
export const AssetArchiveIcon = BsFillFileZipFill
export const AssetMatchIcon = AiOutlineCheck
export const AssetMatchHeaderIcon = AiFillCheckSquare
export const AssetSizeIcon = FaRulerVertical

export const MatchExpandIcon = BsLayerBackward
export const MatchCollapseIcon = BsLayerForward

export const submittedColor: CSSProperties['color'] = 'dodgerblue'
export const publishedColor: CSSProperties['color'] = 'deepskyblue'
export const draftColor: CSSProperties['color'] = 'lightseagreen'
export const pendingColor: CSSProperties['color'] = 'darkorange'
export const rejectedColor: CSSProperties['color'] = 'red'



export const resourceIssueParam = 'resource-issues'
export const recordIssueParam = 'record-issues'
