
import { PatchAndCurrent } from '#submission/records'
import { DateLabel } from '#utils/datelabel'
import { useT } from 'apprise-frontend-core/intl/language'
import { TagColumn } from 'apprise-frontend-tags/tag/tagcolumn'
import { Label } from 'apprise-ui/label/label'
import { useTableUtils } from 'apprise-ui/table/utils'
import { DateIcon, TextIcon } from 'apprise-ui/utils/icons'
import format from 'date-fns/format'
import { DelistingReasonLabel, delistingType } from './constants'
import { DelistingPatch } from './model'

export const useDelistingColumns = () => {

    const t = useT()

    const { Column, compareNumbersOf, compareStringsOf } = useTableUtils<PatchAndCurrent<DelistingPatch>>()


    return <>

        <Column defaultLayout name="delisting-date" width={120} title={<Label icon={<DateIcon />} title={t('delisting.date_col')} />}
            text={patch => patch[delistingType].date && format(new Date(patch[delistingType].date), 'PPP')}
            sort={compareNumbersOf(m => Date.parse(m[delistingType].date))}
            render={patch => <DateLabel noIcon date={patch[delistingType].date} />} />


        <TagColumn<PatchAndCurrent<DelistingPatch>> defaultLayout width={200} name="delisting-reason" 
                        tagOf={patch => patch[delistingType].reason} 
                         title={<DelistingReasonLabel/>} />


        <Column defaultLayout width={300} name="delisting-note" title={<Label bare icon={<TextIcon />} title={t('delisting.note')} />} 
            sort={compareStringsOf(patch => patch[delistingType].date)}
            text={patch => patch[delistingType].note}
            render={patch => <Label noIcon tip={patch[delistingType].note} title={patch[delistingType].note} />}  />

    </>
}