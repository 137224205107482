import { useConfig } from 'apprise-frontend-core/config/api'



export type RemoteAuthzConfiguration = {

    authz: Partial<AuthzConfiguration>
}

export type AuthzConfiguration = {

    enableMultiManagers: boolean
}


export const defaultAuthzConfiguration: AuthzConfiguration = {

    enableMultiManagers: false

}

export const useAuthzConfig = () => useConfig()['authz'] ?? defaultAuthzConfiguration as AuthzConfiguration