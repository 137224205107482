
import { RecordValidationIcon } from '#record/constants'
import { SearchIcon } from '#search/constants'
import { DevSettingsPanel } from '#settings/dev'
import { SearchSettingsPanel } from '#settings/search'
import { AppSettings } from '#settings/settings'
import { SystemSettingsPanel } from '#settings/system'
import { ValidationSettingsPanel } from '#settings/validation'
import { useMode } from 'apprise-frontend-core/config/api'
import { SettingsModule } from 'apprise-frontend-streams/settings/model'
import { DevOnlyIcon } from 'apprise-ui/utils/icons'
import { AiFillAppstore } from 'react-icons/ai'



export const systemSettingsModule: SettingsModule<AppSettings> = {

    Icon: AiFillAppstore,
    Render: SystemSettingsPanel,
    defaults: {
        dateFormat: 'medium'
    },
    type: 'system',
    name: 'rav.appsettings'
}


export const useValidationSettings = () : SettingsModule<AppSettings> => {

    const mode = useMode()

    return {

        Icon: RecordValidationIcon,
        Render: ValidationSettingsPanel,
        defaults: {
            photosRequired: !mode.development,
            validateIMOChecksum: mode.production,
            minPhotoQuality: 0,
            validatePhotoQuality: true
        },
        type: 'validation',
        name: 'rav.validationsettings'
    }
}



export const searchSettingsModule: SettingsModule<AppSettings> = {

    Icon: SearchIcon,
    Render: SearchSettingsPanel,
    type: 'search',
    name: 'rav.searchsettings'
}



export const useDevSettings = (): SettingsModule => {

    const mode = useMode()


    return {

        Icon: DevOnlyIcon,
        name: 'rav.devsettings',
        type: 'dev',
        renderIf: !mode.production,
        defaults: {
            autoMatchAndValidate: true
        },
        Render: DevSettingsPanel

    }
}

// export const useExportCompleteSubsciption = (): EventSubscription => {

//     const t = useT()
//     const fb = useFeedback()

//     return  {

//         topic: 'export',
//         onEvent: () => fb.showNotification(t('rav.setting_export_trigger_done'),{ duration: false})
//     }
    
// }