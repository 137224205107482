import { useToggles } from 'apprise-frontend-core/toggle/toggles'


export const allUserTogggles = ['user-download'] as const

export type UserToggle = typeof allUserTogggles[number]

export const useUserToggles = () => {

    const toggles = useToggles<UserToggle>() 

    const self = {
        get download() {
            return toggles.isActive('user-download')
        }
    }

    return self
}