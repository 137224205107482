
import { RavRecord } from '#record/model'
import { referenceOf } from '#submission/model'
import { PatchAndCurrent } from '#submission/records'
import { isBytestream } from 'apprise-frontend-streams/model'
import { Tag } from 'apprise-frontend-tags/tag/model'
import { useTagStore } from 'apprise-frontend-tags/tag/store'
import { Label } from 'apprise-ui/label/label'
import { useTableUtils } from 'apprise-ui/table/utils'
import { ChangeLabel } from 'apprise-ui/utils/changelabel'
import { PhotographsIcon, photographsTypeCategory } from './constants'
import { PhotographsPatch } from './model'


export const usePhotographsColumns = () => {

    const { Column, compareStringsOf } = useTableUtils<PatchAndCurrent<PhotographsPatch>>()

    const tags = useTagStore()

    const photoTags = tags.allTagsOfCategory(photographsTypeCategory)

    const nameOf = (photo:Tag) => (patch?: RavRecord | PhotographsPatch) => {

        const ref = patch?.photograph?.photos.find(p => p.type === photo.id)?.ref
        return isBytestream(ref) ? referenceOf(ref): ref 
    }

    return <>{

        photoTags.map((tag, i) =>

            <Column text={nameOf(tag)}
            
            defaultLayout name={`photo-${i}`} key={i} width={200} title={<Label icon={<PhotographsIcon />} title={tag.name} />}
                
            sort={compareStringsOf(nameOf(tag))}

                render={patch => {

                    return <ChangeLabel before={nameOf(tag)(patch.current)} after={nameOf(tag)(patch)} render={n => <Label noIcon title={n ?? ''} />} />

                }}
            />
        )
    }</>
}
