import { useCall } from 'apprise-frontend-core/client/call'
import { StoreProps, useMocks } from 'apprise-frontend-core/client/mocks'
import MockAdapter from 'axios-mock-adapter/types'
import * as fflate from 'fflate'
import * as idb from 'idb-keyval'
import partition from 'lodash/partition'
import { deflateApi, deleteApi, streamApi, streamService, uploadBlobPart, uploadStreamPart } from './api'
import { Bytestream, BytestreamRef, newBytestreamId } from './model'


const imageExt2mime = {
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    webp: 'image/webp',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    avif: 'image/avif',
    bmp: 'image/bmp'
}

const ext2mime = {
    ...imageExt2mime
}

const archiveEntryExcludes = ['__MACOSX']

const concatUINT8 = (a: Uint8Array, b: Uint8Array) => {
    const c = new Uint8Array(a.length + b.length);

    c.set(a, 0);
    c.set(b, a.length);

    return c;
}

const getStream = (
    blob: Blob,
    type: string,
    name: string,
    path: string,
    archive: string,
    template: Bytestream,
    width: number,
    lowres?: BytestreamRef
): Bytestream => {
    
    const id = newBytestreamId()

    return {
        ...template,
        id,
        type,
        name,
        size: blob.size,
        properties: {
            ...template.properties,
            archive,
            lowres: !lowres,
            derivatives: [{
                id:lowres!,
                width
            }],
            path
        }
    }
}

const resizeImage = async (
    blob: Blob,
    width: number = 40,
    height: number = 40
): Promise<Blob | void> => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const url = URL.createObjectURL(blob);
    const img = new Image();

    if (!ctx)
        return;

    canvas.width = width;
    canvas.height = height;

    await new Promise((res, rej) => {
        img.onerror = rej;
        img.onload = res;
        img.src = url;
    });

    URL.revokeObjectURL(url);
    ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, 0, 0, width, height);

    const resized = await new Promise<Blob>(
        (res, rej) => canvas.toBlob(b => b ? res(b) : rej(null), blob.type)
    );

    return resized;
}

export const useStreamMockery = () => {

    const streamMocks = useStreamMocks()

    const call = useCall()

    return (mock: MockAdapter) => {

        console.log("mocking stream support...")

        const regapi = RegExp(`${streamApi}/.+$`)


        const fq = api => call.fq(api,streamService)

        const streams = streamMocks.streamStore()
        const blobs = streamMocks.blobStore()

        mock.onGet(call.fq(regapi,streamService)).reply(({ url }) => {

            const id = url!.split('/').pop()

            const stream = streams.oneWith(id)
            const blob = blobs.oneWith(id)

            if (!stream || !blob)
                return [404]

            return blob.data.arrayBuffer().then(data =>

                [200, data, {
                    'content-type': stream?.type,
                    "content-disposition": `filename="${stream?.name ?? stream?.id}"`
                }])

        })

        mock.onPost(fq(streamApi)).reply(async ({ data }) => {

            const multipart = data as FormData

            const stream = multipart.get(uploadStreamPart) as File
            const blob = multipart.get(uploadBlobPart) as File

            const text = await stream.text()

            console.log(`storing stream`, JSON.parse(text))

            const parsed = JSON.parse(text)

            if (parsed.id === 'error')
                throw Error("ooops, something went wrong")

            streams.addOrUpdate(parsed)
            blobs.addOrUpdate({ id: parsed.id, data: blob })

            return [200, [parsed]]


        })


        mock.onPost(fq(deflateApi)).reply(async d => {

            const multipart = d.data as FormData

            const stream = multipart.get(uploadStreamPart) as File

            const file = multipart.get(uploadBlobPart) as File

            const template = JSON.parse(await stream.text()) as Bytestream

            const processFiles: Promise<void>[] = []

            const processEntries: Promise<Bytestream>[] = []

            console.log(`deflating archive ${file.name}...`)

            // const fflate = await import('fflate')

            const generateTask = (path: string, buffer: Uint8Array) => new Promise<Bytestream>(async res => {
                const namefile = path.split('/').pop()
                const [name, ext] = namefile!.split('.')
                const parts = path.split('.')
                const type =  ext2mime[ext.toLowerCase()] ?? 'application/octet-stream'

                parts.pop() // remove ext from path
                const blobFull = new Blob([buffer], { type })
                const url = URL.createObjectURL(blobFull);
                const img = new Image();

                await new Promise((res, rej) => {
                    img.onerror = rej;
                    img.onload = res;
                    img.src = url;
                });
                const blobResized = await resizeImage(blobFull)

                const resized = getStream(blobResized!, type, `${ name }_lowres.${ ext }`, `${ parts.join('.') }_lowres.${ ext }`, file.name, template, 40)
                const full = getStream(blobFull, type, namefile!, path, file.name, template, img.naturalWidth, resized.id)

                streams.addOrUpdate(resized)
                streams.addOrUpdate(full)
                blobs.addOrUpdate({ id: resized.id, data: blobResized })
                blobs.addOrUpdate({ id: full.id, data: blobFull })

                res(full)
            })

            const buffer = await file.arrayBuffer()

            const array = new Uint8Array(buffer)

            const unzip = new fflate.Unzip(stream => {
                let decoded = new Uint8Array()
                const { name } = stream

                if (name.endsWith('/') || name.endsWith('\\') || archiveEntryExcludes.some(ex => name.includes(ex)))
                    return

                processFiles.push(new Promise(res => {
                    stream.ondata = (err, chunk, final) => {
                        decoded = concatUINT8(decoded, chunk)

                        if (final) {
                            processEntries.push(generateTask(name, decoded))
                            res()
                        }
                    }
                    stream.start()
                }))
            })

            unzip.register(fflate.AsyncUnzipInflate)

            unzip.push(array, true)

            // console.log('AFTER')

            await Promise.all(processFiles)

            const entries = await Promise.all(processEntries)

            // console.log('AFTER AWAIT', entries)

            return [200, entries]
        })

        mock.onPost(fq(deleteApi)).reply(({ data }) => {

            const ids = JSON.parse(data) as string[] ?? []

            const [stored, other] = partition(ids, streams.oneWith)

            stored.forEach(streams.delete)

            return [204, other]
        })

    }

}

export type StreamBlob = {

    id: BytestreamRef,
    data: Blob
}

export const useStreamMocks = () => {

    const mocks = useMocks()

    const self = {

        isActive: ()=>self.streamStore().all().length > 0,

        streamStore: (props?: StoreProps<Bytestream>) => mocks.getOrCreateStore<Bytestream>("streams", {

            callbacks: {

                onDelete: streams => streams.forEach(s => {

                    self.blobStore().delete(s.id)

                    const derivatives = s?.properties?.derivatives ?? []

                    derivatives.forEach(self.streamStore().delete)
                })

            },

            ...props
        })

        ,

        blobStore: (props?: StoreProps<StreamBlob>) => mocks.getOrCreateStore<StreamBlob>("blobs", {

            callbacks: {

                onChange: () => idb.set("blobs", self.blobStore().all()),
                onDelete: () => idb.set("blobs", self.blobStore().all())

            },

            ...props
        })

    }

    return self

}
