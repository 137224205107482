import { Card } from '#record/card';
import { PatchCardProps } from '#submission/patchdetail';
import { useValidationIssueIndex } from '#submission/validator';
import { DateLabel } from '#utils/datelabel';
import { useT } from 'apprise-frontend-core/intl/language';
import { Grid } from 'apprise-ui/grid/grid';
import { NamedProperty } from 'apprise-ui/utils/namedproperty';
import { authorizationType } from './constants';
import { AuthorizationPatch } from './model';


export const AuthorizationPatchCard = (props: PatchCardProps<AuthorizationPatch>) => {

    const t = useT()

    const { current, record, previous } = props

    const authorization = record[authorizationType]

    const issue = useValidationIssueIndex()

    return <Card minWidth={400}>
        <Grid rowGap={8} columnGap={10} >

            <Grid.Row>
                <NamedProperty status={ issue.range ?? issue.from } previously={previous?.authorization.from} currently={authorization.from} name={t('authorization.from_col')}>
                    <DateLabel noIcon bare date={authorization.from} />
                </NamedProperty>
            </Grid.Row>

            <Grid.Row>
                <NamedProperty status={ issue.range ?? issue.to } previously={previous?.authorization.to} currently={authorization.to} name={t('authorization.to_col')}>
                    <DateLabel displayMode={ current ? 'expiry' : 'normal' } noIcon date={authorization.to} />
                </NamedProperty>
            </Grid.Row>

        </Grid >
    </Card>
}
