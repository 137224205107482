import { BiTargetLock } from 'react-icons/bi';
import { FiColumns } from 'react-icons/fi';
import { Query } from './model';


export const SearchIcon = BiTargetLock

// the query used if no other filter is set.
// currently it's just empty, but it may not always be (eg. may contain order directives).
export const startQuery: Query = {

    mode: 'current',
    delisted: 'exclude',
    conditions: {}
}

export const defaultPageSize = 100

export const defaultColumns = ['name', 'state', 'loa', 'gt', 'type','from','to', 'date']

export const ColumnIcon = FiColumns
export const manageColumnParam = 'columns'
