import { Card } from '#record/card';
import { useAppSettings } from '#settings/settings';
import { PatchCardProps } from '#submission/patchdetail';
import { useValidationIssueIndex } from '#submission/validator';
import { DateLabel } from '#utils/datelabel';
import { useT } from 'apprise-frontend-core/intl/language';
import { CategoryLabel } from 'apprise-frontend-tags/category/label';
import { TagLabel } from 'apprise-frontend-tags/tag/label';
import { Grid } from 'apprise-ui/grid/grid';
import { Label } from 'apprise-ui/label/label';
import { NamedProperty } from 'apprise-ui/utils/namedproperty';
import { delistingReasonCategory, delistingType } from './constants';
import { DelistingPatch } from './model';


export const DelistingPatchCard = (props: PatchCardProps<DelistingPatch>) => {

    const t = useT()

    const { record } = props

    const formatString = useAppSettings().resolveFormat()

    const delisting = record[delistingType]
   
    const issues = useValidationIssueIndex()

    return <Card width={550}>
        <Grid rowGap={8} columnGap={10} >

            <Grid.Row>
                <NamedProperty status={issues.date} name={t('delisting.date')}>
                    <DateLabel noIcon bare dateFormat={formatString} date={delisting.date} />
                </NamedProperty>
            </Grid.Row>

            <Grid.Row>
                <NamedProperty  status={issues.reason} 
                        name={<CategoryLabel bare noIcon category={delistingReasonCategory} />}>
                    {<TagLabel bare mode='tag' noIcon tag={delisting.reason} />}
                </NamedProperty>
            </Grid.Row>


            <Grid.Row>
                <NamedProperty noFixedHeight status={issues.note}  name={t('delisting.note')}>
                    {<Label bare noIcon title={delisting.note} />}
                </NamedProperty>
            </Grid.Row>

        </Grid >
    </Card>
}
