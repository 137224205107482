import { PortReference } from '#details/model'
import { useAppSettings } from '#settings/settings'
import { useVID, vidSchemeCategory } from '#vid/model'
import { useLanguage } from 'apprise-frontend-core/intl/language'
import { TenantReference } from 'apprise-frontend-iam/tenant/model'
import { useTenantStore } from 'apprise-frontend-iam/tenant/store'
import { SheetWriter, useWorkbookWriter, Writer } from 'apprise-frontend-parse/workbookWriter'
import { useTagCache } from 'apprise-frontend-tags/cache'
import { TagReference } from 'apprise-frontend-tags/tag/model'
import { useTagStore } from 'apprise-frontend-tags/tag/store'
import { GenericRecord } from './model'

export type BaseSerializationProps = {

    mode: 'codes' | 'names' | 'both'
    sheetname: string
}

export type ExcelSerializationProps<T extends GenericRecord> = BaseSerializationProps & {

    data: T[]
}

export type ExcelRecordWriterProps<T extends GenericRecord> = BaseSerializationProps & ReturnType<ReturnType<typeof useSerializationHelpers>> & {

    output: SheetWriter<T>

}


export const useSerializationHelpers = () => {

    const lang = useLanguage().current()

    const tenants = useTenantStore()
    const tags = useTagStore()

    return (props: BaseSerializationProps) => {

        const { mode } = props

        const self = {
            writeTenant: (t: TenantReference | undefined) => mode === 'codes' ? self.writeTenantCode(t) : self.writeTenantName(t),
            writeDomainTag: (id: TagReference | undefined) => mode === 'codes' ? self.writeDomainTagCode(id) : self.writeDomainTagName(id),
            writeInternalTag: (id: TagReference | undefined) => tags.safeLookupTag(id).name[lang],
            writePort: (port: PortReference | undefined) => mode === 'codes' ? self.writePortCode(port) : self.writePortName(port),

            writeTenantCode: (t: TenantReference | undefined) => tenants.safeLookup(t).code,
            writeTenantName: (t: TenantReference | undefined) => tenants.safeLookup(t).name[lang],
            writeDomainTagCode: (id: TagReference | undefined) => tags.safeLookupTag(id).code,
            writeDomainTagName: (id: TagReference | undefined) => tags.safeLookupTag(id).name[lang],
            writePortCode: (port: PortReference | undefined) => port?.code ?? port?.name,
            writePortName: (port: PortReference | undefined) => port?.name ?? port?.code
        }

        return self
    }

}

export type ExcelRecordSerializer<T extends GenericRecord> = (props: ExcelSerializationProps<T>) => Writer

export type ExcelRecordWriter<T extends GenericRecord> = (props: ExcelRecordWriterProps<T>) => void

export const useGenericExcelSerializer = <T extends GenericRecord>(customSerializer: ExcelRecordWriter<T>) => {

    const primaryScheme = useAppSettings().primaryScheme

    const write = useWorkbookWriter()
    const vids = useVID()

    const tags = useTagCache()

    const helpersOf = useSerializationHelpers()

    return (props: ExcelSerializationProps<T>) => {

        const { data, ...rest } = props

        const { sheetname } = rest

        const output = write.sheetOf(data)

        output.text(tags.id2name(primaryScheme).of(vidSchemeCategory)).render(r => vids.valueOf(r.uvi))

        customSerializer({ ...rest, output, ...helpersOf(props) })

        output.as(sheetname)

        return write

    }

}