


import { RavRecord } from '#record/model'
import { State } from 'apprise-frontend-core/state/api'
import { SortSpec } from 'apprise-ui/table/sorting'
import { createContext, PropsWithChildren } from 'react'
import { HistoryItem, Query } from './model'



export type SearchProviderProps = PropsWithChildren<{}>

export type SearchState = {

    query: Query
    results: RavRecord[]
    history: HistoryItem[]
    page: number
    pageSize: number
    total: number
    sort: SortSpec[]
}


export const SearchStateContext = createContext<State<SearchState>>(undefined!)