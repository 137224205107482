

import { utils } from 'apprise-frontend-core/utils/common'
import { DrawerProps, useRoutableDrawer } from 'apprise-ui/drawer/drawer'
import { Menu, MenuItemProps, MenuProps } from 'apprise-ui/menu/menu'
import * as React from 'react'

export type Props =  MenuProps & {

    id?: string

}

export const defaultDrawerParam = 'drawer-header'

export const ActionMenu = (props: Partial<Props>) => {

    const {id, ...rest} = props

    const children = utils().arrayOf(props.children!)

    const drawer = useRoutableDrawer(props.id ?? defaultDrawerParam)

    const {Drawer} = drawer

    // we open in a drawer the children of all Drawer items that don't have an action.
    const items = utils().elementsIn(children).map((item, i) =>  
    
            utils().isElementOf(ActionMenu.Drawer)(item) ?

                <Menu.Item key={item.props.id ?? i} {...item.props} 
                
                        onClick={item.props.onClick ?? item.props.linkTo ? undefined : () => {

                        //match.current = `${item.props.id ?? i}`
                        drawer.openAt(`${item.props.id ?? i}`)
                    
                }} />
        : 

        item
    )
    

    // matches items by position. or failing that by id.
    const matchingItem = items.find(i=>i.props.id===drawer.match) ?? items[drawer.match]

    return <>
        <Menu noIcon className='header-menu-items' {...rest}>
            {items}
        </Menu>
        <Drawer {...matchingItem?.props}  />
    </>

}

ActionMenu.Drawer = function Drawer(_: MenuItemProps & DrawerProps & {id?:string}) { return null }