import { imoscheme, ircsscheme, regnoscheme } from '#iotc/constants';
import { useVesselOracle } from '#vessel/oracle';
import { SlotFormProps } from '#vessel/overview';
import { useVID } from '#vid/model';
import { VidBox } from '#vid/vidbox';
import { useT } from 'apprise-frontend-core/intl/language';
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant';
import { useTenantStore } from 'apprise-frontend-iam/tenant/store';
import { TenantBox } from 'apprise-frontend-iam/tenant/tenantbox';
import { TagReference, useTagModel } from 'apprise-frontend-tags/tag/model';
import { useTagStore } from 'apprise-frontend-tags/tag/store';
import { TagBoxes } from 'apprise-frontend-tags/tag/tagboxes';
import { TagmapBox } from 'apprise-frontend-tags/tag/tagmapbox';
import { TagRefBox } from 'apprise-frontend-tags/tag/tagrefbox';
import { useTagValidation } from 'apprise-frontend-tags/validation';
import { Fields, error, useValidation } from 'apprise-ui/field/validation';
import { Form } from 'apprise-ui/form/form';
import { NumberBox } from 'apprise-ui/numberbox/numberbox';
import { useRecordFields } from 'apprise-ui/recordbox/recordbox';
import { TextBox } from 'apprise-ui/textbox/textbox';
import { Fragment } from 'react';
import { contactTypeCategory, detailsType, fishingKindTag, grtTag, loaTag, operatingCompanyTag, portRegistryCategory, specificationCategory, supplyVesselTypeTag, tonnageTag, totalVolumeTag, totalVolumeTonnesTag, vesselGearCategory, vesselKindCategory, vesselRangeCategory, vesselTypeCategory } from './constants';
import { ContactBox } from './contactbox';
import { Contact, Details } from './model';
import { maxLoa, maxTonnage, maxVolumeFV, minLoa, minTonnage, minVolume } from './validator';


export const DetailsForm = (props: SlotFormProps) => {

    return <Form fieldDefaults={{ help: null, trackChange: true }}>

        <DetailsFormFields {...props} />

    </Form>
}

export const DetailsFormFields = (props: SlotFormProps) => {

    const { current, edited, initial, set } = props

    const vids = useVID()
    const tags = { ...useTagStore(), ...useTagModel() }

    const validation = useValidation()

    const fields = useDetailFields(props)

    const report = validation.reportOf(fields)

    const details = edited.details
    const pastDetails = initial?.details ?? {} as Details

    const logged = useTenancyOracle()
    const oracle = useVesselOracle()

    const tenants = useTenantStore()

    const schemes = vids.allSchemes()

    const canChangeTenant = logged.managesMultipleTenants() && (oracle.canClaim(current) || current.lifecycle.state === 'uploaded')


    const allowedTenants = tenants.allSorted().filter(t => oracle.canAddFor(t.id)).sort()

    return <Fragment>

        {canChangeTenant &&

            <TenantBox tenants={allowedTenants} noClear info={report.flagstate} noReadonly={oracle.canClaim(current)} onChange={set.with((m, v) => {
                m.tenant = v
                m.details.flagstate = v
            })}>
                {details.flagstate}
            </TenantBox>

        }

        <TextBox info={fields.name} pastValue={pastDetails.name} onChange={set.with((m, v) => m.details.name = v)}>
            {details?.name}
        </TextBox>

        {schemes.map(scheme => {

            var match = details.identifiers.find(id => vids.schemeOf(id) === scheme)
            var previousmatch = current.details.identifiers.find(id => vids.schemeOf(id) === scheme)
            var matchIndex = match ? details.identifiers.indexOf(match) : details.identifiers.length
            var readonly = !!previousmatch && scheme.id === imoscheme && !vids.isSpecialValue(previousmatch) && logged.isTenantUser()

            return <VidBox
                key={scheme.id}
                schemes={scheme.id}
                info={report[scheme.id as any]}
                readonly={readonly}
                onChange={set.with((m, v) => m.details.identifiers[matchIndex] = v)}>
                {match}
            </VidBox>
        })}

        <TagRefBox status={report[vesselKindCategory]?.status} category={vesselKindCategory} pastValue={pastDetails.vesselKind} onChange={set.with((m, v) => {

            m.details.vesselKind = v

            // clear any TRX authz on transitioning to FV from CV.
            if (v === fishingKindTag)
                delete m.trxauthz

            // m.details.vesselType = undefined!
        })}>
            {details.vesselKind}
        </TagRefBox>

        <TagRefBox /* enabled={!!details.vesselKind} */ status={report[vesselTypeCategory]?.status} msg={report[vesselTypeCategory]?.msg}
            category={vesselTypeCategory}
            tags={
                tags.allTagsOfCategory(vesselTypeCategory)
                //.filter(t => (t.properties.custom?.[vessselKindProp] ?? fishingKindTag) === details.vesselKind)
            }
            pastValue={pastDetails.vesselType} onChange={set.with((m, v) => m.details.vesselType = v)}>
            {details.vesselType}
        </TagRefBox>

        <TagBoxes categories={[vesselRangeCategory, vesselGearCategory]} report={report}
            pastValue={[pastDetails.range, ...(pastDetails.gears ?? [])].filter(t => !!t)}
            onChange={set.with((m, v) => {

                const operatingRangeTags = v.filter(t => tags.lookupTag(t)?.category === vesselRangeCategory)
                const gears = v.filter(t => tags.lookupTag(t)?.category === vesselGearCategory)

                const range = operatingRangeTags.length > 0 ? operatingRangeTags[0] : ''


                m.details = { ...m.details, range, gears }

            })}>
            {[details.range, ...(details.gears ?? [])].filter(t => !!t)}
        </TagBoxes>

        <TextBox info={fields.port} pastValue={pastDetails.port?.name} onChange={set.with((m, v) => m.details.port = { ...m.details.port, name: v })}>
            {details.port.name}
        </TextBox>

        <TextBox info={fields.portcode} pastValue={pastDetails.port?.code} onChange={set.with((m, v) => m.details.port = { ...m.details.port, code: v })}>
            {details.port.code}
        </TextBox>

        <TextBox info={fields.portflag} pastValue={pastDetails.port?.flag} onChange={set.with((m, v) => m.details.port = { ...m.details.port, flag: v })}>
            {details.port.flag}
        </TextBox>

        <TextBox info={fields.priorHistoryName} pastValue={pastDetails.priorHistory?.name} onChange={set.with((m, v) => m.details.priorHistory = { ...m.details.priorHistory, name: v })}>
            {details.priorHistory?.name}
        </TextBox>

        <TextBox info={fields.priorHistoryFlags} pastValue={pastDetails.priorHistory?.flags} onChange={set.with((m, v) => m.details.priorHistory = { ...m.details.priorHistory, flags: v })}>
            {details.priorHistory?.flags}
        </TextBox>

        <TextBox info={fields.priorHistoryDeletions} pastValue={pastDetails.priorHistory?.deletions} onChange={set.with((m, v) => m.details.priorHistory = { ...m.details.priorHistory, deletions: v })}>
            {details.priorHistory?.deletions}
        </TextBox>

        <TagmapBox category={specificationCategory} include={t => t.id !== totalVolumeTonnesTag && t.id !== grtTag} renderValue={() => <NumberBox width='100%' />} validate={fields.specificationValidators} onChange={set.with((m, v) => m.details.specifications = v)}>
            {details.specifications}
        </TagmapBox>

        {tags.allTagsOfCategory(contactTypeCategory)
            .sort(tags.codeComparator)
            .map(type => (details.contacts?.find(c => c.type === type.id) ?? { type: type.id }) as Contact)
            .map((contact, i) =>

                <ContactBox validate={fields.contactValidators} key={i} onChange={set.with((m, v) => {

                    const index = m.details.contacts?.findIndex(c => c.type === v.type)

                    if (index < 0)
                        m.details.contacts = [...m.details.contacts ?? [], v]
                    else {

                        // if this was a new 
                        if (Object.keys(v).length === 0 && !initial.details?.contacts.find(c => c.type === v.type))

                            m.details.contacts = m.details.contacts.filter((_, i) => i !== index)

                        else

                            m.details.contacts[index] = v
                    }

                })}>
                    {contact}
                </ContactBox>


            )}

    </Fragment>
}


export const useDetailFields = (props: SlotFormProps) => {

    const t = useT()
    const vids = useVID()

    const tags = { ...useTagStore(), ...useTagModel(), ...useTagValidation() }

    const { check, is } = useValidation()

    const { edited, initial, current } = props

    const details = edited?.[detailsType] ?? {}

    const specificationValidators = {

        [loaTag]: (s: string) => ({ 

            ...check(is.empty).and(is.isNaN).and(is.number.smallerOrEqualThan(minLoa)).on(s),
            ...check(is.number.greaterThan(maxLoa)).provided(!!s).given(s).asWarning()
            
            , location: loaTag 
        }),

        // GT
        [tonnageTag]: (s: string, r: Record<TagReference, string>) => {

            const checks = s ? {
                ...check(is.isNaN).and(is.number.smallerOrEqualThan(minTonnage)).on(s),
                ...check(is.number.greaterThan(maxTonnage)).given(s).asWarning()
            }

                :

            // required only on new records mor on records that do not have a GRT
            check(is.empty).provided(!current || !current.details.specifications[grtTag]).on(s)

            return { 
                
                
                ...checks, location: tonnageTag }
        },

        // [totalVolumeTag]: (s: string) =>

        //     details.vesselKind === fishingKindTag ?

        //         {
        //             ...check(is.isNaN).and(is.empty).on(s),
        //             ...check(is.number.smallerOrEqualThan(minVolume)).provided(!!s).on(s),
        //             ...check(is.number.greaterThan(maxVolumeFV)).provided(!!s).given(s).asWarning()
                    
                    
        //             , location: totalVolumeTag }

        //         :

        //         { ...check(is.isNaN).provided(!!s),
        //           ...check(is.number.smallerOrEqualThan(minVolume)).provided(!!s),
        //           ...check(is.number.greaterThan(maxVolumeCV)).provided(!!s).given(s).asWarning()
                  
                  
        //         , location: totalVolumeTag }


        // ,

        [totalVolumeTag]: (s: string) => ({
                    ...check(is.isNaN).and(is.empty).on(s),
                    ...check(is.number.smallerOrEqualThan(minVolume)).provided(!!s).on(s),
                    ...check(is.number.greaterThan(maxVolumeFV)).provided(!!s).given(s).asWarning()
                    
                    
                    , location: totalVolumeTag })

                
        ,

        // legacy and not fielded
        // [grtTag]: (s: string, r: Record<TagReference, string>) => {


        //     return {
        //          ...check(is.number.smallerOrEqualThan(minGrt)).provided(!!s).on(s),
        //          ...check(is.isNaN).and(is.number.greaterThan(maxGrt)).provided(!!s).given(s).asWarning(),
                
                
        //         location: grtTag }
       // }
    }

    const specificationFields = useRecordFields(specificationValidators)

    const contactValidators = {

        name: (s: string) => ({ ...check(is.empty).on(s) }),
        address: (s: string) => ({ ...check(is.empty).on(s) }),
        regno: (s: string, contact: Contact) => ({ ...check(is.empty).provided(contact.type === operatingCompanyTag).on(s) })
    }

    const contactFields = useRecordFields(contactValidators)

    let categoriesToValidate = [vesselKindCategory, vesselTypeCategory, vesselRangeCategory]

    if (details.vesselKind === fishingKindTag)
        categoriesToValidate.push(vesselGearCategory)

    const self = {

        flagstate: {

            label: t('details.flag'),
            msg: t('details.flag_msg'),
            ...check(is.empty).on(details.flagstate),
            location: 'flagstate'
        },

        name: {

            label: t('details.name'),
            msg: t('details.name_msg'),
            help: t('details.name_help'),
            ...check(is.empty).on(details.name),
            location: 'name'
        },

        [imoscheme]: {

            ...check(is.empty)
                .and(imo => !vids.isSpecialValue(imo) && !vids.isValidIMO(imo) && error(t('valid.invalid_imo')))
                .on(vids.valueOf(details.identifiers?.find(i => vids.schemeOf(i)?.id === imoscheme))),
            location: imoscheme

        },

        [regnoscheme]: {

            ...check(is.empty).on(vids.valueOf(details.identifiers?.find(i => vids.schemeOf(i)?.id === regnoscheme))),
            location: regnoscheme

        },

        [ircsscheme]: {

            ...check(is.empty).on(vids.valueOf(details.identifiers?.find(i => vids.schemeOf(i)?.id === ircsscheme))),
            location: ircsscheme
        },

        port: {

            label: t('details.port'),
            msg: t('details.port_msg'),
            help: t('details.port_help'),
            ...check(is.empty).on(details.port?.name),
            location: portRegistryCategory
        },

        portcode: {

            label: t('details.portcode'),
            msg: t('details.portcode_msg'),
            help: t('details.portcode_help')
        },

        portflag: {

            label: t('details.portflag'),
            msg: t('details.portflag_msg'),
            help: t('details.portflag_help')
        },

        priorHistoryName: {
            label: t('details.prev_name'),
            msg: t('details.prev_name_msg')
        }

        ,

        priorHistoryFlags: {
            label: t('details.prev_flags'),
            msg: t('details.prev_flags_msg')
        }

        ,

        priorHistoryDeletions: {
            label: t('details.prev_deletions'),
            msg: t('details.prev_deletions_msg')
        }

        ,

        ...specificationFields(details.specifications)

        ,

        ...tags.validateTags([details.vesselKind, details.vesselType, details.range, ...details.gears])
            .include(...categoriesToValidate)

        ,

        specificationValidators

        ,

        ...tags.allTagsOfCategory(contactTypeCategory).sort(tags.codeComparator)
            .map(typeTag => (details.contacts?.find(c => c.type === typeTag.id) ?? { type: typeTag.id }) as Contact)
            .map(contact => [contact, contactFields(contact)] as const).reduce((acc, [contact, field]) => ({

                ...acc,
                ...Object.keys(field).reduce((a, key) => ({ ...a, [`${contact.type}-${key}`]: { ...field[key], location: `${contact.type}-${key}` } }), {})

            }), {} as Fields)

        ,

        contactValidators

    }

    // custom validation check for "new" supply vessels
    self[vesselTypeCategory] = {

        ...self[vesselTypeCategory],

        ...check(type => type !== initial?.details.vesselType && type === supplyVesselTypeTag && { status: 'warning', msg: t('valid.invalid_supply_type'), location: vesselTypeCategory },).on(details.vesselType)

    }


    return self

}
