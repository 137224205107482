import { useRouting } from 'apprise-ui/utils/routing';
import { useContext } from 'react';
import { Tenant, useNewTenant } from './model';
import { TenantRoutingContext, TenantRoutingState } from './provider';


export const tenantRoute="/tenant"


export const useTenantRouting = () => {

    const state = useContext(TenantRoutingContext)
    const routing = useRouting()
    const newTenant = useNewTenant()


    const self = {

        listRoute: () => tenantRoute
        
        ,

        detailRoute: (id:string) => `${tenantRoute}/${id}`

        ,
        
        innerDetailRoute: (id: string) => `${tenantRoute}/${id}${routing.query()}` 

        ,

        newDetailRoute: () => `${tenantRoute}/new`


        ,


        userInTenantRoute: (tenant: string, id: string) => `${self.detailRoute(tenant)}/user/${id}`

        ,

        
        routeToList : () => routing.routeTo(self.listRoute())

        ,

        routeToDetail : (id: string) => routing.routeTo(self.detailRoute(id))

        ,

        routeToNewDetail: (directives: Partial<TenantRoutingState> = {}) => Promise.resolve(state.reset(directives)).then(() => routing.routeTo(self.newDetailRoute()))
 
        ,


        nextTenant: () => state.get().nextTenant ?? newTenant()

        ,

        routeOnAdded: (user: Tenant) => state.get().routeAfterNext?.(user) ?? self.routeToList()
        
    }

    return self;
}