import { useActionRegistry } from 'apprise-frontend-core/authz/action';
import { useEffect } from 'react';
import { categoryActions } from './oracle';


// registers actions, so doesn't need to wrap around children for now.

export const TagInitializer = () => {

    const actionRegistry = useActionRegistry()

    useEffect(() => { 
    
        actionRegistry.register(Object.values(categoryActions))
    
    // eslint-disable-next-line
    },[])

    return null;
}