
import { useRecordCalls } from '#record/calls'
import { useLanguage, useT } from 'apprise-frontend-core/intl/language'
import { utils } from 'apprise-frontend-core/utils/common'
import { usePrevious } from 'apprise-frontend-core/utils/hooks'
import { useAsyncTask } from 'apprise-ui/utils/asynctask'
import React from 'react'
import { SearchStateContext } from './context'
import { SearchDto } from './model'



export const useSearch = () => {

    const t = useT()


    const context = React.useContext(SearchStateContext)

    const task = useAsyncTask()
    const calls = useRecordCalls()
    const lang = useLanguage()

    const previous = usePrevious(context.get())

    // adds logic to run queries
    const self = {

        ...context

        ,

        call: async (dto: SearchDto) => await calls.search(dto)

        ,

        getSearchDto: () : SearchDto => {

            const search= context.get()

            const queryHasRunBefore = previous && utils().deepequals(search.query, previous.query)

            const conditions = Object.values(search.query?.conditions ?? {}).flat()
            const sort = search.sort?.map(s => ({ field:s.key as string, mode: s.mode!})) ?? []
            const page = queryHasRunBefore ? search.page : 1
           
            const dto: SearchDto = { 

                mode: search.query.mode, 
                sort,
                conditions, 
                includeDelisted: search.query.mode === 'historical' || search.query.delisted === 'include',
                cursor: { page, pageSize:search.pageSize },
                language: lang.current(),
           
            }

            return dto
        }

        ,

        runQueryQuietly: async () => {

            const search= context.get()

            if (!search.query)
                return

            const queryHasRunBefore = previous && utils().deepequals(search.query, previous.query)

            const page = queryHasRunBefore ? search.page : 1
           
            const dto = self.getSearchDto()

            if (queryHasRunBefore)
                dto.total = search.total

            const { results, total } = await self.call(dto)
            
            
            context.set(s => {

                s.results = results

                if (!queryHasRunBefore){
                    s.total = total 
                    s.page = page
                }
            })
        }

    }


    // split api or typing for task.make gets confused when functions call each other via self.
    return {

        ...self,

        runQuery : task.make(self.runQueryQuietly).with($ =>

            $.show(t('search.searching'))
                .throw({

                    title: t('search.search_error_title'),
                    message: t('search.search_error_msg')

                })

        ).done()
    }

}
