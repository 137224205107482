
import { RavRecord } from '#record/model';
import { Language } from 'apprise-frontend-core/intl/language';
import { Wide } from 'apprise-ui/component/model';
import { FC, ReactNode } from 'react';




// a query is a set of conditions, indexed by the pill that collects them.
export type Query = {

    delisted: DelistedMode 
    mode: SearchMode
    conditions: Record<SearchKey,SearchCondition[]>

} 

export type SearchKey=string
export type SearchMode = 'current' | 'historical'

export type DelistedMode = 'include' | 'exclude'


export type SearchCondition = Record<string,any>
export type Sort = {field:string, mode: 'asc' | 'desc'}

export type HistoryItem = {
   
    timestamp: number
    query: Query
    total: number
}


export type SavedQuery = {
    name: string
    query: Query 
}




export type SearchDto = {

    language: Language
    mode: SearchMode
    conditions: SearchCondition[]
    includeDelisted: boolean
    sort: Sort[]
    cursor: SearchCursor
    total?: number      // set only after query is first run.
}

export type SearchCursor = Partial<{

    page: number
    pageSize: number


} | {

    offset: number
    limit: number
}>

export type ResultBatch = {

    results: RavRecord[]
    total: number

}


export type QueryFieldProps<T extends SearchCondition> = Wide & Partial<{

    label: ReactNode
    onChange: (t:T | undefined) => void
    children: T
}>


export type QueryPillProps = {

    name: string
    label: string
    title: string
    field: FC<QueryFieldProps<any>>
    mode?:SearchMode
    repeatable?:boolean

    defaultLayout?: boolean
}


export const QueryPill = (_: QueryPillProps & {

}) => {

    return null;
}