
import { FileIcon } from 'apprise-ui/utils/icons'
import * as React from "react"
import { AiOutlineFileExcel, AiOutlineFileImage, AiOutlineFileJpg, AiOutlineFilePdf, AiOutlineFilePpt, AiOutlineFileText, AiOutlineFileWord, AiOutlineFileZip } from "react-icons/ai"

export const mimeTypes = {

    text : 'text/plain',
    pdf: 'application/pdf',
    zip : 'application/zip',
    tar : 'application/x-xz',
    rar : 'application/vnd.rar',
    _7z :'application/x-7z-compressed',
    png : 'image/png',
    jpeg : 'image/jpeg',
    msword : 'application/msword',
    opendocword : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    msexcel : 'application/vnd.ms-excel',
    opendocexcel : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    csv : 'text/csv',
    msppt : 'application/vnd.ms-powerpoint',
    opendocppt : 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
}

export const mimeIcons = {
    unknown: <FileIcon color='grey' />,
    [mimeTypes.text] : <AiOutlineFileText color='steelblue' />,
    [mimeTypes.csv] : <AiOutlineFileText color='burlywood' /> ,
    [mimeTypes.pdf] : <AiOutlineFilePdf color='indianred' />,
    [mimeTypes.zip] : <AiOutlineFileZip color='deeppink' />,
    [mimeTypes.tar] : <AiOutlineFileZip color='deeppink' />,
    [mimeTypes.rar] : <AiOutlineFileZip color='deeppink' />,
    [mimeTypes.rar] : <AiOutlineFileZip color='deeppink' />,
    [mimeTypes._7z] : <AiOutlineFileZip color='deeppink' />,
    [mimeTypes.png] : <AiOutlineFileImage color='darkcyan' />,
    [mimeTypes.jpeg] : <AiOutlineFileJpg color='darkcyan' />,
    [mimeTypes.msword]: <AiOutlineFileWord color='cadetblue' />,
    [mimeTypes.opendocword]: <AiOutlineFileWord color='cadetblue' />,
    [mimeTypes.msexcel]: <AiOutlineFileExcel color='lightseagreen' />,
    [mimeTypes.opendocexcel]: <AiOutlineFilePpt color='lightseagreen' />,
    [mimeTypes.msppt]: <AiOutlineFilePpt color='darkseagreen' />,
    [mimeTypes.opendocppt]: <AiOutlineFilePpt color='darkseagreen' />
}



export type TypeDescriptorGroup = {

    name: string,
    description: string,
    mime: string[]
}

export const fileTypeInfo:TypeDescriptorGroup[] = [
    {
        name : "ui.filebox.document_name",
        description : "ui.filebox.document_msg",
        mime : [mimeTypes.msword, mimeTypes.opendocword, mimeTypes.pdf, mimeTypes.text]
    },
    {
        name : "ui.filebox.tabular_name",
        description : "ui.filebox.tabular_msg",
        mime: [mimeTypes.msexcel, mimeTypes.opendocexcel, mimeTypes.csv, mimeTypes.text]
    },
    {
        name: "ui.filebox.image_name",
        description : "ui.filebox.image_msg",
        mime: [mimeTypes.jpeg, mimeTypes.png]
    },
    {
        name: "ui.filebox.compressed_name",
        description : "ui.filebox.compressed_msg",
        mime: [mimeTypes.zip, mimeTypes.tar, mimeTypes.rar, mimeTypes._7z]
    },
    {
        name: "ui.filebox.word_name",
        description : "ui.filebox.word_msg",
        mime: [mimeTypes.msword, mimeTypes.opendocword]
    },
    {
        name: "ui.filebox.pdf_name",
        description : "ui.filebox.pdf_msg",
        mime: [mimeTypes.pdf]
    },
    {
        name: "ui.filebox.excel_name",
        description : "ui.filebox.excel_msg",
        mime: [mimeTypes.msexcel,mimeTypes.opendocexcel]
    },
    {
        name: "ui.filebox.csv_name",
        description : "ui.filebox.csv_msg",
        mime: [mimeTypes.csv]
    },
    {
        name: "ui.filebox.zip_name",
        description : "ui.filebox.zip_msg",
        mime: [mimeTypes.zip]
    }
]


export const typeDescriptors = {
    unknown: "ui.filebox.mime_unknown",
    [mimeTypes.text]: "ui.filebox.mime_text",
    [mimeTypes.pdf]:"ui.filebox.mime_pdf",
    [mimeTypes.zip]: "ui.filebox.mime_zip",
    [mimeTypes.tar]: "ui.filebox.mime_tar",
    [mimeTypes.rar]: "ui.filebox.mime_rar",
    [mimeTypes._7z]: "ui.filebox.mime_7z",
    [mimeTypes.png]: "ui.filebox.mime_png",
    [mimeTypes.jpeg]: "ui.filebox.mime_jpeg",
    [mimeTypes.msword]: "ui.filebox.mime_msword",
    [mimeTypes.opendocword]: "ui.filebox.mime_opendocword",
    [mimeTypes.msexcel]: "ui.filebox.mime_msexcel",
    [mimeTypes.opendocexcel]: "ui.filebox.mime_opendocexcel",
    [mimeTypes.csv]: "ui.filebox.mime_csv",
    [mimeTypes.msppt]: "ui.filebox.mime_msppt",
    [mimeTypes.opendocppt]: "ui.filebox.mime_opendocppt" 
}
