import { PatchCardProps } from '#submission/patchdetail';
import { useTagStore } from 'apprise-frontend-tags/tag/store';
import { Grid } from 'apprise-ui/grid/grid';
import { photographsTypeCategory, photographType } from './constants';
import { Photographs, PhotographsPatch } from './model';
import { PhotoCard } from './photocard';



export const PhotographPatchCard = (props: PatchCardProps<PhotographsPatch>) => {

    const tags = useTagStore()

    const { record, previous } = props

    const { photos } = record[photographType]

    const { photos: pastPhotos = [] } = previous?.[photographType] ?? {} as Photographs

    const phototags = tags.allTagsOfCategory(photographsTypeCategory, true)

    return <Grid> { phototags.map((tag, i) => {

        const photo = photos.find(p => p.type === tag.id)

        const pastphoto = pastPhotos.find(p => p.type === tag.id)

        return <PhotoCard height={175} showMetadata key={i} tag={tag} photo={photo} pastphoto={pastphoto} />

    })}</Grid>

}
