import { authorizationType } from '#authorization/constants'
import { AuthorizationJsonSerialization } from '#authorization/jsonserializer'
import { useAuthorizationRowParser } from '#authorization/parser'
import { detailsType } from '#details/constants'
import { DetailsJsonSerialization } from '#details/jsonserializer'
import { useDetailsRowParser } from '#details/parser'
import { iotcnumberscheme } from '#iotc/constants'
import { photographType } from '#photographs/constants'
import { Photo, newPhotoBytestreamFrom, thumbnailWidth } from '#photographs/model'
import { usePhotographsItemParser } from '#photographs/parser'
import { RavRecord } from '#record/model'
import { ImageMap } from '#settings/dev'
import { Asset } from '#submission/model'
import { useVID } from '#vid/model'
import { utils } from 'apprise-frontend-core/utils/common'
import { TenantReference } from 'apprise-frontend-iam/tenant/model'
import { useTenantStore } from 'apprise-frontend-iam/tenant/store'
import { XlsXParseContext } from 'apprise-frontend-parse/file'
import { ParseContext } from 'apprise-frontend-parse/model'
import { useDefaultModelParser, useDefaultParser } from 'apprise-frontend-parse/resource'
import { useDefaultBookParser } from 'apprise-frontend-parse/workbook'
import { useAsyncTask } from 'apprise-ui/utils/asynctask'
import shortid from 'shortid'
import { migrationConfig } from './config'
import { useDelistingRowParser } from '#delisting/parser'
import { delistingType } from '#delisting/constants'
import { AuthorizationPatch } from '#authorization/model'
import isAfter from 'date-fns/isAfter';


type MigrationParseContext = ParseContext<XlsXParseContext & {
    images: ImageMap
    imagesProcessed: number
    imagesUnresolved: number,
    tenant?: TenantReference
}>


export const useMigration = (rows?: number) => {

    const config = rows ? { ...migrationConfig, rowRadius: rows } : migrationConfig

    const itemParser = useMigrationItemParser()
    const modelparser = useDefaultModelParser(itemParser)
    const bookparser = useDefaultBookParser(modelparser, () => config)

    const parse = useDefaultParser<RavRecord, MigrationParseContext>({ modelparser, bookparser })

    const migrate = async (props: { file: File, images: ImageMap }) => {

        const { file, images } = props

        console.log(`migrating ${rows || 'all'} record from ${file.name}`)

        const resource = { id: file.name, name: file.name, file }

        const ctx = { rows, images, imagesProcessed: 0, imagesUnresolved: 0 }

        var outcome = await parse(resource, ctx)

        return { ...outcome, images: ctx.imagesProcessed, imageUnresolved: ctx.imagesUnresolved }

    }

    const task = useAsyncTask()

    return task.make(migrate).with(config => config.notify().wait(100).show(({ file }) => `migrating ${rows || 'all'} records...`)).done()

}

type MigrationItem = DetailsJsonSerialization & AuthorizationJsonSerialization & {

    timestamp: string
    vkey: string
    sorting: number

}


const useMigrationItemParser = () => {

    const vids = useVID()


    const tenants = utils().index(useTenantStore().all()).by(t => t.code)

    const parseDetails = useDetailsRowParser()
    const parseAuthz = useAuthorizationRowParser()
    const parsePhoto = usePhotographMigrationRowParser()
    const parseDelisting = useDelistingRowParser()

    return (row: MigrationItem, ctx: MigrationParseContext): RavRecord => {

        const now = new Date()

        const timestamp = new Date(row.timestamp)

        const tenant = tenants[row.flagstate!]?.id

        ctx.tenant = tenant
       
        const detailsPatch = parseDetails(row, ctx)
        const authzPatch = parseAuthz(row, ctx)

        const noAuthzInfo = !authzPatch.authorization.from && !authzPatch.authorization.to

        const isDelisted = !row.sorting || noAuthzInfo


        //Fix the authzPatch with delisted info if authorized to is after now and record is delisted
        const authzPatchWithDelisted = isDelisted ?
            {
                ...authzPatch,
                authorization : {
                    from: authzPatch.authorization.from,
                    to: authzPatch.authorization.to && isAfter(now, new Date(authzPatch.authorization.to)) ? authzPatch.authorization.to : timestamp.toISOString()
                }
            } as AuthorizationPatch : authzPatch

        const photoPatch = parsePhoto(row, ctx)

        const delistRecordToPatch = {
            reason: 'TG-reason-notauthorized',
            note: '(Delisted on import from legacy data)',
            date: new Date(authzPatchWithDelisted.authorization.to)
        }

        const delistPatch = isDelisted ? parseDelisting(delistRecordToPatch, ctx) : undefined
        

        detailsPatch.details.timestamp = timestamp.toISOString()
        authzPatch.authorization.timestamp = timestamp.toISOString()
        photoPatch.photograph.timestamp = timestamp.toISOString()

        return {

            ...detailsPatch,
            ...(isDelisted ? authzPatchWithDelisted : authzPatch),
            ...photoPatch,
            ...(isDelisted ? {...delistPatch, [delistingType] : {...delistPatch?.[delistingType], timestamp: now.getTime()}}  : {}),

            id: `R-${shortid()}`,
            uvi: row.uvi ? vids.stringify(iotcnumberscheme, row.uvi) : undefined!,
            tenant,
            timestamp: timestamp.toISOString(),
            origin: undefined!,

            lifecycle: {
                state: 'published',

                lastModified: timestamp.getTime(),
                created: timestamp.getTime(),
                lastModifiedBy: 'apprise-support'
            }

            ,

            patchedSlots: isDelisted ? [delistingType] : [detailsType, authorizationType, photographType],


        } as RavRecord

    }

}



const usePhotographMigrationRowParser = () => {

    const baseParser = usePhotographsItemParser()

    const parser = (row: MigrationItem, ctx: MigrationParseContext) => {

        const { images } = ctx

        const baseParsedRow = baseParser(row, ctx)

        baseParsedRow.photograph.photos = baseParsedRow.photograph.photos .filter(photo => photo.ref).map(photo => {


            if (typeof photo.ref !== 'string') {
                console.log({ skipped: photo.ref })
                return
            }

            // heuristic to avoid mis-attributions: file names include vessel key, so we use that to throw away likely mis-attributions.
            // if (!photo.ref.toLocaleLowerCase().match(new RegExp(`${row.vkey}[^0-9]`))) {
            //     const error = new Error(`Likely cross-reference: ${photo.ref} with IOTC Number ${row.uvi} and vessel key ${row.vkey}`)
            //     error['type'] = 'warning'
            //     //throw error
            // }

            const match = images[photo.ref.toLowerCase()]

            // if we can't resolve an image reference, we keep
            if (!match || !match.file) {
                console.warn(`No matching photo found for ${photo.ref} with IOTC Number ${row.uvi}`)
                ctx.imagesUnresolved++

                // const error = new Error(`No matching photo found for ${photo.ref} with IOTC Number ${row.uvi}`)
                // error['type'] = 'warning'
                //throw error
                return
            } 

            match.uvis[row.vkey] = true

            if (match?.stream) {

                photo.ref = match.stream

            }
            else {

                ctx.imagesProcessed++

                const assetId = `BS-photo-${row.uvi}-${match.file.name.replace(/\s/, '')}`

                // const stream = newPhotoBytestreamFrom(match.file, assetId,`${assetId}-derivative-${thumbnailWidth}-${shortid()}`)

                const stream = newPhotoBytestreamFrom(match.file, assetId,`${assetId}-derivative-${thumbnailWidth}`)

                const now = Date.now()

                const asset: Asset = {

                    ...stream,

                    id: assetId,

                    properties: { ...stream.properties, path: photo.ref, width: match.width, height: match.height },

                    lifecycle: {

                        state: "created",
                        created: now,
                        lastModified: now,
                        lastModifiedBy: "apprise-support"

                    }
                }

                photo.ref = asset

                match.stream = asset

            }

            return photo  as Photo

        }).filter(photo => !!photo) as Photo[]

        return baseParsedRow
    }

    return parser

}