
import { useL } from 'apprise-frontend-core/intl/multilang';
import { Optional } from 'apprise-frontend-core/utils/common';
import { TenantReference, useTenantModel } from './model';
import { useTenantStore } from './store';




export const useTenantUtils = () => {

    const l = useL()
    
    const store = useTenantStore()
    const model = useTenantModel()

    const self = {

        nameOf: (t: Optional<TenantReference>) => l(store.safeLookup(t).name),

        codeOf: (t: Optional<TenantReference>) => store.safeLookup(t).code,

        textOf: (t: Optional<TenantReference>) => model.textOf(store.lookup(t)),

        refComparator: (t1: Optional<TenantReference>, t2: Optional<TenantReference>) => model.comparator(store.lookup(t1),store.lookup(t2))

    }

    return self;

}