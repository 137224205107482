import { PointerEvent, useLayoutEffect, useRef, useState } from "react";
import './styles.scss';


export enum FocusModeClasses {
    FOCUS_MODE = 'focus-mode',
    HOVER_LEFT = 'focus-mode-hover-left',
    HOVER_TOP = 'focus-mode-hover-top',
    FOCUS_MODE_AWARE_TOP = 'focus-mode-aware-top',
    FOCUS_MODE_AWARE_LEFT = 'focus-mode-aware-left',
}

const awareTopSelector = `.${ FocusModeClasses.FOCUS_MODE_AWARE_TOP }`
const awareLeftSelector = `.${ FocusModeClasses.FOCUS_MODE_AWARE_LEFT }`
const activeLeftArea = 15
const activeTopArea = 15


type Point = {
    x: number
    y: number
}


export const useFocusMode = () => {
    // const { hackAutoResize } = props;
    const focusModeRef = useRef<boolean>(false);
    const positionRef = useRef<Point>({ x: 0, y: 0 });
    const elementRef = useRef<HTMLDivElement>(null);
    const topHoverRef = useRef<boolean>(false);
    const letHoverRef = useRef<boolean>(false);
    // eslint-disable-next-line
	const [ _, refresh ] = useState<number>(0);

    const getScope = () => {
        const { current: pos } = positionRef;
        const { current: elem } = elementRef;
        const { current: topHover } = topHoverRef;
        const { current: leftHover } = letHoverRef;
        const { current: focusMode } = focusModeRef;

        return { pos, elem, topHover, leftHover, focusMode };
    };

    const onMove = ({ clientX: x, clientY: y, type }: PointerEvent<HTMLDivElement>) => {
        const { pos, elem, topHover, leftHover, focusMode } = getScope();

        if (!focusMode || !elem) {
            return;
        }
        pos.x = x | 0;
        pos.y = y | 0;
        if ((y <= activeTopArea && !topHover && !leftHover) || (type === 'click' && topHover)) {
            const topDom = elem.querySelector(awareTopSelector);

            if (!topDom) {
                return
            }
            topDom.addEventListener('click', onTopLeave as any);
            topDom.addEventListener('pointerleave', onTopLeave as any);
            elem.classList.add(FocusModeClasses.HOVER_TOP);
            topHoverRef.current = true;
            //console.log('HOVER TOP!');
        }
        if ((x <= activeLeftArea && !topHover && !leftHover) || (type === 'click' && leftHover)) {
            const leftDom = elem.querySelector(awareLeftSelector);

            if (!leftDom) {
                return
            }
            leftDom.addEventListener('click', onLeftLeave as any);
            leftDom.addEventListener('pointerleave', onLeftLeave as any);
            elem.classList.add(FocusModeClasses.HOVER_LEFT);
            letHoverRef.current = true;
            //console.log('HOVER LEFT!');
        }
    };

    const onTransitionEnd = ({ propertyName }) => {
		const { elem } = getScope();

		if (elem === null || (
			propertyName !== 'width' && propertyName !== 'height'
		)) {
			return;
		}
        //HackResizer?.disable(false);
    }

    const onTopLeave = (e: PointerEvent, { type } = e) => {
        const { pos: { y }, elem, topHover, focusMode } = getScope();

        if (focusMode && topHover && elem && y > activeTopArea) {
            const topDom = elem.querySelector(awareTopSelector);

            if (!topDom) {
                return
            }
            //HackResizer?.disable();
            topDom.removeEventListener('pointerleave', onTopLeave as any);
            topDom.removeEventListener('click', onTopLeave as any);
            if (type === 'click') {
                setTimeout(() => onMove(e as any), 50);
            } else {
                topHoverRef.current = false;
                elem.classList.remove(FocusModeClasses.HOVER_TOP);
                //console.log('LEAVE TOP!');
            }
        }
    };

    const onLeftLeave = (e: PointerEvent, { type } = e) => {
        const { pos: { x }, elem, leftHover, focusMode } = getScope();

        if (focusMode && leftHover && elem && x > activeLeftArea) {
            const leftDom = elem.querySelector(awareLeftSelector);

            if (!leftDom) {
                return
            }
            //hackAutoResize && HackResizer.disable();
            leftDom.removeEventListener('pointerleave', onLeftLeave as any);
            leftDom.removeEventListener('click', onLeftLeave as any);
            if (type === 'click') {
                setTimeout(() => onMove(e as any), 50);
            } else {
                letHoverRef.current = false;
                elem.classList.remove(FocusModeClasses.HOVER_LEFT);
                //console.log('LEAVE LEFT!');
            }
        }
    };

    const toggleFocusMode = () => {
        const { elem, focusMode } = getScope();
        const state = !focusMode;

        if (!elem) {
            return
        }
        //hackAutoResize && HackResizer.disable();
        if (state) {
            elem.classList.add(FocusModeClasses.FOCUS_MODE);
        } else {
            letHoverRef.current = false;
            topHoverRef.current = false;
            elem.classList.remove(
                FocusModeClasses.FOCUS_MODE,
                FocusModeClasses.HOVER_LEFT,
                FocusModeClasses.HOVER_TOP
            );
        }
        focusModeRef.current = state;
        setTimeout(() => refresh(i => ++i));
    }

    useLayoutEffect(() => {
        const { current: elem } = elementRef;

        if (!elem) {
            return;
        }
        elem.style.setProperty('--top-size-area', `${activeTopArea}px`);
        elem.style.setProperty('--left-size-area', `${activeLeftArea}px`);
        elem.addEventListener('transitionend', onTransitionEnd as any, { capture: true });
        document.addEventListener('pointermove', onMove as any, { passive: true, capture: true });

        return () => {
            elem.removeEventListener('transitionend', onTransitionEnd as any, { capture: true });
            document.removeEventListener('pointermove', onMove as any, { capture: true });
        };
	// eslint-disable-next-line
    }, []);

    return {
        elementRef
        ,
        toggleFocusMode
        ,
        focusModeEnabled: focusModeRef.current
    };
}