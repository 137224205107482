import { useT } from 'apprise-frontend-core/intl/language'
import { utils } from 'apprise-frontend-core/utils/common'
import { PastModeIcon } from 'apprise-ui/field/field'
import { LabelProps } from 'apprise-ui/label/label'
import { Tip } from 'apprise-ui/tooltip/tip'



export type ChangeLabelProps<T> = LabelProps & {

    before: T | undefined
    after: T

    render?: (_: T) => React.ReactNode

    beforeMode?: 'current' | 'previous'

}

export const ChangeLabel = <T extends any>(props: ChangeLabelProps<T>) => {

    const t = useT()

    const { before, after, render = t => (t as JSX.Element), beforeMode = 'current' } = props

    const $render = (t: T) => <>{render(t)}</>

    return before && !utils().deepequals(after,before) ?

        <div className='apprise-row'>
            {$render(after)}
            &nbsp;
            <Tip tip={<><span>{t(`rec.change_${beforeMode}`)}</span>{$render(before)}</>} >
                <PastModeIcon color='seagreen' />
            </Tip>

        </div> : $render(after)
}