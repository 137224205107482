import { useT } from 'apprise-frontend-core/intl/language'
import { Button } from 'apprise-ui/button/button'
import { ChoiceBox } from 'apprise-ui/choicebox/choicebox'
import { classname } from 'apprise-ui/component/model'
import { Form } from 'apprise-ui/form/form'
import { SliderBox } from 'apprise-ui/sliderbox/slider'
import { useAsyncTask } from 'apprise-ui/utils/asynctask'
import { Explainer, ExplainerPanel } from 'apprise-ui/utils/explainer'
import { useState } from 'react'
import { ImDownload } from 'react-icons/im'
import { useSearch } from './api'
import './downloadpanel.scss'
import { useResultSerializer } from './excelserializer'

const max_download_size = 3000


export const DownloadPanel = () => {

    const t = useT()

    const task = useAsyncTask()

    const search = useSearch()

    const { pageSize, total } = search.get()

    const pages = Math.ceil(total / pageSize)
    const maxPages = Math.floor(max_download_size / pageSize)

    const tooManyResults = total > max_download_size
    const defaultScope = tooManyResults ? 'current' : 'all'

    const [scope, scopeSet] = useState<'current' | 'all' | 'range'>(defaultScope)

    const [pagerange, pagerangeSet] = useState([1, maxPages] as [number, number])

    const [mode, modeSet] = useState<'codes' | 'names'>('codes')

    const [firstPage, lastPage] = pagerange

    const tooManyPages = scope === 'range' && (lastPage - firstPage >= maxPages)

    const marksArray = [1, firstPage, lastPage, pages]

    const marks = marksArray.filter((n, i) => marksArray.indexOf(n) === i).reduce((acc, next) => ({ ...acc, [next]: <span>{next}</span> }), {})

    const dto = search.getSearchDto()

    const write = useResultSerializer()

    const download = task.make(async () => {

        const cursor = { offset: (firstPage - 1) * pageSize, limit: (lastPage - (firstPage-1)) * pageSize }

        const { results } = scope === 'current' ? search.get() : await search.call({ ...dto, cursor })

        const output = write({ mode, data: results, sheetname: t('search.download_sheetname') })

        output.save(`${t('search.download_filename', { date: new Date().toJSON() })}.xlsx`)

        
    }).with($ => $.show(t("search.downloading"))).done()

    return <ExplainerPanel className='download-panel'>

        <Explainer icon={ImDownload}>
            {t("search.download_explainer")}
            <br />
            {t("search.download_explainer2", { max: max_download_size })}
        </Explainer>

        <Form centered>

            <ChoiceBox radio value={scope} onChange={v => scopeSet(v!)}>
                <ChoiceBox.Option disabled={tooManyResults} value='all' title={t('search.download_all')} />
                <ChoiceBox.Option disabled={pages<2}value='current' title={t('search.download_current')} />
                <ChoiceBox.Option disabled={pages<2} value='range' title={t('search.download_range')} />
            </ChoiceBox>

            <SliderBox.Interval label={t('search.download_range_lbl')} centered className={classname('page-range', scope === 'range' && 'range-active')}
                status={tooManyPages ? 'error' : 'success'}
                msg={tooManyPages ? t('search.download_range_error', { max: maxPages }) : undefined}
                marks={marks}
                min={1}
                max={pages}
                onChange={range => range && pagerangeSet(range)}>
                {pagerange}
            </SliderBox.Interval>

            <br />
        </Form>

        <Explainer>
            {t("search.download_explainer3")}
        </Explainer>

        <Form centered>

            <ChoiceBox radio value={mode} onChange={v => modeSet(v ?? 'codes')}>
                <ChoiceBox.Option value='codes' title={t('search.download_codes')} />
                <ChoiceBox.Option value='names' title={t('search.download_names')} />
                <ChoiceBox.Option value='both' title={t('search.download_codes_and_names')} />
            </ChoiceBox>

        </Form>

        <Button className='download-btn' noReadonly type="primary" disabled={tooManyPages} onClick={download}>
            {t('search.download_btn')}
        </Button>



    </ExplainerPanel>

}

