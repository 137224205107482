import { bowTag, portsideTag, starboardTag } from '#details/constants'
import { JsonSerializationProps, useGenericJsonSerializer } from '#record/jsonserializer'
import { isBytestream } from 'apprise-frontend-streams/model'
import { photographType } from './constants'
import { PhotographsPatch } from './model'


export type PhotographsJsonSerialization = Partial<{

    uvi: string,
    starboard: string | undefined,
    portside: string | undefined,
    bow: string | undefined

}>

export const usePhotographJsonSerializer = () => {

    const serializer = (props: JsonSerializationProps<PhotographsPatch>): PhotographsJsonSerialization => {

        const { patch } = props

        const { photos=[] } = patch[photographType] ?? {}

        const starboardRef = photos?.find(p => p.type === starboardTag)?.ref 

        const starboard = isBytestream(starboardRef) ?  starboardRef.properties.path : starboardRef 

        const portsideRef = photos?.find(p => p.type === portsideTag)?.ref 

        const portside = isBytestream(portsideRef) ?  portsideRef.properties.path : portsideRef 

        const bowRef = photos?.find(p => p.type === bowTag)?.ref 

        const bow = isBytestream(bowRef) ?  bowRef.properties.path : bowRef 

        return { starboard, portside, bow }
    }

    return useGenericJsonSerializer(serializer)

}
