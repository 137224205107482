import { authorizationType } from '#authorization/constants';
import { useAuthorizationPlugin, useAuthorizationPluginDescriptor } from '#authorization/plugin';
import { baseComboType } from '#basecombo/mode';
import { useBaseComboPlugin, useBaseComboPluginDescriptor } from '#basecombo/plugin';
import { delistingType } from '#delisting/constants';
import { useDelistingPlugin, useDelistingPluginDescriptor } from '#delisting/plugin';
import { detailsType, VesselNameIcon } from '#details/constants';
import { useDetailsPlugin, useDetailsPluginDescriptor } from '#details/plugin';
import { photographType } from '#photographs/constants';
import { usePhotographPlugin, usePhotographPluginDescriptor } from '#photographs/plugin';
import { Submission, SubmissionType } from '#submission/model';
import { trxauthzType } from '#trxauth/constants';
import { useTrxAuthzPlugin, useTrxAuthzPluginDescriptor } from '#trxauth/plugin';
import { SlotCardProps, SlotFormProps } from '#vessel/overview';
import { useT } from 'apprise-frontend-core/intl/language';
import { ParseContext, ResourceParser } from 'apprise-frontend-parse/model';
import { Fields } from 'apprise-ui/field/validation';
import { Grid } from 'apprise-ui/grid/grid';
import { Label } from 'apprise-ui/label/label';
import { Column } from 'apprise-ui/table/table';
import { useTableUtils } from 'apprise-ui/table/utils';
import { ContextualFilterProps } from 'apprise-ui/utils/filter';
import { Todo } from 'apprise-ui/utils/todo';
import { FC } from 'react';
import { FiType } from 'react-icons/fi';
import { IconBaseProps } from 'react-icons/lib';
import { PatchCardProps } from '../submission/patchdetail';
import { PatchAndCurrent } from '../submission/records';
import { AssetResolver } from './assetresolver';
import { ExcelRecordSerializer, ExcelRecordWriter, useGenericExcelSerializer } from './excelserializer';
import { JsonRecordSerializer } from './jsonserializer';
import { GenericRecord, SlotType } from './model';
import { SubmissionParseContext } from './parser';
import { RecordValidator } from './validator';


//  record plugins are delegation points for handling different types of records, eg. in submissions or search.
//  they are looked up by generic clients that work on one or more record types at the time.


//  NOTE: plugins are hooks and so they can be called only top-level in components.
//  this makes them harder to look up dynamically via a registry.
//  we can either:
//  1. call them all in the registry, and then lookup one.
//  2. lookup one in the registry and let the client call it.
//  in the first case, clients can call the registry top-level, then lookup from plain functions inside loops.
//  in the second case, clients _must_ look up top-level in a component.
//  the first case is more flexible, but it's a performance hotspot: 
//  clients initialise all plugins each time they call the registry, even when they need only one plugin at the time.
//  similarly, client depend on all plugins, so re-render on contextual changes in the deep closure of all their dependencies.
//  the second case is less flexible, but it incurs no overhead.
//  we use both solutions to cater for different use cases: 
//  1. clients that work with a single record type at the time can look it up directly with useRecordPlugin().
//  2. clients that work with multiple record types at the same time can call useRecordPlugins(), and then call lookup() from plain functions inside loops.
//  we also use a third solution for clients that work with multiple record types but only to access descriptive properties,
//  (selectors, filters, labels). descriptors are cheap to produce and the task requires limited and shallow dependency closures.
//  3. these clients can then call useRecordPluginDescriptors() and call lookup() from plain functions inside loops.



export type RecordPluginDescriptor = {

    name: string
    Icon: FC<IconBaseProps>

    singular: string
    plural: string

    useAssets: boolean

    patchedTypes: SlotType[]

}


export type RecordPlugin<T extends GenericRecord = GenericRecord> = RecordPluginDescriptor & {


    /** the generic columns to use for records in a submission.  */
    currentColumns: () => JSX.Element | JSX.Element[]

    /** the patch-specific columns to present for record in a submission.  */
    patchColumns: (submission: Submission<T>) => JSX.Element | JSX.Element[]

    patchFilters: (_:FilterProps<T>) => { data: GenericRecord[], filters: JSX.Element[]}
   
    Patch: FC<PatchCardProps<T>>

    Overview?: FC<SlotCardProps<T>>

    Form?: FC<SlotFormProps>

    formFields: (_:SlotFormProps) => Fields

    parse: () => ResourceParser<T, ParseContext<SubmissionParseContext>>

    validate: () => RecordValidator<T>

    jsonserialize: () => JsonRecordSerializer<T>

    excelserialize: () => ExcelRecordSerializer<T>

    excelwrite: () => ExcelRecordWriter<T>

    resolveAssets: () => AssetResolver<T>

    newSlot: () => any

    /** the text extracted from a submission for filtering. */
    stringify: (patch: T) => string
    
}


export type FilterProps<T extends GenericRecord = GenericRecord> = ContextualFilterProps & {

    submission: Submission<T>
    data: T[]
}

// a registry of a plugin descriptors.
// intended for generic componenents that work with multiple times simultaneously, typically in loops (see NOTE above).
export const useRecordPluginDescriptors = () => {

    const plugins = {

        [baseComboType]: useBaseComboPluginDescriptor(),
        [detailsType]: useDetailsPluginDescriptor(),
        [authorizationType]: useAuthorizationPluginDescriptor(),
        [photographType]: usePhotographPluginDescriptor(),
        [delistingType]: useDelistingPluginDescriptor(),
        [trxauthzType]: useTrxAuthzPluginDescriptor()

    }

    const self = {

        allTypes: () => Object.keys(plugins) as SubmissionType[]

        ,

        all: () => Object.values(plugins)

        ,

        lookup: (type: SubmissionType) => plugins[type] as RecordPluginDescriptor

    }

    return self


}


// resolves a submission type in the plugin that handles it.
// intended for generic components that work with one type at the time (see NOTE above).
export const useRecordPlugin = <T extends GenericRecord>(type: SubmissionType) => {

    const plugins = {

        [baseComboType]: useBaseComboPlugin,
        [detailsType]: useDetailsPlugin,
        [authorizationType]: useAuthorizationPlugin,
        [photographType]: usePhotographPlugin,
        [delistingType]: useDelistingPlugin,
        [trxauthzType]: useTrxAuthzPlugin
    
    }


    // unsafe junction: client indicates what static type T it expects back, but there's
    // no formal link between T and the runtime type the client passes in as a key for lookup.
    // eg could declare the type of details and pass in the type of authorizations.
    // we could  
    return plugins[type]() as RecordPlugin<T>

}


// a registry of record plugins.  
// intended for generic componenents that work with multiple times simultaneously, typically in loops (see NOTE above).
export const useRecordPlugins = () => {

    
    const plugins = {

        [baseComboType]: useBaseComboPlugin(),
        [detailsType]: useDetailsPlugin(),
        [authorizationType]: useAuthorizationPlugin(),
        [photographType]: usePhotographPlugin(),
        [delistingType]: useDelistingPlugin(),
        [trxauthzType] : useTrxAuthzPlugin()
    
    }

    const self = {


        allTypes: () => Object.keys(plugins) as SubmissionType[]

        ,

        // unsafe junction: client indicates what static type T it expects back, but there's
        // no formal link between T and the runtime type the client passes in as a key for lookup.
        lookup: <T extends GenericRecord> (type: SubmissionType) => plugins[type] as RecordPlugin<T>
    
    }

    return self

}


const useDefaultSerializer= () => useGenericExcelSerializer(()=>{})

// defaults for the partial/incremental implementation of record plugins.
export const useDefaultRecordPlugin = () => {

    const t = useT()
    
    const { compareStringsOf } = useTableUtils<PatchAndCurrent>()

    return {

        Icon: FiType
        
        ,

        name: 'unknown'

        ,

        useAssets: false
        ,

        newSlot: () => ({}) as any

        ,

        stringify: () => ``

        ,

        // dummy impl based on a parser that returns no records.
        parse: () => () => Promise.resolve(({ data: [], issues: []  }))

        ,

        resolveAssets: () => ({
            
            resolve:() => {},
            restore:() =>{},
            matches: () => ({})
        }) as AssetResolver<any>

        ,

        validate: () => () => ({})

        ,

        jsonserialize: () => () => ({})

        ,

        excelserialize: useDefaultSerializer

        ,

        excelwrite:  () => (()=>{})


        ,

        Form: () => <Todo fit size={24} />

        ,

        formFields: () => ({} as Fields)

        ,

        Patch: () => <Todo fit size={24} />

        ,

        Overview: () => <Grid />
        
        ,

        /** by default, extracts the name of the vessel. */
        currentColumns: () => <>

            <Column<PatchAndCurrent> defaultLayout width={200} name='vesselname' title={<Label icon={<VesselNameIcon />} title={t('rec.vesselname_col')} />}
                text={r => r.current?.details.name}
                sort={compareStringsOf(r => r.current?.details.name)}
                render={r => r.current?.details.name} />

        </>

        ,

        patchFilters: (props: FilterProps<GenericRecord>) => ({data:props.data,filters:[]})


        ,

        patchColumns: () => []


    }

}