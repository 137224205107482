
import { RavConfig } from '#config'
import { useConfig } from 'apprise-frontend-core/config/api'
import { useT } from 'apprise-frontend-core/intl/language'
import { Button } from 'apprise-ui/button/button'
import './upgradebutton.scss'

export const UpgradeButton = () => {

    const t = useT()

    const config = useConfig<RavConfig>()

    return config.latest ?

        null :

        <Button noReadonly className='upgradebutton' size='small' tipPlacement='bottom' tip={t('upgrade_btn_tooltip')} onClick={() => window.location.reload()}>
            {t('upgrade_btn')}
        </Button>

}