
import { useT } from 'apprise-frontend-core/intl/language'
import { Optional } from 'apprise-frontend-core/utils/common'
import { logoutBaseUrl } from 'apprise-frontend-iam/login/constants'
import { useLogged, useLogin } from 'apprise-frontend-iam/login/logged'
import { Button } from 'apprise-ui/button/button'
import { Menu } from 'apprise-ui/menu/menu'
import { useTableData } from 'apprise-ui/table/provider'
import { useCopytoClipboard } from 'apprise-ui/utils/copytoclipboard'
import { MdLogout } from 'react-icons/md'
import { userTableName } from './list'
import { User, UserReference, useUserModel } from './model'
import { useUserStore } from './store'

import './styles.scss'

export const useUserUtils = () => {

    const t = useT()
    const store = useUserStore()
    const model = useUserModel()
    const logged = useLogged()
    const { logout } = useLogin()

    const self = {

        nameOf: (u: Optional<UserReference>) => model.fullName(store.safeLookup(u))

        ,

        userBadge: <div style={{ fontSize: 'small' }}>{model.fullName(logged)}</div>


        ,

        loginBtn: <Button noReadonly style={{ fontSize: 'small' }} type='ghost' onClick={() => { window.location.href = `${logoutBaseUrl}${window.location.pathname}` }}>{t('user.login')}</Button>

        ,

        logoutMenuItem: <Menu.Item noReadonly title={t('user.logout')} icon={<MdLogout />} onClick={() => logout()} />

        ,

        textOf: (t: Optional<UserReference>) => t ? model.textOf(store.lookup(t)) : ''

        ,

        refComparator: (t1: Optional<UserReference>, t2: Optional<UserReference>) => model.comparator(store.lookup(t1), store.lookup(t2))

        ,

        remotelogoutMenuItem: <Menu.Item noReadonly title={t('user.logout')} icon={<MdLogout />} onClick={() => { window.location.href = `${logoutBaseUrl}${window.location.pathname}` }} />

    }


    return self

}


export const useUserEmailCopyToClipboard = () => {

    const t = useT()

    const tabledata = useTableData<User>(userTableName)

    const filteredOrSelected = (tabledata.selected.length ? tabledata.selected : tabledata.data).filter(u => u.lifecycle.state === 'active')

    const { copyBtn } = useCopytoClipboard({
        title: t("user.copy_emails", { count: filteredOrSelected.length }),
        text: () => filteredOrSelected.map(u => u.details.email).join(';')
    })

    return copyBtn

}