import { ActionDto } from 'apprise-frontend-core/authz/action'
import { Multilang } from 'apprise-frontend-core/intl/multilang'
import { noTenant } from 'apprise-frontend-iam/tenant/model'
import { TOptions } from 'i18next'


export type Mail = {

    target: MailTarget;
    topic: MailTopic;
    content:  MailContent

}

// broadcast, only for admins, or for selected users (colon-separated user names).
export type MailTarget = 'all' | typeof noTenant | string

export type MailTopic = MailTopicDto & {

    // frontend-only, labels the topic. 
    key: string    

}

export const noMailTopic: MailTopic = {key: 'mail.no_topic', value: 'none'}


export type MailTopicDto = {

    // actual identifier.
    value: string

    // intended audience for this topic:  who can subscribe to this topic and receive messages, based on permissions.
    audience?: TopicAudience

}

export type TopicAudience = ActionDto[]

export type MailContent = EmbeddedMailContent | TemplatedMailContent

// mail content and subject prepared by the client for all languages, and embedded in events.
// mail targets will get both in their language of preference.
export type EmbeddedMailContent = {

    subject: Multilang
    text: Multilang
}

// mail content and subjects are generated by the server by injecting params into some template.
export type TemplatedMailContent = {

    template: string
    parameters: TParams
}

// content params: a keyed piece of:
// 1. text, optionally in multiple languages. mail targets get the content that matches their language of preference.
// 2. the arguments for a call that resolves to the above.
export type TParams = Record<string, Multilang | TArgs | string>

export type TArgs = {

    // the key that identifies the target text in a lang-independent manner.
    key: string

    // the parameters to inject in the text identified by the key.
    // this is recursive: each param could be text or the params to a call that resolves to text.
    params?: TParams

    // various call options, based on i18next.
    options?: TOptions

}
