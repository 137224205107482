import { TenantPreferences, partyFlagType, partyTenantTypeCategory } from '#modules/tenants'
import { RecordState } from '#record/model'
import { useRecordPluginDescriptors } from '#record/plugin'
import { useAppSettings } from '#settings/settings'
import { useActions } from 'apprise-frontend-core/authz/action'
import { useMode } from 'apprise-frontend-core/config/api'
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant'
import { useUserOracle } from 'apprise-frontend-iam/authz/user'
import { useLogged } from 'apprise-frontend-iam/login/logged'
import { TenantDto, TenantReference } from 'apprise-frontend-iam/tenant/model'
import { useTenantStore } from 'apprise-frontend-iam/tenant/store'
import { User } from 'apprise-frontend-iam/user/model'
import { SubmissionIcon, submissionType } from './constants'
import { Submission, useSubmissionModel } from './model'
import { ValidationState } from './validator'
import { useTagStore } from 'apprise-frontend-tags/tag/store'




export const base = { icon: <SubmissionIcon />, type: submissionType }


const manage = { ...base, labels: ["manage"], shortName: "action.manage_short", name: "action.manage_name", description: "action.manage_desc" }
const edit = { ...base, labels: ["manage", "edit"], virtual: false, shortName: "action.edit_short", name: "action.edit_name", description: "action.edit_desc" }



export const submissionAdminActions = {

    manage,
    edit,
    publish: { ...base, labels: ["manage", "publish"], virtual: true, shortName: "action.publish_short", name: "action.publish_name", description: "action.publish_desc" }
}

export const submissionTenantActions = {

    manage,
    edit,
    approve: { ...base, labels: ["manage", "approve"], virtual: true, shortName: "action.approve_short", name: "action.approve_name", description: "action.approve_desc" }
}

export const submissionActions = { ...submissionAdminActions, ...submissionTenantActions }




export const useSubmissionOracle = () => {

    const loggedUser = useLogged()
    const tenancyOracle = useTenancyOracle()
    const userOracle = useUserOracle()
    const actions = useActions()
    const model = useSubmissionModel()
    const mode = useMode()
    const tenants = useTenantStore()
    const settings = useAppSettings()
    const plugins = useRecordPluginDescriptors()
    const tags = useTagStore()


    const can = (user: User = loggedUser) => {

        const allTenantTypesTags = tags.allTagsOfCategory(partyTenantTypeCategory).map(t => t.id).filter(id => id !== partyFlagType)

        const flagStateFilter = (t: TenantDto) => {
            const empty = t.tags.length === 0
            const hasPartyFlag = t.tags.includes(partyFlagType)
            if (empty || hasPartyFlag) return true

            return !t.tags.map( id => allTenantTypesTags.includes(id)).some(res => res === true)
        }

        const flagstates = tenants.all().filter(flagStateFilter)?.map(t => t.id)

        const oracle = { ...tenancyOracle.given(user), ...userOracle.given(user) }

        return {

            // country must be flagstate and user must have generic edit permissions in the scope of this tenant,
            createFor: (t: TenantReference | undefined) => !t || ( flagstates.includes(t) &&  oracle.can(submissionActions.edit,t)),
            editAll: () => oracle.can(submissionActions.edit),
            edit: (s: Submission) => oracle.can(actions.specialise(submissionActions.edit, s.id), s.tenant),
            publish: (s: Submission) => oracle.hasNoTenant() && oracle.can(actions.specialise(submissionActions.publish, s.id), s.tenant),
            approve: (s: Submission) => oracle.can(actions.specialise(submissionActions.approve, s.id),s.tenant)
        }
    }


    const apiFor = (user?: User) => {

        const self = {

            given: apiFor

            ,

            canAddNew: can(user).editAll

            ,


            canAddFor: can(user).createFor

            ,

            canUploadResources: (submission: Submission) => self.canEdit(submission) && submission.lifecycle.state === 'draft'

            ,


            canRemove: (submission?: Submission) => {

                if (submission) {

                    const state = submission.lifecycle.state === 'draft' || mode.development

                    return state && can(user).edit(submission)

                }

                return can(user).editAll()

            }

            ,

            canEdit: (submission: Submission) => {

                const state = submission.lifecycle.state

                return (state === 'draft' && can(user).edit(submission)) || (state === 'pending' && can(user).approve(submission)) || (state === 'submitted' && can(user).publish(submission))

            }

            ,

            needsApproval: (submission: Submission) => {

                const preferences = tenants.safeLookup(submission.tenant).preferences as TenantPreferences

                const cycle = preferences.approveCycle ?? settings.approveCycle

                return cycle === 'all' || cycle?.includes(submission.type)
            }


            ,

            canValidate: (submission: Submission) =>  (submission.lifecycle.state === 'draft' && submission.records?.length > 0) || submission.lifecycle.state==='submitted'

            ,

            canMatch: (submission: Submission) => submission.lifecycle.state === 'draft' && submission.records?.length > 0


            ,

            canSubmitForApproval: (submission: Submission) => {

                const state = submission.lifecycle.state === 'draft' && !!self.needsApproval(submission)

                return state && can(user).edit(submission)

            }

            ,

            canSubmit: (submission: Submission) => {

                if (self.needsApproval(submission)) {

                    const state = submission.lifecycle.state === 'pending'

                    return state && can(user).approve(submission)

                }
                else {

                    const state = submission.lifecycle.state === ('draft')

                    return state && can(user).edit(submission)

                }

            }

            ,

            canPublish: (submission: Submission) => {

                const state = submission.lifecycle.state === 'submitted' && model.unresolvedIssues(submission) === 0

                return state && can(user).publish(submission)

            }

            ,

            canReject: (submission: Submission) => {

                const state = submission.lifecycle.state === 'submitted' 

                return state && can(user).publish(submission)

            }

            ,

            canRestore: (submission: Submission) => {

                const state = submission.lifecycle.state === 'rejected' 

                return state && can(user).publish(submission)

            }

            ,

            canUploadAssets: (submission: Submission) => plugins.lookup(submission.type).useAssets && submission.lifecycle.state === 'draft'

            ,

            alreadySubmitted: (submission: Submission) => submission.lifecycle.state === 'submitted' || submission.lifecycle.state === 'published'


            ,

            recordStatesFor: (submission: Submission): RecordState[] => {

                switch (submission.lifecycle.state) {

                    case 'draft':
                    case 'pending': return ['ignored', 'uploaded']
                    case 'rejected':
                    case 'submitted': return ['ignored', 'submitted', 'rejected']
                    case 'published':
                    default: return ['ignored', 'rejected', 'published']

                }
            }

            ,

            validationState: (submission: Submission): ValidationState | 'flagged' => {

                return submission.stats?.errors > 0 ? 'invalid' : submission.stats.warnings > 0 ? 'flagged' : 'valid'
            }

        }

        return self
    }

    return apiFor()

}