import { RecordExpiredIcon } from '#record/constants'
import { useAppSettings } from '#settings/settings'
import { Label, LabelProps } from 'apprise-ui/label/label'
import { DateIcon } from 'apprise-ui/utils/icons'
import { useDateFnsLocale } from 'apprise-ui/utils/localeloader'
import format from 'date-fns/format'
import { useDateHelper } from './datehelper'

export type DateLabelProps = LabelProps & Partial<{

    date: number | string

    dateFormat: string

    displayMode: 'normal' | 'expiry'

    dateMode: 'settings' | 'short' | 'long'
}>

export const DateLabel = (props: DateLabelProps) => {

    const { date: clientdate, dateFormat, decorations, displayMode = 'normal', dateMode = 'settings', icon, ...rest } = props

    const date = typeof clientdate === 'string' ? Date.parse(clientdate) : clientdate

    const locale = useDateFnsLocale()
    const helper = useDateHelper()

    const dateSettingsFormat = useAppSettings().resolveFormat()

    if (!date)
        return null

    const expired = displayMode === 'expiry' && helper.expired(date)
    
    const computedFormat = dateFormat ? dateFormat : (dateMode === 'settings' ? dateSettingsFormat : dateMode === 'short' ? 'PP' : 'PPp')

    let defaultDecorations = decorations

    if (expired)
        defaultDecorations = [<RecordExpiredIcon />, ...decorations ?? []]

    return <Label tip={format(date, 'pp')} icon={icon ?? <DateIcon />} title={format(date, computedFormat, { locale })} decorations={defaultDecorations} {...rest} />
}
