import { JsonSerializationProps, useGenericJsonSerializer } from '#record/jsonserializer'
import { useAppSettings } from '#settings/settings'
import format from 'date-fns/format'
import { authorizationType } from './constants'
import { AuthorizationPatch } from './model'




// types the json row produced by a parse with the keys specified in config.
// types the json row produced by a parse with the keys specified in config.
export type AuthorizationJsonSerialization = Partial<{

    uvi: string,
    from: string,
    to: string
    
  }>


export const useAuthorizationJsonSerializer = () => {

    const formatString = useAppSettings().resolveFormat()

    const serializer = (props: JsonSerializationProps<AuthorizationPatch>): AuthorizationJsonSerialization => {
       
        const { patch, mode } = props

        const { from, to } = patch[authorizationType] ?? {}

        const fromString = mode === 'full' ? new Date().toISOString() : format(new Date(from),formatString)
        const toString = mode === 'full' ? new Date().toISOString() : format(new Date(to),formatString)

        return { from: fromString , to: toString }
    }

    return useGenericJsonSerializer(serializer)
     
}