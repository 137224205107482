import { useL } from 'apprise-frontend-core/intl/multilang'
import { useCategoryStore } from 'apprise-frontend-tags/category/store'
import { useTagStore } from 'apprise-frontend-tags/tag/store'
import { RecordBox, RecordBoxProps } from 'apprise-ui/recordbox/recordbox'
import { TextBox } from 'apprise-ui/textbox/textbox'
import { operatingCompanyTag } from './constants'
import { Contact } from './model'


export type ContactBoxProps = RecordBoxProps<Contact>

const prototype = (contact: Contact) => ( {

    name: undefined!,
    address: undefined!,
    ...(contact.type === operatingCompanyTag ? {regno: undefined!} : {})
} )

export const ContactBox = (props: ContactBoxProps) => {

    const { children: values } = props

    const l = useL()
    const tag = useTagStore().safeLookupTag(values?.type)
    const cat = useCategoryStore().safeLookupCategory(tag.category)

    props.debug && console.log({ values, tag, cat })

    const info = {
        label: l(tag.name),
        msg: l(tag.description ?? cat.properties.field.message ?? cat.description),
        help: l(cat.properties.field.help)
    }


    return <RecordBox info={info} keys={contact => Object.keys(prototype(contact))} field={TextBox} {...props} />

}