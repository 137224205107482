import { RecordPlugin, RecordPluginDescriptor, useDefaultRecordPlugin } from '#record/plugin';
import { useT } from 'apprise-frontend-core/intl/language';
import { useDetailsColumns } from './columns';
import { DetailsIcon, patchedTypes } from './constants';
import { useDetailsFilters } from './filters';
import { DetailsForm, useDetailFields } from './form';
import { newDetailSlot, DetailsPatch } from './model';
import { DetailOverview } from './overview';
import { useDetailsParser } from './parser';
import { DetailsPatchCard } from './patch';
import { useDetailsJsonSerializer } from './jsonserializer';
import { useDetailValidation } from './validator';
import { useDetailsExcelSerializer, useDetailsExcelWriter } from './excelserializer';



export const useDetailsPluginDescriptor = (): RecordPluginDescriptor => {

    const t = useT()
    const defaults = useDefaultRecordPlugin()

    return {

        ...defaults,

        name: t('details.type')

        ,

        singular: t('details.singular')

        ,

        plural: t('details.plural')

        ,


        Icon: DetailsIcon

        ,

        patchedTypes
    }

}

export const useDetailsPlugin = () => {


    const self: RecordPlugin<DetailsPatch> = {

        ...useDefaultRecordPlugin()
        
        ,
        
        ...useDetailsPluginDescriptor()

        ,

        parse: useDetailsParser

        ,

        validate: useDetailValidation

        ,

        jsonserialize: useDetailsJsonSerializer

        ,

        excelserialize: useDetailsExcelSerializer

        ,
        
        excelwrite: useDetailsExcelWriter
        
        ,

        stringify: r => `${r.details.port}`

        ,

        currentColumns: () => []      // we get these fromm the patch details

        ,
        
        Patch: DetailsPatchCard    
        
        ,

        patchFilters: useDetailsFilters

        ,

        Form: DetailsForm

        ,

        formFields: useDetailFields

        ,

        Overview: DetailOverview

        ,

        patchColumns: useDetailsColumns


        ,

        newSlot: newDetailSlot


    }


    return self
}
