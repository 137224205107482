import { appColor, AppIcon, HomeIcon } from '#constants'
import { useCrumbs } from '#crumbs'
import { Home } from '#home'
import { SearchIcon } from '#search/constants'
import { submissionRoute } from '#submission/routing'
import { RecordRouter } from '#vessel/router'
import { recordRoute } from '#vessel/routing'
import { useT } from 'apprise-frontend-core/intl/language'
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant'
import { logoutBaseUrl } from 'apprise-frontend-iam/login/constants'
import { tenantType } from 'apprise-frontend-iam/tenant/constants'
import { tenantRoute } from 'apprise-frontend-iam/tenant/routing'
import { userType } from 'apprise-frontend-iam/user/constants'
import { userRoute } from 'apprise-frontend-iam/user/routing'
import { tagType } from 'apprise-frontend-tags/constants'
import { RoutableTargets } from 'apprise-ui/link/routabletargets'
import { Scaffold, Section } from 'apprise-ui/scaffold/scaffold'
import { useEffect } from 'react'
import { GuestHeader } from './header'


export const GuestScaffold = () => {

    const t = useT()

    const crumbs = useCrumbs()

    const noTenant = useTenancyOracle().hasNoTenant()

    const excludes = noTenant ? [] : [tenantType, tagType, userType]

    return <RoutableTargets exclude={excludes}>

        <Scaffold icon={<AppIcon />} color={appColor} title={t('rav.title')} sidebar header={<GuestHeader />}>

            {crumbs}

            <Section icon={<HomeIcon />} title='Home' exact route='/'>
                <Home />
            </Section>

            <Section icon={<SearchIcon />} title={t('search.title')} route={recordRoute}>
                <RecordRouter />
            </Section>


            <Section disabled route={submissionRoute}>
                <LoginRedirect/>
            </Section>

            <Section disabled route={[userRoute, tenantRoute, submissionRoute]}>
                <LoginRedirect/>
            </Section>

        </Scaffold>

    </RoutableTargets>

}


const LoginRedirect = () => {

    useEffect(()=> {

        window.location.href = `${logoutBaseUrl}${window.location.pathname}`
   
    },[])


    return null
}