import { SlotType } from '#record/model'
import { Vid, useVID } from '#vid/model'
import { useRouting } from 'apprise-ui/utils/routing'
import { useHistory, useLocation, useRouteMatch } from 'react-router'
import { editModeParam } from './constants'




export const recordRoute='/record'


export const useVesselRouting = () => {

    const history = useHistory()
    const routing = useRouting()
    const location = useLocation()

    const match = useRouteMatch<{uvi:string}>(`${recordRoute}/:uvi`)

   
    const vid = useVID()
    

     const self = {


        searchRoute: () => recordRoute

        ,

        newRoute: () => `${recordRoute}/new`

        ,

        detailRoute: (id:string=`:uvi`, age:number = 0, historySlot? : SlotType, origin :boolean = false) => `${recordRoute}/${vid.valueOf(id)}${(age || historySlot || origin) ? routing.updateQueryWith( ps => {

            ps['age'] = `${age}`
            ps[`history`] = historySlot

            if (origin)
                ps['origin']=encodeURIComponent(`${location.pathname}${location.search}`)

        }):''}`

        ,

        backRoute: () => {
            
            const origin = routing.queryParams()['origin']

            return origin ? decodeURIComponent(origin) : undefined
        }

        ,

        innerDetailRoute: (age:number = 0, historySlot?: SlotType) => `${routing.routeQueryTo( ps => {
            ps[`age`] = `${age}`
            ps['history'] = historySlot
            
        })}`


        ,

        ageInRoute: () => parseInt(routing.queryParams()['age'] ?? '0')

        ,

        uviInRoute: () => vid.primaryOf(match?.params.uvi!)

        ,

        historyInRoute: () => routing.queryParams()['history'] as SlotType

        ,

        routeToSearch: () => history.push(self.searchRoute())

        ,

        routeToDetail: (id:Vid,age:number,historySlot?:SlotType, origin?: boolean) => history.push(self.detailRoute(id,age,historySlot, origin))

        ,

        routeToNew: () => history.push(self.newRoute())

        ,

        routeToInnerDetail: (age:number = 0, historySlot?: SlotType) => history.push(self.innerDetailRoute(age,historySlot))

        ,

        routeToInnerDetailForm: (type:SlotType) => history.push(routing.routeQueryTo(params => params[`${editModeParam}-${type}`] = "true"))
    

    }

    return self
}