import { iotcnumberscheme } from '#iotc/constants';
import { RavRecord } from '#record/model';
import { ClaimDecoration } from '#submission/patchlabel';
import { useValidationIssueIndex } from '#submission/validator';
import { useVesselOracle } from '#vessel/oracle';
import { useVID, vidSchemeCategory } from '#vid/model';
import { useT } from 'apprise-frontend-core/intl/language';
import { utils } from 'apprise-frontend-core/utils/common';
import { TenantLabel } from 'apprise-frontend-iam/tenant/label';
import { CategoryLabel } from 'apprise-frontend-tags/category/label';
import { TagLabel } from 'apprise-frontend-tags/tag/label';
import { useTagModel } from 'apprise-frontend-tags/tag/model';
import { useTagStore } from 'apprise-frontend-tags/tag/store';
import { Label } from 'apprise-ui/label/label';
import { NamedProperty } from 'apprise-ui/utils/namedproperty';
import { contactTypeCategory, portRegistryCategory, specificationCategory, totalVolumeTonnesTag, vesselGearCategory, vesselKindCategory, vesselRangeCategory, vesselTypeCategory } from './constants';
import { DetailsPatch, OperatingCompany, isOperatingCompany } from './model';



type CardProps = {

    showChange?: boolean
    record: DetailsPatch
    current: DetailsPatch | undefined
}

export const useCardProperties = (props: CardProps) => {


    const t = useT()

    const vids = useVID()
    const tags = { ...useTagModel(), ...useTagStore() }

    const oracle = useVesselOracle()

    const { record, current, showChange = true } = props

    const uvi = record.uvi
    const details = record.details
    const previous = showChange ? current?.details : undefined

    const claimed = current && oracle.canClaim(current as RavRecord) && current?.tenant !== record.tenant

    const schematags = tags.allTagsOfCategory(vidSchemeCategory, true)
    const spectags = tags.allTagsOfCategory(specificationCategory, true)
    const contacttags = tags.allTagsOfCategory(contactTypeCategory, true).sort(tags.codeComparator)

    const lessGears = previous && (previous?.gears.length > details.gears.length )

    const issues = useValidationIssueIndex()

    return {

        uvi: <NamedProperty status={issues.uvi} name={<TagLabel bare noIcon tag={schematags.find(t => t.id === iotcnumberscheme)} />}>
            <Label noIcon title={vids.valueOf(uvi)} decorations={claimed ? [<ClaimDecoration tenant={current?.tenant!} />] : undefined} />
        </NamedProperty>

        ,

        name: <NamedProperty status={issues.name} previously={previous?.name} currently={details.name} name={t('details.name')}>
            <Label bare noIcon title={details.name} />
        </NamedProperty>

        ,

        range: <NamedProperty status={issues[vesselRangeCategory]} previously={previous?.range} currently={details.range} name={<CategoryLabel bare noIcon category={vesselRangeCategory} title={t('details.range_short')} />}>
            <TagLabel bare noIcon noReadonly tag={details.range} />
        </NamedProperty>

        ,

        flagstate: <NamedProperty status={issues.flagstate} previously={previous?.flagstate} currently={details.flagstate} name={t('details.flag')}>
            {<TenantLabel bare noIcon tenant={details.flagstate} />}
        </NamedProperty>

        ,

        port: <NamedProperty status={issues[portRegistryCategory]} previously={previous?.port.name} currently={details.port.name} name={t('details.port_short')}>
            <Label bare noIcon title={details.port.name} />
        </NamedProperty>

        ,

        vesselkind: <NamedProperty status={issues[vesselKindCategory]} previously={previous?.vesselKind} currently={details.vesselKind} name={<CategoryLabel bare noIcon category={vesselKindCategory} title={t('details.kind_short')} />}>
            {<TagLabel bare noIcon noReadonly tag={details.vesselKind} />}
        </NamedProperty>

        ,

        vesseltype: <NamedProperty status={issues[vesselTypeCategory]} previously={previous?.vesselType} currently={details.vesselType} name={<CategoryLabel bare noIcon category={vesselTypeCategory} title={t('details.type_short')} />}>
            {<TagLabel bare noIcon noReadonly tag={details.vesselType} />}
        </NamedProperty>

        ,

        uvis: schematags.filter(({ id }) => id !== iotcnumberscheme).map((tag, i) => {
            const curr = details.identifiers.find(idd => vids.schemeOf(idd) === tag)
            const prev = previous?.identifiers.find(idd => vids.schemeOf(idd) === tag)

            return <NamedProperty key={`uvi-${i}`} status={issues[tag.id]} previously={prev} currently={curr} name={<TagLabel bare noIcon tag={tag} />}>
                <Label bare noIcon title={vids.valueOf(curr)} />
            </NamedProperty>
        })

        ,

        specs: spectags.filter(tag=>tag.id!== totalVolumeTonnesTag).map((tag, i) => {
            const spec = details.specifications[tag.id]

            return <NamedProperty key={`spec-${i}`} status={issues[tag.id]} previously={previous?.specifications[tag.id]} currently={spec} name={<TagLabel bare noIcon tag={tag.id} />}>
                <Label bare noIcon title={spec} />
            </NamedProperty>
        })

        ,

        gears: utils().split(details.gears).in(2).map((gears, j) =>

            gears.map((gear, i) => <NamedProperty noFixedHeight key={`gear-${j}-${i}`} status={issues[gear]}  previously={previous ? ( !lessGears && previous.gears.includes(gear) ? gear : 'forcenew') : undefined} currently={gear} name={<CategoryLabel bare noIcon category={vesselGearCategory} />}>
                <TagLabel bare noIcon tag={gear} />
            </NamedProperty>

            )
        )

        ,

        contacts: contacttags.map(tag => {

            const contact = details.contacts.find(c => c.type === tag.id)
            const prev = previous?.contacts.find(c => c.type === tag.id)

            return {

                contact, prev,

                name: <NamedProperty noFixedHeight status={issues[`${tag.id}-name`]} previously={prev?.name} currently={contact?.name} name={<TagLabel noIcon bare tag={tag.id} />}>
                    <Label bare noIcon title={contact?.name} />
                </NamedProperty>

                ,

                address: <NamedProperty noFixedHeight status={issues[`${tag.id}-address`]} previously={prev?.address} currently={contact?.address} name={t('details.contact_address')}>
                    <Label bare noIcon title={contact?.address} />
                </NamedProperty>

                ,

                ...(contact && isOperatingCompany(contact) ?

                    {
                        regno: <NamedProperty noFixedHeight status={issues[`${tag.id}-regno`]} previously={(prev as OperatingCompany)?.regno} currently={contact?.regno} name={t('details.company_regno')}>
                            <Label bare noIcon title={contact.regno} />
                        </NamedProperty>
                    }

                    :
                    {})
            }
        })

        ,

        priorHistory: ({
            name: <NamedProperty previously={previous?.priorHistory?.name} currently={details.priorHistory?.name} name={t('details.prev_name')}>
                <Label bare noIcon title={details.priorHistory?.name} />
            </NamedProperty>,
            flags: <NamedProperty previously={previous?.priorHistory?.flags} currently={details.priorHistory?.flags} name={t('details.prev_flags')}>
                <Label bare noIcon title={details.priorHistory?.flags} />
            </NamedProperty>,
            deletions: <NamedProperty nameAlign='right' noFixedHeight previously={previous?.priorHistory?.deletions} currently={details.priorHistory?.deletions} name={<div style={{ width: 'max-content' }}>{t('details.prev_deletions')}</div>}>
                <Label bare noIcon title={details.priorHistory?.deletions} />
            </NamedProperty>
        })
    }
}