import { carrierKindTag, vesselTypeCategory } from '#details/constants';
import { RecordValidator, useGenericValidator } from '#record/validator';
import { DetailContext } from '#submission/context';
import { RecordIssue } from '#submission/validator';
import { useVID } from '#vid/model';
import { useT } from 'apprise-frontend-core/intl/language';
import { useTagCache } from 'apprise-frontend-tags/cache';
import isAfter from 'date-fns/isAfter';
import format from 'date-fns/format';
import { useContext } from 'react';
import { trxauthzType, trxAuthzTypeCategory } from './constants';
import { TrxAuthPatch } from './model';
import { useAppSettings } from '#settings/settings';
import { unknownTenantId } from 'apprise-frontend-iam/tenant/model';
import { delistingType } from '#delisting/constants';


export const useTrxAuthzValidation = (): RecordValidator<TrxAuthPatch> => {

    const validator = useTrxAuthzValidator()

    return useGenericValidator(trxauthzType, validator)
}


export const useTrxAuthzValidator = (): RecordValidator<TrxAuthPatch> => {

    const t = useT()

    const tagmap = useTagCache()
    const vid = useVID()

    const formatString = useAppSettings().resolveFormat()

    const { currentRecords, edited } = useContext(DetailContext)

    return data => data.reduce((acc, { patch }) => {

        const issues: RecordIssue[] = []

        const grantedBy = edited.tenant ?? unknownTenantId

        const { type, from, to, revocation } = patch.trxauthz.authzs[grantedBy][0]

        const parsedFrom = Date.parse(from)
        const parsedTo = Date.parse(to)

        const current = patch.uvi ? currentRecords[patch.uvi] : undefined


        if (!type)
            issues.push({ message: t('valid.missing_trx_type'), type: 'error', location: trxAuthzTypeCategory })

        if (type && !tagmap.id2tag(type).of(trxAuthzTypeCategory))
            issues.push({ message: t('valid.invalid_trx_type', { type: type }), type: 'error', location: trxAuthzTypeCategory })

        if (!from)
            issues.push({ message: t('valid.missing_from_date'), type: 'error', location: 'from' })

        if (!to)
            issues.push({ message: t('valid.missing_to_date'), type: 'error', location: 'to' })


        if (from && to && parsedFrom >= parsedTo)
            issues.push({ message: t('valid.invalid_auth_range'), type: 'error', location: 'range' })

        if (to && parsedTo <= Date.now())
            issues.push({ message: t('valid.expired_auth_range'), type: 'warning', location: 'range' })


        // current-based checks
        // conditional to existence, generic validator will report separately if current is missing.
        if (current) {

            const uvi = vid.valueOf(patch.uvi)

            if (current.details.vesselKind !== carrierKindTag)  // wrong type.
                issues.push({ message: t('valid.invalid_trx_kind', { uvi, type: tagmap.id2name(current.details.vesselType).of(vesselTypeCategory) }), type: 'error', location: 'uvi' })


            else if (current.patchedSlots.includes(delistingType)) {  // is delisted.
                issues.push({ message: t('valid.invalid_trx_delisted', { uvi }), type: 'error', location: 'uvi' })
            }

            else {
                
                const now = Date.now()

                const currentFrom = Date.parse(current.authorization.from)
                const currentTo = Date.parse(current.authorization.to)

                if (isAfter(now, currentTo))  // expired
                    issues.push({ message: t('valid.invalid_trx_carrier_expired', { to: format(currentTo, formatString) }), type: 'warning', location: 'range' })

                else { // compatibility checks


                    if (to && isAfter(parsedTo, currentTo))  // exceeds
                        issues.push({ message: t('valid.invalid_trx_carrier_to', { to: format(currentTo, formatString) }), type: 'warning', location: 'to' })

                    if (from && isAfter(currentFrom, parsedFrom))  // preempts
                        issues.push({ message: t('valid.invalid_trx_carrier_from', { from: format(currentFrom, formatString) }), type: 'warning', location: 'from' })


                    const currentAuthzs = current?.trxauthz?.authzs[grantedBy]?.filter(a => a.type === type) ?? []

                    if (revocation && !currentAuthzs.some(a => a.type === type && a.from === from && a.to === to))
                        issues.push({ message: t('valid.invalid_trx_revocation'), type: 'error', location: 'range' })

                    else {

                        const overlapping = currentAuthzs.filter(a => a.type === type).find(current => {

                            const currentFrom = Date.parse(current.from)
                            const currentTo = Date.parse(current.to)

                            return (isAfter(currentTo, parsedFrom) && isAfter(parsedTo, currentTo))    //  [...{..]...}
                                ||
                                (isAfter(currentFrom, parsedFrom) && isAfter(parsedTo, currentFrom))   // {...[...}..]
                        })

                        if (overlapping)
                            issues.push({ message: t('valid.invalid_trx_overlap', { from: format(Date.parse(overlapping.from), 'P'), to: format(Date.parse(overlapping.to), 'P') }), type: 'error', location: 'range' })
                    }

                }

            }
        }

        return { ...acc, [patch.id]: issues }

    }, {})

}


