
import { RavConfig } from '#config';
import { useConfig } from 'apprise-frontend-core/config/api';
import { useConfigState } from 'apprise-frontend-core/config/state';
import { useLanguage } from 'apprise-frontend-core/intl/language';
import { EventSubscriber } from 'apprise-frontend-events/subscriptions';
import { PropsWithChildren } from 'react';


export type InfosysLifecycleEvent = {

    version: string
}

export const VersionUpgradeObserver = (props: PropsWithChildren<{}>) => {

    const { children } = props

    const config = useConfigState()
    const lang = useLanguage()
    const current = useConfig<RavConfig>()

    const checkUpgrade = (e: InfosysLifecycleEvent) => {

        if ( e.version === undefined || current.version === e.version)
            return
       
        // a reset of config has the drawback of resetting the lang to the default language.
        // so we override that to the language of the current user.
        const defaultLanguage = lang.current();
        const newconfig ={...config.remoteConfig(), latest:false, intl: {...config.remoteConfig().intl, defaultLanguage }}

        console.log("lifecycle event", e, "config:", {current: config.remoteConfig(), new:newconfig} )
       
        config.addRemoteConfig(newconfig) 

    }

    return <EventSubscriber name={`console-lifecycle-listener`} subscriptions={[{topic: 'console', onEvent: checkUpgrade}]}>
        {children}
    </EventSubscriber>


}
