import { useT } from 'apprise-frontend-core/intl/language'
import { useL } from 'apprise-frontend-core/intl/multilang'
import { TagCategory, useCategoryModel } from 'apprise-frontend-tags/category/model'
import { useCategoryStore } from 'apprise-frontend-tags/category/store'
import { ChoiceBox } from 'apprise-ui/choicebox/choicebox'
import { Debugged, Disabled } from 'apprise-ui/component/model'
import { useFieldProps } from 'apprise-ui/field/field'
import { ChangeTracked, Fielded, FieldInfo } from 'apprise-ui/field/model'
import { FieldReport } from 'apprise-ui/field/validation'
import { ControlledReadonly } from 'apprise-ui/readonly/model'
import { useReadonlyProps } from 'apprise-ui/readonly/readonly'
import { MultiSelectBox, SelectBox } from 'apprise-ui/selectbox/selectbox'
import partition from 'lodash/partition'
import capitalize from 'lodash/capitalize'
import { TagLabel } from './label'
import { Tag, useTagModel } from './model'
import { useTagModules } from './modules'
import { useTagStore } from './store'
import { useTagUtils } from './utils'




type BoxsetProps = Debugged & Disabled & ControlledReadonly & Pick<Fielded<string[]>,'onChange'> & ChangeTracked<string[]> & Partial<{


    report: FieldReport<any>

    categories: (string | TagCategory)[] 

    type: string

    children: string[] | undefined

    includeStandalone : boolean
}>

export const TagBoxes = (props: BoxsetProps) => {

    const catstore = useCategoryStore()
    const catmodel = useCategoryModel()
    const store = useTagStore()

    const {

        children: edited = [],

        type,

        categories = type ? catstore.allActiveCategoriesOf(type) : [],

        includeStandalone

    } = props


    const resolvedCategories = categories.map(c => typeof c === 'string' ? catstore.safeLookupCategory(c) : c)
  
    // separate categories that get a dedicated box from those whose tags end up in a single box along with category-less tags.
    const [fielded, lumped] = partition(resolvedCategories, c => c.properties.field?.enabled)


    const lumpedCategoryIds = lumped.map(c => c.id)

    // it's either active now, or there's a tag chosen when it was active in the past.
    const available = (cat: TagCategory) => {

        const categoryTags = store.allTagsOfCategory(cat.id)

        const activeAndWithAnActiveTag = cat.lifecycle.state === 'active' && categoryTags.some(t => t.lifecycle.state === 'active')
        const inUse = !activeAndWithAnActiveTag && edited.some(editedId => store.allTagsOfCategory(cat.id).map(t => t.id).includes(editedId))
        
        return activeAndWithAnActiveTag || inUse

    }

    const fieldedFiltered = fielded.filter(available)
    const sorted = type ? fieldedFiltered.sort(catmodel.ordinalComparator) : fieldedFiltered
    const unfielded = (type:string) => store.allTagsOf(type).filter(t => t.category === undefined || lumpedCategoryIds.includes(t.category))

    return <>

        {sorted.map((c, i) =>
            <TagField key={i} {...props} category={c} categoryTags={store.allTagsOfCategory(c.id)} />)
        }

        {includeStandalone && type && unfielded(type).length > 0 && 
            <TagField key="common" {...props} categoryTags={unfielded(type)} />}
    </>
}

type TagFieldProps = BoxsetProps & Partial<{

    // the category for this field.
    category: TagCategory,

    // all the tag in the category.
    // if there's no category, all the tags that have no category or are to be lumped.
    categoryTags: Tag[]
}>

const TagField = (props: TagFieldProps) => {

    const catstore = useCategoryStore()
    const store = useTagStore()
    const utils = useTagUtils()
    const modules = useTagModules()
    const model = useTagModel()

    const {

        children: edited = [],
        pastValue = [],

        category,
        type = category?.type,
        categoryTags: unfilteredCategoryTags = [],

        onChange,

        disabled: clientDisabled,
       
        report = {}

    } = useFieldProps<TagFieldProps>(props) 

    const l = useL()
    const t = useT()


    const module = modules.all().find(m => m.type === type)

    const [singular, plural] = [t(module?.singular ?? '<?>').toLowerCase(), t(module?.plural ?? '<?>').toLowerCase()]

    // prunes available tags with a conditon that is unmatched by selected tags.
    const categoryTags =  utils.givenTags(edited).filterMatching(unfilteredCategoryTags)
    const pastCategoryTags =  utils.givenTags(pastValue).filterMatching(unfilteredCategoryTags)

    // on change, prune selected tags with a condition that has now become unmatched.
    const changeTags = (newtags: string[] | undefined) => onChange?.( newtags ? utils.givenTags(newtags).filterMatching().map(t=>t.id) : newtags)


    const disabled = clientDisabled || !utils.givenTags(edited).matches(category?.properties.includeCondition)

   // isolates the tags under editing that belong to this category
    const [editedInCategory = [], other = []] = categoryTags?.length ? partition(edited.map(store.safeLookupTag), e => categoryTags.find(t => e.id === t.id)) : []
    const pastEditedInCategory = categoryTags?.length ? pastValue.map(store.safeLookupTag).filter( e=> pastCategoryTags.find(t => e.id === t.id)) : []

    const {readonly,noReadonly,canUnlock} = useReadonlyProps(props)

    const baseinfo: FieldInfo = category ?

        {
            label: capitalize(l(category.properties.field?.title ?? category.name)),
            msg: readonly ? l(category.description) : l(category.properties.field?.message) ?? t('tag.field_msg'),
            help: l(category.properties.field?.help ?? category.description)
        }

        : {
            label: t('tag.field_name'),
            msg: t('tag.field_msg'),
            help: t('tag.field_help', { singular, plural })
        }



    // for validation: find outcome for current category.
    // if there's no category report the first error outcome of a non-fielded category, if any.

    const reportId = category ?

        category.id
        :

        categoryTags.filter(t => !!t.category)
            .map(t => catstore.safeLookupCategory(t.category))
            .filter(c => !c.properties.field?.enabled)
            .find(c => report[c.id]?.status === "error")?.id



    // merges base field info with dynamic status and message in report
    const info = { ...baseinfo, ...(reportId ? report[reportId] : {}) }


    // const augmentedCategoryTags = [...categoryTags, ...(editedInCategory.filter(t => t.unknown) ?? [])].sort((a, b) => {
    //     if (a.properties.value && b.properties.value) {
    //         if (a.properties.value < b.properties.value) return -1
    //         if (a.properties.value > b.properties.value) return 1
    //     }
    //     return l(a.name).localeCompare(l(b.name))
    // })

    const multi = !category?.cardinality?.max

    const fieldType = category?.properties.field?.type

    //props.debug && console.log(category?.id, category?.cardinality, "multi", multi, "pool",categoryTags,"edited",editedInCategory)

    switch (fieldType) {

        case 'radio':

            return <ChoiceBox radio info={info} disabled={disabled} readonly={readonly} noReadonly={noReadonly} canUnlock={canUnlock}
                    
                pastValue= {pastEditedInCategory.map(t => t.id)?.[0] } 
                value={edited.length ? edited.find(e => categoryTags.some(tag => e === tag.id)) : undefined}
                onChange={(v:any) => changeTags([...(v ? [v] : []), ...other.map(t => t.id)])}>

                {categoryTags.filter(t => t.lifecycle.state === 'active' ? true : t.id === edited[0]).map((tag, i) =>

                    <ChoiceBox.Option key={i} value={tag.id} title={<TagLabel bare mode='normal' tag={tag} />} />
                )}

            </ChoiceBox>


        default: {

            const selectProps = {

                info,

                disabled: category?.lifecycle.state === 'inactive',
                readonly, noReadonly, canUnlock,
                noClear: !!category?.cardinality?.min ,


                options: categoryTags.filter(t => t.lifecycle.state === 'active' ? true : editedInCategory.includes(t)).sort(model.comparator).map(t => t.id),
                render: (tag: string) => <TagLabel bare tag={tag} />
            }

            return multi ?

                <MultiSelectBox {...selectProps} defaultValue={category?.properties.field?.default} onChange={tags => changeTags([...(tags ? tags : []), ...other.map(t => t.id)])}
                    pastValue={props.pastValue?.length ===0 ? undefined : pastEditedInCategory.map(t => t.id) }>
                    {editedInCategory?.length ? editedInCategory?.map(t => t.id) : undefined}
                </MultiSelectBox>

                :

                <SelectBox {...selectProps} defaultValue={category?.properties.field?.default?.[0]} onChange={tag => changeTags([...(tag ? [tag] : []), ...other.map(t => t.id)])}
                              pastValue={pastEditedInCategory.map(t => t.id)?.[0] }>
                    {editedInCategory?.length ? editedInCategory?.map(t => t.id)[0] : undefined}
                </SelectBox>

        }

    }

}
