
import { GenericRecord, RavRecord } from '#record/model';
import { Vid } from '#vid/model';
import { useCall } from 'apprise-frontend-core/client/call';
import { usePreload } from 'apprise-frontend-core/client/preload';
import { Submission, SubmissionRef } from './model';
import { ValidationReport } from './validator';



export const submissionApi = '/submission'


export const useSubmissionCalls = () => {

    const call = useCall()

    const preload = usePreload()

    const self = {

        fetchAll : () => preload.get<Submission[]>(submissionApi) ?? call.at(submissionApi).get<Submission[]>()

        ,

        fetchOne : (submission: SubmissionRef) => call.at(`${submissionApi}/${submission}`).get<Submission>()

        ,

        add: (submission: Submission) => call.at(submissionApi).post<Submission>(submission)

        ,

        update: (submission: Submission) => call.at(`${submissionApi}/${submission.id}`).put<Submission>(submission)

        ,

        remove: (id: string) => call.at(`${submissionApi}/${id}`).delete()

        ,


        validate: <T extends GenericRecord> (submission: Submission<T>) => call.at(`${submissionApi}/validate`).post<ValidationReport>(submission)

        ,

        match: <T extends GenericRecord> (submission: Submission<T>) => call.at(`${submissionApi}/match`).post<Record<Vid,RavRecord[]>>(submission)


    }


    return self;
}