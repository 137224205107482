import { baseComboType } from '#basecombo/mode'
import { GenericRecord, RavRecord, RecordIdentifier, SlotType, slotTypes } from '#record/model'
import { useRecordOracle } from '#record/oracle'
import { useRecordPluginDescriptors } from '#record/plugin'
import { Vid } from '#vid/model'
import { useT } from 'apprise-frontend-core/intl/language'
import { Lifecycle } from 'apprise-frontend-core/utils/lifecycle'
import { TenantResource } from 'apprise-frontend-iam/tenant/model'
import { Note } from 'apprise-frontend-iam/user/notebox'
import { Bytestream, BytestreamRef, StandardBytestreamProperties } from 'apprise-frontend-streams/model'
import { Tip } from 'apprise-ui/tooltip/tip'
import partition from 'lodash/partition'
import { IconBaseProps } from 'react-icons'
import shortid from 'shortid'
import { SubmissionDraftIcon, SubmissionIcon, SubmissionPendingIcon, SubmissionPublishedIcon, SubmissionRejectedIcon, SubmissionSubmittedIcon, draftColor, pendingColor, publishedColor, rejectedColor, submittedColor } from './constants'
import { RecordIssue, ValidationReport, emptyReport } from './validator'

export type SubmissionDto = Submission

export type Submission<T extends GenericRecord = GenericRecord> = TenantResource & {


    id: string
    type: SubmissionType

    live: boolean

    lifecycle: SubmissionLifecycle

    resources: Bytestream[]
    records: T[]

    stats: SubmissionStats,
    validationReport?: ValidationReport
    matchReport?: MatchReport

    note?: Note


}

export type SubmissionType = SlotType | typeof baseComboType
export const submissionTypes = [...slotTypes, baseComboType] as SubmissionType[]

export type SubmissionLifecycle = Lifecycle<SubmissionLifecycleState> & Partial<{

    submitted: number,
    submittedBy: string
    published: number,
    publishedBy: string
    rejected: number,
    rejectedBy: string
    rejectedWith: string
}>

export type SubmissionStats = {

    parsed: number
    new: number,
    matchPending: number,
    errors: number
    warnings: number
    ignored: number
    rejected: number
    published: number

}

export const emptyStats: SubmissionStats = { parsed: 0, new: 0, matchPending: 0, errors: 0, warnings: 0, ignored: 0, rejected: 0, published: 0 }

export type MatchReport = Record<RecordIdentifier, RecordMatch>

export type RecordMatch = {

    // the possible matches for the record.
    matches: RavRecord[]

    // the match confermed by the user.
    matched?: Vid

    // if the record was confirmed to be unmatched by the user.
    unmatched?: true

}


export type SubmissionLifecycleState = 'draft' | 'pending' | 'submitted' | 'published' | 'rejected'

export type SubmissionRef = Submission['id']

export type Asset = Bytestream<AssetProperties>

export type AssetProperties = Partial<StandardBytestreamProperties & {

    height: number
    width: number

}>

export type AssetReference = string

export const referenceOf = (asset: Asset) => (asset.properties?.path ?? asset.name)?.toLocaleLowerCase().trim()


export const appriseReferenceOf = (asset: Asset | AssetReference ) => 

     (typeof asset === 'string' ?  `__apprise__/${asset}` : `__apprise__/${asset.properties.path ? asset.properties.path.split("/").slice(1).join("/") : asset.name}`).toLocaleLowerCase().trim() 


export const useSubmissionUtils = () => {

    const t = useT()
    const { given } = useRecordOracle()

    const plugins = useRecordPluginDescriptors()

    const self = {

        newSubmission: (): Submission => ({


            id: `SUB-${shortid()}`,   // eagerly generates, available for ref when we upload the bytestreams _before_ saving the model.
            live: false,
            type: baseComboType,
            lifecycle: { state: 'draft' },
            tenant: undefined!,
            resources: [],
            records: [],
            matchReport: {},
            validationReport: emptyReport,
            stats: emptyStats
        })

        ,

        stateOf: (submission: Submission) => t(`sub.state_${submission.lifecycle.state}`)

        ,


        colorOf: (submission: Submission) => {

            switch (submission.lifecycle.state) {
                case 'submitted': return submittedColor
                case 'pending': return pendingColor
                case 'published': return publishedColor
                case 'rejected': return rejectedColor
                default: return draftColor

            }
        }

        ,

        statusIconOf: (submission: Submission) => {

            switch (submission.lifecycle.state) {
                case 'draft': return SubmissionDraftIcon
                case 'submitted': return SubmissionSubmittedIcon
                case 'pending': return SubmissionPendingIcon
                case 'published': return SubmissionPublishedIcon
                case 'rejected': return SubmissionRejectedIcon
                default: return SubmissionIcon
            }
        }

        ,

        validationAwareIconOf: (submission: Submission) => {

            const { Icon } = plugins.lookup(submission.type)

            const InvalidWithTip = (_: IconBaseProps) => <Tip tip={t('rec.issue_count', { count: submission.stats.errors })}>
                <Icon color='orangered'  {..._} />
            </Tip>

            const ValidWithTip = (_: IconBaseProps) => <Tip tip={t('rec.issue_count', { count: submission.stats.warnings })}>
                <Icon color='orange'  {..._} />
            </Tip>

            switch (submission.lifecycle.state) {
                case 'draft':
                case 'submitted': return submission.stats?.errors > 0 ? InvalidWithTip : submission.stats.warnings > 0 ? ValidWithTip : Icon
                default: return Icon
            }
        }

        ,

        // binds the lifecycle of upload resources to submission and tenant.
        bindResources: (submission:Submission) : Submission => ({

            ...submission,

            resources: submission.resources.map(res => ({...res, tenant: submission.tenant, ref: submission.id}))

        })



        ,

        computeRecordStats: (submission: Submission): SubmissionStats => {

            let novel = 0
            let matchPending = 0
            let ignored = 0
            let rejected = 0
            let published = 0
            let errors = 0
            let warnings = 0

            submission.records.forEach(r => {

                switch (r.lifecycle.state) {

                    case 'ignored': ignored++; break
                    case 'rejected': rejected++; break
                    case 'published': published++; break
                }

                if (r.lifecycle.state === 'ignored')
                    return

                // has a match yet to resolve.
                const unresolvedMatch = given(submission).matchStatusOf(r) === 'pending'


                // unless ignored, increment invalid and unmatched.
                if (unresolvedMatch)
                    matchPending++


                // increment new that have no match to resolve. 
                if (!r.uvi && !unresolvedMatch)
                    novel++


                const [recerrors, recwarnings] = partition(submission.validationReport?.records[r.id] ?? [], issue => issue.type === 'error')

                errors = errors + recerrors.length;
                warnings = warnings + recwarnings.length
            
            })

            return { parsed: submission.records.length, new: novel, matchPending, errors, warnings, ignored, published, rejected }


        }

        ,

        recordIssueCount: (submission: Submission) => submission.stats.errors + submission.stats.warnings

    }

    return self
}

export const useSubmissionModel = () => {

    const self = {

        ...useSubmissionUtils()

        ,

        unresolvedIssues: (submission: Submission) => submission.stats.errors + submission.stats.matchPending

        ,

        resourceIssues: (submission: Submission, props: Partial<{ resource: BytestreamRef, type: RecordIssue['type'] }> = {}) => {

            const { type, resource } = props

            const issueMap = submission.validationReport?.resources ?? {}

            const issues = resource ? issueMap[resource] : Object.values(issueMap).flat()

            return issues?.filter(i => !type || type === i.type)

        }


    }

    return self
}