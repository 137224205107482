import { VidExtension } from '#vid/ext';
import { useMocks } from 'apprise-frontend-core/client/mocks';




const IOTCNumberRegexp = /^IOTC[0-9]{1,6}$/


export const useIOTCVid = ()  => {

    const mocks = useMocks() 

    const self : VidExtension = {

        mintPrimaryUvi: () => `IOTC${mocks.randomNumberBetween(0,999999)}`.padStart(0),

        isValidPrimaryUvi: uvi =>  IOTCNumberRegexp.test(uvi),

        postProcessValue: value =>  value ? self.formatUvi(value) : value,

        formatUvi: value => `IOTC${`${value}`.toLocaleUpperCase().replace('IOTC', '').padStart(6, '0')}`
        
    }

    return self 

}