import { RecordValidator, useGenericValidator } from '#record/validator';
import { RecordIssue } from '#submission/validator';
import { useT } from 'apprise-frontend-core/intl/language';
import { utils } from 'apprise-frontend-core/utils/common';
import { useTagStore } from 'apprise-frontend-tags/tag/store';
import { delistingReasonCategory, delistingType } from './constants';
import { DelistingPatch } from './model';


export const useDelistingValidation = (): RecordValidator<DelistingPatch> => {

    const validator = useDelistingValidator()

    return useGenericValidator(delistingType, validator)
}


const useDelistingValidator = (): RecordValidator<DelistingPatch> => {

    const t = useT()

    const tags = useTagStore()

    const reasons = utils().index(tags.allTagsOfCategory(delistingReasonCategory)).by(t => t.id)

    return data => data.reduce((acc, { patch, current }) => {

        const issues: RecordIssue[] = []

        const { date, reason } = patch[delistingType]

        if (current?.patchedSlots.includes(delistingType))
            issues.push({ message: t('valid.already_delisted'), type: 'error', location: 'date' })

        if (!date)
            issues.push({ message: t('valid.missing_delisting_date'), type: 'error', location: 'date' })

        if (!reason)
            issues.push({ message: t('valid.missing_delisting_reason'), type: 'error', location: 'reason' })

        if (reason && !reasons[reason])
            issues.push({ message: t('valid.invalid_delisting_reason', { reason }), type: 'error', location: 'reason' })

        return { ...acc, [patch.id]: issues }

    }, {})

}


