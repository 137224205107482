import { VesselIcon } from '#record/constants';
import { SlotType } from '#record/model';
import { TenantIcon } from 'apprise-frontend-iam/tenant/constants';
import { UserIconFull } from 'apprise-frontend-iam/user/constants';
import { TagReference } from 'apprise-frontend-tags/tag/model';
import { Label, LabelProps } from 'apprise-ui/label/label';
import { AiOutlineFlag } from 'react-icons/ai';
import { FaRuler } from 'react-icons/fa';
import { FiAnchor } from 'react-icons/fi';
import { GiBoatPropeller, GiFishingHook, GiWeight } from 'react-icons/gi';
import { MdSignalWifi2Bar } from 'react-icons/md';
import { Contact } from './model';

export const DetailsIcon = VesselIcon
export const VesselNameIcon = VesselIcon
export const VesselFlagIcon = AiOutlineFlag
export const VesselPortIcon = FiAnchor
export const VesselTypeIcon = GiBoatPropeller
export const VesselGearIcon = GiFishingHook
export const VesselRangeIcon = MdSignalWifi2Bar
export const DimensionIcon = FaRuler
export const WeightIcon = GiWeight


export type ContactProps = LabelProps & { contact: Contact | undefined }

export const ContactLabel = (props: ContactProps) => {

    const { contact, ...rest } = props

    return contact ? <Label icon={<UserIconFull />} title={props.contact?.name} {...rest} /> : null

}

export const OwnerLabel = (props: ContactProps) => <ContactLabel {...props} />
export const BenefacialOwnerLabel = (props: ContactProps) => <ContactLabel icon={<UserIconFull color='bluecadet' />} {...props} />
export const OperatorLabel = (props: ContactProps) => <ContactLabel icon={<UserIconFull color='coral' />} {...props} />
export const CompanyLabel = (props: ContactProps) => <ContactLabel icon={<TenantIcon />} {...props} />

export const detailsType = 'details';

export const patchedTypes: SlotType[] = [detailsType]

export const ravContext = 'TG-rav-context'

export const portRegistryCategory = 'TGC-details-port'
export const portRegistryFlagstate = 'flagstate'

export type PortRegistryProps = {
    [portRegistryFlagstate]: TagReference
}

export const vesselKindCategory = 'TGC-details-kind'
export const fishingKindTag = 'TG-fishery-kind'
export const carrierKindTag = 'TG-carrier-kind'
export const supplyKindTag = 'TG-supply-kind'
export const supplyVesselTypeTag = 'TG-sp-type'

export const starboardTag = 'TGC-starboard-photo'
export const portsideTag = 'TGC-portside-photo'
export const bowTag = 'TGC-bow-photo'

export const vesselTypeCategory = 'TGC-details-type'

export const vesselGearCategory = 'TGC-details-gear'
export const vesselRangeCategory = 'TGC-details-range'

export const specificationCategory = 'TGC-details-specification'
export const loaTag = "TG-loa-specification"
export const tonnageTag = "TG-tonnage-specification"
export const grtTag = "TG-grt-specification"
export const totalVolumeTag = "TG-totvol-specification"
export const totalVolumeTonnesTag = "TG-totvoltonnes-specification"

export const contactTypeCategory = 'TGC-details-contact'
export const ownerTag = 'TG-owner-contact'
export const beneficialOwnerTag = 'TG-beneficialowner-contact'
export const operatorTag = 'TG-operatorcontact'
export const operatingCompanyTag = 'TG-operatingcompany-contact'