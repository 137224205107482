

import { useActions } from 'apprise-frontend-core/authz/action';
import { } from 'nats.ws';
import { mailTopicSeparator } from './constants';
import { MailTopic, noMailTopic } from './model';



export const useMailTopics = () => {

    const actions = useActions()

    const self = {

        extends: (t1: MailTopic | string, t2: MailTopic | string) => {
            
            const lbls1 = (typeof t1 === 'string' ? t1 : t1.value)?.split(mailTopicSeparator)
            const lbls2 = (typeof t2 === 'string' ? t2 : t2.value)?.split(mailTopicSeparator)

            return lbls1.length >= lbls2.length && lbls2.every((l2, i) => lbls1[i] === l2)
        }

        ,

        extendsAny: (t1: MailTopic | string, t2: (MailTopic | string)[] = []) => t2.some(t => self.extends(t1, t))

        ,


        comparator: (t1: MailTopic | string, t2: MailTopic | string) => self.extends(t1, t2) ? 1 : self.extends(t2, t1) ? -1 : 0

        ,

        audienceOf: (t: MailTopic) => (t.audience ?? []).map(actions.intern)

        ,

        reconcile: (topics?: MailTopic[]) => {

            if (!topics)
                return topics
                
            if (topics.includes(noMailTopic))
                return [noMailTopic]
                
            // sort upfront from most generic to most specific, so that reconciliation can be treated in 'streaming' fashion
            const sorted = [...topics].sort(self.comparator);

            //  simply add one by one if not already implied by previous ones
            return sorted.reduce((acc, a) => self.extendsAny(a, acc) ? acc : [a, ...acc], [] as MailTopic[])

        }


    }

    return self
}



