
import { StateProvider } from 'apprise-frontend-core/state/provider'
import { utils } from 'apprise-frontend-core/utils/common'
import { BreadcrumbContext } from 'apprise-ui/breadcrumb/breadcrumbs'
import { FocusModeProvider } from 'apprise-ui/focusmode/provider'
import { NoSuchRoute } from 'apprise-ui/link/nosuchroute'
import { BasicPageProps, PageModel, SectionModel } from 'apprise-ui/page/model'
import { PageModelContext } from 'apprise-ui/page/page'
import { SidebarContext, SidebarState } from 'apprise-ui/page/sidebar'
import { TableDataProvider, TableProvider } from 'apprise-ui/table/provider'
import { FilterProvider } from 'apprise-ui/utils/filter'
import * as React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { ScrollRestorationProvider } from 'apprise-ui/utils/scrollrestoration'
import { appColor, installCssProperties } from './constants'
import './styles.scss'
import { DateFnsLocaleProvider } from 'apprise-ui/utils/localeloader'

export type Props = BasicPageProps & {

    color: React.CSSProperties['color']
    children: React.ReactNode

}

export const initialSidebarState: SidebarState = {
    collapsed: undefined,            // will be defualted page by page
    ongoing: false
}

export const Scaffold = (props: Partial<Props>) => {


    const { pathname } = useLocation()

    const children = utils().elementsIn(props.children)

    const sections = children.filter(utils().isElementOf(Section)).map((c, i) => (

        {
            route: `/section-${i}`,
            ...c.props
        }

    )) as SectionModel[]

    const crumbs = children.filter(utils().isElementOf(Crumb)).map(c => c.props).reduce((acc, { path, omit, children }) => ({ ...acc, [path]: { label: children, omit } }), {})

    const links = children.filter(utils().isElementOf(SideLink)).map(c => c.props.children as JSX.Element);

    const model: Partial<PageModel> = { ...props, sections: sections ?? [], links }

    // redirects home to first section if there's no section that claims it (and we have at least one section).
    const firstRoute = typeof model.sections?.[0]?.route === 'string' ? model.sections[0].route : model.sections?.[0]?.route?.[0]
    const redirectFallback = pathname === '/' ? firstRoute : undefined

    React.useEffect(() => {

        installCssProperties({ [appColor]: props.color ?? 'teal' });

        // eslint-disable-next-line
    }, [])


    return (

        <BreadcrumbContext.Provider value={crumbs}>
            <PageModelContext.Provider value={model}>
                <StateProvider initialState={initialSidebarState} context={SidebarContext}>
                    <FocusModeProvider>
                        <ScrollRestorationProvider>
                            <TableProvider>
                                <TableDataProvider>
                                    <FilterProvider>
                                        <DateFnsLocaleProvider>
                                            <Switch>

                                                {model.sections?.map((s, i) =>

                                                    <Route exact={s.exact} path={s.route} key={i} >

                                                        {s.children}

                                                    </Route>

                                                )}

                                                {redirectFallback ?

                                                    <Redirect to={redirectFallback} />

                                                    :

                                                    <Route component={NoSuchRoute} />

                                                }

                                            </Switch>
                                        </DateFnsLocaleProvider>
                                    </FilterProvider>
                                </TableDataProvider>
                            </TableProvider>
                        </ScrollRestorationProvider>
                    </FocusModeProvider>
                </StateProvider>
            </PageModelContext.Provider>
        </BreadcrumbContext.Provider>
    )
}


// config components


export const Section = (_: React.PropsWithChildren<Partial<SectionModel>>) => null

type CrumbProps = {
    path: string
    omit?: boolean
    children?: React.ReactNode | string | ((segs: string[]) => string | undefined) | undefined
}

export const Crumb = (_: CrumbProps) => null

type SideLinkProps = {
    children: JSX.Element
}

export const SideLink = (_: SideLinkProps) => null
