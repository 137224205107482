import { JsonSerializationProps, useGenericJsonSerializer } from '#record/jsonserializer'
import { TrxAuthPatch } from './model'




// types the json row produced by a parse with the keys specified in config.
// types the json row produced by a parse with the keys specified in config.
export type TrxAuthzJsonSerialization = Partial<{

    uvi: string
    type: string
    from: string
    to: string
    revoke:string
    
  }>


export const useTrxAuthzJsonSerializer = () => {

    //const formatString = useAppSettings().resolveFormat()

    const serializer = (props: JsonSerializationProps<TrxAuthPatch>): TrxAuthzJsonSerialization => {
       
        // const { patch } = props

        // const { } = patch.trxauth.authzs

        // const fromString = mode === 'full' ? new Date().toISOString() : format(new Date(from),formatString)
        // const toString = mode === 'full' ? new Date().toISOString() : format(new Date(to),formatString)

        return { }
    }

    return useGenericJsonSerializer(serializer)
     
}