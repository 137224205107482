
import { useSearchColumns } from '#search/columns'
import { AppSettings } from '#settings/settings'
import { useT } from 'apprise-frontend-core/intl/language'
import { utils } from 'apprise-frontend-core/utils/common'
import { SettingRenderProps } from 'apprise-frontend-streams/settings/model'
import { Form } from 'apprise-ui/form/form'
import { GroupBox } from 'apprise-ui/groupbox/groupbox'
import { ColumnCard } from 'apprise-ui/table/columnpanel'
import { Explainer } from 'apprise-ui/utils/explainer'
import React, { useRef } from 'react'


// gathers the columns to include in the default layout of the search table.
// applies to users that haven't made a personal choice yet for the current or future sessions.
// so, it's the default decided by the _admin_, vs. the default provided in code by marking columns.
// the hard-coded default serves as a fallback until the admin decides.

export const SearchSettingsPanel = (props: SettingRenderProps<AppSettings>) => {

    const t = useT()

    const { settings, onChange } = props

    const columns = useSearchColumns()

    // all available columns.
    const allNames = columns.map(c => c.props.name as string)

    const hardcodedDefaults = columns.filter(c => c.props.defaultLayout || c.props.pinned).map(c => c.props.name)

    const active = settings.defaultColumns ?? hardcodedDefaults
    const activeMap: Record<string, boolean> = allNames.reduce((acc, name) => ({ ...acc, [name!]: active.includes(name) }), {})

    const inactive = allNames.filter(n => !activeMap[n])

    //active-first.
    const list = [...active, ...inactive]

    // we use local state to keep column order stable when columns are toggled.
    // but we need to resync on external changes, like reverts or push notifications.
    // so we keep a copy of the active columns to tell which changes are external.
    const local = useRef({list,active})

    const internalChange = utils().deepequals(local.current.active,active)

    // but we need to resync on external changes, like reverts or push notifications.
    React.useEffect(() => {

        if (!internalChange)
            local.current={list,active}
            
    })

 
    
    const reorder = (list: string[] = []) => {
    
        const active = list.filter(name => activeMap[name])

        change(list, active)
    }
   
    const activate = (name: string) => (selected: boolean = false) => {
    
        const active = local.current.list.filter(n => n === name ? selected : activeMap[n])

        change( local.current.list /* keep same order */, active)
    
    }

    const change = (list: string[], active:string[]) => {

        local.current={ list, active} // maintain local state

        // if the change reverts to the hardcoded defaults, store nothing.
        const sameAsDefaults = utils().deepequals(active,hardcodedDefaults) 

        onChange({ ...settings, defaultColumns: sameAsDefaults ? undefined! : active })    // update settings.

    }
    return <React.Fragment>

        <Form style={{ marginTop: 40 }}>

            <Explainer title={t('search.settings_title')}>{t('search.settings_explainer')}</Explainer>

            <GroupBox noAdd noRemove onChange={reorder}
                render={name => <ColumnCard column={columns.map(c => c.props).find(e => e.name === name)} value={activeMap[name]} onSelect={activate(name)} />} >

                {local.current.list}

            </GroupBox>

        </Form>

    </React.Fragment>
}