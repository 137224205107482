import { useMocks } from 'apprise-frontend-core/client/mocks'
import getYear from 'date-fns/getYear'
import shortid from 'shortid'
import { Authorization } from './model'

export const useAuthorizationMocks = () => {

    const mocks = useMocks()

    const self = {

        store: () => mocks.getOrCreateStore<Authorization>('authorizations', { id: m => m.id })

        ,

        mockSlot: (props: { current?: Authorization, timestamp: string}): Authorization => {

            const { current, timestamp } = props

            return {

                ...current,

                id: `AZ-${shortid()}`,
                timestamp,

                from: timestamp,
                to:  new Date( getYear(new Date(timestamp)), 11, 31).toISOString(),
             
              
            }

        }


    }

    return self
}