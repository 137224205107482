
import { submissionRoute, useSubmissionRouting } from '#submission/routing';
import { useSubmissionStore } from '#submission/store';
import { recordRoute } from '#vessel/routing';
import { useT } from 'apprise-frontend-core/intl/language';
import { Crumb } from 'apprise-ui/scaffold/scaffold';
import format from 'date-fns/format';
import React from 'react';
import logobar from './logobar.png';


export const useCrumbs = () => {

    const t = useT()
    const store = useSubmissionStore()
    const routing = useSubmissionRouting()
    

    return <React.Fragment>
    
        <Crumb path="/"><img alt='logo' height={18} src={logobar} /></Crumb>
        <Crumb path={submissionRoute}>{t('sub.list_crumb')}</Crumb>
        <Crumb path={routing.detailRoute('*')}>{
            (path:string[])=>format(store.lookup(path[2])?.lifecycle.created ?? Date.now(),"PPp")
        }</Crumb>
        <Crumb path={recordRoute}>{t('search.search_crumb')}</Crumb>
    
    </React.Fragment>

}

