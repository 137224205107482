
import { useEventBus } from 'apprise-frontend-events/bus';
import { mailSubject, targetSeparator } from './constants';
import { EmbeddedMailContent, Mail, MailContent, MailTarget, MailTopic, TopicAudience, TParams } from './model';



export const useMail = () => {

    const events = useEventBus()

    const self = {

        // one or more targets.
        sendTo: (target: MailTarget | MailTarget[]) => {

            const onClause = (content: MailContent) => {

                const self = {

                    on: (topic: MailTopic) => 
                    
                        // TODO: when we have push for settings changes, may use to short-circuit here instead of having the stack drop the message.
                        events.publish(mailSubject, { topic, target: typeof target === 'string' ? target : target.join(targetSeparator), content } as Mail)
                        
                    

                    ,

                    onTopic: (value: string, audience?: TopicAudience) => self.on({ key: undefined!, value, audience })

                }

                return self
            }



            return {

                
                subject: (subject: EmbeddedMailContent['subject']) => ({


                    text: (text: EmbeddedMailContent['text']) => onClause({ subject, text }) // translated mail content


                })

                ,

                content: onClause

                ,

                template: (template: string) => ({

                    params: (parameters: TParams) => onClause({ template, parameters }) // raw mail content

                })


            }
        }


    }


    return self;
}

