
import { useBaseComboExcelWriter } from '#basecombo/excelserializer'
import { BaseComboPatch } from '#basecombo/mode'
import { ExcelRecordWriterProps, useGenericExcelSerializer } from '#record/excelserializer'
import { RavRecord } from '#record/model'
import { useT } from 'apprise-frontend-core/intl/language'

export const useResultSerializer = () => {

    const t = useT()

    // reuses the base combo aggregation work.
    const combowrite = useBaseComboExcelWriter()

    const resultwriter = (props:ExcelRecordWriterProps<RavRecord>) => {

        combowrite(props as unknown as ExcelRecordWriterProps<BaseComboPatch>)

        // adds result-specific cols.
        props.output.date(t('search.date_col')).render(r => r.timestamp)
        
    }
    

    return useGenericExcelSerializer(resultwriter) 

}