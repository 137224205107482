import { ImageMap, UploadRecord } from "#settings/dev"
import { utils } from 'apprise-frontend-core/utils/common'
import { useJsonParser } from "apprise-frontend-parse/file"
import { useDefaultModelParser } from "apprise-frontend-parse/resource"
import { useBytestreams } from "apprise-frontend-streams/api"


const paralellUploads = 4


type UploadParseContext = {
    images: ImageMap
    stats: {
        errors: number
        uploaded: number
    }
}

type UploadTask = {

    row: UploadRecord,
    file?: File
}

export const useImageUploader = () => {

    const streams = useBytestreams()

    const itemparser = useItemParser()
    const modelparser = useDefaultModelParser(itemparser)
    const fileparser = useJsonParser<UploadTask, UploadParseContext>()

    return async (file: File, images: ImageMap) => {

        const stats = {
            errors: 0,
            uploaded: 0
        }

        const taskOutcome = await fileparser({ id: 'upload-records', name: file.name, file }).with(modelparser, { images, stats })

        const uploadTasks = taskOutcome.data.filter(task => task.file)

        await utils().split(uploadTasks).in(paralellUploads).reduce((previousChunk, tasks) => previousChunk.then(() =>

            Promise.all(tasks.map(async task => {

                const { row, file } = task

                console.log(`uploading ${JSON.stringify(row)}`)

                try {

                   await streams.upload([[row.stream, file!]], {reportUploadErrors: false})

                    row.uploaded = true

                    stats.uploaded += 1

                }
                catch (err) {

                    console.error(`cannot upload ${JSON.stringify(row)} - ${err}`)
                }

            }))


        ), Promise.resolve() as Promise<any>)

        const outcome = { ...taskOutcome, data: taskOutcome.data.map(task => task.row) }

        return { outcome, stats }
    }

}

const useItemParser = () => {

    return (row: UploadRecord, ctx: UploadParseContext): UploadTask => {


        const { images, stats } = ctx

        if (!row.uploaded)
            try {

                const file = images[row.path]?.file

                if (!file)
                    throw new Error(`No such file "${row.path}"`)

                return { row, file }

            } catch (err) {

                stats.errors += 1

                console.warn(`cannot upload ${JSON.stringify(row)} - ${err}`)
            }

        return { row }
    }
}