import { ExcelRecordWriter, useGenericExcelSerializer } from '#record/excelserializer'
import { useT } from 'apprise-frontend-core/intl/language'
import { PhotographsPatch } from './model'
import { bowTag, portsideTag, starboardTag } from '#details/constants'



export const usePhotographsExcelSerializer = () => useGenericExcelSerializer(usePhotographsExcelWriter())

export const usePhotographsExcelWriter= () => {

    const t = useT()

    const writer: ExcelRecordWriter<PhotographsPatch> = (props) => {

        const { output } = props

        output.text(t("search.photo_starboard")).render(r => r.photograph?.photos?.some(photo => photo.type === starboardTag) ? t("common.yes") : t("common.no"))
        output.text(t("search.photo_portside")).render(r => r.photograph?.photos?.some(photo => photo.type === portsideTag) ? t("common.yes") : t("common.no"))
        output.text(t("search.photo_bow")).render(r => r.photograph?.photos?.some(photo => photo.type === bowTag) ? t("common.yes") : t("common.no"))
        
    }


    return writer
}