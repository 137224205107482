
import { DelistingFullIcon } from '#delisting/constants'
import { useT } from 'apprise-frontend-core/intl/language'
import { utils } from 'apprise-frontend-core/utils/common'
import { FormState, useForm } from 'apprise-frontend-core/utils/form'
import { Button } from 'apprise-ui/button/button'
import { classname } from 'apprise-ui/component/model'
import { SidebarContext } from 'apprise-ui/page/sidebar'
import { Tip } from 'apprise-ui/tooltip/tip'
import { RemoveItemIcon, SaveIconFull } from 'apprise-ui/utils/icons'
import { useContext, useEffect } from 'react'
import { AiFillDelete, AiFillFolderOpen, AiFillPlusCircle } from 'react-icons/ai'
import { BiUndo } from 'react-icons/bi'
import { IoIosPlay, IoIosTime } from 'react-icons/io'
import { startQuery } from './constants'
import { Query, QueryPill, QueryPillProps, SearchCondition } from './model'
import './querypanel.scss'

export type FilterPanelProps = {

    onChange: (_: Query) => any
    onRefresh: () => any
    onSave: () => any
    form: FormState<Query>

    children: JSX.Element[]
}


export const QueryPanel = (props: FilterPanelProps) => {

    const t = useT()

    const { form, onRefresh: refreshResults, onChange: changeQuery, onSave, children } = props

    const { initial: lastQuery, edited: query, set: setQuery, reset: resetQuery, dirty, rebased, resetSignal } = form

    const pills = utils().elementsIn(children).filter(utils().isElementOf(QueryPill))

    const pillprops = pills.map(c => c.props as QueryPillProps).filter(p => !p.mode || p.mode === query.mode)

    const initialLayout = () => pillprops.filter(p => p.defaultLayout || query.conditions[p.name])
        // clones pills to match current condition cardinality for repeatable props
        .flatMap(p => query.conditions[p.name] ? query.conditions[p.name].map(_ => ({ ...p })) : [p])

    //console.log({lastQuery, layout: initialLayout()})

    const { edited: selected, reset: resetSelected } = useForm(initialLayout())

    const ctx = useContext(SidebarContext)

    // 'new' query => changed via this panel or reset externally.
    const changedQuery = dirty || rebased

    const updateQuery = () => {

        if (changedQuery)
            onReset(query)

        else refreshResults()  // triggers result refreshes for current query.

    }

    useEffect(() => {

        if (resetSignal)
            resetSelected.to(initialLayout()).quietly()

        // eslint-disable-next-line
    }, [resetSignal])

    const onReset = (query: Query = startQuery) => {

        resetQuery.to(query).quietly() // resets, so future reverts will go back to this query.

        resetQuery.base.quietly()       // unflags rebased, if set.

        changeQuery(query)

        if (query === startQuery)
            resetSelected.to(initialLayout()).quietly()

    }


    // resets to last executed query, but without a reset so that it preserves the current layout.
    const revert = () => setQuery.to(lastQuery)

    const toggleHistoryMode = () => setQuery.using(q => {

        q.mode = q.mode === 'current' ? 'historical' : 'current'

        if (q.mode === 'historical')
            // we force the toggle off to enforce toggle is not honoured.
            q.delisted = 'exclude'
    })

    const toggleDelistedMode = () => setQuery.using(q => q.delisted = q.delisted === 'include' ? 'exclude' : 'include')

    // assumes starts collapsed: must align with defaultOpen prop on search page.
    const toggle = () => ctx.set(s => { s.collapsed = s.collapsed === undefined ? false : !s.collapsed })

    const revertBtn = <Button enabled={dirty} className='form-btn' tip={t('search.query_revert_tip')} tipPlacement='bottom' tipDelay={.7} type='ghost' onClick={revert}>
        <BiUndo color={dirty ? 'lightseagreen' : 'inherit'} />
    </Button>


    const resetBtn = <Button className='form-btn' tip={t('search.query_reset_tip')} tipPlacement='bottom' tipDelay={.7} type='ghost' onClick={() => onReset(undefined!)}>
        <AiFillDelete color='lightseagreen' />
    </Button>

    const saveBtn = <Button className='form-btn' disabled={dirty} tipPlacement='bottom' tip={t('search.query_save_tip')} tipDelay={.7} type='ghost' onClick={onSave}>
        <SaveIconFull color={dirty ? 'inherit' : 'dodgerblue'} />
    </Button>

    const openBtn = <Button className='form-btn' tipPlacement='bottom' tip={t('search.query_open_tip')} tipDelay={.7} type='ghost' onClick={toggle}>
        <AiFillFolderOpen style={{ marginLeft: 3 }} color={ctx.get().collapsed ? 'dodgerblue' : 'hotpink'} />
    </Button>

    const historyModeBtn = <Button className={classname('form-btn', `mode-btn`, 'history-btn', `mode-${query.mode}`)} tipPlacement='bottom' tip={t('search.historical_mode_tip')} tipDelay={.7} type='ghost' onClick={toggleHistoryMode}>
        <IoIosTime color={'dodgerblue'} />
    </Button>

    const delisteModedBtn = <Button disabled={query.mode === 'historical'} className={classname('form-btn', `mode-btn`, 'delisted-btn', `mode-${query.delisted}`)} tipPlacement='bottom' tip={t('search.delisted_mode_tip')} tipDelay={.7} type='ghost' onClick={toggleDelistedMode}>
        <DelistingFullIcon color={'dodgerblue'} />
    </Button>


    const applyBtn = <Button className={classname('form-btn', 'apply-btn', changedQuery && 'btn-highlight')} tip={t('search.apply')} tipPlacement='bottom' tipDelay={.7} type='ghost' onClick={updateQuery}><IoIosPlay style={{ marginLeft: 3 }} size={20} /></Button>

    const selectPill = (pill: QueryPillProps) => resetSelected.to([...selected, pill]).quietly


    const deselectPill = (pill: QueryPillProps, position: number) => () => {

        resetSelected.to(selected.filter(p => p !== pill)).quietly()

        // pill may have been used already, remove corresponding condition from query.
        removeCondition(pill.name, position)
    }

    // add,change or remove the condition at a given pill.
    // the condition is identified by the logical name of the pill and its order among other pills with the same name.
    const changePill = (name: string, order: number) => (condition?: SearchCondition) => {

        if (condition)
            updateCondition(name, order, condition)
        else
            removeCondition(name, order)

    }

    // add/change the condition at a given pill.
    // the condition is identified by the logical name of the pill and its order among other pills with the same name. 
    const updateCondition = (name: string, order: number, condition: SearchCondition) => setQuery.using(query => {

        //console.log("updating", name, "@", order, "with", condition, "current", query.conditions[name])

        if (!query.conditions[name]) 
            query.conditions[name] = []
        
        query.conditions[name][order] = condition

    })

    // removes the condition at a given pill.
    // the condition is identified by the logical name of the pill and its order among other pills with the same name. 
    const removeCondition = (name: string, position: number) => setQuery.using(query => {

        // console.log("remove condition", name, "@", position)

        query.conditions[name]?.splice(position)

        if (query.conditions[name]?.length === 0)
            delete query?.conditions[name]
    })

    //console.log({ query: query?.conditions, selected })

    return <div className='query-form'>,

        <div className='pills-unselected'>
            {pillprops.map(props =>

                <UnselectedQueryPill disabled={!props.repeatable && selected.some(p => p.name === props.name)} key={props.name} {...props} onSelect={selectPill(props)} />

            )}
        </div>

        <div className='pills-selected'>
            {selected.map(props => {

                // finds the ordinal of this props among those of the same name. 
                const order = selected.filter(ps => ps.name == props.name).findIndex(ps => ps === props)

                //console.log("selected", props.name, "at", order)

                return <SelectedQueryPill key={`${resetSignal}-${props.name}-${order}`} {...props} disabled={selected.length === 1}

                    onChange={changePill(props.name, order)}
                    condition={query?.conditions[props.name]?.[order]}
                    onDeselect={deselectPill(props, order)} />

            })}
        </div>

        <div className='form-controls'>

            <span className={classname('mode-lbl', 'delisted-lbl', query.mode === 'historical' && 'apprise-disabled', `mode-${query.delisted}`)}>{t('search.delisted_mode')}</span>

            {delisteModedBtn}

            {revertBtn}

            {resetBtn}

            {applyBtn}

            {saveBtn}

            {openBtn}

            {historyModeBtn}

            <span className={classname('mode-lbl', 'history-lbl', `mode-${query.mode}`)}>{t('search.historical_mode')}</span>

        </div>

    </div>

}





const SelectedQueryPill = (props: QueryPillProps & {

    //form: FormState<Query>
    disabled: boolean
    onDeselect: () => void
    condition: SearchCondition | undefined,
    onChange: (_: SearchCondition | undefined) => void

}) => {

    const t = useT()

    const { label, title, field: Field, onDeselect, onChange: updateCondition, condition, disabled } = props

    const tippedLabel = label ? <Tip tip={t(title)}>{t(label)}</Tip> : undefined

    return <div className='query-pill-selected' style={{ display: 'flex' }} >
        <Field label={tippedLabel} onChange={updateCondition}>
            {condition}
        </Field>
        <Button disabled={disabled} noLabel className='query-pill-selected-close' type='ghost' icon={<RemoveItemIcon size={12} />} onClick={onDeselect} />
    </div >
}


const UnselectedQueryPill = (props: QueryPillProps & {

    onSelect: () => void
    disabled: boolean

}) => {

    const t = useT()

    const { label, title, onSelect, disabled } = props

    return <Button tip={t('search.pill_unselected_tip', { title: t(title) })} tipDelay={0.8} tipPlacement='top' disabled={disabled} iconPlacement='left' icon={<AiFillPlusCircle />} type='ghost' className='query-pill-unselected' size='small' onClick={onSelect}>
        {t(label)}
    </Button>
}