import { useT } from 'apprise-frontend-core/intl/language'
import { EventSubscription } from "apprise-frontend-events/subscriptions"
import { useReloadOnEvent } from 'apprise-frontend-events/utils'
import { defaultDrawerParam } from 'apprise-ui/header/actionmenu'
import { useLazyRouting } from 'apprise-ui/utils/routing'
// import { useLocation } from 'react-router'
import * as React from 'react'
import { settingsParamValue } from './constants'
import { AppSettings } from './model'
import { SettingsCacheContext } from "./provider"

export type SettingsChangeEvent = {
    settings: AppSettings
}


export const useSettingsChangeListener = (): EventSubscription<SettingsChangeEvent> => {

    // const location = useLocation()

    const state = React.useContext(SettingsCacheContext)
    const t = useT()

    const inform = useReloadOnEvent({ singular: t('settings.title') })

    const routing = useLazyRouting()
   
    return {

        topic: 'settings'

        ,

        onEvent: (event: SettingsChangeEvent) => {

            const { settings } = event

            console.log("updating settings on event notification...", { settings })

            const updateState = () => state.set(s => s.all = settings) 

            if (routing.get().query().includes(`${defaultDrawerParam}=${settingsParamValue}`))
                inform.thenRun(updateState)
            else
                updateState()

            

            // todo: should we remount the current route if it's protected by an EventGuard?
            // seems a bit extreme, at least until event guards become selective.
            // the guard may be there for other types of events that impact specific routes.

            // const { url, routeTo } = routing.get()            
            // routeTo( url(), shortid())



        }
    }
}


