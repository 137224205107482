import { useT } from 'apprise-frontend-core/intl/language'
import { Lifecycle } from 'apprise-frontend-core/utils/lifecycle'
import { SidebarProperty } from 'apprise-ui/page/sidebar'
import { useUsernameResolver } from 'apprise-ui/usernameresolver'
import { useDateFnsLocale } from 'apprise-ui/utils/localeloader'
import format from 'date-fns/format'
import formatDistance from 'date-fns/formatDistance'
import * as React from 'react'

export type Props = React.PropsWithChildren<{

    lifecycle: Lifecycle
}>


// displays props of the standard lifecycle as sidebar properties.
// can be extended by children.
export const LifecycleSummary = (props: Props) => {

    const { lifecycle, children } = props

    const t = useT()
    const locale = useDateFnsLocale()

    const now = Date.now()

    const { created, lastModified, lastModifiedBy } = lifecycle

    const resolveUser = useUsernameResolver()

    return <React.Fragment>

        <SidebarProperty name={t("ui.lifecycle_created")} tip={created && format(created, 'PPpp')}>
            {created && formatDistance(created, now, { addSuffix: true, locale })}
        </SidebarProperty>

        <SidebarProperty name={t("ui.lifecycle_modified")} tip={lastModified && format(lastModified, 'PPpp')}>
            {lastModified && formatDistance(lastModified, now, { addSuffix: true, locale })}
        </SidebarProperty>

        <SidebarProperty name={t("ui.lifecycle_modifiedby")}>
            {lastModifiedBy && resolveUser(lastModifiedBy)}
        </SidebarProperty>

        {children}

    </React.Fragment >
}