import { useContext } from 'react'
import { ExtensionContext } from './provider'


export type ExtensionState = Record<string,any>


export const useExtensions = <T extends any=any>() => {

    const state = useContext(ExtensionContext)

    const self = {

        register: (api: Record<string,any>) => state.resetQuietly({...state.get(),...api})

        ,

        get: () => state.get() as T

    }

    return self

}