import { Card } from '#record/card';
import { PatchCardProps } from '#submission/patchdetail';
import { Grid } from 'apprise-ui/grid/grid';
import { Fragment } from 'react';
import { useCardProperties } from './cardutils';
import { DetailsPatch } from './model';


export const DetailsPatchCard = (props: PatchCardProps<DetailsPatch>) => {

    const { record, previous } = props

    const { uvi, name, port, vesseltype, vesselkind, gears, range, uvis, specs, flagstate, contacts, priorHistory } = useCardProperties({

        record,
        current: previous

    })

    return <Card width={700}>

        <Grid rowGap={8} columnGap={5} >

            <Grid.Row>

                <Grid.Col>{uvi}</Grid.Col>
                <Grid.Col>{name}</Grid.Col>
                <Grid.Col>{flagstate}</Grid.Col>



            </Grid.Row>

            <Grid.Row>
                {uvis.map((vid, i) =>

                    <Grid.Col key={`vid-${i}`}>{vid}</Grid.Col>

                )}

            </Grid.Row>

            <Grid.Row>
                <Grid.Col>{vesselkind}</Grid.Col>
                <Grid.Col>{vesseltype}</Grid.Col>
                <Grid.Col>{range}</Grid.Col>
            </Grid.Row>

            <Grid.Row>{port}</Grid.Row>

            <Grid.Row>{
                specs.map((spec, i) => <Grid.Col key={`spec-${i}`}>{spec}</Grid.Col>)
            }
            </Grid.Row>

            {gears.map((gears, j) =>

                <Grid.Row key={`gear-${j}`}>{gears.map((gear, i) =>

                    <Grid.Col key={`prop-${i}`}>{gear}</Grid.Col>)

                }</Grid.Row>
            )}


            {contacts.map(({ name, address, regno }, i) => <Fragment key={`contact-${i}`}>

                {regno ?

                    < Grid.Row>
                        <Grid.Col>{name}</Grid.Col>
                        <Grid.Col width={300}>{regno}</Grid.Col>
                    </Grid.Row>

                    :

                    <Grid.Row >
                        {name}
                    </Grid.Row>
                }

                <Grid.Row>
                    {address}
                </Grid.Row>

            </Fragment>
            )}

            {record.details.priorHistory &&
                <Fragment>
                    <Grid.Row>{priorHistory.name}</Grid.Row>
                    <Grid.Row>{priorHistory.flags}</Grid.Row>

                    <Grid.Row>
                        <Grid.Col>{priorHistory.deletions}</Grid.Col>
                    </Grid.Row>
                </Fragment>
            }

        </Grid>
    </Card>
}