
import { useMode } from 'apprise-frontend-core/config/api'
import { useT } from 'apprise-frontend-core/intl/language'
import { TenantIcon } from 'apprise-frontend-iam/tenant/constants'
import { TenantLabel } from 'apprise-frontend-iam/tenant/label'
import { noTenant, Tenant } from 'apprise-frontend-iam/tenant/model'
import { useTenantStore } from 'apprise-frontend-iam/tenant/store'
import { useMultiTagFilter } from 'apprise-frontend-tags/tag/filter'
import { Button } from 'apprise-ui/button/button'
import { Label } from 'apprise-ui/label/label'
import { OptionMenu } from 'apprise-ui/optionmenu/optionmenu'
import { Page } from 'apprise-ui/page/page'
import { SidebarContent } from 'apprise-ui/page/sidebar'
import { Selection, useSelection } from 'apprise-ui/table/selection'
import { SortSpec } from 'apprise-ui/table/sorting'
import { Table } from 'apprise-ui/table/table'
import { Titlebar } from 'apprise-ui/titlebar/titlebar'
import { Topbar } from 'apprise-ui/topbar/topbar'
import { useBaseFilter } from 'apprise-ui/utils/basefilter'
import { ContextualFilterProps, useFilterState } from 'apprise-ui/utils/filter'
import { AddIcon, DevOnlyIcon } from 'apprise-ui/utils/icons'
import { useCallback, useMemo } from 'react'
import { useInternalUserOracle } from '../authz/oracle'
import { useUserColumns } from './columns'
import { userType } from './constants'
import { useUserDownload } from './download'
import { User } from './model'
import { useUserRouting } from './routing'
import { useUserStore } from './store'
import { useUserToggles } from './toggles'
import { useUserEmailCopyToClipboard } from './utils'
import { FaUser } from 'react-icons/fa6'

export const userTableName = 'user-table'

export const UserList = () => {

    const t = useT()
    const store = useUserStore()
    const routing = useUserRouting()
    const { development } = useMode()
    const userOracle = useInternalUserOracle()
    const selection = useSelection<User>()

    const toggles = useUserToggles()

    const copyAddressesBtn = useUserEmailCopyToClipboard()

    const { UserDownloadDrawer, userDownloadBtn } = useUserDownload()

    // actions

    const removeMany = () => store.removeMany(selection.selected, () => selection.select([]))

    const add = () => routing.routeToNewDetail()

    // buttons

    const addBtn = < Button type="primary" icon={<AddIcon />} onClick={add} enabled={userOracle.canAdd()} linkTo={routing.newDetailRoute()}>
        {t("user.add", { singular: t('user.singular') })}
    </ Button>

    const removeManyBtn = <Button iconPlacement='left' type="danger" icon={<DevOnlyIcon size={22} color='orange' />} onClick={removeMany} >
        {t("ui.feedback.consent_removal_many_title", { count: selection.selected.length })}
    </Button>


    return <Page >

        <SidebarContent>
            {addBtn}
            <br />
            {development && selection.selected.length > 0 && removeManyBtn}
            <br />
            {copyAddressesBtn}
            {toggles.download && userDownloadBtn}
        </SidebarContent>

        <Titlebar title={t('user.list_title')} />

        <Topbar>
            {addBtn}
        </Topbar>

        <UserTable selection={selection} />

        <UserDownloadDrawer />

    </Page>
}


export type UserTableProps = {

    tenant?: Tenant
    selection: Selection<User>

}

export const UserTable = (props: UserTableProps) => {

    const store = useUserStore()
    const userOracle = useInternalUserOracle()

    const userCols = useUserColumns(props)

    // filters

    const { tenant, selection } = props

    const allUsers = store.all()

    const unfilteredData = useMemo(() => tenant ? allUsers.filter(u => u.tenant === tenant.id) : allUsers, [allUsers, tenant])


    const { BaseFilter, baseFilteredData } = useBaseFilter({
        data: unfilteredData,
        isReadonly: u => !userOracle.canEdit(u),
        context: userType
    })

    const { TenantFilter, tenantFilteredData } = useTenantFilter({
        data: baseFilteredData,
        context: userType
    })

    const { filters, tagFilteredData } = useMultiTagFilter({
        filtered: tenantFilteredData,
        tagged: unfilteredData,
        context: userType,
        tagsOf: u => u.tags
    })

    const data = tagFilteredData

    const initialSort: SortSpec[] = [{ key: 'name', mode: 'asc' }]

    return <Table
        name={userTableName}
        mountDelay context={userType} rowId={u => u.username}
        selection={selection}
        data={data} total={unfilteredData.length}
        initialSort={initialSort}>

        <Table.Filter key='base'>
            {BaseFilter}
        </Table.Filter>

        <Table.Filter key='tenant'>
            {TenantFilter}
        </Table.Filter>

        {Object.entries(filters).map(([category, filter]) =>

            <Table.Filter name={category} key={category}>
                {filter}
            </Table.Filter>
        )}

        {userCols}

    </Table>
}

type UserFilterProps = ContextualFilterProps & {

    data: User[]
}

const noTenantKey = noTenant

export const useTenantFilter = (props: UserFilterProps) => {

    const t = useT()

    const { context, contextKey = 'tenantfilter', data = [] } = props

    const { get, set } = useFilterState<string[]>(context)

    const tenants = useTenantStore().allSorted()

    // eslint-disable-next-line 
    const defaultActive = useMemo(() => [noTenantKey, ...tenants.map(t => t.id)], [])

    const active = get(contextKey) ?? defaultActive
    const setActive = set(contextKey)

    const options = <>{tenants.map(t => <OptionMenu.Option key={t.id} value={t.id} title={<TenantLabel tenant={t} noLink />} />)}</>

    const TenantFilter = <OptionMenu active={active} dropDownClassName='user-tenant-filter' setActive={setActive} label={<Label icon={<TenantIcon />} title={t("user.filter.tenant_filter")} />} >
        <OptionMenu.Option key={noTenantKey} value={noTenantKey} title={<Label title={t("user.filter.no_tenant")} icon={<FaUser size={11} style={{marginRight: 1, marginLeft: 2}} />} titleStyle={{opacity: 0.4}}/>} />
        {options}
    </OptionMenu>

    const tenantFilter = useCallback(

        (user: User) => (active.includes(noTenantKey) && user.tenant === noTenant) || active.includes(user.tenant)

        // eslint-disable-next-line     
        , [active])                   // recompute on filter change


    const tenantFilteredData = useMemo(() => data.filter(tenantFilter), [data, tenantFilter])

    return { TenantFilter, tenantFilter, tenantFilteredData, active, setActive }
}