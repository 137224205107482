

import { useActionRegistry } from 'apprise-frontend-core/authz/action'
import { MockHorizonContext } from 'apprise-frontend-core/client/mockhorizon'
import { useMockery } from 'apprise-frontend-core/client/mocks'
import { utils } from 'apprise-frontend-core/utils/common'
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import { useContext } from 'react'
import { categoryActions } from './oracle'
import { useCategoryMockery } from './category/mockery'
import { useCategoryStore } from './category/store'
import { useTagMockery } from './tag/mockery'
import { useTagStore } from './tag/store'
import { TagProps } from './tags'



export const TagLoader = (props: TagProps) => {

    const belowMockHorizon = useContext(MockHorizonContext)

    const { children, mock = belowMockHorizon } = props

    const tagmockery = useTagMockery()
    const catmockery = useCategoryMockery()

    const catStore = useCategoryStore()
    const store = useTagStore()
    const mocks = useMockery()
    const actions = useActionRegistry()

    // loads tags and categories, optionally after installing mocks.
    const activate = async () => {

        if (mock) {

            mocks.initMockery(tagmockery)
            mocks.initMockery(catmockery)

            actions.register(Object.values(categoryActions))


        }

        await utils().waitOn(store.fetchAllTags(), catStore.fetchAllCategories())

    }



    // activates before rendering children.
    const { content } = useRenderGuard({

        render: children,

        orRun: activate,

    })

    return content
}