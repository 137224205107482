import { AuthorizationJsonSerialization } from '#authorization/jsonserializer'
import { DetailsJsonSerialization } from '#details/jsonserializer'
import { PhotographsJsonSerialization } from '#photographs/jsonserializer'
import { GenericRecord, RecordIdentifier } from '#record/model'
import { useRecordPlugin } from '#record/plugin'
import { Submission } from "#submission/model"
import { utils } from 'apprise-frontend-core/utils/common'
import { BytestreamRef } from 'apprise-frontend-streams/model'
import { FieldInfo } from 'apprise-ui/field/model'
import React, { useContext, useMemo } from 'react'
import { DetailContext } from './context'


export type ValidationReport = {

  resources: Record<BytestreamRef, RecordIssue[]>
  records: Record<RecordIdentifier, RecordIssue[]>

}

export const emptyReport: ValidationReport = { resources: {}, records: {} }

export type LocationKey = keyof DetailsJsonSerialization | keyof AuthorizationJsonSerialization | keyof PhotographsJsonSerialization

// a non-blocking issue encounted during a parse.
export type RecordIssue = {
  message: string
  type: 'error' | 'warning'
  location?: string

}


export type ValidationState = 'valid' | 'invalid'

export const RecordValidationContext = React.createContext<RecordIssue[] | undefined>([])


export const useValidationIssueIndex = () => {

  const issues = React.useContext(RecordValidationContext)

  const issuemap = useMemo(() => {

    return utils().group(issues ?? []).by(issue => issue.location!, () => -1).reduce((acc, { key: location, group }) => {

      const match = group.find(g => g.type === 'error') ?? group[0]


      return ({ ...acc, [location]: { msg: match.message, status: match.type } })

    }, {} as Record<string, FieldInfo>)

  }, [issues])


  return issuemap

}


export const useSubmissionValidator = <T extends GenericRecord>() => {

  const { edited, currentRecords } = useContext(DetailContext)

  const plugin = useRecordPlugin<T>(edited.type)

  const validator = plugin.validate()

  return (submission: Submission<T>): ValidationReport => {

    const data = submission.records.map(patch => ({
      patch,
      current: currentRecords[patch.uvi],
      record: { ...currentRecords[patch.uvi] ?? {}, ...patch }

    }))

    const recordIssues = validator(data, submission)

    return { records: recordIssues } as ValidationReport

  }
}

