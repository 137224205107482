

import { UpgradeButton } from '#utils/upgradebutton';
import 'antd/dist/antd.css';
import { useLanguage } from 'apprise-frontend-core/intl/language';
import { EventBusConnectionLabel } from 'apprise-frontend-events/label';
import { useUserUtils } from 'apprise-frontend-iam/user/utils';
import { Breadcrumbs } from 'apprise-ui/breadcrumb/breadcrumbs';
import { FocusModeButton } from 'apprise-ui/focusmode/button';
import { Header } from 'apprise-ui/header/header';
import { SwitchBox } from 'apprise-ui/switchbox/switchbox';


export const GuestHeader = () => {

    const lang = useLanguage()

    const { loginBtn } = useUserUtils()

    return <Header>

        <Header.Left>
            <Breadcrumbs />
        </Header.Left>

        <Header.Right>
            <UpgradeButton />
        </Header.Right>

        <Header.Right>
            <EventBusConnectionLabel disconnectedOnly />
        </Header.Right>

        <Header.Right>
            <FocusModeButton />
        </Header.Right>

        <Header.Right>
            <SwitchBox noReadonly className='guest-lang-switch' checkedLabel='en' uncheckedLabel='fr' onChange={state => { lang.change(state ? 'en' : 'fr') }}>
                {lang.current() === 'en'}
            </SwitchBox>
        </Header.Right>

        <Header.Right>
            {loginBtn}
        </Header.Right>

    </Header>

}