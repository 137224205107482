import { PatchAndCurrent } from '#submission/records'
import { DateLabel } from '#utils/datelabel'
import { useT } from 'apprise-frontend-core/intl/language'
import { TagColumn } from 'apprise-frontend-tags/tag/tagcolumn'
import { classname } from 'apprise-ui/component/model'
import { Label } from 'apprise-ui/label/label'
import { useTableUtils } from 'apprise-ui/table/utils'
import { DateIcon } from 'apprise-ui/utils/icons'
import format from 'date-fns/format'
import { TrxTypeIcon } from './constants'
import { TrxAuthPatch, TrxAuthz } from './model'
import './styles.scss'

export const useTrxAuthzColumns = () => {

    const t = useT()

    const { Column, compareDatesOf } = useTableUtils<PatchAndCurrent<TrxAuthPatch>>()


    const given = <T extends any>(f: (authz: TrxAuthz) => T) => (p: PatchAndCurrent<TrxAuthPatch>) => f(Object.values(p.trxauthz.authzs).flat()[0])
    const revokedClass = given(authz => classname(authz.revocation && 'trxauthz-revoked'))


    return <>

        <TagColumn<PatchAndCurrent<TrxAuthPatch>> defaultLayout name="type" width={150} classnameFor={revokedClass}
            tagOf={given(authz => authz.type)} title={<Label icon={<TrxTypeIcon />} title={t('trxauthz.type_col')} />} />

        <Column defaultLayout name="from" width={140} classnameFor={revokedClass} title={<Label icon={<DateIcon />} title={t('trxauthz.from_col')} />}
            text={given(authz => authz.from && format(new Date(authz.from), 'PPP'))}
            sort={compareDatesOf(given(authz => authz.from))}
            render={given(authz => <DateLabel noIcon date={authz.from} />)} />


        <Column defaultLayout name="to" width={140} classnameFor={revokedClass} title={<Label icon={<DateIcon />} title={t('trxauthz.to_col')} />}
            text={given(authz => authz.to && format(new Date(authz.to), 'PPP'))}
            sort={compareDatesOf(given(authz => authz.to))}
            render={given(authz => <DateLabel noIcon date={authz.to} />)} />

    </>
}