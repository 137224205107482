import { useActions } from 'apprise-frontend-core/authz/action';
import { useT } from 'apprise-frontend-core/intl/language';
import { useL } from 'apprise-frontend-core/intl/multilang';
import { utils } from 'apprise-frontend-core/utils/common';
import { tenantActions } from 'apprise-frontend-iam/authz/oracle';
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant';
import { Permission } from 'apprise-frontend-iam/permission/model';
import { PermissionBox, PermissionBoxProps } from 'apprise-frontend-iam/permission/permissionbox';
import { useUserModel } from 'apprise-frontend-iam/user/model';
import { useUserStore } from 'apprise-frontend-iam/user/store';
import { useFeedback } from 'apprise-ui/utils/feedback';
import { tenantPlural, tenantSingular } from './constants';
import { TenantLabel } from './label';
import { noTenant, Tenant, useTenantModel } from './model';
import { useTenantStore } from './store';


// this is the hardest case, as permissions over tenants combines with tenant membership.
// in particular we may need to distinguish the "logged user" from the "target users", where the former
// may be tenant-less, or may be in the same tenant as all the other target users. 
// overall:
// - the logged user should be able grant and revoke any permission he has over any tenant.
// - if the logged user is tenant-less, then any tenant can be shown or selected.
// - if the logged user IS in a tenant, then tenant can be selected only if the logged user manages it or if the target users already manage it.
// - in BOTH cases, target users that have tenants must manage their own tenant before they can manage any other.

export const TenantPermissionBox = (props: Partial<PermissionBoxProps<Tenant>>) => {

    const t = useT()
    const l = useL()

    const users = { ...useUserStore(), ...useUserModel() }

    const store = useTenantStore()
    const oracle = useTenancyOracle()
    const model = useTenantModel()
    const actions = useActions()
    const { showFailure } = useFeedback()

    const { onChange, userRange, resourceComparator = model.comparator, preselectedResources, ...rest } = props

    //enforces the "full Management" rule of tenancy: only tenant managers can manage other tenants.
    const onValidChange = (permissions: Permission[] | undefined = []) => {

        // find manage permissions where the subject is a tenant user.
        const managePermissions = permissions.filter(p => actions.match(tenantActions.manage, p.action))
            .map(({ subject, action }) => ({ user: users.safeLookup(subject), tenant: action.resource }))
            .map(({ user, tenant }) => ({ user, own: user.tenant, tenant }))
            .filter(({ own }) => own !== noTenant)


        // find the first manage permission of a user that doesn't also manage its own tenant.
        const issue = utils().group(managePermissions).by(mp => mp.user.username, (u1,u2) => u1?.localeCompare(u2)).find(({ key, group }) => !group.find(g => g.tenant === g.own))?.group[0]

        if (issue)
            showFailure({
                title: t("tenant.fullmgmt_error_title"),
                message: t("tenant.fullmgmt_error_msg", { user: users.fullName(issue.user), own: l(store.safeLookup(issue.own).name), other: l(store.safeLookup(issue.tenant).name) })
            })

        else
            onChange?.(permissions)
    }

    const singleTenant = userRange?.length === 1 && userRange?.[0].tenant !== noTenant ? userRange?.[0].tenant : undefined


    const ownTenantFirst = singleTenant ? 
        (t1, t2) => t1.id === singleTenant ? -1 : t2.id === singleTenant ? 1 : resourceComparator(t1, t2)
        :
        resourceComparator


    const managedTenants = oracle.managedTenants()

     // //  usability aid: preselect managed tenants if there are many, otherwise the tenant of the logged user, if there is one.
    const preselected = managedTenants.length > 0 ?
        store.all().filter(t =>  managedTenants.includes(t.id) )
        : singleTenant ? [store.safeLookup(singleTenant)]
            : preselectedResources

    
    return <PermissionBox<Tenant>

        resourceSingular={tenantSingular}

        permissionRange={Object.values(tenantActions)}
        maxPrivilege={tenantActions.manage}
        resourcePlural={tenantPlural}
        resourceId={t => t.id}
        resourceRender={t => <TenantLabel bare tenant={t} />}
        resourceText={t => l(t.name)}
        resourceComparator={ownTenantFirst}
        preselectedResources={preselected}
        onChange={onValidChange}
        userRange={userRange}

        wildcard={false}

        {...rest}

    />

}