
import { Breadcrumb as AntBreadcrumb } from 'antd'
import { useLanguage } from 'apprise-frontend-core/intl/language'
import { Component } from 'apprise-ui/component/component'
import { Styled } from 'apprise-ui/component/model'
import { Link } from 'apprise-ui/link/link'
import * as React from 'react'
import { useLocation } from 'react-router-dom'

import "./styles.scss"

export type Props = Styled

export type Crumbs = { [_: string]: Crumb }

export type Crumb = Partial<{

    label?: string | ((segs: string[]) => string) | React.ReactNode,
    omit?: boolean
}>

export const BreadcrumbContext = React.createContext<Crumbs>(null!)


export const Breadcrumbs = ($: Props) => {

    const lang = useLanguage()
    const { pathname } = useLocation()

    const crumbs = React.useContext(BreadcrumbContext) ?? {}

    const render = () => {

        const items = toItems(pathname, crumbs)

        return items.length === 1 ? null :

            <Component name='crumbs' {...$}>
                <AntBreadcrumb >

                    {items.filter(i => !i.omit).map((i, j) => {

                        let lbl = typeof i.label === 'function' ? i.label?.(i.path.split("/")) : i.label

                        if (typeof lbl === 'string')
                            lbl = capitalise(lbl)

                        return <AntBreadcrumb.Item key={j}>
                            <Link linkTo={decodeURI(i.path)}>{lbl}</Link>
                        </AntBreadcrumb.Item>
                    })
                    }
                </AntBreadcrumb>
            </Component>


    }

    //  currently useLocation() forces a render even if the route hasn't changed.
    //  to avoid that, we memoise the render output explicitly.
    //  eslint-disable-next-line
    return React.useMemo(render, [pathname, lang.current()])

}

const capitalise = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

const toItems = (path: string, crumbs: Crumbs) => {


    return [{ seg: '', path: '/' }].concat(

        path.split('/')
            .filter(seg => seg)  // remove start/end spaces
            .map((seg, i, self) => ({ seg, path: "/" + self.slice(0, i + 1).join('/') }))

    )
        .map(({ seg, path }) => {

            const matched = match(path, crumbs)

            return matched ? { ...matched, path } : { label: seg, path, omit: false }

        })

}

const match = (path: string, crumbs: Crumbs): Crumb | undefined => {

    const segs = path.split("/")

    const key = Object.keys(crumbs).find(key => {

        const keysegs = key.split("/")
        return keysegs.length === segs.length && !keysegs.some((s, i) => s !== "*" && s !== segs[i])

    })

    return key ? crumbs[key] : undefined
}




