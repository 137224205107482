import { SlotType } from '#record/model'
import { BiLayer } from 'react-icons/bi'
import { GiIceShield } from 'react-icons/gi'



export const trxauthzType = 'trxauthz'

export const patchedTypes : SlotType[]  = [trxauthzType]

export const TrxAuthzIcon = GiIceShield
export const TrxTypeIcon = BiLayer
export const trxAuthzTypeCategory = 'TGC-trxauthz-type'
