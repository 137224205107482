import { SlotFormProps } from '#vessel/overview';
import { useT } from 'apprise-frontend-core/intl/language';
import { DateBox } from 'apprise-ui/date/datebox';
import { useValidation } from 'apprise-ui/field/validation';
import { Form } from 'apprise-ui/form/form';
import { Authorization } from './model';

export const AuthorizationForm = (props: SlotFormProps) => {

    return <Form fieldDefaults={{ help: null, trackChange: true }}>

        <AuthorizationFormFields {...props} />

    </Form>
}


export const AuthorizationFormFields = (props: SlotFormProps) => {

    const validation = useValidation()

    const { edited, initial, set } = props

    const authz = edited?.authorization ?? {}
    const pastAuthz = initial?.authorization ?? {} as Authorization

    const fields = validation.reportOf(useAuthorizationFormFields(props))

    return <>

        <DateBox noClear info={fields.from} pastValue={pastAuthz.from} onChange={set.with((m, v) => m.authorization.from = v!)}>
            {authz.from}
        </DateBox>

        <DateBox noClear info={fields.to} pastValue={pastAuthz.to} onChange={set.with((m, v) => m.authorization.to = v!)}>
            {authz.to}
        </DateBox>

    </>
}

export const useAuthorizationFormFields = (props: SlotFormProps) => {

    const t = useT()

    const { edited } = props

    const authz = edited?.authorization ?? {}

    const { check, is } = useValidation()

    return {

        from: {

            label: t("authorization.from"),
            msg: t("authorization.from_msg"),
            ...check(is.empty).and(is.date.notAfter(authz.to)).on(authz.from),
            location:'from'
        }

        ,

        to: {

            label: t("authorization.to"),
            msg: t("authorization.to_msg"),
            ...check(is.empty).and(is.date.notBefore(authz.from)).on(authz.to),
            location:'to'
        }
    }
}