import { useAuthorizationExcelWriter } from '#authorization/excelserializer'
import { AuthorizationPatch } from '#authorization/model'
import { useDetailsExcelWriter } from '#details/excelserializer'
import { DetailsPatch } from '#details/model'
import { usePhotographsExcelWriter } from '#photographs/excelserializer'
import { PhotographsPatch } from '#photographs/model'
import { ExcelRecordWriter, ExcelRecordWriterProps, useGenericExcelSerializer } from '#record/excelserializer'
import { BaseComboPatch } from './mode'


export const useBaseComboExcelSerializer = () => useGenericExcelSerializer(useBaseComboExcelWriter())

export const useBaseComboExcelWriter = () => {


    const serializeDetails = useDetailsExcelWriter()
    const serializeAuthz = useAuthorizationExcelWriter()
    const serializePhotos = usePhotographsExcelWriter()

    const writer: ExcelRecordWriter<BaseComboPatch> = (props) => {


        serializeDetails(props as unknown as ExcelRecordWriterProps<DetailsPatch> )
        serializeAuthz(props as unknown as ExcelRecordWriterProps<AuthorizationPatch>)
        serializePhotos(props as unknown as ExcelRecordWriterProps<PhotographsPatch>)
        
    }


    return writer
}