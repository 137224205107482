import { Submission } from '#submission/model'
import { ValidationState } from '#submission/validator'
import partition from 'lodash/partition'
import { GenericRecord } from './model'


export const useRecordOracle = () => {

    return {

        given: (submission: Submission) => {

            const self = {


                canSubmit: (r: GenericRecord) => r.lifecycle.state === 'uploaded' && self.validationStatusOf(r) === 'valid'

                ,

                canPublish: (r: GenericRecord) => r.lifecycle.state === 'submitted' && self.validationStatusOf(r) === 'valid'

                ,

                canReject:  (r: GenericRecord) => r.lifecycle.state === 'submitted'
                
                ,

                matchStatusOf: (r:GenericRecord) =>  {
                    
                    const entry = submission.matchReport?.[r.id]

                    const defaultedEntry = {matched: false, unmatched: false, matches:[],...entry}
                    
                    const status =  (defaultedEntry.matched && !defaultedEntry.unmatched ) ? 'matched' : defaultedEntry.unmatched === true ? 'new' : defaultedEntry.matches.length ? 'pending' : r?.uvi ? 'none' : 'new'

                    return status
                    
                }

                ,


                validationStatusOf: (record: GenericRecord) : ValidationState => 
                
                    submission.validationReport?.records[record.id]?.some(issue=>issue.type==='error') ? 'invalid' : 'valid'

                ,

                fullValidationStatusOf: (record: GenericRecord) : ValidationState | 'flagged' => {

                    const [errors,other] = partition(submission.validationReport?.records[record.id] ?? [],  t => t.type==='error')
                
                    return errors.length ? 'invalid' : other.length ? 'flagged' : 'valid'
            
                }

                ,

                hasMatches: (r:GenericRecord | undefined) =>  !!r && (submission.matchReport?.[r.id]?.matches?.length ?? 0) > 0

                ,

                hasValidationIssues: (record: GenericRecord) : boolean => 
                
                    !!submission.validationReport?.records[record.id]?.length

                ,

                isInvalid: (r:GenericRecord | undefined) => !!r && self.validationStatusOf(r) === 'invalid'

                ,

                isExcluded: (r:GenericRecord) => r.lifecycle.state==='ignored' || r.lifecycle.state==='rejected'


            }

            return self
            
        }

    }

   
}