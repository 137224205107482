import { Fragment, useContext, useEffect, useState } from "react"
import { EventConnectionContext } from './context'
import { Tip } from "apprise-ui/tooltip/tip"
import { useT } from "apprise-frontend-core/intl/language"

import './styles.scss'


type Props = {
    disconnectedOnly?: boolean
}


export const EventBusConnectionLabel = (props: Props) => {

    const { disconnectedOnly = false } = props

    const t = useT()

    const ctx = useContext(EventConnectionContext)

    const [isConnected, setConnected] = useState(true)

    useEffect(() => {
        const interval = setInterval(() => setConnected(!(ctx.get().connection?.isClosed() ?? true)), 3000)
        return () => clearInterval(interval)
        //eslint-disable-next-line
    }, [])

    const disconnected = <Tip tip={t('bus.connection_failure')}><div className='disconnectbadge'>
        {t('bus.disconnected')}
    </div></Tip>

    const connected = <Tip tip={t('bus.connection_ok')}><div className='connectbadge'>
        {t('bus.connected')}
    </div></Tip>

    return <Fragment>
        {
            disconnectedOnly ? !isConnected ? disconnected : null
                :
                isConnected ? connected : disconnected
        }
    </Fragment>

}