import { ClaimIcon } from '#delisting/constants'
import { RecordStateLabel } from '#record/label'
import { useVesselOracle } from '#vessel/oracle'
import { VidLabel } from '#vid/label'
import { useT } from 'apprise-frontend-core/intl/language'
import { useTenantUtils } from 'apprise-frontend-iam/tenant/utils'
import { Label, LabelProps } from 'apprise-ui/label/label'
import { Tip } from 'apprise-ui/tooltip/tip'
import { useContext } from 'react'
import { RecordInvalidIcon, RecordValidIcon, RecordValidWithWaningIcon } from '../record/constants'
import { RavRecord, RecordPatch } from '../record/model'
import { useRecordOracle } from '../record/oracle'
import { DetailContext } from './context'



export type PatchLabelProps = LabelProps & {

    patch: RecordPatch
    current?: RavRecord

    displayMode?: 'id' | 'state' | 'validation'

}

export const PatchLabel = (props: PatchLabelProps) => {

    const t = useT()

    const { patch, displayMode = 'id', decorations, current, ...rest } = props

    const { edited, sameRecords } = useContext(DetailContext)

    const vessels = useVesselOracle()
    const oracle = useRecordOracle().given(edited)

    if (displayMode === 'state')

        return <RecordStateLabel record={patch} {...rest} />


    if (displayMode === 'validation') {

        const issues = edited.validationReport?.records?.[patch.id]
        const count = issues?.length ?? 0
        const status = oracle.validationStatusOf(patch)
        const Icon = status === 'invalid' ? RecordInvalidIcon : (count > 0 ? RecordValidWithWaningIcon : RecordValidIcon)
        const unchanged = status === 'valid' && sameRecords.includes(patch.id)
        const title =  t(`rec.validation_${unchanged ? 'same' : status}`)

        return <Label icon={<Icon />} tip={status === 'invalid' && t('rec.error_count', { count })} title={title} {...rest} />

    }


    const status = oracle.matchStatusOf(patch)
    const id = patch?.uvi ?? (status === 'new' ? t('rec.no_identifier') : t('rec.matched_identifier'))
    const mode = status === 'none' ? 'normal' : 'tag'
    const tip = status === 'none' || status === 'new' ? undefined : t('rec.match_count', { count: edited.matchReport?.[patch.id]?.matches?.length })

    const decorationsWithDefault = decorations ?? []
    
    if (patch.tenant && current?.tenant && vessels.canClaim(current))
        decorationsWithDefault.push(<ClaimDecoration tenant={current.tenant === patch.tenant ? undefined : current.tenant} />)

    return <VidLabel tip={tip} decorations={decorationsWithDefault} mode={mode} className={`${status}-record`} vid={id} {...rest} />


}

export const ClaimDecoration = (props:{
    tenant?:string

}) => {

    const t = useT()
    const tenants = useTenantUtils()

    const { tenant } = props

    return <Tip tip={tenant ? t("rec.claim_tip",{from: tenants.nameOf(tenant)}) : t("rec.relist_tip")}><ClaimIcon style={{marginLeft:2}} size={12} /></Tip>

}