

import { useT } from 'apprise-frontend-core/intl/language'
import { classname } from 'apprise-ui/component/model'
import { Label } from 'apprise-ui/label/label'
import { MultiSelectBox, MultiSelectProps } from 'apprise-ui/selectbox/selectbox'
import { MailIcon } from './constants'
import './mailtopicbox.scss'
import { MailTopic, noMailTopic } from './model'
import { useMailRegistry } from './modules'
import { useMailTopics } from './topics'




type MailTopcBoxProps = MultiSelectProps<MailTopic>

export const MailTopicBox = (props: MailTopcBoxProps) => {

    const t = useT()

    const { children, onChange, ...rest } = props

    const registry = useMailRegistry()
    const topics = useMailTopics()

    const allTopics = registry.all()

    const selected = allTopics.filter(topic => children?.some(t => t?.value === topic.value))


    const noTopicSelectedOrAlreadyImplied = (topic: MailTopic) =>
    
        // when No Topic is selected no other topic must be deselected
        // but only No Topic can be de-selected, so new choices can be made.
        (topic !== noMailTopic && selected.includes(noMailTopic)) 
        
        // already selected or implied by selected.
        ||  (!!topic.value && topics.extendsAny(topic.value, children?.filter(t => t.value !== topic.value).map(t => t.value)))

    const isRoot = (topic: MailTopic) => !topic.value || !topics.extendsAny(topic, allTopics.filter(t => t.value !== topic.value))

    const render = (topic: MailTopic) => <Label icon={<MailIcon />} className={classname(isRoot(topic) &&'root-topic')} title={t(topic.key)} />

    return <MultiSelectBox className='mailtopics'  showSelected
        placeholder={`[${t('mail.all_topics')}]`} label={t('mail.profile_label')} msg={t('mail.profile_msg')} help={t('mail.profile_help')}
        disabledOf={noTopicSelectedOrAlreadyImplied} 
        options={allTopics}
        keyOf={t => t.key}
        render={render}
        textOf={topic => t(topic.key)}
        onChange={newtopics => onChange?.(topics.reconcile(newtopics))}
        {...rest}>
        {children}
    </MultiSelectBox>

} 