import { useMocks } from 'apprise-frontend-core/client/mocks';
import { useService } from 'apprise-frontend-core/client/service';
import { useIntlMocks } from 'apprise-frontend-core/intl/mocks';
import { useLifecycleMocks } from 'apprise-frontend-core/utils/lifecycle';
import { useUserMocks } from 'apprise-frontend-iam/user/mockery';
import MockAdapter from 'axios-mock-adapter/types';
import shortid from "shortid";
import { contextCategory, defaultContextTag, systemModule, tagType } from '../constants';
import { tagapi } from './calls';
import { Tag, TagLifecycleState, useTagModel } from './model';


export const useTagMockery = () => {

    const service = useService()
    const tagmocks = useTagMocks()
    const usermocks = useUserMocks()

    return (mock: MockAdapter) => {

        console.log("mocking tags and contegories...")

        const svc = service.get(tagType)
        const api = `${svc.prefix}${tagapi}`
        const regapi = RegExp(`${api}/.+$`)

        const newTagId = () => `TG-${shortid()}`

        const tags = tagmocks.tagStore()

        mock.onGet(api).reply(() => [200, tags.all()])

        mock.onPost(api).reply(({ data }) => {

            const now = Date.now()
            const model = JSON.parse(data) as Tag

            model.id = newTagId()

            model.lifecycle.created = now

            model.lifecycle.lastModified = now

            model.lifecycle.lastModifiedBy = usermocks.logged().username

            return [200, tags.add(model)]
        })

        // don't need to use path parm, but real backend will check it matches model in body.  
        mock.onPut(regapi).reply(({ data }) => {

            const model = JSON.parse(data) as Tag

            model.lifecycle.lastModified = Date.now()

            model.lifecycle.lastModifiedBy = usermocks.logged().username

            return [200, tags.update(model)]
        })

        mock.onDelete(regapi).reply(({ url }) => [204, tags.delete(url!.split('/').pop())])

    }



}



export const useTagMocks = () => {

    const mocks = useMocks()

    const { mockMultilang } = useIntlMocks()
    const { pastLifecycle } = useLifecycleMocks()

    const model = useTagModel()

    const self = {


        tagStore: () => mocks.getOrCreateStore<Tag>("tags")

        ,


        mockTags: () => ({

            defaultContext: {

                ...model.newTag(systemModule.type),
                category: contextCategory,
                id: defaultContextTag,
                predefined: true,
                lifecycle: pastLifecycle<TagLifecycleState>('active'),
                name: mockMultilang('Default Context'),
                code: "DEF",
                description: mockMultilang('Default Context Description')
            },

            demoContext: {
                ...model.newTag(systemModule.type),
                category: contextCategory,
                id: 'TG-system-demo',
                lifecycle: pastLifecycle<TagLifecycleState>('active'),
                name: mockMultilang('Demo'),
                code: "DM",
                description: mockMultilang('Demo Context Description')
            },

            trainingContext: {
                ...model.newTag(systemModule.type),
                category: contextCategory,
                id: 'TG-system-training',
                lifecycle: pastLifecycle<TagLifecycleState>('active'),
                name: mockMultilang('Training'),
                code: "TR",
                description: mockMultilang('Training Context Description')
            }

        })

        ,

        mockTag: (seed: string, category?: string, type: string = systemModule.type): Tag => ({
            ...model.newTag(type),
            id: `TG-${seed.toLowerCase().replace(' ', '-')}`,
            category,
            code: `code-${shortid()}`,
            lifecycle: pastLifecycle<TagLifecycleState>('active'),
            name: mockMultilang(`${seed}`),
            description: mockMultilang(`${seed} description`)
        })

    }

    return self

}

