import { delistingType } from '#delisting/constants';
import { coreSlotTypes, GenericRecord, RavRecord } from '#record/model';
import { useRecordPlugins } from '#record/plugin';
import { Vid } from '#vid/model';
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant';
import shortid from 'shortid';


export type Vessel = {

    uvi: Vid

    history: RavRecord[]

}

export const useVesselModel = () => {

    const logged = useTenancyOracle()

    const plugins = useRecordPlugins()


    const self = {

        // creates a vessel's history with a single first record.
        // sets the flagstate to the user's own tenant, if it exists.
        newVessel: (): Vessel => {

            const firstRecord: RavRecord = self.newRecord()

            if (!logged.managesMultipleTenants()) {

                firstRecord.tenant = logged.tenant()
                firstRecord.details = {...firstRecord.details, flagstate: firstRecord.tenant}
            }


            return { history: [self.nextRecordFrom(firstRecord)], uvi: undefined! }
        }
        ,

        // creates a new record in a vessel's history.
        // we must match  what the parser of full vessel submission would do,
        // only we don't have the data yet and prepare blank slots instead.
        newRecord: (): RavRecord => {

            const patch: Partial<RavRecord> = {

                id: `R-${shortid()}`,
                lifecycle: { state: 'uploaded'},
                patchedSlots: [...coreSlotTypes]
            }

            // prefil slots with "blanks" (it's convenient for forms to work with existing structures).
            patch.patchedSlots?.forEach(t => {

                const {id, ...blankslot} = plugins.lookup(t).newSlot()

                patch[t] = { ...blankslot, ...patch[t], id } as any
                
            })

            return patch as RavRecord
        }

        ,

        // create the next record in a vessel's history.
        // it's based off new record (new id, blank slots), but completes with uvi, tenant, and all slots.
        // if the  record is delisted, the delisting slot is discarded.
        nextRecordFrom: (record: RavRecord): RavRecord => {

            const {[delistingType]:ignored, id, timestamp, origin, patchedSlots, lifecycle,  ...inheritedRest} = record

            return {...self.newRecord(), ...inheritedRest }
     
        }

        ,

        delisted: (record: GenericRecord) => record.patchedSlots.includes(delistingType)

    }

    return self
}