
import { useL, useMultilang } from 'apprise-frontend-core/intl/multilang'
import { classname, Debugged, Styled } from 'apprise-ui/component/model'
import { FieldInfo } from 'apprise-ui/field/model'
import { ValidationStatus } from 'apprise-ui/field/validation'
import { Tip } from 'apprise-ui/tooltip/tip'
import { createContext, useContext } from 'react'
import './namedproperty.scss'


export type NamedPropertyProps<T = any> = React.PropsWithChildren<Debugged & Styled & Partial<{

    name: React.ReactNode

    nameAlign: 'left' | 'right' | 'center'
    valueAlign: 'left' | 'right' | 'center'
    valueSuffix: React.ReactNode

    previously: T
    currently: T
    highlightMode: 'change' | 'match'

    noFixedHeight: boolean

    status: ValidationStatus | FieldInfo

}>>

export const NamedProperty = <T extends Object = any>(props: NamedPropertyProps<T>) => {

    const l = useL()
    const ml = useMultilang()

    const ctx = useContext(NamedPropertyContext)

    const { className, style, name, children, currently = children, previously, highlightMode = 'change', noFixedHeight, nameAlign = 'left', valueAlign = 'left', valueSuffix, status = 'success' } = {...ctx, ...props}

    const highlighted = previously !== undefined && (highlightMode === 'change' ? (previously !== currently) : (previously === currently) )

    const info = typeof status === 'string' ? { type: status } as FieldInfo : status

    props.debug && console.log({ currently, previously, highlighted, name, highlightMode })

    return <Tip tip={info.msg} tipPlacement='bottom' tipType='error'>
        <div className={classname('named-property', `property-${info.status}`, highlighted && 'property-highlighted', noFixedHeight && 'auto-height', className)} style={style}>

            {name !== undefined && <div className={classname('property-name', `align-${nameAlign}`)}>
                <div>{ml.test(name) ? l(name) : name}</div>
            </div>}

            <div className={classname('property-value', `align-${valueAlign}`)}>
                <div>{ml.test(children) ? l(children) : children}</div>

                {valueSuffix !== undefined
                    && <div className='value-suffix'>{valueSuffix}</div>
                }
            </div>

        </div>
    </Tip>

}


export const NamedPropertyContext = createContext<NamedPropertyProps>({})