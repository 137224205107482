
import { LanguageObserver } from 'apprise-frontend-core/intl/langobserver'
import { TenantInitialiser, TenantInitialiserProps } from './initialiser'
import { TenantLoader, TenantLoaderProps } from './loader'
import { useLoggedTenant } from './model'
import { TenantProvider } from './provider'

export type TenantProps = TenantInitialiserProps & TenantLoaderProps

// initialises the model and loads users, and mounts the state if required.
export const Tenants = (props: TenantProps) => {

    const { children } = props

    return <TenantProvider>
        <TenantInitialiser>
            <TenantLoader {...props}>
                <LanguageChangeObserver />
                {children}
            </TenantLoader>
        </TenantInitialiser>
    </TenantProvider>

}

const LanguageChangeObserver = () => {

    const current = useLoggedTenant()

    return <LanguageObserver language={current?.preferences.language} />
}