import { RecordPatch, RecordSlot } from '#record/model';
import { DeepPartial } from 'apprise-frontend-core/utils/common';
import shortid from 'shortid';
import { authorizationType } from './constants';

export type AuthorizationPatch = RecordPatch<typeof authorizationType>

export type Authorization = RecordSlot & {


    from: string
    to: string

}

// minimal skeleton for missing slots.
export const newAuthorizationSlot = () => {

    const partials : DeepPartial<Authorization> = {

        id: `A-${shortid()}`,

    } 

    return partials as Authorization
}