import { useActionRegistry } from 'apprise-frontend-core/authz/action'
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import { tenantActions } from 'apprise-frontend-iam/authz/oracle'
import { useTagModules } from 'apprise-frontend-tags/tag/modules'
import { PropsWithChildren } from 'react'
import { useTenantTaggeModule } from './plugins'
import { useTenantChangeListener } from './events'
import { EventSubscriber } from 'apprise-frontend-events/subscriptions'

export type TenantInitialiserProps = PropsWithChildren<{}>

export const TenantInitialiser = (props: TenantInitialiserProps) => {

    const { children } = props

    const tagRegistry = useTagModules()
    const tagModule = useTenantTaggeModule()

    const actions = useActionRegistry()

    const changeListener = useTenantChangeListener()

    const activate = () => {

        tagRegistry.register(tagModule)
        actions.register(Object.values(tenantActions))

    }

    const { content } = useRenderGuard({

        render: <EventSubscriber name='tenant-change-listener' subscriptions={[changeListener]} >
            {children}
        </EventSubscriber>,
        orRun: activate
    })

    return content
}