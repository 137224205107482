import { useAuthzConfig } from 'apprise-frontend-core/authz/config'
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant'
import { useUserOracle } from 'apprise-frontend-iam/authz/user'
import { UserPermissionModule } from 'apprise-frontend-iam/user/modules'
import { TaggedModule } from 'apprise-frontend-tags/tag/modules'
import { TenantIcon, tenantPlural, tenantSingular, tenantType } from './constants'
import { Tenant } from './model'
import { TenantPermissionBox } from './permissions'
import { useTenantStore } from './store'



export const useTenantPermissionModule = () => {

    const store = useTenantStore()

    const userOracle = useUserOracle()
    const tenantOracle = useTenancyOracle()

    const {enableMultiManagers} = useAuthzConfig()

    const module: UserPermissionModule<Tenant> = {

        Icon: TenantIcon,
        type: tenantType,
        plural: tenantPlural,
        box: props => {

            // all oracles for user under editing
            const edited = {...userOracle.given(props.edited), ...tenantOracle.given(props.edited) }

            // we use this to delegate mgm rights over multiple tenants (has to be enabled).  
            // ( we use instead a simpler switch-based UI to make users managers of their own tenant.) 
            if (!enableMultiManagers || !tenantOracle.managesMultipleTenants() )
                return undefined

            // logged user has no tenant? can see any tenant in principle.
            // logged user is in a tenant? can see its own (will be preselected)
            // logged user is multimanager? can see any managed tenant it can delegate.
            // edited is a manager? can see any already managed (though they may not be visible for tenancy rules).
            const tenantInScope = (t:Tenant) => tenantOracle.hasNoTenant() || tenantOracle.tenant() === t.id || tenantOracle.isManagerOf(t.id) || edited.isManagerOf(t.id)
            
            const range = store.all().filter(tenantInScope)

            // show option for All Tenants if the user under editing has none.
            const showAllTenant = edited.hasNoTenant()

            return <TenantPermissionBox wildcard={showAllTenant} preselectWildcard resourceRange={range}  {...props} />
    
    }
    }

    return module
}

export const useTenantTaggeModule = () => {

     const module: TaggedModule = {
        
        type: tenantType,
        singular: tenantSingular,
        plural: tenantPlural
    }

    return module
}

