import { ExtendedState } from './model';


export const accountColors : Record<ExtendedState, React.CSSProperties['color']> = { 
    offsync: 'orangered', 
    none: 'slategray', 
    pending: 'orange', 
    active: 'lightseagreen', 
    suspended:'lightgrey', 
    unknown:'dimgray'
}
  