
import { useAuthorizationPlugin } from '#authorization/plugin'
import { useDetailsPlugin } from '#details/plugin'
import { usePhotographPlugin } from '#photographs/plugin'
import { FilterProps } from '#record/plugin'
import { BaseComboPatch } from './mode'



export const useBaseComboFilters = (props:FilterProps<BaseComboPatch>) => {

   const {data: filteredDetails,filters: detailsFilters} = useDetailsPlugin().patchFilters(props)
   const {data: filteredAuthorizations,filters: authorizationFilters} = useAuthorizationPlugin().patchFilters({...props, data: filteredDetails as any })
   const {data,filters: photoFilters} = usePhotographPlugin().patchFilters({...props, data: filteredAuthorizations as any })
   

    return {data,filters:[...detailsFilters,...authorizationFilters,...photoFilters]}
}

