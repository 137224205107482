import { useL } from 'apprise-frontend-core/intl/multilang'
import { OneOrMore, Optional, utils } from 'apprise-frontend-core/utils/common'
import { Tagged, TagReference, useTagModel } from './model'
import { useTagStore } from './store'

//  aggregates a set of api for external clients, as a convenience.
//  avoid where performance is critical, or dependencies are naturally to a single api. 

export const useTagUtils = () => {

    const store = useTagStore()
    const model = useTagModel()
    const l = useL()

    const self = {

        // conceptually belongs to the model api but has a dependency on state.
        // so we place it here as client have already accepted all dependencies.
        givenTagged: (tagged: Tagged) => self.givenTags(tagged.tags)

        ,

        givenTags: (tags: TagReference[] = []) => model.given(tags.map(store.safeLookupTag))

        ,

        // returns the text fingerprint of one or more tags.
        nameOf: (tag: Optional<TagReference>) =>  l(store.lookupTag(tag)?.name)

        ,

        // returns the text fingerprint of one or more tags.
        textOf: (tags: OneOrMore<Optional<TagReference>>) =>  model.textOf(utils().arrayOf(tags).map(store.lookupTag))

        ,

        compareRef: (t1: Optional<TagReference>, t2: Optional<TagReference>) => model.comparator(store.safeLookupTag(t1), store.safeLookupTag(t2 || undefined))

        ,

        compareTagsOf: <T> (f: (_: T) => Optional<TagReference>) => (t1:T,t2:T) => self.compareRef(f(t1),f(t2))
    }


    return self

}