import { ExcelRecordWriter, useGenericExcelSerializer } from '#record/excelserializer'
import { useT } from 'apprise-frontend-core/intl/language'
import { AuthorizationPatch } from './model'



export const useAuthorizationExcelSerializer = () => useGenericExcelSerializer(useAuthorizationExcelWriter())

export const useAuthorizationExcelWriter= () => {

    const t = useT()

    const writer: ExcelRecordWriter<AuthorizationPatch> = (props) => {

        const { output } = props

        output.date(t('authorization.from_col')).render(r => r.authorization?.from)
        .date(t('authorization.to_col')).render(r => r.authorization?.to)
    }


    return writer
}