import { useT } from 'apprise-frontend-core/intl/language';
import { Button } from 'apprise-ui/button/button';
import { useContext } from 'react';
import { BiExitFullscreen, BiFullscreen } from 'react-icons/bi';
import { FocusModeContext } from './provider';



export const FocusModeButton = () => {


    const t = useT()
    const { toggleFocusMode, focusModeEnabled } = useContext(FocusModeContext);

    const focusModeTip = focusModeEnabled ? t("ui.focus_mode.exit") : t("ui.focus_mode.enter")

    return <Button noLabel noReadonly type="ghost" tip={focusModeTip} className="focus-mode-btn" onClick={toggleFocusMode}
        icon={focusModeEnabled ? <BiExitFullscreen /> : <BiFullscreen />}>
    </Button>


}



