import { RecordPlugin, RecordPluginDescriptor, useDefaultRecordPlugin } from '#record/plugin'
import { useT } from 'apprise-frontend-core/intl/language'
import { useAuthorizationColumns } from './columns'
import { AuthorizationIcon, patchedTypes } from './constants'
import { AuthorizationForm, useAuthorizationFormFields } from './form'
import { AuthorizationPatch, newAuthorizationSlot } from './model'
import { AuthorizationOverview } from './overview'
import { useAuthorizationParser } from './parser'
import { AuthorizationPatchCard } from './patch'
import { useAuthorizationJsonSerializer } from './jsonserializer'
import { useAuthorizationValidation } from './validator'
import { useAuthorizationExcelSerializer, useAuthorizationExcelWriter } from './excelserializer'

export const useAuthorizationPluginDescriptor = (): RecordPluginDescriptor => {

    const t = useT()

    const defaults = useDefaultRecordPlugin()

    return {

        ...defaults

        ,

        Icon: AuthorizationIcon

        ,

        name: t('authorization.type')

        ,

        singular: t('authorization.singular')

        ,

        plural: t('authorization.plural')

        ,

        patchedTypes
        

    }

}

export const useAuthorizationPlugin = () => {


    const self: RecordPlugin<AuthorizationPatch> = {


        ...useDefaultRecordPlugin()

        ,

        ...useAuthorizationPluginDescriptor()

        ,


        parse: useAuthorizationParser

        ,

        jsonserialize: useAuthorizationJsonSerializer

        ,

        excelserialize: useAuthorizationExcelSerializer

        ,

        excelwrite: useAuthorizationExcelWriter

        ,

        Patch: AuthorizationPatchCard

        ,

        Form: AuthorizationForm

        ,

        formFields: useAuthorizationFormFields

        ,

        Overview: AuthorizationOverview

        ,

        patchColumns: useAuthorizationColumns

        ,

        validate: useAuthorizationValidation

        ,
        
        newSlot: newAuthorizationSlot

    }

    return self
}
