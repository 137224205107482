import { Vid } from '#vid/model';
import { State } from 'apprise-frontend-core/state/api';
import { createContext } from 'react';
import { useDetailData } from './formdata';
import { Vessel } from './model';



export type VesselCache = Record<Vid, Vessel>

export const VesselCacheContext = createContext<State<VesselCache>>(undefined!)


export type DetailState = ReturnType<typeof useDetailData>

export const DetailContext = createContext<DetailState>(undefined!)