import { useLanguage } from 'apprise-frontend-core/intl/language'
import { State } from 'apprise-frontend-core/state/api'
import { StateProvider } from 'apprise-frontend-core/state/provider'
import en from 'date-fns/locale/en-US'
import { createContext, PropsWithChildren, useContext, useEffect } from 'react'



export type DateFnsLocales = {

    locale: Locale

}

export const DateFnsLocaleContext = createContext<State<DateFnsLocales>>(undefined!)


export const useDateFnsLocale = () => useContext(DateFnsLocaleContext).get()?.locale


const initialState: DateFnsLocales = {

    locale: undefined!
}


export const DateFnsLocaleProvider = (props: PropsWithChildren<{}>) => {

    const { children } = props

    return <StateProvider initialState={initialState} context={DateFnsLocaleContext}>
        <ContextLoader />
        {children}
    </StateProvider>

}


const ContextLoader = () => {

    const language = useLanguage()

    const currentLanguage = language.current()

    const state = useContext(DateFnsLocaleContext)

    const loadLocale = async () => {

        if (!currentLanguage)
            return

        console.log(`fetching locale for ${currentLanguage}...`)

        try {
            const locale = currentLanguage === 'en' ? en : currentLanguage === 'fr' ? (await import('date-fns/locale/fr')).default : undefined

            if (locale)
                state.set(s => s.locale = locale)
        }
        catch(err) {

            console.warn(`couldn't fetch locale for ${currentLanguage}: ${JSON.stringify(err)}`);
            
        }

    }

    useEffect(() => {

        loadLocale()

        //eslint-disable-next-line
    }, [currentLanguage])

    return null

}