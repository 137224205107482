import { RavConfig } from '#config';
import { textDateFormats, useGenericPatchParser } from '#record/parser';
import { useConfig } from 'apprise-frontend-core/config/api';
import { useT } from 'apprise-frontend-core/intl/language';
import { DeepPartial } from 'apprise-frontend-core/utils/common';
import { useDefaultModelParser, useDefaultParser } from 'apprise-frontend-parse/resource';
import { useParseUtils } from 'apprise-frontend-parse/utils';
import { useDefaultBookParser } from 'apprise-frontend-parse/workbook';
import shortid from 'shortid';
import { authorizationType } from './constants';
import { AuthorizationPatch } from './model';
import { AuthorizationJsonSerialization } from './jsonserializer';




export const useAuthorizationParser = () => {

  const config = useConfig<RavConfig>()

  const modelparser = useDefaultModelParser(useAuthorizationRowParser())

  const bookparser = useDefaultBookParser(modelparser, () => config, authorizationType)

  return useDefaultParser({ modelparser, bookparser })


}


export const useAuthorizationRowParser = () => {

  const t = useT()
  const parse = useParseUtils()

  const parser = (row: AuthorizationJsonSerialization) => {

    const patch: DeepPartial<AuthorizationPatch> = {

      patchedSlots: [authorizationType],

      [authorizationType]: {

        timestamp: undefined!,

        id: `A-${ shortid() }`,

        from: parse.date(row.from,textDateFormats).orIssue('error').about(t('authorization.from')).on(row.uvi ?? t('rec.no_identifier')),
        to: parse.date(row.to,textDateFormats).orIssue('error').about(t('authorization.to')).on(row.uvi ?? t('rec.no_identifier'))
      }
    }

    return patch as AuthorizationPatch
  }

  return useGenericPatchParser(parser)

}