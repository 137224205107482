import { authorizationType } from '#authorization/constants';
import { delistingType } from '#delisting/constants';
import { detailsType } from '#details/constants';
import { coreSlotTypes, GenericRecord, RavRecord } from '#record/model';
import { useRecordOracle } from '#record/oracle';
import { useSearch } from '#search/api';
import { trxauthzType } from '#trxauth/constants';
import { useVesselCache } from '#vessel/cache';
import { useMode } from 'apprise-frontend-core/config/api';
import { utils } from 'apprise-frontend-core/utils/common';
import { useLogged } from 'apprise-frontend-iam/login/logged';
import { useBytestreamTracker } from 'apprise-frontend-streams/track/tracker';
import { useAsyncTask } from 'apprise-ui/utils/asynctask';
import { useCrud } from 'apprise-ui/utils/crudtask';
import isAfter from 'date-fns/isAfter';
import { useContext } from 'react';
import { useSubmissionCalls } from './calls';
import { submissionPlural, submissionSingular } from './constants';
import { SubmissionStoreContext } from './context';
import { Submission, SubmissionRef, useSubmissionModel } from './model';


export type SubmissionStore = {

    all: Submission[]
}



export const useSubmissionStore = () => {

    const mode = useMode()

    const task = useAsyncTask()
    const crud = useCrud({ singular: submissionSingular, plural: submissionPlural })

    const state = useContext(SubmissionStoreContext)

    const oracle = useRecordOracle()
    const tracker = useBytestreamTracker()
    const calls = useSubmissionCalls()

    const model = useSubmissionModel()

    const search = useSearch()
    const cache = useVesselCache()

    const logged = useLogged()

    const self = {


        all: () => state.get().all

        ,

        lookup: (id: SubmissionRef) => self.all().find(s => s.id === id)

        ,


        fetchAll: crud.fetchAllWith(async (props: { force: boolean } = { force: true }) => {

            const { force } = props

            const all = state.get().all

            if (force || !all) {

                const fetched = await calls.fetchAll()

                state.set(s => s.all = fetched)

                return fetched

            }

            return all


        })
            .done()

        ,

        fetchOne: crud.fetchOneWith(async (submission: SubmissionRef) => {

            const fetched = await calls.fetchOne(submission)

            state.set(s => s.all = s.all.map(s => s.id === fetched.id ? fetched : s))

            return fetched

        })
            .with(config => config.log(ref => `fetching submission ${ref}...`))
            .done()


        ,

        save: crud.saveOneWith(async (submission: Submission) => {

            let saved: Submission

            const submissionWithBoundResources = model.bindResources(submission)

            await tracker.upload(...submissionWithBoundResources.resources ?? [])

            const firstSave = !submission.lifecycle.created

            saved = firstSave ? await calls.add(submissionWithBoundResources) : await calls.update(submissionWithBoundResources)

            state.set(s => s.all = firstSave ? [saved, ...s.all] : s.all.map(t => t.id === saved.id ? saved : t))

            tracker.reset()

            return saved


        })
            .with(config => config.log(s => `saving ${s.id}...`))
            .done()


        ,

        remove: crud.removeOneWith(async (submission: Submission, onConfirm: () => any) => {

            await calls.remove(submission.id)

            state.set(s => s.all = s.all.filter(c => c.id !== submission.id))

            onConfirm?.()

            if (submission.lifecycle.state === 'published')
                cache.resetForSubmission(submission)
        })
            .with(config => config.log(s => `removing ${s.id}...`))
            .withConsent()

        ,

        removeMany: crud.removeManyWith(async (list: string[], onConfirm?: () => void) => {

            if (!mode.development)
                throw Error("this is a dev-only operation")

            list.forEach(calls.remove) // best effort

            state.set(s => s.all = s.all.filter(t => !list.includes(t.id)))

            onConfirm?.()

        }).withConsent()


    }


    // when we pass a function to task.make() typechecking breaks if we use self inside the function.
    return {

        ...self,

        submit: task.make((submission: Submission) => {

            const recordOracle = oracle.given(submission)

            const submitted: Submission = {

                ...submission,
                lifecycle: {

                    ...submission.lifecycle,

                    state: 'submitted',
                    previousState: submission.lifecycle.state,

                    submitted: Date.now(),
                    submittedBy: logged.username,

                },

                records: submission.records.map(r => recordOracle.canSubmit(r) ?

                    { ...r, lifecycle: { ...r.lifecycle, state: 'submitted' as const, previousState: r.lifecycle.state } }

                    : r)
            }

            return self.save(submitted)

        })
            .with(config => config.log(s => `submitting ${s.id}...`))
            .done()

        ,

        submitForReview: task.make((submission: Submission) => {

            const submitted: Submission = {

                ...submission,

                lifecycle: { ...submission.lifecycle, state: 'pending', previousState: submission.lifecycle.state }
            }

            return self.save(submitted)

        })
            .with(config => config.log(s => `submitting for review ${s.id}...`))
            .done()

        ,

        reject: task.make(async (submission: Submission, comment?: string) => {

            const rejected: Submission = {

                ...submission,
                lifecycle: {

                    ...submission.lifecycle,

                    state: 'rejected',
                    previousState: submission.lifecycle.state,

                    rejected: Date.now(),
                    rejectedBy: logged.username,
                    rejectedWith: comment

                }

            }

            return self.save(rejected)

        })
            .with(config => config.log(s => `rejecting ${s.id}...`))
            .done()


        ,

        restore: task.make(async (submission: Submission) => {

            const restored: Submission = {

                ...submission,
                lifecycle: {

                    ...submission.lifecycle,

                    state: submission.lifecycle.previousState,
                    previousState: 'rejected',

                    rejected: undefined,
                    rejectedBy: undefined,
                    rejectedWith: undefined

                }

            }

            return self.save(restored)

        })
            .with(config => config.log(s => `restoring ${s.id}...`))
            .done()


        ,

        publish: task.make(async (submission: Submission, currentRecords: Record<string, RavRecord>) => {


            const stageRecord = (record: GenericRecord) => {

                // won't touch records that are not to be published.
                if (!oracle.given(submission).canPublish(record))
                    return record;

                // moves lifecycle.
                const staged: GenericRecord = {
                    ...record, lifecycle: {

                        ...record.lifecycle,
                        state: 'published' as const,
                        previousState: record.lifecycle.state

                    }
                }

                const current = record.uvi ? currentRecords[record.uvi] : undefined

                // special case: patches auth on delisting if it cuts it shorter.
                if (submission.type === delistingType) {

                    const date = record.delisting?.date
                    const auth = current?.authorization

                    const patchAuth = date && auth && (!auth.to || isAfter(new Date(auth.to), new Date(date))) && isAfter(new Date(date), new Date(auth.from))

                    if (patchAuth) {

                        staged[authorizationType] = { ...auth, to: date }
                        staged.patchedSlots = [...record.patchedSlots, authorizationType]
                    }

                }

                else if (submission.type === trxauthzType && staged.trxauthz) {

                    // nothing to do for now, just don't want standard behavious.

                }
                else {


                    // refines patched slots by actual comparison (exlcludes identifiers and timestamps)
                    staged.patchedSlots = coreSlotTypes.filter(type => staged[type]).filter(type => {

                        const { id, timestamp, ...stagedslot } = staged[type] ?? {}
                        const { id: cid, timestamp: cts, ...currentslot } = current?.[type] ?? {}

                        return !utils().deepequals(stagedslot, currentslot)

                    })

                    // there could be an implicit claim, in that sense the details must be patched too.

                    // curent flagstate may come with patch or in previous, or it may not exist at all.
                    const currentFlagState = staged.details?.flagstate ?? current?.details.flagstate

                    if (currentFlagState && staged.tenant !== currentFlagState) {

                        staged.details = { ...(staged.details ?? current?.details)!, flagstate: staged.tenant }

                        if (!staged.patchedSlots.includes(detailsType))
                            staged.patchedSlots.push(detailsType)
                    }

                }

                return staged

            }

            const staged: Submission = {

                ...submission,

                lifecycle: {
                    ...submission.lifecycle,

                    state: 'published',
                    previousState: submission.lifecycle.state,

                    published: Date.now(),
                    publishedBy: logged.username

                },

                records: submission.records.map(stageRecord)

            }

            staged.stats = model.computeRecordStats(staged)

            console.log('publishing as', staged)

            const published = await self.save(staged)

            search.runQueryQuietly()

            cache.resetForSubmission(published)

            return published

        })
            .with(config => config.log(s => `publishing records ${s.id}...`))
            .done()


    }


}

