import { delistColor, DelistingIcon, delistingReasonAuto, delistingReasonCategory, delistingType } from '#delisting/constants';
import { Delisting } from '#delisting/model';
import { useT } from 'apprise-frontend-core/intl/language';
import { useForm } from 'apprise-frontend-core/utils/form';
import { TagRefBox } from 'apprise-frontend-tags/tag/tagrefbox';
import { Button } from 'apprise-ui/button/button';
import { DateBox } from 'apprise-ui/date/datebox';
import { useValidation } from 'apprise-ui/field/validation';
import { Form } from 'apprise-ui/form/form';
import { RouteGuard } from 'apprise-ui/link/routeguard';
import { TextBox } from 'apprise-ui/textbox/textbox';
import { useAsyncTask } from 'apprise-ui/utils/asynctask';
import { Explainer } from 'apprise-ui/utils/explainer';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import { useContext } from 'react';
import { DetailContext } from './context';
import { useEffects } from './effects';


export const DelistingPanel = () => {

    const t = useT()

    const { delisted, delistingKit } = useContext(DetailContext)

    const { Drawer } = delistingKit

    const title = delisted ? t('delisting.form_delisted_title') : t('delisting.form_delist_title')

    const readonly = delisted


    return <Drawer renderOnMount={false} unmountOnClose readonly={readonly} icon={<DelistingIcon />} title={title} width={500}>

        <Inner />

    </Drawer>
}

const Inner = () => {

    const t = useT()

    const { record } = useContext(DetailContext)

    const { delist } = useEffects()

    const form = useForm<Delisting>(record[delistingType] ?? {

        date: new Date().toISOString()

    } as Delisting)

    const task = useAsyncTask()

    const { reportOf, check, is } = useValidation()

    const submitForm = task.make(async () => {

        form.reset.to(form.edited).quietly()

        delist(form.edited)


    })
        .with($ => $.show(t('delisting.form_delisting')))
        .done()

    const fields = reportOf({

        date: {

            label: t("delisting.form_date_lbl"),
            msg: t("delisting.form_date_msg"),
            ...check(is.empty).on(form.edited.date),
        }

        ,

        reason: {

            label: t("delisting.form_reason_lbl"),
            msg: t("delisting.form_reason_msg"),
            ...check(is.empty).on(form.edited.reason),
        }

        ,

        note: {

            label: t("delisting.form_note_lbl"),
            msg: t("delisting.form_note_msg"),
        }


    });

    const delistBtn = <Button type='primary' enabled={form.dirty} disabled={fields.errors() > 0} onClick={submitForm}>
        {t('delisting.form_btn')}
    </Button>

    const auth = record?.authorization ?? undefined

    const from = new Date(auth.from)
    const to = new Date(auth.to)
    const date = new Date(form.edited.date)

    const patchAuth = form.edited.date && auth && isAfter(to, date)

    return <>

        <RouteGuard when={form.dirty} ignoreQueryParams={false} />

        <Explainer icon={<DelistingIcon color={delistColor} />}>
            {t('delisting.form_explainer')}
        </Explainer>

        <br />

        <Form centered >

            <DateBox info={fields.date} onChange={form.set.with((m, v) => m.date = v!)}>
                {form.edited.date}
            </DateBox>

            <div className='delist-authz-notice'>
                {patchAuth &&

                    t('delisting.auth_correction_msg',
                        {
                            from: format(from, 'P'),
                            to: format(to, 'P'),
                            newto: format(date, 'P')
                        }
                    )}
            </div>

            <TagRefBox width='70%' info={fields.reason} noClear category={delistingReasonCategory} disabledOf={t => t === delistingReasonAuto} onChange={form.set.with((m, v) => m.reason = v!)}>
                {form.edited.reason}
            </TagRefBox>

            <TextBox width='80%' info={fields.note} multi={{ start: 4, end: 8 }} onChange={form.set.with((m, v) => m.note = v)}>
                {form.edited.note}
            </TextBox>

            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 25 }}>
                {delistBtn}
            </div>

        </Form>


    </>


}