import { ParserConfig } from 'apprise-frontend-parse/config';

export const migrationConfig: ParserConfig = {

    parse: {

        headerCornerstone: 'pk_id',

        keys: {
            
            "vr_vessel": { key: 'vkey' },
            'record_type': { key: 'kind', aliases: { 'FC': 'CV' } },
            "date_recorded": { key: 'timestamp' },

            'operating_range': { key: 'range', aliases: { 'EEZ and High Seas': "HIS", "UNK": undefined } },
            'vessel_name': { key: 'name' },
            'flag': { key: 'flagstate', filter: v => v !== 'UNK' },

            'imo': { key: 'imo' },
            'iotc_number': { key: 'uvi' },
            'tuvi': { key: 'tuvi' },

            'registration_number': { key: 'regno' },
            'registration_port_name': { key: 'portname', aliases: { 'Unknown': undefined } },
            'registration_port_country': { key: 'portflag', aliases: { 'UNK': undefined } },
            'registration_port_code': { key: 'portcode', aliases: { 'UNKN': undefined } },

            'national_number': { key: 'natno' },
            'ircs': { key: 'ircs', aliases: { 'unk': undefined, 'Unknown': undefined } },

            'vessel_type': { key: 'type', aliases: { 'UN': undefined } },
            'gear_type': {
                key: 'gear',
                aliases: {

                    'GILL': 'GI', 'Gill': 'GI',
                    "GNRN": ["GI", "RIN"],
                    "LLPS": ["LL", "PS"],
                    "GIHA": ["GI", "HAND"],
                    "HABB": ["HAND", "PL"],
                    "LLGI": ["LL", "GI"],
                    "LLHA": ["LL", "HAND"],
                    "LLLI": ["LL", "LINE"],
                    "LLRI": ["LL", "RIN"],
                    "LLTR": ["LL", "TROL"],
                    "LLTW": ["LL", "TRAW"],
                    "LLGR": ["LL", "GI", "RIN"],
                    "LLBH": ["LL", "PL", "HAND"],
                    "LLNT": ["LL", "PS", "GI"],
                    "HOOK": ["LL", "PL", "HAND", "TROL"],
                    "BBTR": ["PL", "TROL"],
                    "LISJ": ["LINE", "OTH"],
                    "TRAW": 'MWT',
                    "BB": "PL",
                    "LINE": "LI",
                    "BBLI": ["PL", "HAND", "TROL"],
                    "UNCL": undefined,
                }
            },

            'loa': { key: 'loa' },
            'grt': { key: 'grt' },
            'gt': { key: 'tonnage' },
            'cc_m3': { key: 'volume' },
            'cc_mt': { key: 'volumeTonnes' },

            'authorized_from': { key: 'from' },
            'authorized_to': { key: 'to' },

            'owner_name': { key: 'owner', aliases: { 'UNKNOWN*': undefined } },
            'owner_address': { key: 'ownerAddress', aliases: { 'UNKNOWN': undefined } },
            'beneficial_owner_name': { key: 'beneficial', aliases: { 'UNKNOWN': undefined } },
            'beneficial_owner_address': { key: 'beneficialAddress', aliases: { 'UNKNOWN*': undefined } },
            'operator_name': { key: 'operator', aliases: { 'UNKNOWN': undefined } },
            'operator_address': { key: 'operatorAddress', aliases: { 'UNKNOWN*': undefined } },
            'company_name': { key: 'company', aliases: { 'UNKNOWN': undefined } },
            'company_address': { key: 'companyAddress', aliases: { 'UNKNOWN*': undefined } },
            'company_registration': { key: 'companyRegno'},
            
            'BowImageURL': { key: 'bow' },
            'PortImageURL': { key: 'portside' },
            'StarboardImageURL': { key: 'starboard' },
            'ExternalMarkingImageURL': { key: 'markings' },
            'sorting_order': { key: 'sorting'}


        }

    }
}

