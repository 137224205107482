import { NoPhotoIcon, photographType } from '#photographs/constants';
import { Photo } from '#photographs/model';
import { SlotCardProps } from '#vessel/overview';
import { ImageLoader } from 'apprise-frontend-streams/imageloader/imageloader';
import { Grid } from 'apprise-ui/grid/grid';
import { Fragment } from 'react';
import { useCardProperties } from './cardutils';
import { DetailsPatch } from './model';

export const DetailOverview = (props: SlotCardProps<DetailsPatch>) => {

    const { showChange, record, previous } = props

    const { uvi, name, port, vesseltype, vesselkind, gears, range, uvis, specs, flagstate, contacts, priorHistory } = useCardProperties({

        showChange,
        record,
        current: previous
    })

    const photo = (record?.[photographType]?.photos ?? []).find((p: Photo) => p.ref)?.ref

    return <Grid rowGap={8} columnGap={5} >

        <Grid.Row>
            <Grid.Col width='min-content' >
                <ImageLoader height={153} stream={photo!} placeholder={<NoPhotoIcon className='detail-photo-placeholder' />} />
            </Grid.Col>

            <Grid.Col>

                <Grid.Row>
                    <Grid.Col>{uvi}</Grid.Col>
                    <Grid.Col>{name}</Grid.Col>
                    <Grid.Col>{flagstate}</Grid.Col>
                </Grid.Row>

                <Grid.Row>
                    {uvis.map((vid, i) =>

                        <Grid.Col key={`vid-${i}`}>{vid}</Grid.Col>

                    )}

                </Grid.Row>

                <Grid.Row>
                    <Grid.Col>{vesselkind}</Grid.Col>
                    <Grid.Col>{vesseltype}</Grid.Col>
                    <Grid.Col>{range}</Grid.Col>
                </Grid.Row>

                <Grid.Row>{port}</Grid.Row>


            </Grid.Col>


        </Grid.Row>



        <Grid.Row>
            {specs.map((spec, i) =>

                <Grid.Col key={`spec-${i}`}>{spec}</Grid.Col>)

            }
        </Grid.Row>


        {gears.map((gears, j) =>

            <Grid.Row key={`gear-${j}`}>{gears.map((gear, i) =>

                <Grid.Col key={`prop-${i}`}>{gear}</Grid.Col>)

            }</Grid.Row>
        )}

        {contacts.map(({ contact, name, address, regno }, i) =>

            <Fragment key={contact?.id ?? i}>

                {regno ?

                    < Grid.Row>
                        <Grid.Col>{name}</Grid.Col>
                        <Grid.Col width={300}>{regno}</Grid.Col>
                    </Grid.Row>

                    :

                    <Grid.Row >
                        {name}
                    </Grid.Row>
                }

                <Grid.Row>
                    {address}
                </Grid.Row>

            </Fragment>

        )}
        {record.details.priorHistory &&
            <Fragment>

                <Grid.Row>{priorHistory.name}</Grid.Row>
                <Grid.Row>{priorHistory.flags}</Grid.Row>

                <Grid.Row>
                    <Grid.Col>{priorHistory.deletions}</Grid.Col>
                </Grid.Row>
            </Fragment>
        }
    </Grid >
}


