import { useT } from 'apprise-frontend-core/intl/language'
import { FormState } from 'apprise-frontend-core/utils/form'
import { Tenant } from 'apprise-frontend-iam/tenant/model'
import { Fields } from 'apprise-ui/field/validation'
import { AppSettings } from './settings'


export const useAppSettingsFields = (settings: AppSettings) => {

    const t = useT()

    const self = {


        primary: {

            label: t('rav.setting_primary'),
            msg: t('rav.setting_primary_msg'),
            help: t('rav.setting_primary_help')

        }
        
        ,

        secondary: {

            label: t('rav.setting_secondary'),
            msg: t('rav.setting_secondary_msg'),
            help: t('rav.setting_secondary_help')

        }
        
        ,
        
        approveCycle: {

            label: t('rav.approve_cycle_title'),
            msg: t('rav.approve_cycle_msg'),
            help: t('rav.approve_cycle_help')

        }

        ,

        excludedFlags: {

            label: t('rav.excluded_flags_title'),
            msg: t('rav.excluded_flags_msg'),
            help: t('rav.excluded_flags_help')

        }

        ,

        autodelisting: {

            label: t('rav.autodelist_title'),
            msg: t('rav.autodelist_msg'),
            help: t('rav.autodelist_help')

        }

        ,
        
        autodelistThreshold: {

            label: t('rav.autodelist_threshold_title'),
            msg: t('rav.autodelist_threshold_msg'),
            help: t('rav.autodelist_threshold_help')

        }

        ,
        
        dateFormat: {

            label: t('rav.dates'),
            msg: t('rav.dates_msg'),
            help: t('rav.dates_help')

        }

        ,

        tenantApproveCycle: {

            label: t('rav.approve_cycle_title'),
            msg: t('rav.approve_cycle_msg'),
            help: t('rav.approve_cycle_help')

        }

        ,

        minImageQuality: {
            label: t('rav.min_img_quality_title'),
            msg: t('rav.min_img_quality_msg'),
            help: t('rav.min_img_quality_help')
        }

        ,

        photosRequired: {
            label: t('rav.photos_required_title'),
            msg: t('rav.photos_required_msg'),
            help: t('rav.photos_required_help')
        }

        ,

        imoChecksum: {
            label: t('rav.imo_checksum_title'),
            msg: t('rav.imo_checksum_msg'),
            help: t('rav.imo_checksum_help')
        }

        ,

        qualitySwitch: {
            label: t('rav.img_validate_title'),
            msg: t('rav.img_validate_msg'),
            help: t('rav.img_validate_help')
        }
    }


    return self


}


export const useTenantPreferencesFields = (form:FormState<Tenant>) => useDeferredTenantPreferencesFields()(form) 


export const useDeferredTenantPreferencesFields = () => {

    const t = useT()

    

    return (_: FormState<Tenant>) : Fields  => {  

       return {


            approveCycle: {

                label: t('rav.approve_cycle_title'),
                msg: t('rav.approve_cycle_msg'),
                help: t('rav.approve_cycle_help')

            }
        }

    }
}