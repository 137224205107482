import { StoreProps, useMocks } from 'apprise-frontend-core/client/mocks'
import { useService } from 'apprise-frontend-core/client/service'
import MockAdapter from 'axios-mock-adapter/types'
import { settingsApi, settingsType } from './constants'
import { AppSettings, Settings, SettingsType } from './model'


export const useSettingsMockery = () => {

    const settings = useSettingsMocks()
    const service = useService()

    const svc = service.get(settingsType)
    const api = `${svc.prefix}${settingsApi}`


    return (mock: MockAdapter) => {

        console.log('mocking settings...')


        mock.onGet(api).reply(() => [200, settings.getAll()])

        mock.onPut(api).reply(({ data }) => {

            const updatedSettings = JSON.parse(data) as AppSettings

            settings.replace(updatedSettings)

            return [200]

        })


    }
}


export const useSettingsMocks = () => {

    const mocks = useMocks()

    const self = {

        settingsStore: (props?:StoreProps<AppSettings>) => mocks.getOrCreateStore<AppSettings>(settingsType, { initial: () => [{}], ...props },)

        ,

        getAll: () => self.settingsStore().all()[0]

        ,

        get: <T extends Settings>(type: SettingsType) => self.getAll()?.[type] ?? {} as T

        ,

        update: <T extends Settings>(type: SettingsType, settings: Partial<T>) => self.settingsStore().set(0,

            {
                ...self.getAll(),
                [type]: { ...self.get(type), ...settings }
            }

        )

        ,

        replace: (settings: AppSettings) => self.settingsStore().set(0, settings)



    }

    return self
}