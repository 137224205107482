
import { useLoggedOracle } from 'apprise-frontend-core/authz/oracle';
import { State } from 'apprise-frontend-core/state/api';
import { utils } from 'apprise-frontend-core/utils/common';
import { } from 'nats.ws';
import { createContext, useContext } from 'react';
import { MailTopic, noMailTopic } from './model';
import { useMailTopics } from './topics';



export type MailRegistry = {
    
    modules: MailModule[]
}

export type MailModule = {

    name: string,
    topics: MailTopic[]

}



export const TopicRegistryContext = createContext<State<MailRegistry>>(undefined!)



// client-facing.
export const useMailModules = () => {

    const state = useContext(TopicRegistryContext)

    const self = {

        register: (module: MailModule) => state.set(s => s.modules.push(module))

    }

    return self
}

// internal.
export const useMailRegistry = () => {

    const logged = useLoggedOracle()

    const topics = useMailTopics()

    const registry = useContext(TopicRegistryContext)

    const self = {

        all: () => {

            const modules = registry.get().modules;

            const allTopics = [...modules]
                            .sort(utils().compareStringsOf(m => m.name)) // order by module.
                            .flatMap(s => [...s.topics ?? []].sort(topics.comparator)) // and by specificity within modules.
                            .filter(t => !topics.audienceOf(t).length || logged.canAny(topics.audienceOf(t))) // filter by audience. if any.

            return [noMailTopic,...allTopics] as MailTopic[];

        }

    }

    return self
}



