import { ValidationStatus } from '../field/validation'

export const lorem = "Lorem ipsum dolor sit amet."

export const fieldinfo = {label:'Label',msg:'Fill howto.'}
export const errorinfo = {status:'error' as ValidationStatus,...fieldinfo,msg:'Error details.'}
export const warninfo = {status:'warning' as ValidationStatus,...fieldinfo,msg:'Warning details.'}

export const loremM = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`

export const loremL = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit essecillum 
dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
