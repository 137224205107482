import { useT } from "apprise-frontend-core/intl/language"
import { useL } from "apprise-frontend-core/intl/multilang"
import { elementProxyRole, OneOrMore, Optional, utils } from "apprise-frontend-core/utils/common"
import { CategoryReference, TagCategory } from "apprise-frontend-tags/category/model"
import { useCategoryStore } from 'apprise-frontend-tags/category/store'
import { TagLabel, TagLabelProps, TagLabels, TagLabelsProps } from "apprise-frontend-tags/tag/label"
import { Tagged, TagReference } from "apprise-frontend-tags/tag/model"
import { useTagStore } from "apprise-frontend-tags/tag/store"
import { Column, ColumnProps, TableElement } from "apprise-ui/table/table"
import { useTableUtils } from "apprise-ui/table/utils"
import { useTagUtils } from './utils'

export type TagColumnProps <T extends TableElement> = ColumnProps<T> & { 
    renderProps?: TagLabelProps,
    tagOf: (_: T) => Optional<TagReference> 
}

// a column that shows one tag as labels.
export const TagColumn = <T extends TableElement>(props: TagColumnProps<T>) => {

    const { compareTagsOf, textOf } = useTagUtils()

    const { tagOf, renderProps, ...rest } = props

    const text = (e: T) => textOf(tagOf(e))
    const sort = compareTagsOf(tagOf)

    const render = (e: T) => <TagLabel bare  mode='tag' tag={tagOf(e)} {...renderProps} />

    return <Column width={250} text={text}sort={sort} render={render} {...rest} />
}

TagColumn[elementProxyRole] = true


export type MultiTagColumnProps <T extends TableElement> = ColumnProps<T> & { 

    renderProps?: TagLabelsProps
    tagsOf: (_: T) => Optional<TagReference[]> 

}


// a column that shows one more more tags as labels.
export const MultiTagColumn = <T extends TableElement>(props: MultiTagColumnProps<T>) => {

    const { textOf } = useTagUtils()

    const { tagsOf, renderProps, ...rest } = props

    const text = (e: T) => textOf(tagsOf(e))
    const render = (e: T) => <TagLabels bare mode='tag' tags={tagsOf(e)} {...renderProps} />

    return <Column width={250} text={text} render={render} {...rest} />
}

MultiTagColumn[elementProxyRole] = true

// a column that shows the tags of a Tagged object as labels, optionally limited to those in one or more categories.
// if a single category is provided, the title defaults to the name of the category.
// the 
export const TagViewColumn = (props: ColumnProps<Tagged> & Partial<{

    renderProps?: TagLabelProps,
    category: OneOrMore<TagCategory | CategoryReference>
    
}>) => {

    const { category, renderProps, ...rest } = props

    const l = useL()
    const t = useT()

    const tagutils = useTagUtils()
    const store = useTagStore()
    const categories = useCategoryStore()

    const { Column } = useTableUtils<Tagged>()

    const normalised = utils().arrayOf(category).filter(c => c!).map(c => typeof c === 'string' ? c : c!.id) as string[]

    const render = (u: Tagged) => {

        const tags = u.tags.map(store.safeLookupTag)

        return <TagLabels mode='tag' noLink {...renderProps} tags={normalised.length ? tags.filter(tag => !!tag.category && normalised.includes(tag.category)) : tags.filter(tag => !tag.category)} />
    }

    const name = normalised.length ? normalised.join('-') : 'all-tags'
    const title = normalised.length ? normalised.length === 1 ? l(categories.lookupCategory(normalised[0])?.name) : undefined : t('tag.tags_col')

    return <Column width={250} name={name} text={o => tagutils.textOf(o?.tags)} title={title} render={render}  {...rest} />
}

TagViewColumn[elementProxyRole] = true
