import { contactTypeCategory, DimensionIcon, OwnerLabel, specificationCategory, VesselGearIcon, VesselNameIcon, VesselPortIcon, VesselRangeIcon, VesselTypeIcon } from '#details/constants'
import { imoscheme } from '#iotc/constants'
import { RecordDateIcon, RecordIdIcon } from '#record/constants'
import { RecordLabel } from '#record/label'
import { RavRecord } from '#record/model'
import { DateLabel } from '#utils/datelabel'
import { VidLabel } from '#vid/label'
import { useVID } from '#vid/model'
import { useT } from 'apprise-frontend-core/intl/language'
import { TenantIcon } from 'apprise-frontend-iam/tenant/constants'
import { TenantLabel } from 'apprise-frontend-iam/tenant/label'
import { UserIconFull } from 'apprise-frontend-iam/user/constants'
import { TagLabel, TagLabels } from 'apprise-frontend-tags/tag/label'
import { useTagStore } from 'apprise-frontend-tags/tag/store'
import { Label } from 'apprise-ui/label/label'
import { Column } from 'apprise-ui/table/table'
import { useTableUtils } from 'apprise-ui/table/utils'
import { DateIcon } from 'apprise-ui/utils/icons'

export const useSearchColumns = () => {

    const vid = useVID()
    const tags = useTagStore()


    const { Column: RecordColumn, columnsOf } = useTableUtils<RavRecord>()

    const t = useT()

    return columnsOf(

        <RecordColumn defaultLayout pinned name="id" className='result-uvi' width={140} title={<Label icon={<RecordIdIcon />} title={t('search.uvi_col')} />} render={r => <RecordLabel iconMode='normal' record={r} />} />,

        <RecordColumn sort='external' defaultLayout name='name' width={250} title={<Label icon={<VesselNameIcon />} title={t('search.vesselname_col')} />} render={r => r.details.name} />,

        <RecordColumn sort='external'  defaultLayout name='flag' width={200} title={<Label icon={<TenantIcon />} title={t('search.tenant_col')} />} render={r => <TenantLabel bare noIcon tenant={r.tenant} />} />,

        ...vid.allSchemes().map(scheme =>

            <RecordColumn defaultLayout={scheme.id===imoscheme} name={scheme.id} key={scheme.id} width={150} title={<VidLabel scheme={scheme.id} bare />} render={r => <VidLabel noIcon vid={vid.find(scheme.id, r.details.identifiers)} />} />

        ),

        <RecordColumn defaultLayout name='type' sort='external'  width={150} title={<Label icon={<VesselTypeIcon />} title={t('search.vesseltype_col')} />} render={r => <TagLabel bare mode='tag' noIcon tag={r.details.vesselType} />} />,

        <RecordColumn defaultLayout name='gear'  width={200} title={<Label icon={<VesselGearIcon />} title={t('search.vesselgear_col')} />} render={r => <TagLabels bare noIcon tags={r.details.gears} />} />,

        <RecordColumn defaultLayout name='range' sort='external' width={200} title={<Label icon={<VesselRangeIcon />} title={t('search.vesselrange_col')} />} render={r => <TagLabel bare mode='tag' noIcon tag={r.details.range} />} />,

        <RecordColumn defaultLayout name='port' width={250} title={<Label icon={<VesselPortIcon />} title={t('search.vesselport_col')} />} render={r => r.details.port.name} />,

     

        <RecordColumn defaultLayout name='timestamp'  sort='external' width={160} title={<Label icon={<RecordDateIcon />} title={t('search.date_col')} />} render={r => <RecordLabel noIcon displayMode='date' dateMode='settings' iconMode='patch' record={r} />} />,

        <RecordColumn sort='external' defaultLayout name='from' width={130} title={<Label icon={<DateIcon />} title={t('authorization.from_col')} />} render={r => <DateLabel noIcon date={r.authorization.from} noTip />} />,

        <RecordColumn sort='external' defaultLayout name='to' width={130} title={<Label icon={<DateIcon />} title={t('authorization.to_col')} />} render={r => <DateLabel displayMode='expiry' noIcon date={r.authorization.to} noTip />} />,

   

        ...tags.allTagsOfCategory(specificationCategory).map(t => 
        
            <Column sort='external' name={t.id} width={100} title={<TagLabel icon={<DimensionIcon />} bare tag={t.id} />} render={r => r.details.specifications[t.id]} />
            
        ),

        ...tags.allTagsOfCategory(contactTypeCategory).map(t => 
        
            <Column name={t.id} width={200} title={<TagLabel icon={<UserIconFull />} bare tag={t.id} />} render={r => <OwnerLabel contact={r.details.contacts.find(c => c.type === t.id)} />} />
            
        )

    )

}


