import { RecordPlugin, RecordPluginDescriptor, useDefaultRecordPlugin } from '#record/plugin'
import { useT } from 'apprise-frontend-core/intl/language'
import { useTrxAuthzColumns } from './columns'
import { patchedTypes, TrxAuthzIcon } from './constants'
import { newTrxAuthzSlot, TrxAuthPatch } from './model'
import { TrxAuthzOverview } from './overview'
import { useTrxAuthzParser } from './parser'
import { TrxAuthzPatchCard } from './patch'
import { useTrxAuthzJsonSerializer } from './jsonserializer'
import { useTrxAuthzValidation } from './validator'


export const useTrxAuthzPluginDescriptor = (): RecordPluginDescriptor => {

    const t = useT()

    const defaults = useDefaultRecordPlugin()

    return {

        ...defaults

        ,

        Icon: TrxAuthzIcon

        ,

        name: t('trxauthz.type')

        ,

        singular: t('trxauthz.singular')

        ,

        plural: t('trxauthz.plural')

        ,

        patchedTypes
        

    }

}

export const useTrxAuthzPlugin = () => {

    const self: RecordPlugin<TrxAuthPatch> = {

        ...useDefaultRecordPlugin(),

        ...useTrxAuthzPluginDescriptor(),

        patchColumns: useTrxAuthzColumns,

        parse: useTrxAuthzParser,

        jsonserialize: useTrxAuthzJsonSerializer,

        Patch: TrxAuthzPatchCard,

        Overview: TrxAuthzOverview,

        validate: useTrxAuthzValidation,

        newSlot: newTrxAuthzSlot
      
    }

    return self
}
