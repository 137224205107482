import { useL } from 'apprise-frontend-core/intl/multilang'
import { CategoryReference } from 'apprise-frontend-tags/category/model'
import { useCategoryStore } from 'apprise-frontend-tags/category/store'
import { FieldInfo } from 'apprise-ui/field/model'
import { useReadonlyProps } from 'apprise-ui/readonly/readonly'
import { RecordBox, RecordBoxProps } from 'apprise-ui/recordbox/recordbox'
import React from 'react'
import { Tag, TagReference } from './model'
import { useTagStore } from './store'
import "./tagmapbox.scss"

export type TagmapBoxProps = Omit<RecordBoxProps<Record<TagReference,string>>, 'keys'> & {

    category: CategoryReference
    include?: (tag: Tag) => boolean

}
export const TagmapBox = (props: TagmapBoxProps) => {

    const l = useL()

    const tagstore = useTagStore()
    const categories = useCategoryStore()

    const { category, include=()=>true, ...rest } = props

    const { children: values } = props
    
    const { readonly } = useReadonlyProps(rest)

    const cat = categories.safeLookupCategory(category)
    const keys = tagstore.allTagsOfCategory(category).filter(include).map(t=>t.id).filter(id=> readonly ? values?.[id] : id )

    const info: FieldInfo = {
        label: l(cat.name),
        msg: l(cat.properties.field.message ?? cat.description),
        help: l(cat.properties.field.help)
    }

    return <RecordBox info={info} keys={keys} renderKey={tag => l(tagstore.safeLookupTag(tag).name)}  {...rest} />

}