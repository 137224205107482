import { RecordPlugin, RecordPluginDescriptor, useDefaultRecordPlugin } from '#record/plugin'
import { useT } from 'apprise-frontend-core/intl/language'
import { usePhotgraphsAssetResolver } from './assetresolver'
import { usePhotographsColumns } from './columns'
import { photographPatchedTypes, PhotographsIcon } from './constants'
import { PhotographForm, usePhotographFormFields } from './form'
import { newPhotoSlot, PhotographsPatch } from './model'
import { PhotographOverview } from './overview'
import { usePhotographsParser } from './parser'
import { PhotographPatchCard } from './patch'
import { usePhotographJsonSerializer } from './jsonserializer'
import { usePhotographValidation } from './validator'


export const usePhotographPluginDescriptor = (): RecordPluginDescriptor => {

    const t = useT()



    return {


        ...useDefaultRecordPlugin()

        ,

        Icon: PhotographsIcon

        ,

        name: t('photograph.type')

        ,

        singular: t('photograph.singular')

        ,

        plural: t('photograph.plural')

        ,

        patchedTypes: photographPatchedTypes

        ,

        useAssets: true

    }

}

export const usePhotographPlugin = () => {


    const self: RecordPlugin<PhotographsPatch> = {


        ...useDefaultRecordPlugin()

        ,

        ...usePhotographPluginDescriptor()

        ,


        parse: usePhotographsParser

        ,

        resolveAssets: usePhotgraphsAssetResolver

        ,

        jsonserialize: usePhotographJsonSerializer

        ,

        Patch: PhotographPatchCard

        ,

        formFields: usePhotographFormFields

        ,

        Form: PhotographForm as any

        ,

        Overview: PhotographOverview

        ,

        patchColumns: usePhotographsColumns

        ,

        validate: usePhotographValidation

        ,

        newSlot: newPhotoSlot


    }

    return self
}
