

export type FileSizeLabelProp = {

    size: number
}

const unitStyle = {padding:"0px 3px", borderRadius:4, background: 'lightslategrey', color: 'white', fontSize:9, display: 'inline-block' }

export const getFileSize = (size: number): { val: number | string, unit: JSX.Element } => {

    if (typeof size !== 'number')
      return {
          val: '',
          unit: <></>
      }

    const mbs = Math.round(((size / (1024 * 1024)) + Number.EPSILON) * 100) / 100

    return mbs > 1 ? {
        val: mbs,
        unit: <div style={{...unitStyle, background:'dodgerblue'}}>MB</div>
    } : {
        val: Math.round(((size / 1024) + Number.EPSILON) * 100) / 100,
        unit: <div style={unitStyle}>KB</div>
    }
}

export const FileSizeLabel = (props:FileSizeLabelProp) => {

    const {size} = props
 
    const data = getFileSize(size)

    return <div className='apprise-row'>{data.val}&nbsp;{data.unit}</div>

}