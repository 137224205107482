import { MockHorizonContext } from 'apprise-frontend-core/client/mockhorizon'
import { useMockery } from 'apprise-frontend-core/client/mocks'
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import { PropsWithChildren, useContext } from 'react'
import { useTenantMockery } from './mockery'
import { useTenantStore } from './store'


export type TenantLoaderProps = PropsWithChildren<Partial<{

    mock: boolean

}>>



export const TenantLoader = (props: TenantLoaderProps) => {

    const belowMockHorizon = useContext(MockHorizonContext)

    const { children, mock=belowMockHorizon } = props

    const store = useTenantStore()
    const mocks = useMockery()
    const mockery = useTenantMockery()
    
    const activate = () => Promise.resolve(mock ? mocks.initMockery(mockery) : true).then(store.fetchAll)

    const { content } = useRenderGuard({

        render: children,
        orRun: activate
    })

    return content
}

