import { useT } from 'apprise-frontend-core/intl/language'
import { utils } from 'apprise-frontend-core/utils/common'
import { TagLabel } from 'apprise-frontend-tags/tag/label'
import { TagReference } from 'apprise-frontend-tags/tag/model'
import { classname } from 'apprise-ui/component/model'
import { useChangeHelper } from 'apprise-ui/field/changehelper'
import { Field, useFieldProps } from 'apprise-ui/field/field'
import { ChangeTracked, Fielded } from 'apprise-ui/field/model'
import { useReadonlyHelper } from 'apprise-ui/field/readonlyhelper'
import { useResetHelper } from 'apprise-ui/field/resethelper'
import { SelectBox } from 'apprise-ui/selectbox/selectbox'
import { TextBox } from 'apprise-ui/textbox/textbox'
import * as React from 'react'
import { useVID, Vid } from './model'
import './vidbox.scss'


// collects vessel identifiers in one ore more inoput schemes (all non-primary ones by default).
// when working with a single scheme it reduces to a text box. otherwise is synchronises it with a box for scheme selection.

export type VidboxProps = Fielded<Vid> & ChangeTracked<Vid> & Partial<{

    schemes: TagReference | TagReference[]

    children: Vid

}>


export const VidBox = (clientprops: VidboxProps) => {

    const t = useT()
    const vids = useVID()

    const props = useFieldProps(clientprops)

    const { pastMode, pastValue } = useChangeHelper(props)

    useReadonlyHelper(props)

    useResetHelper(props)

    const { info, innerStyle, innerClassName, children , defaultValue, readonly, schemes, onChange, disabled } = props

    const vid = children ?? defaultValue

    const schemeOptions = schemes ? utils().arrayOf(schemes) : vids.allSchemes().map(s => s.id)

    const fixedScheme = schemeOptions.length === 1 && vids.allSchemes(true).find(s => s.id === schemeOptions[0])

    const defaultInfo = fixedScheme ? { label: fixedScheme?.name, msg: fixedScheme?.description } : { label: t('vid.name'), msg: t('vid.scheme_description') }

    const infoOrDefault = { label: defaultInfo.label, msg: defaultInfo.msg, ...info }

    const { scheme, value } = vids.parse(vid)

    const latestValue = pastMode ? pastValue : value

    const classes = classname('vid-row', innerClassName, fixedScheme && 'noscheme')

    props.debug && console.log({ info: props.labelDecorations, value, pastValue, latestValue })

    return <Field name='vidbox' {...props} info={infoOrDefault} >

        {/* 
            innert styling cannot be unambguously placed (select or textbox?), 
            must use className as hook to reach into specific components. 
        */}
        <div className={classes} style={innerStyle}>

            {!!fixedScheme ||

                <SelectBox disabled={disabled} readonly={readonly} noClear width={150} options={schemeOptions} render={s => <TagLabel bare tag={s} />} onChange={s => onChange?.(s ? vids.stringify(s, value) : undefined)}>
                    {scheme}
                </SelectBox>
            }

            <TextBox enabled={!!scheme || !!fixedScheme} disabled={disabled} readonly={readonly} onChange={v => onChange?.(vids.stringify(fixedScheme ? schemeOptions[0] : scheme!, v))}>
                {latestValue}
            </TextBox>
        </div>

    </Field>
}