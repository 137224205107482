
import { DateLabel } from '#utils/datelabel'
import { useT } from 'apprise-frontend-core/intl/language'
import { Button } from 'apprise-ui/button/button'
import { Label } from 'apprise-ui/label/label'
import { SidebarContent } from 'apprise-ui/page/sidebar'
import { Table } from 'apprise-ui/table/table'
import { useTableUtils } from 'apprise-ui/table/utils'
import { DateIcon, SaveIconFull } from 'apprise-ui/utils/icons'
import React from 'react'
import { HistoryItem, Query } from './model'


export type HistoryRow = HistoryItem & {

    name: string | undefined

    disabled: boolean,
    highlighted: boolean
}

export type HistorySiderProps = {

    history: HistoryRow[]
    onSelect: (_: Query) => any
    onSave: (_: Query) => any

}

export const HistorySider = (props: HistorySiderProps) => {

    const { history, onSelect, onSave } = props

    const t = useT()

    const { Column } = useTableUtils<HistoryRow>()

    return <div style={{ height: 'calc(50% - 34px)' }}>

        <SidebarContent.Title>{t('search.history_title')}</SidebarContent.Title>

        <Table.Sider<HistoryItem> name='queryhistory' noFilter emptyPlaceholder={t('search.history_placeholder')} rowId={i => i.timestamp} data={history} mountDelay={0} >

            <Column render={row => {

                const { name, query, timestamp, highlighted, disabled } = row

                const content =   name ?

                <Label tip={t('search.query_load_tip')} icon={<DateIcon />} title={name} highlighted={highlighted} />
                :
                <DateLabel noReadonly tip={t('search.query_load_tip')} dateFormat='HH:mm:ss' date={timestamp} highlighted={highlighted} />

                return  highlighted ? content : <Button disabled={disabled} readonly={highlighted} onClick={() => onSelect(query)}>{content}</Button>

            }} />


            <Column align='right' width={40} render={({ query, highlighted, total, disabled }) =>


                <React.Fragment>
                    <Label className='history-count' tip={t('search.query_count_tip')} noIcon highlighted={highlighted} title={total} />
                    <Button className='history-save' tip={t('search.query_save_tip')} disabled={disabled} onClick={() => onSave(query)}>{<SaveIconFull color={disabled ? 'inherit' : 'lightseagreen'} />}</Button>
                </React.Fragment>}

            />


        </Table.Sider>
    </div>


}
