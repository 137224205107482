
import { authorizationType } from '#authorization/constants';
import { delistColor, DelistingIcon } from '#delisting/constants';
import { RecordExpiredIcon } from '#record/constants';
import { RecordLabel } from '#record/label';
import { RavRecord } from '#record/model';
import { ClaimDecoration } from '#submission/patchlabel';
import { useDateHelper } from '#utils/datehelper';
import { useVesselModel } from '#vessel/model';
import { useVID } from '#vid/model';
import { Styled } from 'apprise-ui/component/model';
import { Tip } from 'apprise-ui/tooltip/tip';
import { EditIcon } from 'apprise-ui/utils/icons';
import "./detail.scss";
import { useVesselRouting } from './routing';
import format from 'date-fns/format';

import { FaAngleDoubleLeft } from "react-icons/fa";
import { useDateFnsLocale } from 'apprise-ui/utils/localeloader';
import { useT } from 'apprise-frontend-core/intl/language';

export const HistoryEntryLabel = (props: Styled & {

    current: RavRecord,
    record: RavRecord,
    previous?: RavRecord,
    age: number

    noLink?: boolean

}) => {

    const t  = useT()

    const model = useVesselModel()
    const routing = useVesselRouting()

    const locale = useDateFnsLocale()

    const helper = useDateHelper()

    const vid = useVID()

    const { current, record, previous, age, className, style, noLink } = props

    const isNew = !record.lifecycle.created

    const hasExpired = age === 0 && helper.expired(new Date(record[authorizationType].to))

    let decorations = hasExpired ? [<RecordExpiredIcon />] : undefined

    if (previous && record.tenant !== previous.tenant)
        decorations = [...decorations ?? [], <ClaimDecoration tenant={previous.tenant} />]

    const icon = isNew ? <EditIcon /> : model.delisted(record) ? <DelistingIcon color={delistColor} /> : undefined

    const base = <RecordLabel className={className} style={{ flexShrink: 0, ...style }} icon={icon} noLink={noLink}
        linkTo={routing.innerDetailRoute(age)} highlighted={record.id === current.id}
        displayMode={'date'} dateMode='short'
        decorations={decorations} record={record} />

    const namechange = previous && previous?.details.name !== record.details.name
    const aliased = record.aliases?.length


    return namechange ?

        <div className={className} style={{ display: 'flex', ...style }}>
            {base} &nbsp;
            <Tip style={{ display: 'inline-grid' }} tip={record.details.name}>
                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}><FaAngleDoubleLeft color="darkorange" style={{ paddingTop: 2 }} />{record.details.name}</div>
            </Tip>
        </div>

        : aliased ?

            <div className={className} style={{ display: 'flex', ...style }}>
                {base} &nbsp;
                <Tip tipPlacement='bottomLeft' tip={t('merge.since_tip', {date:record.aliases[0].since && format(new Date(record.aliases[0].since), 'PP', { locale })})}>
                    <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}><FaAngleDoubleLeft color="darkorange" style={{ paddingTop: 2 }} />{vid.valueOf(record.aliases[0].uvi)}</div>
                </Tip>
            </div>

            :

            base
}