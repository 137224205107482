import { BsImage } from 'react-icons/bs'
import { SlotType } from '#record/model'
import { GiFishingBoat} from 'react-icons/gi'




export const photographsTypeCategory = 'TGC-photographs-type'


export const photographType = 'photograph'

export const photographPatchedTypes : SlotType[]  = [photographType]

export const PhotographsIcon = BsImage
export const NoPhotoIcon = GiFishingBoat


export const photoQualityLevels = [
    
    // { fsize: 0, isize: 0, msg: "rav.image_quality_none" },

    { fsize: 50, isize: 0.48, msg: "800x600 @ 50Kb" },

    { fsize: 50, isize: 0.48, msg: "800x600 @ 100Kb" },

    { fsize: 50, isize: 0.48, msg: "800x600 @ 150Kb" },

    { fsize: 200, isize: 0.75, msg: "1024x768 @ 200Kb"  },

    { fsize: 300, isize: 0.75, msg: "1024x768 @ 350Kb"  },

    { fsize: 450, isize: 0.75, msg: "1024x768 @ 450Kb"  },
    
    { fsize: 500, isize: 1.3, msg: "1280x1024 @ 500Kb"  }
]

