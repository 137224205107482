import { delistingType } from '#delisting/constants'
import { useRavPreferences } from '#modules/users'
import { RavRecord } from '#record/model'
import { useAppSettings } from '#settings/settings'
import { useVesselOracle } from '#vessel/oracle'
import { useVesselRouting } from '#vessel/routing'
import { useT } from 'apprise-frontend-core/intl/language'
import { useBusyGuardIndicator } from 'apprise-frontend-core/utils/busyguard'
import { utils } from 'apprise-frontend-core/utils/common'
import { useForm } from 'apprise-frontend-core/utils/form'
import { usePrevious } from 'apprise-frontend-core/utils/hooks'
import { Button } from 'apprise-ui/button/button'
import { classname } from 'apprise-ui/component/model'
import { Drawer, useDrawer } from 'apprise-ui/drawer/drawer'
import { Page } from 'apprise-ui/page/page'
import { SidebarContent } from 'apprise-ui/page/sidebar'
import { Pagination } from 'apprise-ui/pagination/pagination'
import { TableContext } from 'apprise-ui/table/context'
import { Table } from 'apprise-ui/table/table'
import { useFeedback } from 'apprise-ui/utils/feedback'
import { AddIcon, DownloadIcon, SearchIcon } from 'apprise-ui/utils/icons'
import React, { Fragment, useContext, useEffect } from 'react'
import { useSearch } from './api'
import { useSearchColumns } from './columns'
import { startQuery } from './constants'
import { DownloadPanel } from './downloadpanel'
import { AuthzField, ContactField, FlagField, GearField, KindField, NameField, PortField, RangeField, SpecField, TypeField, VidField } from './fields'
import { HistoryRow, HistorySider } from './historysider'
import { Query, QueryPill, SavedQuery } from './model'
import { QueryPanel } from './querypanel'
import { SavedQueryRow, SavedQuerySider } from './savedquerysider'
import { SaveQueryPanel } from './savequerypanel'
import './search.scss'


const searchTableName = 'search'

export const RecordSearch = () => {

    const t = useT()

    const oracle = useVesselOracle()
    const routing = useVesselRouting()

    useSideEffects()

    const columns = useSearchColumns()

    const search = useSearch()

    const { query, history, results, total } = search.get()

    const prefs = useRavPreferences()

    const { queries } = prefs.get().search

    const initialQuery = query ?? startQuery

    const queryform = useForm<Query>(initialQuery)

    const { reset, edited, dirty, rebased } = queryform


    const [savedQuery, savedQuerySet] = React.useState<SavedQuery>(undefined!)

    const { Drawer: DownloadDrawer, openSet: downloadOpenSet } = useDrawer()


    const closeSavedQuery = () => savedQuerySet(undefined!)


    // actions

    const nameOf = React.useCallback((query: Query) => queries.find(saved => utils().deepequals(saved.query, query))?.name, [queries])


    // puts a query into the store, and syncs the form with it.
    // if the query is undefined, then resets the form with the initial one.
    const changeQuery = (query: Query | undefined) => {

        search.set(s => {

            if (s.query.mode !== query?.mode)
                s.page = 1

            s.query = query!


        })
    }


    const saveQuery = (query: Query) => savedQuerySet({ name: nameOf(query)!, query })

    const { askRemovalConsent } = useFeedback()

    const removeQuery = (query: SavedQuery) => askRemovalConsent(t('search.query_singular')).thenRun(() => prefs.saveWith(prefs =>

        prefs.search.queries = queries.filter(q => q.name !== query.name)

    ))


    const selectQuery = (query: Query) => reset.to(query).quietly()

    // decorates history with UI state.
    const historyRows: HistoryRow[] = React.useMemo(() =>

        history.map(item => ({

            disabled: dirty
            ,

            name: nameOf(item.query)

            ,

            highlighted: utils().deepequals(edited, item.query)

            ,

            ...item
        }))


        , [dirty, nameOf, history, edited])


    // decorates query with UI state.
    const queryRows: SavedQueryRow[] = React.useMemo(() =>

        queries.map(query => ({

            disabled: dirty
            ,

            highlighted: utils().deepequals(edited, query.query)

            ,

            ...query
        }))


        , [dirty, queries, edited])



    const Indicator = useBusyGuardIndicator()

    const classes = classname((dirty || rebased) && 'search-dirty')

    const changePage = (page: number) => search.set(s => s.page = page)

    const layout = useAppSettings().defaultColumns

    const rowClasses = (t: RavRecord) => classname(`result-${t.lifecycle.state}`, t.patchedSlots.includes(delistingType) && 'result-delisted')

    const initialSort = search.get().sort


    return <Page className='rav-search' noIndicator>

        <SidebarContent>

            {oracle.canAddNew() && <Fragment>
                <Button type='primary' icon={<AddIcon />} onClick={routing.routeToNew}>
                    {t('vessel.add_btn')}
                </Button>

                <br />


            </Fragment>
            }

            <Button icon={<DownloadIcon />} onClick={() => downloadOpenSet(true)}>
                {t('search.download_title')}
            </Button>

            <br />

            <SavedQuerySider queries={queryRows} onSelect={selectQuery} onEdit={savedQuerySet} onRemove={removeQuery} />

            <br />

            <HistorySider history={historyRows} onSelect={selectQuery} onSave={saveQuery} />


        </SidebarContent>


        <QueryPanel onChange={changeQuery} onRefresh={search.runQueryQuietly} onSave={() => saveQuery(edited)} form={queryform}>

            <QueryPill repeatable defaultLayout name='vid' label='search.pill_vid_label' title='search.pill_vid_title' field={VidField} />
            <QueryPill defaultLayout name='name' label='search.pill_name_label' title='search.pill_name_title' field={NameField} />
            <QueryPill defaultLayout name='flag' label='search.pill_flag_label' title='search.pill_flag_title' field={FlagField} />
            <QueryPill name='type' label='search.pill_type_label' title='search.pill_type_title' field={TypeField} />
            <QueryPill name='kind' label='search.pill_kind_label' title='search.pill_kind_title' field={KindField} />
            <QueryPill name='gears' label='search.pill_gears_label' title='search.pill_gears_title' field={GearField} />
            <QueryPill repeatable name='specs' label='search.pill_spec_label' title='search.pill_spec_title' field={SpecField} />
            <QueryPill name='range' label='search.pill_range_label' title='search.pill_range_title' field={RangeField} />
            <QueryPill name='port' label='search.pill_port_label' title='search.pill_port_title' field={PortField} />
            <QueryPill repeatable name='contact' label='search.pill_contact_label' title='search.pill_contact_title' field={ContactField} />
            <QueryPill mode='historical' name='authz' label='search.pill_authz_label' title='search.pill_aithz_title' field={AuthzField} />

        </QueryPanel>


        <Indicator>

            <Table name="search" layout={layout} className={classes} initialSort={initialSort}
                data={results} total={total} noFilter emptyPlaceholder={t('search.empty')}
                rowClassName={rowClasses}>

                <Table.Counter mode='total' overflow={999999} >
                    <span className='counter-label'>{t('search.counter_label')}</span>
                </Table.Counter>

                <Table.Control>
                    <Pagination total={total} pageSize={search.get().pageSize} onChange={changePage}>
                        {search.get().page}
                    </Pagination>
                </Table.Control>

                {columns}

            </Table>

            <Drawer unmountOnClose open={!!savedQuery} onClose={closeSavedQuery} width={450} title={t('search.query_save_drawer')} icon={<SearchIcon />} >
                <SaveQueryPanel query={savedQuery} close={closeSavedQuery} />
            </Drawer>

            <DownloadDrawer title={t('search.download_title')} icon={<DownloadIcon />} unmountOnClose >
                <DownloadPanel />
            </DownloadDrawer>

        </Indicator>



    </Page >
}


const useSideEffects = () => {

    const search = useSearch()

    const { results, query, page } = search.get()

    // we use it to track real query changes
    const previous = usePrevious({ query, page })

    // if we have no query (first mount, reset), set the start query.
    useEffect(() => {

        if (!query)
            search.set(s => s.query = startQuery)

        // eslint-disable-next-line
    }, [query])


    // if the query has really changed, or we have no results (first mount,reset), run the query. 
    useEffect(() => {

        // a real change, not because we've just been mounted, not because of unstable values.
        const queryHasEffectivelyChanged = previous && !utils().deepequals(query, previous.query)
        const pageHasEffectivelyChanged = previous && page !== previous.page

        const queryHasntRunYet = query && (results === undefined)

        const runQuery = queryHasEffectivelyChanged || pageHasEffectivelyChanged || queryHasntRunYet

        //console.log({ previous, query, queryHasEffectivelyChanged, pageHasEffectivelyChanged, queryHasntRunYet, runQuery })


        if (runQuery) {

            search.runQuery().then(utils().through(() => {

                if (!query || query === startQuery) // no history mgmt with base query.
                    return

                // query has changed: add to history.
                if (queryHasEffectivelyChanged)

                    search.set(s => s.history.unshift({ timestamp: Date.now(), query, total: s.total }))

                // current fitler has been re-applied: update history. 
                if (queryHasntRunYet)

                    search.set(s => {
                        s.history[0].total = s.total
                        s.history[0].timestamp = Date.now()
                    })


            }))
                .catch(() => {

                    if (pageHasEffectivelyChanged)

                        search.setQuietly(s => {
                            s.page = previous.page
                        })
                }
                )
        }

        // eslint-disable-next-line
    }, [query, results, page])


    // listens to own table to react to sort spec changes and re-run query at page 1.

    const { sort: sortspecs } = useContext(TableContext).get()[searchTableName] ?? {}

    useEffect(() => {

        if (sortspecs) {

            search.set(s => {
                s.sort = sortspecs
                s.page = 1;
            })

            search.runQuery()
        }

        // eslint-disable-next-line
    }, [sortspecs])
}
