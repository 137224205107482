
import { photoQualityLevels } from '#photographs/constants'
import { useT } from 'apprise-frontend-core/intl/language'
import { utils } from 'apprise-frontend-core/utils/common'
import { SettingRenderProps } from 'apprise-frontend-streams/settings/model'
import { Form } from 'apprise-ui/form/form'
import { SliderBox } from 'apprise-ui/sliderbox/slider'
import { SwitchBox } from 'apprise-ui/switchbox/switchbox'
import { useAppSettingsFields } from './fields'
import { AppSettings } from './settings'


export type DateFormatOption = {
    name: string
    format: string
}

export const ValidationSettingsPanel = (props: SettingRenderProps<AppSettings>) => {

    const t = useT()

    const { settings, onChange } = props

    const fields = useAppSettingsFields(settings)

    const mainlevels = utils().dedupBy(photoQualityLevels, l => l.isize)

    const qualityMarks = photoQualityLevels.reduce((acc,next,index) => ({...acc, [index]: 
       mainlevels.includes(next) ? <span style={{ fontSize: 'smaller', color: 'coral', fontVariant: 'small-caps' }}>{ t(`rav.image_quality_${mainlevels.indexOf(next)}`) }</span> : ''
    }) ,{})

    return <Form>

        <SwitchBox info={fields.photosRequired} onChange={flag => onChange({ ...settings, photosRequired: flag! })}  >
            {settings.photosRequired}
        </SwitchBox>

        <SwitchBox info={fields.imoChecksum} onChange={flag => onChange({ ...settings, validateIMOChecksum: flag! })}  >
            {settings.validateIMOChecksum}
        </SwitchBox>

        <SwitchBox info={fields.qualitySwitch} onChange={flag => onChange({ ...settings, validatePhotoQuality: flag! })} >
            {settings.validatePhotoQuality}
        </SwitchBox>

        <SliderBox enabled={settings.validatePhotoQuality} info={fields.minImageQuality} width={300}
                     min={ 0 } max={ photoQualityLevels.length-1 }
                     tipRender={ (quality=0) => t(photoQualityLevels[quality]?.msg)}
                     marks={qualityMarks} 
                     onChange={(minPhotoQuality=0) => onChange({ ...settings, minPhotoQuality })}>
            {settings.minPhotoQuality}
        </SliderBox>


    </Form>
}



