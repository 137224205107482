
import { RavConfig } from '#config'
import { VidLabel } from '#vid/label'
import { vidSchemeCategory } from '#vid/model'
import { useCall } from 'apprise-frontend-core/client/call'
import { useConfig } from 'apprise-frontend-core/config/api'
import { useT } from 'apprise-frontend-core/intl/language'
import { useLogged } from 'apprise-frontend-iam/login/logged'
import { MultiTenantBox } from 'apprise-frontend-iam/tenant/tenantbox'
import { SettingRenderProps } from 'apprise-frontend-streams/settings/model'
import { useTagModel } from 'apprise-frontend-tags/tag/model'
import { useTagStore } from 'apprise-frontend-tags/tag/store'
import { Button } from 'apprise-ui/button/button'
import { Field } from 'apprise-ui/field/field'
import { Form } from 'apprise-ui/form/form'
import { Label } from 'apprise-ui/label/label'
import { NumberBox } from 'apprise-ui/numberbox/numberbox'
import { SelectBox } from 'apprise-ui/selectbox/selectbox'
import { SwitchBox } from 'apprise-ui/switchbox/switchbox'
import { useAsyncTask } from 'apprise-ui/utils/asynctask'
import { ApproveCycleBox } from './approvecyclebox'
import { useAppSettingsFields } from './fields'
import { AppSettings } from './settings'

export type DateFormatOption = {
    name: string
    format: string
}

export const SystemSettingsPanel = (props: SettingRenderProps<AppSettings>) => {

    const t = useT()
    const logged = useLogged()

    const { settings, onChange } = props

    const tags = { ...useTagModel(), ...useTagStore() }
    const task = useAsyncTask()

    const call = useCall()

    const schemes = tags.allTagsOfCategory(vidSchemeCategory).sort(tags.comparator)

    const { dateFormats } = useConfig<RavConfig>()

    const fields = useAppSettingsFields(settings)

    const schemeOptions = schemes.map(s => s.id)

    const runExport = task.make(async (mode: 'all' | 'current') =>

        call.atPath("/export").post<{ status: number }>({ user: logged.username, mode }, { raw: true })

    ).with($ => $.wait(500).notify((response) => {

        switch (response.status) {

            case 202: return t("appsettings.export_trigger_started")
            case 204: return t('appsettings.export_already_running')
        }

    }).error(t('appsettings.export_general_error'))).done()

    return <Form>

        <SelectBox info={fields.primary} noClear
            options={schemeOptions}
            render={scheme => <VidLabel bare scheme={scheme} />}
            onChange={scheme => onChange({ ...settings, primaryScheme: scheme! })}  >
            {settings.primaryScheme}
        </SelectBox>

        <SelectBox info={fields.secondary} noClear
            options={schemeOptions.filter(o => o !== settings.primaryScheme)}
            render={scheme => <VidLabel bare scheme={scheme} />}
            onChange={scheme => onChange({ ...settings, secondaryScheme: scheme! })}  >
            {settings.secondaryScheme}
        </SelectBox>

        <SelectBox info={fields.dateFormat} noClear
            options={Object.keys(dateFormats)}
            render={id => <Label bare title={t(dateFormats[id].name)} />}
            onChange={id => onChange({ ...settings, dateFormat: id! })}  >
            {settings.dateFormat}
        </SelectBox>

        <ApproveCycleBox info={fields.approveCycle} defaultValue='none'
            onChange={cycle => onChange({ ...settings, approveCycle: cycle })}>
            {settings.approveCycle}
        </ApproveCycleBox>

        <MultiTenantBox info={fields.excludedFlags} onChange={party => party && onChange({ ...settings, excludedFlags: party })}>
            {settings.excludedFlags}
        </MultiTenantBox>

        <SwitchBox info={fields.autodelisting} onChange={v => onChange({ ...settings, autodelisting: !!v })}>
            {settings.autodelisting}
        </SwitchBox>

        <NumberBox enabled={settings.autodelisting} width={200} info={fields.autodelistThreshold}
            onChange={threshold => onChange({ ...settings, autodelistThreshold: threshold, autodelistMessage: t('delisting.note_msg', { threshold }) })}>
            {settings.autodelistThreshold}
        </NumberBox>

        <SwitchBox label={t('appsettings.export_enable_lbl')} msg={t('appsettings.export_enable_msg')} help={t('appsettings.export_enable_help')}
            onChange={v => onChange({ ...settings, exportEnabled: !!v })}>
            {settings.exportEnabled}
        </SwitchBox>

        <Field label={t('appsettings.export_trigger_lbl')} help={t('appsettings.export_trigger_help')}>
            <div style={{ display: 'flex' }}>
                <Button enabled={!!settings.exportEnabled} type='primary' onClick={_ => runExport('current')}>{t('appsettings.export_trigger_current_btn')}</Button>
                <div style={{width: 10}} />
                <Button enabled={!!settings.exportEnabled} type='primary' onClick={_ => runExport('all')}>{t('appsettings.export_trigger_all_btn')}</Button>
            </div>
        </Field>
    </Form>
}



