
import { State } from 'apprise-frontend-core/state/api'
import { createContext } from 'react'
import { DetailState } from './provider'
import { SubmissionRoutingState } from './routing'
import { SubmissionStore } from './store'



// note: these contexts would be best placed next to the provider that mounts them.
// but doing so resets the context on HMR in Vite, which breaks the app and forces us to reload it.
// keep the context definitions in the same file seems to patch that.


export const SubmissionStoreContext = createContext<State<SubmissionStore>>(undefined!)

export const SubmissionRoutingContext = createContext<State<Partial<SubmissionRoutingState>>>(undefined!)

export const DetailContext = createContext<DetailState>(undefined!)