import { useT } from 'apprise-frontend-core/intl/language'
import { useComponentBridge } from 'apprise-frontend-core/utils/bridge'
import { useBytestreams } from "apprise-frontend-streams/api"
import { Bytestream, isBytestream } from "apprise-frontend-streams/model"
import { StreamContext } from 'apprise-frontend-streams/track/state'
import { useBytestreamTracker } from 'apprise-frontend-streams/track/tracker'
import { Button } from 'apprise-ui/button/button'
import { Styled, Tall, Wide, classname } from "apprise-ui/component/model"
import { useDrawer } from 'apprise-ui/drawer/drawer'
import { DownloadIcon, OpenIcon } from 'apprise-ui/utils/icons'
import { ImgHTMLAttributes, useContext, useEffect, useMemo, useRef, useState } from "react"
import "./styles.scss"



export type ImageLoaderProps = Styled & Wide & Tall & {

  stream: string | Bytestream

  alt?: string
  placeholder?: JSX.Element
  linkOf?: (stream: string) => string

}

export const maxWait = 5000

export const ImageLoader = (props: ImageLoaderProps) => {

  const { stream, placeholder, width, height } = props

  const hasStream = isBytestream(stream)

  if (!hasStream)
    return <div style={{ width, height }}>{placeholder}</div>

  return <Inner {...props} stream={stream} />

}

const Inner = (props: Omit<ImageLoaderProps, 'stream'> & { stream: Bytestream }) => {

  const t = useT()

  // const refDrawer = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement>(null)

  const streams = useBytestreams()

  // guard for lack of a context that tracker api would normally hide.
  // prefDrawerer to hack it here than mount a fake context in all cases, as that might be harder to then debug.
  const context = useContext(StreamContext)
  const tracker = useBytestreamTracker()

  const localLink = (stream: string) => context ? tracker?.linkOf(stream) : undefined
  const defaultLinkOf = (stream: string) => localLink(stream) ?? streams.linkOf(stream)

  const { stream, linkOf = defaultLinkOf, style = {}, alt = "", width, height, placeholder } = props

  const isLocalPhoto = !!localLink(stream.id)
  
  const { Drawer, open, openSet } = useDrawer()

  const { Indicator } = useComponentBridge()

  const lowRes = stream.lifecycle?.created ? stream.properties?.processors?.find(d => d.img)?.derivatives?.[0]?.id : undefined

  const [urlFull, urlThumb] = useMemo(() => {

    return [linkOf(stream.id), lowRes && linkOf(lowRes)]

    // eslint-disable-next-line
  }, [lowRes, stream.id])

  useEffect(() =>

    () => {

      urlFull && URL.revokeObjectURL(urlFull)
      urlThumb && URL.revokeObjectURL(urlThumb)

    }

    // eslint-disable-next-line
    , [])


    useEffect(()=> {

      setTimeout(()=> {
        
        setState(s => ({...s, timeout:true}))

        ref.current?.classList.add('timeout')

      } ,maxWait)

    },[])



  const imgProps: ImgHTMLAttributes<Element> = { decoding: 'async', loading: 'lazy', style, width, height }

  const loadingMsg = t('stream.loading_generic')

  const [state, setState] = useState({
    baseloaded: !!localLink(stream.id),
    layeredloaded: !!localLink(stream.id),
    timeout: false
  })

  const loadwait = !state.layeredloaded && !state.timeout

  return <div className={classname("image-loader", isLocalPhoto && 'base-loaded', isLocalPhoto && 'layer-loaded')} ref={ref} style={{ width: width, height: height }}>


    <div className='loader-btns' >
      <Button tip={t('stream.download')} tipDelay={.8} noLabel icon={<DownloadIcon />} noReadonly onClick={() => streams.download(stream.id)} />
      <Button tip={t('stream.view')} tipDelay={.8} noLabel icon={<OpenIcon />} noReadonly onClick={() => openSet(true)} />
    </div>



    <div className='loader-preview' style={{ width, height }}>

      <Indicator waiting={loadwait} msg={loadingMsg}>

         {/* first layer: placeholder shows and takes space until some image has loaded.  */}
         <div style={{ zIndex:0, position: state.baseloaded || state.layeredloaded ? 'absolute' : 'relative', width, height }}>{placeholder}</div>  

         {/* second layer: thumbnail if we have it, or full image. shows and takes space.  */}
         <img  {...imgProps} alt={alt} height={height} width={width}
          className={classname("base-image", urlThumb && 'image-blur')}
          onLoad={() => {

            setState({...state, baseloaded:true})

            ref.current?.classList.add('base-loaded')
          
          } }
          src={urlThumb ?? urlFull} />

         {/* third layer: full image if we have a thumbnail, takes space if thumbnail has loaded  */}
        {urlThumb &&

          <img  {...imgProps} alt={alt} style={{position: state.baseloaded ? 'absolute' : 'relative'}}

            className="layered-image"
            src={urlFull}
            onLoad={() =>{

              setState(s => ({...s, layeredloaded:true}))
  
              ref.current?.classList.add('layer-loaded')
              
            }} />
        }

      </Indicator>

    </div>

    {
      open &&

      <Drawer noBusyGuard innerClassName='image-viewer' width={1040}>

      
          <Indicator waiting={loadwait} msg={loadingMsg}>
    
            <img  {...imgProps} alt={alt}
              className={classname("base-image", urlThumb && 'image-blur')}
              src={urlThumb ?? urlFull}
              width={1024}
              height='auto' />

            {urlThumb &&

              <img {...imgProps} alt={alt} style={{position: state.baseloaded ? 'absolute' : 'relative'}}
                className="layered-image"
                src={urlFull}
                width={1024}
                height='auto' />
            }


          </Indicator>



      </Drawer >
    }

  </div >
}
