import { MockHorizonContext } from 'apprise-frontend-core/client/mockhorizon'
import { useMockery } from 'apprise-frontend-core/client/mocks'
import { StateProvider } from 'apprise-frontend-core/state/provider'
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import { EventSubscriber } from 'apprise-frontend-events/subscriptions'
import { PropsWithChildren, useContext } from 'react'
import { vesselType } from './constants'
import { VesselCache, VesselCacheContext } from './context'
import { useVesselChangeListener } from './events'
import { useVesselMockery } from './mockery'



export type VesselProps = PropsWithChildren<Partial<{

    mock: boolean

}>>


export const Vessels = (props: VesselProps) => {

    const beforeHorizon = useContext(MockHorizonContext)

    const { children, mock = beforeHorizon } = props

    const mockery = useMockery()
    const recordMockery = useVesselMockery()

    const activate = () => {

        if (mock)
            mockery.initMockery(recordMockery)

    }

    const { content } = useRenderGuard({

        render: <VesselProvider>
            <VesselInitialiser >
                {children}
            </VesselInitialiser>
        </VesselProvider>,

        orRun: activate
    })

    return content



}


export type VesselProviderProps = PropsWithChildren<{}>


export const initialVesselCache: VesselCache = {}


const VesselProvider = (props: VesselProviderProps) => {

    const { children } = props

    return <StateProvider initialState={initialVesselCache} context={VesselCacheContext}>
        {children}
    </StateProvider>
}

export const VesselInitialiser = (props: VesselProps) => {

    const { children } = props

    const changeListener = useVesselChangeListener()

    return <EventSubscriber name={vesselType} subscriptions={[changeListener]}>
        {children}
    </EventSubscriber>

}