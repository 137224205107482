
import { SavedQuery } from '#search/model'
import { SubmissionIcon, submissionPlural, submissionSingular, submissionType } from '#submission/constants'
import { SubmissionLabel } from '#submission/label'
import { Submission } from '#submission/model'
import { submissionActions, submissionAdminActions, submissionTenantActions } from '#submission/oracle'
import { standardActions } from 'apprise-frontend-core/authz/constants'
import { utils } from 'apprise-frontend-core/utils/common'
import { FormState } from 'apprise-frontend-core/utils/form'
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant'
import { PermissionBox, PermissionBoxProps } from 'apprise-frontend-iam/permission/permissionbox'
import { User } from 'apprise-frontend-iam/user/model'
import { UserPermissionModule, UserPreferencesModule } from 'apprise-frontend-iam/user/modules'
import { usePreferences } from "apprise-frontend-iam/user/preferences"
import { MailTopicBox } from 'apprise-frontend-mail/mailtopicbox'
import { MailTopic } from 'apprise-frontend-mail/model'



// ----preferences -------------------------------------------------------------------------------------------------------------------


export type RavUserPreferences = RavUserMailProfile & {

    // these are not set in the user form, but indirectly through table drawers.
    search: {

        columns: React.Key[],

        queries: SavedQuery[]
    }

}

export type RavUserMailProfile = {

    // these are set in the user form.
    mailProfile: {

        topics: MailTopic[]
    }
}


export const defaultPreferences: RavUserPreferences = {

    search: {

        columns: [],
        queries: []
    },

    mailProfile: {

        topics: []
    }
}

export const useRavPreferences = () => {

    const preferences = usePreferences<RavUserPreferences>()

    const self = {

        ...preferences,

        get: () => utils().merge(defaultPreferences, preferences.get())

    }

    return self
}


const Form = (props: FormState<User>) => {

    const { edited, set } = props

    const prefs = utils().merge(defaultPreferences,edited.details.preferences) as RavUserPreferences 

    return <>

        <MailTopicBox onChange={set.with((m, v) => {

            const profile: RavUserMailProfile = { mailProfile: { ...prefs.mailProfile, topics: v } }

            m.details.preferences = { ...prefs, ...profile }


        })}>
            {prefs.mailProfile?.topics}
        </MailTopicBox>

    </>
}

export const userPreferencesModule: UserPreferencesModule = {
    Form
}


// ----permissions -------------------------------------------------------------------------------------------------------------------


export const useSubmissionPermissionModule = () => {

    const oracle = useTenancyOracle()

    return {

        Icon: SubmissionIcon,
        type: submissionType,
        plural: submissionPlural,

        box: props => <SubmissionPermissionBox resourceRange={[]}  {...props} />,

        initial: (user: User) => {

            const u = oracle.given(user)

            return u.hasNoTenant() ? [standardActions.do_anything] : [submissionActions.manage]

        }

    } as UserPermissionModule<Submission>


}

export const SubmissionPermissionBox = (props: Partial<PermissionBoxProps<Submission>>) => {

    const { ...rest } = props

    const oracle = useTenancyOracle()

    const target = props.userRange?.[0]

    const actions = oracle.given(target).hasNoTenant() ? submissionAdminActions : submissionTenantActions

    return <PermissionBox<Submission>

        resourceSingular={submissionSingular}

        permissionRange={Object.values(actions)}
        maxPrivilege={actions.manage}

        resourcePlural={submissionPlural}
        resourceId={s => s.id}
        resourceRender={s => <SubmissionLabel bare submission={s} />}

        tenantResource
        wildcard preselectWildcard

        {...rest} />

}