import { delistingReasonDeflagged, delistingReasonScrapped, delistingType } from '#delisting/constants';
import { RavRecord } from '#record/model';
import { useSubmissionOracle } from '#submission/oracle';
import { useLoggedOracle } from 'apprise-frontend-core/authz/oracle';
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant';



export const useVesselOracle = () => {


    const logged = {...useTenancyOracle(), ...useLoggedOracle()}
    const submissions = useSubmissionOracle()
   
    // to refine: interactive edits only from managers
    const can = {

        editAll: submissions.canAddNew,
        addFor: submissions.canAddFor
    }

    const self = {


        canAddNew: can.editAll

        ,

        canAddFor: can.addFor
        
        ,

        // any user that can create new vessels for a given tenant can patch an unscrapped one in the same tenant.
        canEdit: (record: RavRecord) => {

            const state = record.delisting?.reason !== delistingReasonScrapped && record.delisting?.reason !== delistingReasonDeflagged

            const tenancy = logged.hasNoTenant() || logged.managesMultipleTenants() || logged.tenant() === record.tenant

            return state && tenancy && can.addFor(record.tenant)
        }

        ,

        // any user that can patch a vessel can delist an non-delisted one.
        canDelist: (record: RavRecord) => {

            const state = !record.patchedSlots.includes(delistingType)

            return state && self.canEdit(record)    // tenancy triggers here.
        }

        ,

         // any user that can add records can claim deflagged vessels from any tenant.
         // admins may also claim records delisted because vessels were scrapped.
        canClaim: (record: RavRecord ) => {

            const state = record.delisting && (logged.isAdmin() ? record.delisting.reason !== delistingReasonScrapped : record.delisting.reason === delistingReasonDeflagged)

            return state && can.editAll()   // no tenancy here.

        }

        ,

         canMerge: (record: RavRecord ) => {

            const state = true //!record.patchedSlots.includes(delistingType)

            return state && logged.isAdmin()

        }

    }

    return self
}