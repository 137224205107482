import { RavRecord } from '#record/model';
import { Submission } from '#submission/model';
import { Vid } from '#vid/model';
import React from 'react';
import { VesselCacheContext } from './context';
import { Vessel } from './model';




export const useVesselCache = () => {

    const cache = React.useContext(VesselCacheContext)

    const self = {

        
        get: (uvi:Vid) => cache.get()[uvi]

        ,

        set: (vessel:Vessel) => cache.set(c=>c[vessel.uvi]=vessel)

        ,

        udpateWith: (record:RavRecord) => {

            const cached = self.get(record.uvi)

            if (!cached)
                return
            
            self.set({...cached, history: [record, ...cached.history]})
        }

        ,

        resetForSubmission: (submission:Submission) => submission.records.forEach(r => cache.set(c=> delete c[r.uvi]))

        ,


        resetForUvis: (uvis:Vid[]) => uvis.forEach(uvi => cache.set(c=> delete c[uvi]))
          

    }

    return self


}