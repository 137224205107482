
import { authorizationType } from '#authorization/constants';
import { delistingType } from '#delisting/constants';
import { detailsType } from '#details/constants';
import { iotcnumberscheme } from '#iotc/constants';
import { photographType } from '#photographs/constants';
import { DateFormatOption, ImageSizeOption } from '#settings/settings';
import { trxauthzType } from '#trxauth/constants';
import { RemoteClientConfiguration } from 'apprise-frontend-core/client/config';
import { Config } from 'apprise-frontend-core/config/model';
import { RemoteIntlConfiguration } from 'apprise-frontend-core/intl/config';
import { DeepPartial } from 'apprise-frontend-core/utils/common';
import { ParserConfig, defaultParserConfig } from 'apprise-frontend-parse/config';
import { tagType } from 'apprise-frontend-tags/constants';
import { TagReference } from 'apprise-frontend-tags/tag/model';


// this is the type we project over the configuration when we want to read it: useConfig<RavConfig>().
// it includes options defined by libraries and options defined by the application.
// some library-defined options are read only by libraries, but some may be read also bvy the application.

export type RavConfig = Config & RemoteIntlConfiguration & RemoteClientConfiguration & ParserConfig & {

    primaryUVI: TagReference

    dateFormats: Record<string, DateFormatOption>

    imageFormats: ImageSizeOption[]

    version: string

    latest: boolean

}



// this is hardcoded configuration and provides default for all options, library-defined and application-defined.  
// the remote configuration we load from the backend is layered over this to complete it and/or override it.

export const defaultConfiguration: DeepPartial<RavConfig> = {

    latest: true,

    intl: {
        supportedLanguages: ['en', 'fr'],
        requiredLanguages: ['en', 'fr']
    }
    ,

    client: {

        mocks: { responseDelay: 150 },
        request: {
            timeout: 15000
        },
        services: { domain: { label: "domain", prefix: "/domain", default: true } }

    },

    propertyExcludes: {

        [tagType]: ['value']
    }

    ,


    dateFormats: {

        short: {
            name: 'rav.dates_short',
            format: 'd/M/yy'
        },
        medium: {
            name: 'rav.dates_medium',
            format: 'dd/MM/yy'
        },
        full: {
            name: 'rav.dates_full',
            format: 'dd/MM/yyyy'
        }

    },

    imageFormats: [{
        name: 'HD',
        val: 980
    }, {
        name: 'FULL HD',
        val: 1920
    }, {
        name: '2K',
        val: 3840
    }]

    ,

    primaryUVI: iotcnumberscheme


    ,

    parse: {

        ...defaultParserConfig,

        excludeSheet: ['example', 'conseils', 'exemple'],
        headerCornerstone: ['entry_#', '#', 'entrée_#'],
        headerCornerstoneColOffset: 1,

        keys: {

            'iotc_record': {tkey:'parse.uvi', key: 'uvi', colAliases: 'numéro_de_registre' }
        }

        ,

        type: {

            [detailsType]: {

                keys: {

                    'record_kind': { tkey:'parse.kind', key: 'kind', colAliases: 'type_de_registre' },
                    'vessel_name': { tkey:'parse.name', key: 'name', colAliases: 'nom_du_navire'},
                    'imo_number': {tkey:'parse.imo', key: 'imo', colAliases: 'numéro_omi' },
                    'national_vessel': { tkey:'parse.regno', key: 'regno', colAliases: ['numéro_national', 'nnuméro_national'] },
                    'international_radio': { tkey:'parse.ircs', key: 'ircs', colAliases: 'indicatif_radio_international' },
                    'area_of_operation': { tkey:'parse.range', key: 'range', colAliases: 'zone_d\'activité' },
                    'vessel_type': { tkey:'parse.type',  key: 'type', colAliases: 'type_de_navire' },
                    'gear_used': { tkey:'parse.gear', key: 'gear', colAliases: 'engin_utilisé' },
                    'port_of_registration': { tkey:'parse.port', key: 'portname', colAliases: 'port_d\'immatriculation' },
                    'vessel_loa': { tkey:'parse.loa', key: 'loa', colAliases: 'lht_du_navire' },
                    'vessel_tonnage': { tkey:'parse.tonnage', key: 'tonnage', colAliases: 'tonnage_du_navire' },
                    'total_volume_of': { tkey:'parse.volume', key: 'volume', colAliases: 'volume_total' },
                    'name_of_owner': {tkey:'parse.owner',  key: 'owner', colAliases: 'nom_du_propriétaire' },
                    'address_of_owner': { tkey:'parse.ownerAddress', key: 'ownerAddress', colAliases: 'adresse_du_propriétaire' },
                    'name_of_operator': {tkey:'parse.operator', key: 'operator', colAliases: 'nom_de_l\'opérateur' },
                    'address_of_operator': { tkey:'parse.operatorAddress', key: 'operatorAddress', colAliases: 'adresse_de_l\'opérateur' },
                    'name_of_beneficial': {tkey:'parse.beneficial', key: 'beneficial', colAliases: 'nom_du_bénéficiaire_effectif' },
                    'address_of_beneficial': { tkey:'parse.beneficialAddress', key: 'beneficialAddress', colAliases: 'adresse_du_bénéficiaire_effectif' },
                    'name_of_company': {  tkey:'parse.company', key: 'company', colAliases: 'nom_de_la_société' },
                    'address_of_company': { tkey:'parse.companyAddress', key: 'companyAddress', colAliases: 'adresse_de_la_société' },
                    'registration_number_of': { tkey:'parse.companyRegno', key: 'companyRegno', colAliases: 'numéro_d\'immatriculation_de_la_société' },
                    'previous_name': { tkey:'parse.prevName', key: 'previousName', colAliases: 'nom(s)_précédent(s)_du_navire' },
                    'previous_flag': { tkey:'parse.prevFlag', key: 'previousFlag', colAliases: 'pavillon(s)_précédent(s)_du_navire' },
                    'details_of_previous_registry_deletions': { tkey:'parse.prevRegistryDeletions', key: 'previousDeletion', colAliases: 'détails_des_suppressions_précédentes' }

                }

            }

            ,

            [authorizationType]: {

                keys: {

                    'period_of_authorisation_from': { tkey:'parse.from', key: 'from', colAliases: 'période_d\'autorisation_de' },
                    'period_of_authorisation_to': { tkey:'parse.to', key: 'to', colAliases: 'période_d\'autorisation_à' }

                }
            }

            ,


            [trxauthzType]: {

                keys: {

                    'type_of': { tkey:'parse.trxtype', key: 'type', colAliases: 'type_d\'autorisation' },
                    'authorised_from': { tkey:'parse.from', key: 'from', colAliases: 'autorisé_du' },
                    'authorised_to': {tkey:'parse.to',  key: 'to', colAliases: 'autorisé_au' },
                    'revoke': {tkey:'parse.trxrevoke',  key: 'revoke', colAliases: 'révoquer' }

                }
            }

            ,


            [delistingType]: {

                keys: {

                    reason: { tkey:'parse.delisttype', key: 'reason', colAliases: 'motif' },
                    note: { tkey:'parse.delistnote', key: 'note', colAliases: 'note' },
                    date: { tkey:'parse.delistdate', key: 'date', colAliases: 'date_de_radiation' }


                }
            }

            ,

            [photographType]: {

                keys: {

                    'starboard_side_of_the_vessel': { tkey:'parse.starboard', key: 'starboard', colAliases: 'côté_tribord' },
                    'portside_of_the_vessel': { tkey:'parse.portside', key: 'portside', colAliases: 'côté_bâbord' },
                    'bow_of_the_vessel': { tkey:'parse.bow', key: 'bow', colAliases: 'proue' }

                }
            }
        }

    }

}