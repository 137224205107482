
import { AssetResolver } from '#record/assetresolver'
import { Asset, AssetReference, appriseReferenceOf, referenceOf } from '#submission/model'
import { isBytestream } from 'apprise-frontend-streams/model'
import { photographType } from './constants'
import { PhotographsPatch } from './model'


export const usePhotgraphsAssetResolver = (): AssetResolver<PhotographsPatch> => {


    return {

        resolve: (records: PhotographsPatch[], assets: Record<AssetReference, Asset>) => {

            return records.flatMap(record => record[photographType].photos).forEach(photo => {


                // if we have resolved this reference before...
                if (isBytestream(photo.ref)) {

                    // ....can we still resolve it now? 
                    const match = assets[referenceOf(photo.ref)] ?? assets[appriseReferenceOf(photo.ref)]

                    //console.log(match ? "rematch!" : "no rematch",{ref:photo.ref,match, nested: nestedReferenceOf(photo.ref)})

                    // then update to current one bytestream, if different.
                    if (match && match.id !== photo.ref.id)
                        photo.ref = match


                }

                // resolve it now.
                else {

                    const normalisedRef = photo.ref?.trim().toLocaleLowerCase()

                    // 'some.jpg'
                    const match = assets[normalisedRef] ?? assets[appriseReferenceOf(normalisedRef)]

                    if (match)
                        photo.ref = match

                    //console.log(match ? "match" : "no rematch", {ref:normalisedRef, nestedRef:nestedReferenceOf(normalisedRef), match})

                }


            })
        },


        matches: (records: PhotographsPatch[]) =>

            records.reduce((acc, record) =>

            ({
                ...acc,

                ...record[photographType].photos.map(p => p.ref).filter(isBytestream).reduce((acc, ref) => ({ ...acc, [ref.id]: record }), {})

            })

                , {})

        ,

        restore: (records: PhotographsPatch[], assets: Record<AssetReference, Asset>) =>

            records.flatMap(record => record[photographType].photos).forEach(photo => {


                if (isBytestream(photo.ref) && assets[referenceOf(photo.ref)])

                    photo.ref = referenceOf(photo.ref)

            })

    }


}