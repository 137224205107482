import { patchedTypes as authzPatchedTypes } from '#authorization/constants'
import { useAuthorizationPlugin } from '#authorization/plugin'
import { patchedTypes as detailPatchedTypes } from '#details/constants'
import { useDetailsPlugin } from '#details/plugin'
import { usePhotgraphsAssetResolver } from '#photographs/assetresolver'
import { photographPatchedTypes } from '#photographs/constants'
import { usePhotographPlugin } from '#photographs/plugin'
import { RecordPlugin, RecordPluginDescriptor, useDefaultRecordPlugin } from '#record/plugin'
import { useT } from 'apprise-frontend-core/intl/language'
import { useBaseComboColumns } from './columns'
import { BaseComboIcon } from './constants'
import { useBaseComboExcelSerializer, useBaseComboExcelWriter } from './excelserializer'
import { useBaseComboFilters } from './filters'
import { useBaseComboJsonSerializer } from './jsonserializer'
import { BaseComboPatch } from './mode'
import { useBaseComboParser } from './parser'
import { useBaseComboValidation } from './validator'

export const useBaseComboPluginDescriptor = (): RecordPluginDescriptor => {

    const t = useT()

    const defaults = useDefaultRecordPlugin()

    return {


        ...defaults,

        name: t('base.type')

        ,

        singular: t('base.singular')

        ,

        plural: t('base.plural')

        ,

        Icon: BaseComboIcon

        ,

        patchedTypes: [...detailPatchedTypes, ...authzPatchedTypes, ...photographPatchedTypes]

        ,

        useAssets: true

    }

}

export const useBaseComboPlugin = () => {

    const { stringify: stringifyAuth } = useAuthorizationPlugin()
    const { stringify: stringifyDetails } = useDetailsPlugin()
    const { stringify: stringifyPhoto } = usePhotographPlugin()


    const self: RecordPlugin<BaseComboPatch> = {


        ...useDefaultRecordPlugin()


        ,

        ...useBaseComboPluginDescriptor()

        ,


        parse: useBaseComboParser

        ,

        jsonserialize: useBaseComboJsonSerializer

        ,

        excelserialize: useBaseComboExcelSerializer

        ,

        excelwrite: useBaseComboExcelWriter

        ,

        validate: useBaseComboValidation

        ,

        patchColumns: useBaseComboColumns

        ,

        resolveAssets: usePhotgraphsAssetResolver

        ,

        currentColumns: () => []

        ,

        patchFilters: useBaseComboFilters

        ,

        stringify: r => `${stringifyAuth(r)} ${stringifyDetails(r)} ${stringifyPhoto(r)}`
    }


    return self
}
