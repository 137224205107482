import { SubmissionDateIcon, SubmissionIcon, SubmissionPublishedIcon, SubmissionStateIcon, SubmissionTypeIcon } from '#submission/constants';
import { useT } from 'apprise-frontend-core/intl/language';
import { Tip } from 'apprise-ui/tooltip/tip';
import { AllertIcon, EditIcon } from 'apprise-ui/utils/icons';
import { CSSProperties } from 'react';
import { AiFillStop, AiOutlinePlusCircle } from 'react-icons/ai';
import { HiOutlineIdentification } from 'react-icons/hi';
import { IoBoatOutline } from 'react-icons/io5';
import { IconBaseProps } from 'react-icons/lib';
import { MdCenterFocusStrong } from 'react-icons/md';
import { RiForbid2Line, RiShieldCheckFill } from 'react-icons/ri';



export const VesselIcon = IoBoatOutline

export const recordType = 'record'



export const validColor: CSSProperties['color'] = 'lightseagreen'
export const validWithWarningColor: CSSProperties['color'] = 'orange'
export const invalidColor: CSSProperties['color'] = 'orangered'
export const ignoredColor: CSSProperties['color'] = 'gray'
export const rejectedColor: CSSProperties['color'] = 'orangered'
export const publishedColor: CSSProperties['color'] = 'orangered'
export const matchedColor: CSSProperties['color'] = 'deepskyblue'

export const RecordIdIcon = HiOutlineIdentification
export const RecordDateIcon = SubmissionDateIcon
export const NewRecordIcon = AiOutlinePlusCircle
export const MatchedRecordIcon =(props:IconBaseProps) => <MdCenterFocusStrong  color={matchedColor} {...props} />  
export const RecordTypeIcon = SubmissionTypeIcon
export const RecordStateIcon = SubmissionStateIcon
export const RecordValidationIcon = RiShieldCheckFill
export const RecordIgnoredIcon = RiForbid2Line
export const RecordRejectedIcon = (props:IconBaseProps) => <AiFillStop  color={rejectedColor} {...props} />
export const RecordUploadedIcon = EditIcon
export const RecordSubmittedIcon = SubmissionIcon
export const RecordPublishedIcon = SubmissionPublishedIcon

export const RecordValidIcon = (props:IconBaseProps) => <RiShieldCheckFill  color={validColor} {...props} />
export const RecordValidWithWaningIcon = (props:IconBaseProps) => <RiShieldCheckFill  color={validWithWarningColor} {...props} />
export const RecordInvalidIcon = (props:IconBaseProps) => <RiShieldCheckFill color={invalidColor} {...props}  />

export const RecordExpiredIcon = (props:IconBaseProps) => {

    const t = useT()

    return <Tip tip={t('rec.expired')}>
        <AllertIcon {...props} />
    </Tip>

}