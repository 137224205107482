import { MdMail } from 'react-icons/md'


export const mailType = "mail"
export const MailIcon = MdMail

export const mailSingular = 'mail.singular'
export const mailPlural = mailSingular

export const mailSubject = 'mail'
export const anyTopic = '*'
export const anyTarget = '*'
export const mailTopicSeparator = '.'
export const targetSeparator = ':'



