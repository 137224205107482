import Title from 'antd/lib/typography/Title';
import { useT } from 'apprise-frontend-core/intl/language';
import { Component } from 'apprise-ui/component/component';
import { CSSProperties } from 'react';

import todourl from './todo.jpg';


export const Todo = ({ msg, fit, size }: { msg?: React.ReactNode, fit?:boolean, size?:number|string }) => {

  const t = useT()
 
  const bleedStyle: CSSProperties = {
    flexGrow:1,     // flex-parents
    height:'100%',  // sized-parents
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white'
  }

  return <Component name='todo' style={bleedStyle}>
    <img alt="in-development" style={{ objectFit: "cover", width:fit?'100%':undefined, height:fit?'100%':undefined }} src={todourl} />
    <Title style={{fontSize:size}}>{msg || t('ui.todo_msg')}</Title>
  </Component>
}