import { useCall } from 'apprise-frontend-core/client/call';
import { usePreload } from 'apprise-frontend-core/client/preload';
import { useT } from 'apprise-frontend-core/intl/language';
import { useBusyState } from 'apprise-frontend-core/utils/busyguard';
import { defaultDrawerParam } from 'apprise-ui/header/actionmenu';
import { useAsyncTask } from 'apprise-ui/utils/asynctask';
import { useFeedback } from 'apprise-ui/utils/feedback';
import { useRouting } from 'apprise-ui/utils/routing';
import { useContext } from 'react';
import { settingsApi, settingsParam, settingsParamValue } from './constants';
import { AppSettings, Settings, SettingsModule, SettingsType } from './model';
import { SettingsCacheContext, SettingsModuleContext } from './provider';



export const useSettingsModules = () => {

    const state = useContext(SettingsModuleContext) 

    if (!state)
        throw new Error("no <Settings/> in component hierarchy")

     const self = {

        all: () => state.get().all

        ,

        // add and sort by name
        register: (module: SettingsModule<any>) => state.set(s => s.all = [...s.all, module])

    }

    return self;
}


export const useSettingStore = () => {

    const t = useT()

    const state = useContext(SettingsCacheContext) 

    if (!state)
        throw new Error("no <Settings/> in component hiearchy")

    const call = useCall()
    const busy = useBusyState()
    const routing = useRouting()
    const { notify, showError } = useFeedback()

    const task = useAsyncTask()

    const preload = usePreload()

    const self = {

         fetch: task.make( async () => {

            const settings = await (preload.get<AppSettings>(settingsApi) ?? call.at(settingsApi, "admin").get<AppSettings>())

            state.set(s => s.all = settings)

        })
        .with( $ => $.log("fetching application settings...").show(t('settings.loading')))
        .done()

        ,

        save: (settings: AppSettings) => {

            console.log("updating application settings...")

            return busy.toggle('save.settings')
                .then(() => call.at(settingsApi,"admin").put(settings))
                .then(() => state.set(s => s.all = settings))
                .then(notify())
                .catch(showError(t('settings.save_error')))
                .finally(() => busy.toggle('save.settings'))

        }

        ,

        get: () => state.get().all


        ,


        routeToPanel: (type:SettingsType) => routing.routeQueryTo(params=>{
        
            params[defaultDrawerParam]=settingsParamValue
            params[settingsParam]=type
        
        })
    }

    return self;
}


export const useSettings = <T extends any = Settings>(type: SettingsType) => {

    const state = useContext(SettingsCacheContext)
    
    const settings = state.get().all
    
    const modules = useSettingsModules()

    const module = modules.all().find(m => m.type === type)

    return (settings[type] ?? (module?.defaults || {})) as T
}