import { useLanguage, useT } from 'apprise-frontend-core/intl/language'
import { FormState } from 'apprise-frontend-core/utils/form'
import { useValidation } from 'apprise-ui/field/validation'
import { tenantPlural, tenantSingular } from './constants'
import { Tenant } from './model'
import { useTenantStore } from './store'


export const useTenantFields = (form: FormState<Tenant>) => {

    const t = useT()
    const lang = useLanguage()

    const { check, is } = useValidation()
    const store = useTenantStore()

    const { edited, initial } = form

    const otherTenants = store.all().filter(u => u.id !== initial.id)
    const otherCodes = store.all().filter(c=>c.id!==edited.id).map(c=>c.code) || [] as string[]

    const singular = t(tenantSingular).toLowerCase()
    const plural = t(tenantPlural).toLowerCase()

    const self = {

        active: {
            label: t('common.active'),
            msg: t('common.active_msg'),
            help: t('common.active_help',{plural}),

        }

        ,

        name: {
            label: t('common.name_multi'),
            msg: t('common.name_multi_msg'),
            help: t('common.name_multi_help',{plural,required:lang.required()}),

            ...check(is.noLanguages()).on(edited.name),
            ...check(is.duplicateLanguages(otherTenants.map(t=>t.name))).on(edited.name)
       
        }

        ,

        description: {

            label: t('common.description_multi'),
            msg: t('common.description_multi_msg'),
            help: t('common.description_multi_help',{plural}),

        }

        ,

        code: {

            label: t('tenant.code'),
            msg: t('tenant.code_msg'),
            help: t('tenant.code_help',{singular}),

            ...check(is.changeText(initial.code))
                .andProvided(!!edited.code)
                .check(is.duplicateWith(otherCodes))
                .given(edited.code)
                .asWarning()
        }

        ,

        focalPoint: {

            label: t('tenant.focalpoint'),
            msg: t('tenant.focalpoint_msg'),
            help: t('tenant.focalpoint_help',{singular}),

        }

        ,

        approveCycle: {

            label: t('tenant.approve_cycle_title'),
            msg: t('tenant.approve_cycle_msg'),
            help: t('tenant.approve_cycle_help')
        
        }

        ,


        language: {

            label: t('tenant.language'),
            msg: t('tenant.language_msg'),
            help: t('tenant.language_help')
            
        }

        ,

        note: {

            label: t('common.note'),
            msg: t('common.note_msg'),
            help: t('common.note_help',{singular})
            
        }
         
    }

    return self
}