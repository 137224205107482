import { detailsType } from '#details/constants';
import { Card } from '#record/card';
import { useAppSettings } from '#settings/settings';
import { Asset } from '#submission/model';
import { useValidationIssueIndex } from '#submission/validator';
import { SlotFormProps } from '#vessel/overview';
import { useT } from 'apprise-frontend-core/intl/language';
import { useL } from 'apprise-frontend-core/intl/multilang';
import { utils } from 'apprise-frontend-core/utils/common';
import { Lifecycle } from 'apprise-frontend-core/utils/lifecycle';
import { useBytestreams } from 'apprise-frontend-streams/api';
import { ImageLoader } from 'apprise-frontend-streams/imageloader/imageloader';
import { isBytestream } from 'apprise-frontend-streams/model';
import { useBytestreamTracker } from 'apprise-frontend-streams/track/tracker';
import { Tag, useTagModel } from 'apprise-frontend-tags/tag/model';
import { useTagStore } from 'apprise-frontend-tags/tag/store';
import { error, Fields, useValidation } from 'apprise-ui/field/validation';
import { FileBox, FileDescriptor } from 'apprise-ui/filebox/filebox';
import { Form } from 'apprise-ui/form/form';
import { Grid } from 'apprise-ui/grid/grid';
import { Label } from 'apprise-ui/label/label';
import { FileSizeLabel } from 'apprise-ui/utils/filesizelabel';
import { NamedProperty } from 'apprise-ui/utils/namedproperty';
import { NoPhotoIcon, photographsTypeCategory } from './constants';
import { newPhotoBytestreamFrom, Photo, usePhotoQualityHelper } from './model';
import { PhotoCardProps } from './photocard';


// TODO replace card with form
export const PhotographForm = (props: SlotFormProps) => {

    const tags = { ...useTagModel(), ...useTagStore() }

    const { edited, initial } = props

    const phototypes = tags.allTagsOfCategory(photographsTypeCategory, true)
    const { photos = [] } = edited?.photograph ?? {}
    const { photos: pastphotos = [] } = initial?.photograph ?? {}

    return <Form fieldDefaults={{ help: null, trackChange: true }}>

        <Grid> {

            phototypes.map((tag, i) => {

                const photo = photos.find(p => p.type === tag.id)
                const pastphoto = pastphotos.find(p => p.type === tag.id)

                return <PhotoField height={175} showMetadata key={i} tag={tag} photo={photo} pastphoto={pastphoto} {...props} />

            })

        }</Grid>

    </Form>

}

const PhotoField = (props: PhotoCardProps & SlotFormProps) => {

    const t = useT()
    const l = useL()
    const tags = useTagStore()
    const streams = useBytestreams()
    const validation = useValidation()
    const tracker = useBytestreamTracker()


    const fields = validation.reportOf(usePhotographFormFields(props))

    const { photo = { type: '', ref: '' }, pastphoto, width, height, showMetadata, set } = props

    const { type, ref } = photo

    const tag = props.tag || tags.safeLookupTag(type)

    const info = fields[tag.id]

    const unknownPlaceholder = <span style={{ color: 'lightgrey' }}>{t('photograph.img_unknown')}</span> as any

    const unknownAsset = ref => ({

        id: 'unknown', lifecycle: {} as Lifecycle, type: unknownPlaceholder, size: 0, properties: { archive: unknownPlaceholder, path: typeof ref === 'string' ? ref : '' }

    })

    const image = (isBytestream(ref) ? ref : unknownAsset(ref)) as Asset

    const pastImage = (isBytestream(pastphoto?.ref) ? pastphoto!.ref : unknownAsset(pastphoto?.ref)) as Asset

    const issues = useValidationIssueIndex()

    const issue = issues[tag.id]

    const onChange = async (assets: FileDescriptor[] | undefined) => {

        const asset = assets?.[0] as Asset

        if (asset) {

            const file = tracker.lookup(asset.id)?.file!

            const img = new Image();

            const src = URL.createObjectURL(file)

            const sizer = new Promise((res) => {

                img.onload = () => {

                    URL.revokeObjectURL(src)

                    //console.log('adding',file.name)

                    asset.properties.width = img.naturalWidth
                    asset.properties.height = img.naturalHeight

                    res(undefined)

                }

                img.onerror = res

            })


            img.src = src;

            await sizer

        }


        set.using(record => {

            const photos = record.photograph.photos

            const match = photos.findIndex(p => p.type === tag.id)

            if (match < 0)  // add new photo.

                photos.push({ type: tag.id, ref: asset })

            else    // modify existing photo.

                if (asset)
                    photos[match].ref = asset
                else
                    photos.splice(match, 1)

        })


    }

    const descriptorOf = (file: File) => {

        const stream = newPhotoBytestreamFrom(file)

        tracker.add(stream.id, file)

        return stream
    }

    return <Card className='photo-field' >

        <Grid rowGap={5}>

            <Grid.Row>

                <FileBox className='photo-upload' multi={false} info={info} dragMsg={t("photograph.upload_msg")} onChange={onChange} descriptorOf={descriptorOf}>
                    {typeof photo.ref === 'string' ? undefined : photo.ref}
                </FileBox>

            </Grid.Row>

            <Grid.Row>

                <NamedProperty previously={image.id === 'unknown' ? undefined : pastImage?.size} currently={image.size}>
                    {l(tag.name)}
                </NamedProperty>

            </Grid.Row>

            <Grid.Row className='photo-card' columnGap={10} >

                <Grid.Col className='photo-box' width="min-content">
                    <ImageLoader stream={ref} linkOf={id => tracker.linkOf(id) || streams.linkOf(id)} width={width} height={height} placeholder={<>

{/*                         {issue && <div className={`photo-message photo-${issue}`}>{t(`photograph.${issue.status}`)}</div>}
 */}
                        <NoPhotoIcon className='photo-placeholder' />
                    </>} />
                </Grid.Col>

                {showMetadata && <Grid.Col rowGap={3}>

                    <Grid.Row>
                        <NamedProperty status={issue} noFixedHeight name={t('photograph.img_name')}>
                            <Label noIcon unknown title={image.name ?? image.properties.path} />
                        </NamedProperty>
                    </Grid.Row>
                    <Grid.Row>
                        <NamedProperty name={t('photograph.img_type')}>
                            {image.type}
                        </NamedProperty>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Col>
                            <NamedProperty noFixedHeight name={t('photograph.img_archive')}>
                                {image.properties.archive || unknownPlaceholder}
                            </NamedProperty>
                        </Grid.Col>
                        {image.properties.archive && <Grid.Col>
                            <NamedProperty noFixedHeight name={t('photograph.img_path')}>
                                {image.properties.path}
                            </NamedProperty>
                        </Grid.Col>}
                    </Grid.Row>
                    <Grid.Row>
                        <NamedProperty status={issues[`${tag.id}-quality`]} previously={image.id === 'unknown' ? undefined : pastImage?.size} currently={image.size} name={t('photograph.img_size')}>
                            {image.size ? <FileSizeLabel size={image.size} /> : unknownPlaceholder}
                        </NamedProperty>
                    </Grid.Row>
                    <Grid.Row>
                        <NamedProperty status={issues[`${tag.id}-quality`]} previously={`${pastImage?.properties.width}${pastImage?.properties.height}`} currently={`${image?.properties.width}${image?.properties.height}`} name={t('photograph.img_resolution')}>
                            {image.properties.width ? `${image?.properties.width}x${image?.properties.height}` : unknownPlaceholder}
                        </NamedProperty>
                    </Grid.Row>
                </Grid.Col>
                }
            </Grid.Row>
        </Grid>
    </Card>
}


export const usePhotographFormFields = (props: SlotFormProps) => {

    const l = useL()
    const t = useT()

    const { edited, current } = props

    const { photos = [] } = edited?.photograph ?? {}
    const { photos: previousPhotos } = current?.photograph ?? {}

    const { photosRequired } = useAppSettings()

    const severity = photosRequired ? 'error' : 'warning'

    const tags = useTagStore()


    const isLowQuality = usePhotoQualityHelper()

    const { check } = useValidation()

    const phototags = tags.allTagsOfCategory(photographsTypeCategory, true)

    const quality = (photo: Photo) => () => {

        const errormsg = isBytestream(photo?.ref) && isLowQuality(photo?.ref, photo.type)

        return !!errormsg && error(errormsg)

    }

    const expectPhotos = previousPhotos && (edited[detailsType] && [
        [edited.tenant, current?.tenant],
        [edited[detailsType]?.name, current?.[detailsType]?.name],
        [edited[detailsType]?.identifiers, current?.[detailsType]?.identifiers],
        [edited[detailsType]?.port, current?.[detailsType]?.port]

    ].find(([p, c]) => !utils().deepequals(p, c)))

    const validate = (tag: Tag) => {

        const photo = photos.find(p => p.type === tag.id)

        return {

             ...check(photo => !isBytestream(photo?.ref) && error(t('ui.empty_error')))
            .given(photo)
            .andFinishWith(o => ({ ...o , 
                    msg: expectPhotos && previousPhotos?.some(p => p.type === tag.id && isBytestream(p.ref)) ? t('valid.expected_photo', {type: l(tag.name)}) : o.msg, 
                    status: severity,
                    location: tag.id
                
                }))

            , 

            ...check(quality(photo!))
                    .given(photo)
                    .andFinishWith(o => ({ ...o , 
                        status: 'warning',
                        location: `${tag.id}-quality`
                    
                    }))

        }


    }

    return phototags.reduce((acc, next) => ({ ...acc, [next.id]: validate(next) }), {} as Fields)

}