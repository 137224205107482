import { useConfig } from 'apprise-frontend-core/config/api'
import { useT } from 'apprise-frontend-core/intl/language'
import { utils } from 'apprise-frontend-core/utils/common'
import { Button } from 'apprise-ui/button/button'
import { classname, Tall, Wide } from 'apprise-ui/component/model'
import { Field, useFieldProps } from 'apprise-ui/field/field'
import { Fielded } from 'apprise-ui/field/model'
import { useReadonlyHelper } from 'apprise-ui/field/readonlyhelper'
import { useValidation } from 'apprise-ui/field/validation'
import { Label, LabelProps } from 'apprise-ui/label/label'
import { Tip } from 'apprise-ui/tooltip/tip'
import { useFeedback } from 'apprise-ui/utils/feedback'
import { RemoveItemIcon } from 'apprise-ui/utils/icons'
import React, { ReactNode } from 'react'
import { AiFillQuestionCircle, AiTwotoneFolderOpen } from 'react-icons/ai'
import { FileBoxConfiguration } from './configuration'
import { mimeIcons, typeDescriptors } from './constants'
import { FileDroppable } from './filedroppable'
import "./styles.scss"

export type FileDescriptor = {

    name: string
    type: string

}



export type FileboxProps<T extends FileDescriptor = FileDescriptor> = Fielded<T[]> & Wide & Tall & Partial<{

    children: T | T[]

    multi: boolean

    accept: string | string[]

    dragMsg: ReactNode
    dropMsg: ReactNode
    maxSizeMb: number

    render: (_: T) => React.ReactNode
    descriptorOf: (_: File) => T

}>


export const FileDescriptorLabel = (props: LabelProps & {

    descriptor: FileDescriptor

}) =>   {

    const t = useT()

    const {descriptor, ...rest} = props

    return <Label noReadonly className="descriptor-name" icon={<Tip tip={t(typeDescriptors[descriptor.type] ?? typeDescriptors.unknown)}>{iconFor(descriptor)}</Tip>} title={descriptor.name} {...rest} />
}


export const iconFor = (d: FileDescriptor) => mimeIcons[d.type] ?? mimeIcons.unknown

export const FileBox = <T extends FileDescriptor = File>(clientprops: FileboxProps<T>) => {

    const t = useT()
    const config = useConfig<FileBoxConfiguration>()
    const { ask } = useFeedback()

    const props = useFieldProps(clientprops)

    useReadonlyHelper(props)

    const { children = [],

        render = d => d.name,
        descriptorOf = ({ name, type,  }) => ({ name, type }) as T,

        multi = true,
        accept = [],


        dragMsg = t(multi ? "ui.filebox.drag_many_msg" : "ui.filebox.drag_msg"),
        dropMsg = t("ui.filebox.drop_msg"),
        maxSizeMb,

        disabled, onChange, width, height, minHeight, minWidth, noReadonly
        
     } = props

   
    const descriptors = utils().arrayOf(children)



    const removeAt = (pos: number) => () => onChange?.(descriptors.filter((_, j) => pos !== j))

    // prevent
    const canDropMore = multi || descriptors.length === 0

    const fileLimitSize = maxSizeMb ?? config.fileLimitSize

    const hiddenFileInputRef = React.createRef<HTMLInputElement>()
    const openFileDialog = () => hiddenFileInputRef.current?.click()

    const validateFileLength = (f: File) => !fileLimitSize || (f.size <= (fileLimitSize * Math.pow(1024, 2)))

    const onDrop = (files: File[]) => {

        const validFiles = files.filter(f => validateFileLength(f as File))

        ask({

            title: t("ui.filebox.sizewarning_title"),
            body: t("ui.filebox.sizewarning_msg", { limit: fileLimitSize }),
            okText: t("ui.filebox.sizewarning_okmsg"),
            noCancel: true
        })
            .if(validFiles.length < files.length)
            .thenRun(() => { })


        //  pass on to callback.
        if (validFiles.length > 0)
            onChange?.([...descriptors, ...files.map(f => descriptorOf?.(f))])

        // reset hidden input
        if (hiddenFileInputRef.current)
            hiddenFileInputRef.current.value = ''
    }


    const onChoose = (filelist: FileList | null) => {

        const files = filelist?.item(0) ? Array.from(filelist) : []

        onDrop(files)
    }

    // we perform internal validation to as mark invalid entries.
    // client must useFileBoxInfo() to validate externally.
    const acceptedTypes = utils().arrayOf(accept)
    const invalidDescriptors = descriptors.filter(d => acceptedTypes.length && !acceptedTypes.includes(d.type))
    const tooManyDescriptors = !multi && descriptors.length > 1

   const descriptorClasses = (d: T, i: number) => classname('filebox-descriptor',
        ((invalidDescriptors.includes(d) || tooManyDescriptors) && i > 0) && 'descriptor-error')


    const { check } = useValidation()
    const { tooManyFiles, notValidType } = useFileboxValidation()

    const info = {
        ...props.info,
        ...check(notValidType(accept))
            .andProvided(!multi).check(tooManyFiles)
            .on(descriptors)
    }

    const dropareaStyle = { width, minWidth, minHeight, height }


    return <Field name='filebox' {...props} info={info}  >

        <div className='filebox-descriptors'>

            {descriptors.map((d, i) =>

                <div style={{width}} key={i} className={descriptorClasses(d, i)}>

                    <FileDescriptorLabel descriptor={d} title={render?.(d)} />

                    <Button noReadonly={noReadonly} disabled={disabled} className='descriptor-removebtn' type='ghost' size="small" onClick={removeAt(i)}>
                        <RemoveItemIcon />
                    </Button>


                </div>

            )}

        </div>


        {
            props.readonly && descriptors.length === 0 &&

            <div style={dropareaStyle} className='drop-area' >
                <div className='filebox-msg'>
                    {t("ui.filebox.no_files")}
                </div>
            </div>
        }


        {props.readonly || !canDropMore ||

            <FileDroppable disabled={disabled} className="filebox-droppable" onDrop={onDrop} >
                <div style={dropareaStyle} className='drop-area drop-contents' onClick={openFileDialog}>

                    <input ref={hiddenFileInputRef} accept={utils().arrayOf(accept).join(',')} type="file" multiple={multi} name="name" style={{ "display": "none" }} onChange={e => onChoose(e.target.files)} />

                    <div className={classname('filebox-msg', 'msg-drop')}>
                        {dropMsg}
                    </div>
                    <div className={classname('filebox-msg', 'msg-drag')}>
                        <div className='apprise-row'>
                            {!!fileLimitSize && <Tip style={{ marginRight: 4 }} tip={t('ui.filebox.max_size', { size: fileLimitSize })}>
                                <AiFillQuestionCircle />
                            </Tip>}
                            {dragMsg}
                            &nbsp;
                            <Button noReadonly={noReadonly} type='ghost' className="filebox-open-button" disabled={disabled} onClick={e => { e?.stopPropagation(); openFileDialog() }} >
                                <AiTwotoneFolderOpen />
                            </Button>
                        </div>
                    </div>

                </div>
            </FileDroppable>
        }

    </Field>
}



export const useFileboxValidation = () => {

    const t = useT()


    const self = {

        tooManyFiles: (ds?: FileDescriptor | FileDescriptor[]) =>

            utils().arrayOf(ds).length > 1 && { status: "error" as const, msg: t("ui.filebox.too_many_files") }

        ,

        notValidType: (types: string | string[]) => (ds?: FileDescriptor | FileDescriptor[]) => {

            const acceptedTypes = utils().arrayOf(types)
            const invalidDescriptors = utils().arrayOf(ds).filter(d => acceptedTypes.length && !acceptedTypes.includes(d!.type))

            return invalidDescriptors.length > 0 && { status: "error" as const, msg: t('ui.filebox.invalid_types', { count: invalidDescriptors.length }) }

        }
    }

    return self

}