import { imoscheme, ircsscheme, natnoscheme, regnoscheme, tuvischeme } from '#iotc/constants'
import { JsonSerializationProps, useGenericJsonSerializer } from '#record/jsonserializer'
import { useVID } from '#vid/model'
import { useTagStore } from 'apprise-frontend-tags/tag/store'
import { beneficialOwnerTag, grtTag, loaTag, operatingCompanyTag, operatorTag, ownerTag, tonnageTag, totalVolumeTag } from './constants'
import { DetailsPatch } from './model'




// types the json row produced by a parse with the keys specified in config.
export type DetailsJsonSerialization = Partial<{

    uvi: string
    name: string
    imo: string
    tuvi: string
    regno: string
    natno: string
    ircs: string
    type: string
    kind: string
    range: string
    gear: string | string[]
    portname: string
    portflag: string,
    portcode:string
    flagstate: string
    loa: string
    tonnage: string
    grt: string
    volume: string
    volumeTonnes: string
    owner: string
    ownerAddress: string
    operator: string
    operatorAddress: string
    beneficial: string
    beneficialAddress: string
    company: string
    companyAddress: string
    companyRegno: string
    previousName: string
    previousFlag: string
    previousDeletion: string
}>


export const useDetailsJsonSerializer = () => {

    const vids = useVID()
    const tags = useTagStore()


    const serializer = (props: JsonSerializationProps<DetailsPatch>): DetailsJsonSerialization => {

        const { patch, mode } = props
        const { details } = patch

        const gears = typeof details.gears === 'string' ? tags.lookupTag(details.gears)?.code :
            Array.isArray(details.gears) ? details.gears.map(g => tags.lookupTag(g)?.code ?? g)
                : undefined


        const summary = {

            name: details.name,
            imo: vids.find(imoscheme, details.identifiers),
            tuvi: vids.find(tuvischeme, details.identifiers),
            regno: vids.find(regnoscheme, details.identifiers),
            natno: vids.find(natnoscheme, details.identifiers),
            ircs: vids.find(ircsscheme, details.identifiers),
            type: tags.lookupTag(details.vesselType)?.code,
            kind: tags.lookupTag(details.vesselKind)?.code,
            portname: details.port?.name,
            portcode: details.port?.code,
            portflag: details.port?.flag,
            flagstate: details.flagstate

        }

        const serialization = () => ({

            ...summary,

            range: tags.lookupTag(details.range)?.code,
            gear: gears,
            loa: details.specifications[loaTag],
            tonnage: details.specifications[tonnageTag],
            grt: details.specifications[grtTag],
            volume: details.specifications[totalVolumeTag],
            owner: details.contacts.find(c => c.type === ownerTag)?.name,
            ownerAddress: details.contacts.find(c => c.type === ownerTag)?.address,
            operator: details.contacts.find(c => c.type === operatorTag)?.name,
            operatorAddress: details.contacts.find(c => c.type === operatorTag)?.address,
            beneficial: details.contacts.find(c => c.type === beneficialOwnerTag)?.name,
            beneficialAddress: details.contacts.find(c => c.type === beneficialOwnerTag)?.address,
            company: details.contacts.find(c => c.type === operatingCompanyTag)?.name,
            companyAddress: details.contacts.find(c => c.type === operatingCompanyTag)?.name

        })


        return mode === 'summary' ? summary : serialization()


    }


    return useGenericJsonSerializer(serializer)
}