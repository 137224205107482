import { useInclusionList } from 'apprise-frontend-core/config/api'
import { Multilang, useMultilang } from 'apprise-frontend-core/intl/multilang'
import { Optional, utils } from 'apprise-frontend-core/utils/common'
import { Lifecycle } from 'apprise-frontend-core/utils/lifecycle'
import { categoryType } from 'apprise-frontend-tags/constants'
import { CustomPropertyRef } from 'apprise-frontend-tags/customprop/model'
import { TagExpression, TagReference } from 'apprise-frontend-tags/tag/model'



export type CategoryLifecycleState = 'active' | 'inactive'


export type TagCategory = {

    // unambiguously distinguishes this category from other categories over time.
    id: CategoryReference

    // constrains the domain objects that can be annotated with tags in this category.
    // cannot change over time.
    type: string

    // short and mnemonic, must be unique in the scope of the category's type.
    name: Multilang

    // free-form and instructional.
    description?: Multilang

    // the number of tags in this category that can annotate the same target object.
    cardinality: CategoryCardinality

    // flags the category is protected from unintended change to its properties or tag population.
    // can change at any time and must be iherited by individual tags.
    guarded?: boolean

    // flags the category is required by the system, so it can only be partially changed and it can't be removed in any case.
    // predefined categories can't change state, cardinalities, dependencies. they may change other fields if/when unguarded.
    // cannot change over time.
    predefined?: boolean

    // tracks event timestamps, event agency, and current/previous state.
    lifecycle: Lifecycle<CategoryLifecycleState>

    // extension point for additional property for the category. opaque to the backend.
    properties: CategoryProperties
}

export type CategoryReference = string

export type CategoryCardinality = { min: number, max: number | undefined }

export const keyOf = (card: CategoryCardinality) => Object.keys(cardinalities).find(k => cardinalities[k].min === card?.min && cardinalities[k].max === card?.max)!


export type CardinalityNames = 'any' | 'zeroOrOne' | 'one' | 'atLeastOne'

export const cardinalityLabels: { [key in CardinalityNames]: string } = {
    any: 'tag.cardinality_any',
    zeroOrOne: 'tag.cardinality_zeroOrOne',
    one: 'tag.cardinality_one',
    atLeastOne: 'tag.cardinality_atLeastOne'
}

export const cardinalities: { [key in CardinalityNames]: CategoryCardinality } = {

    any: { min: 0, max: undefined },
    zeroOrOne: { min: 0, max: 1 },
    one: { min: 1, max: 1 },
    atLeastOne: { min: 1, max: undefined },


}

export type CategoryProperties = {

    // info for a field that presents/collect tags in this category in forms defined over objects of the target type.
    field: Partial<TagCategoryField>

    // a boolean combination of tags in same/other categories, intended as dependencies.
    // if present, must be true or a field for this category will be disabled in forms defined over objects of the target type.
    includeCondition?: TagExpression

    // specifications for custom fields in forms defined over tags of this category.
    custom?: CustomPropertyRef[]
}

export type TagCategoryField = {

    enabled: boolean
    type?: 'radio'
    default?: Array<TagReference>
    title: Multilang
    message: Multilang
    help: Multilang
    ordinal: number
}



export const noRef = 'noneOf'

export const noCategory = (id: string = noRef): TagCategory => ({

    id,
    type: undefined as any,
    cardinality: cardinalities.any,
    name: { en: 'None' },
    lifecycle: { state: 'active' },
    properties: {
        field: {
            enabled: true, title: { en: 'tag.field_name' },
            message: { en: 'tag.field_msg' },
            help: { en: 'tag.field_help' }
        }
    }
})


export const unknownCategory = 'unknown'

export const useCategoryModel = () => {

    const ml = useMultilang()
    const { isIncluded } = useInclusionList(categoryType)

    const self = {



        newCategory: (type: string): TagCategory => ({

            id: undefined!,
            type,
            cardinality: cardinalities.any,
            lifecycle: { state: isIncluded('active') ? 'inactive' : 'active' },
            name: {},
            description: {},
            properties: {
                field: {
                    enabled: false
                }
            }
        })

        ,

        // returns an object where none can't be found: we have no id or the id cannot be resolved.
        noCategory: (id?: Optional<CategoryReference>): TagCategory => ({

            id: id || unknownCategory,
            type: unknownCategory,
            cardinality: cardinalities.any,
            lifecycle: { state: 'active' },
            name: { en: id || undefined },
            properties: {
                field: { enabled: false }
            }
        })

        ,

        categoryComparator: (o1?: TagCategory, o2?: TagCategory) => ml.comparator(o1?.properties.field?.title ?? o1?.name, o2?.properties.field?.title ?? o2?.name)

        ,

        ordinalComparator: (o1?: TagCategory, o2?: TagCategory) => {

            const outcome = (o1?.properties.field.ordinal ?? Number.MAX_SAFE_INTEGER) - (o2?.properties.field.ordinal ?? Number.MAX_SAFE_INTEGER)

            return outcome ? outcome : self.categoryComparator(o1, o2)

        }

        ,

        clone: (category: TagCategory): TagCategory => ({ ...utils().deepclone(category), id: undefined!, guarded:false, predefined:false, lifecycle: { state: 'inactive' } })


    }


    return self
}