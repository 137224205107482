import { Breadcrumbs } from 'apprise-ui/breadcrumb/breadcrumbs'
import { Header } from 'apprise-ui/header/header'
import * as React from 'react'



export const appColor = "--app-color"

const defaultCssProps = {
    
    [appColor]: 'lightseagreen'

}

type CssProperties = {

    [appColor] : React.CSSProperties['color']
}

export const defaultHeader = 

        <Header>
            <Header.Left>
                <Breadcrumbs />
            </Header.Left>
        </Header>


export const installCssProperties = (props:CssProperties) => {


    const { [appColor] : defaultedAppColor = defaultCssProps[appColor] } = props

    const cssProps = {[appColor]: defaultedAppColor }

    document.documentElement.style.cssText +=  Object.keys(cssProps).reduce( (acc,prop) => `${acc}${prop}:${cssProps[prop]};`,';')

}