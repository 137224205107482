import { useTenantPreferencesFields } from '#settings/fields'
import { SubmissionTypeIcon } from '#submission/constants'
import { useT } from 'apprise-frontend-core/intl/language'
import { FormState } from 'apprise-frontend-core/utils/form'
import { useTenantFields } from 'apprise-frontend-iam/tenant/fields'
import { Tenant } from 'apprise-frontend-iam/tenant/model'
import { useSettingStore } from 'apprise-frontend-streams/settings/api'
import { Label } from 'apprise-ui/label/label'
import { Link } from 'apprise-ui/link/link'
import { HelpIcon } from 'apprise-ui/utils/icons'
import { ApproveCycle, ApproveCycleBox } from "../settings/approvecyclebox"
import { systemSettingsModule } from './settings'


export const tenantPreferencesModule = {fields: useTenantFields, Form }

export const partyTenantTypeCategory='TGC-tenant-type'
export const partyFlagType='TG-tenant-flag'

export const partyMembershipCategory = 'TGC-tenant-membership'
export const partyMembershipCP = 'TG-tenant-cpc'
export const partyMembershipCNCP = 'TG-tenant-ncp'
export const partyMembershipNCP = 'TG-tenant-pnc'

export type TenantPreferences = {

    approveCycle: ApproveCycle
}

function Form(props: FormState<Tenant>) {

    const t = useT()
    const { edited, set } = props

    const settings = useSettingStore()
    const fields = useTenantPreferencesFields(props)


    const preferences = edited.preferences as TenantPreferences

    const linkToPanel =  <Link  linkTo={settings.routeToPanel(systemSettingsModule.type)}>
            <HelpIcon color='dodgerblue' onMouseDown={e => e.stopPropagation()} />  
        </Link>

    return <ApproveCycleBox info={fields.approveCycle}
        placeholder={<Label decorations={[linkToPanel]} icon={<SubmissionTypeIcon />} title={t('rav.approve_default')}  />}
        placeholderAsOption
        onChange={set.with(((t, v) => (t.preferences as TenantPreferences).approveCycle = v))}>
        {preferences.approveCycle}
    </ApproveCycleBox>
}
