import { MockHorizonContext } from 'apprise-frontend-core/client/mockhorizon'
import { useMockery } from 'apprise-frontend-core/client/mocks'
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import { EventSubscriber } from 'apprise-frontend-events/subscriptions'
import { PropsWithChildren, useContext } from 'react'
import { useSettingStore } from './api'
import { settingsType } from './constants'
import { useSettingsChangeListener } from './events'
import { useSettingsMockery } from './mockery'
import { SettingsProvider } from './provider'







export type SettingsProps = PropsWithChildren<Partial<{
    mock: boolean
}>>


export const Settings = (props: SettingsProps) => {

    const { children } = props

    return <SettingsProvider>
        <Initialiser>
            <Loader {...props}>
                {children}
            </Loader>
        </Initialiser>
    </SettingsProvider>
}



const Initialiser = (props: SettingsProps) => {

    const { children } = props

    const changeListener = useSettingsChangeListener()

    return <EventSubscriber name={settingsType} subscriptions={[changeListener]}>
        {children}
    </EventSubscriber>
}

const Loader = (props: SettingsProps) => {

    const belowMockHorizon = useContext(MockHorizonContext)

    const { children, mock =belowMockHorizon } = props

    const mocks = useMockery()
    const mockery = useSettingsMockery()
    const store = useSettingStore()

    const activate = () => Promise.resolve(mock ? mocks.initMockery(mockery) : true).then(store.fetch)


    const { content } = useRenderGuard({

        render: children,
        orRun: activate
    })


    return content
}

