import { useCall } from "apprise-frontend-core/client/call"
import { useConfig } from 'apprise-frontend-core/config/api'
import { useT } from "apprise-frontend-core/intl/language"
import { useBusyState } from "apprise-frontend-core/utils/busyguard"
import { utils } from 'apprise-frontend-core/utils/common'
import { iconFor } from 'apprise-ui/filebox/filebox'
import { useAsyncTask } from 'apprise-ui/utils/asynctask'
import { useFeedback } from "apprise-ui/utils/feedback"
import { saveAs } from "file-saver"
import partition from 'lodash/partition'
import { defaultUploadTimeout, RemoteStreamConfiguration } from './config'
import { streamType } from './constant'
import { useStreamMocks } from "./mockery"
import { Bytestream, newBytestreamId } from "./model"

// statless, uploads and downloads to and from the backend.


export const streamService = streamType
export const streamApi = "/stream"
export const deflateApi = `${streamApi}/deflate`
export const deleteApi = `${streamApi}/discard`
export const uploadStreamPart = 'descriptor'
export const uploadBlobPart = 'stream'

const contentdispositionPattern = /filename="(?<filename>.*)"/


export type UploadProps = Partial<{

    deflateArchives: boolean
    // true => we show failure details in error dialog.
    // func => client has the successful responses and the unsuccessfuly requests payload.
    reportUploadErrors: boolean | ((uploaded: Bytestream[], failures: Bytestream[]) => void)
    concurrency?: number
}>

export const newFromFile = ({ name, type, size }: File): Bytestream => ({

    id: newBytestreamId(),
    name,
    type,
    size,
    properties: {},
    lifecycle: undefined!

})

export const useBytestreams = () => {

    const t = useT()

    const { showError, showFailure, showAndRethrow } = useFeedback()
    const busy = useBusyState()
    const call = useCall()
    const mocks = useStreamMocks()

    const { uploadTimeout = defaultUploadTimeout } = useConfig<RemoteStreamConfiguration>()?.stream ?? {}

    const task = useAsyncTask()

    const self = {

        newFromFile

        ,

        iconFor: iconFor

        ,

        newFromBlob: (name: string, { type, size }: Blob) => self.newFromFile({ name, type, size } as File)

        ,

        absoluteLinkOf: (stream: string) => {

            return window.location.protocol + "//" + window.location.host + self.linkOf(stream)
        }

        ,

        linkOf: (stream: string) => {

            if (!mocks.streamStore().oneWith(stream))
                return call.fq(`${streamApi}/${stream}`, streamService)

            const blobs = mocks.blobStore()
            const blob = blobs.get(stream)

            return URL.createObjectURL(blob.data)
        }

        ,

        download: task.make(async (id: string) => {

            const response = await call.at(`${streamApi}/${id}`, streamService).get<{ data: any, headers: any }>({ raw: true, responseType: 'blob' })

            // saves blob in a file typed and named as the backend indicates 
            // in the Content-Type and Content-Disposition response headers
            saveAs(

                new Blob([response.data], { type: response.headers?.['content-type'] }),
                contentdispositionPattern.exec(response.headers?.['content-disposition'])?.groups?.filename
            )

        })
            .with($ => $.wait(200).show(`stream.loading`).error('stream.download_error'))
            .done()

        ,


        // takes (stream,blob) pairs and persists them at the backend. 
        upload: async <T extends Bytestream>(streamsAndBlobs: [T, Blob][], props: UploadProps = {}): Promise<T[]> => {

            var count = streamsAndBlobs?.length ?? 0


            if (count === 0)
                return []


            const { deflateArchives, concurrency = 3, reportUploadErrors = true } = props

            try {

                await busy.toggle(`stream.upload`, count > 1 ? t("stream.uploading_many", { count }) : t("stream.uploading_one"))

                // splits requests in small groups, to rate-limit them.
                const groups = utils().split(streamsAndBlobs).in(concurrency)

                // this generates a promise on demand which resolves when each group has fully completed.
                const res = (function* generate() {

                    for (const group of groups)

                        // we wait for all requests to complete, for best effort and to report failues to clients.
                        yield Promise.allSettled(group.map(async ([stream, data]) => {

                            const multipart = new FormData();

                            multipart.append(uploadStreamPart, new Blob([JSON.stringify(stream)], { type: "application/json" }))
                            multipart.append(uploadBlobPart, data)

                            const deflate = self.isArchive(data) && deflateArchives

                            const uploaded = call.at(deflate ? deflateApi : streamApi, streamService).post<T[]>(multipart, { timeout: uploadTimeout, headers: { 'Content-Type': 'multipart/form-data' } })

                            count--

                            if (count > 0)
                                busy.update(`stream.upload`, t("stream.upload_progress", { name: stream.name, count }))

                            return uploaded


                        }))

                })()


                // now we wait on each group, and accumulate the outcomes.
                const results = [] as PromiseSettledResult<T[]>[]

                for await (const tasks of res)
                    results.push(...tasks)

                // we partition the outcomes in successes and failures.
                const [fullfilled, rejected] = partition(results, r => r.status === 'fulfilled') as [PromiseFulfilledResult<T[]>[], PromiseRejectedResult[]]

                // if we have some failures and the client wants to know about them we call the client with all outcomes, then throw an error.
                if (rejected.length > 0) {

                    if (reportUploadErrors) {

                        if (typeof reportUploadErrors === 'function')
                            // we report the successful responses and the failed request payloads.
                            reportUploadErrors(fullfilled.flatMap(s => s.value), rejected.map((_, i) => streamsAndBlobs[i][0]))

                        showAndRethrow(new Error(), {

                            type: 'warning',
                            message: t('stream.upload_errors', { count: rejected.length }),
                            details: rejected.map((r, i) => `[${streamsAndBlobs[i][0].name}]\t${r.reason}`).join('\n')

                        })
                    }
                    else throw new Error(`${t('stream.upload_errors', { count: rejected.length })}: ${rejected.map((r, i) => `[${streamsAndBlobs[i][0].name}]\t${r.reason}`).join('\n')}`)
                }



                return fullfilled.flatMap(s => s.value)


            }
            finally {

                busy.toggle(`stream.upload`)
            }


        }

        ,

        delete: (streams: (Bytestream | string)[]) => {

            console.log(`removing ${streams.length} stream(s)...`)

            return busy.toggle(`stream.delete`, t('stream.delete_many', { count: streams.length }))

                .then(() => call.at(deleteApi, streamService).post<string[]>(streams.map(s => typeof s === 'string' ? s : s.id)))

                .then(undeleted => undeleted?.length > 0 && showFailure({

                    type: 'warning',
                    body: t('stream.delete_some', { count: undeleted.length, all: streams.length })

                }))

                .catch(showError(t('stream.delete_error')))
                .finally(() => busy.toggle(`stream.delete`))

        }

        ,

        isArchive: (blob: Blob) => {

            return blob.type === 'application/zip'
        }

    }

    return self
}