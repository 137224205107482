
import { classname, Styled, Wide } from 'apprise-ui/component/model'
import "./card.scss"

export const Card = (props: React.PropsWithChildren<Styled & Wide>) => {

    const { children, className, style, width, minWidth } = props

    return <div className={classname('rav-card', className)} style={{minWidth, width,...style}}>
        {children}
    </div>
}