import { SlotCardProps } from '#vessel/overview';
import { utils } from 'apprise-frontend-core/utils/common';
import { Grid } from 'apprise-ui/grid/grid';
import { photographType } from './constants';
import { PhotographsPatch } from './model';
import { PhotoCard } from './photocard';


const columnsplit = 3

export const PhotographOverview = (props: SlotCardProps<PhotographsPatch>) => {

    const { record, showChange } = props
    const { photos } = record[photographType]
    const  pastPhotos = showChange ? props.previous?.photograph?.photos ?? {} : undefined

    return <Grid>{
        
        utils().split(photos).max(columnsplit).map((_photos, j) =>

            <Grid.Row key={j}>{_photos.map((photo, i) =>

                <Grid.Col width='min-content' key={i}>

                    <PhotoCard height={220}  photo={photo} pastphoto={pastPhotos?.[(j * columnsplit) + i]} />

                </Grid.Col>

            )}
            </Grid.Row>
        )

    }</Grid>


}
