import { Pagination as AntdPagination } from 'antd'
import { Component, useComponentProps } from 'apprise-ui/component/component'
import { Debugged, Disabled, Sized, StyledContainer } from 'apprise-ui/component/model'


export type PaginationProps = Debugged & StyledContainer & Disabled & Sized<'small' | 'normal'> & Partial<{

    total: number
    pageSize: number

    startPage: number
    onChange: (page: number, size: number) => any

    noSinglePage: boolean

    children: number
}>


export const Pagination = (clientPropps: PaginationProps) => {

    const props = useComponentProps(clientPropps)

    const { innerClassName, innerStyle, size = 'normal', noSinglePage, children: currentPage, total, pageSize = 20, startPage, onChange, disabled, ...rest } = props

    props.debug && console.log({ total, pageSize, startPage, currentPage })

    // add current props only if truly controlled (antd sees undefined as zero and thinks it's controlled...)
    const controlProps = currentPage !== undefined ? { current: currentPage } : {}

    return <Component name='pagination' {...rest}>

        <AntdPagination style={innerStyle} className={innerClassName} showSizeChanger={false} hideOnSinglePage={noSinglePage} showTitle={false}

            disabled={disabled}

            total={total}
            pageSize={pageSize}

            defaultCurrent={startPage}
            onChange={onChange}

            size={size === 'normal' ? 'default' : size}

            {...controlProps} />

    </Component>
}