
import { FilterProps } from '#record/plugin'
import { useT } from 'apprise-frontend-core/intl/language'
import { utils } from 'apprise-frontend-core/utils/common'
import { TagLabel } from 'apprise-frontend-tags/tag/label'
import { Label } from 'apprise-ui/label/label'
import { OptionMenu } from 'apprise-ui/optionmenu/optionmenu'
import { useFilterState } from 'apprise-ui/utils/filter'
import React from 'react'
import { detailsType, VesselPortIcon, VesselTypeIcon } from './constants'
import { DetailsPatch } from './model'



export const useDetailsFilters = (props:FilterProps<DetailsPatch>) => {

    const {data, ...rest} = props
    
    const { PortFilter, filteredData:portFilteredData } = usePortFilter({ data, initial:data, ...rest })
    const { VesselTypeFilter, filteredData: vesselTypeFilteredData } = useVesselTypeFilter({ data: portFilteredData, initial:data, ...rest })

    return { data: vesselTypeFilteredData, filters: [VesselTypeFilter, PortFilter] }


}


type PluginFilterProps = FilterProps<DetailsPatch> & {

    initial: DetailsPatch[]
}


export const usePortFilter = (props: PluginFilterProps) => {

    const t = useT()

    const { data, initial, context, contextKey = 'detailport' } = props

    const existingOptions: string[] = React.useMemo(() =>

        utils().dedup(initial.map(r => r[detailsType].port?.name).filter(p => !!p).sort())

        // eslint-disable-next-line
        , [initial])

    const state = useFilterState<string[]>(context)

    const active = state.get(contextKey) ?? existingOptions

    const setActive = state.set(contextKey)

    const PortFilter = (

        <OptionMenu  key={existingOptions.length} active={active} setActive={setActive} label={<Label icon={<VesselPortIcon />} title={t('rec.vesselport_col')} />}>
            {existingOptions.map(opt =>

                <OptionMenu.Option
                    key={opt}
                    disabled={!existingOptions.includes(opt)}
                    value={opt}
                    title={opt} />
            )}
        </OptionMenu>
    )

    const portfilter = React.useCallback(

        (r: DetailsPatch) => !r[detailsType].port?.name || active.some(a => r[detailsType].port?.name === a)

        // eslint-disable-next-line
        , [active])  // recompute when selection changes


    const filteredData = React.useMemo(

        () => data.filter(portfilter),

        [data, portfilter])

    return { PortFilter, portfilter, filteredData, active, setActive }
}

export const useVesselTypeFilter = (props: PluginFilterProps) => {

    const t = useT()

    const { data, initial, context, contextKey = 'detailvesseltype' } = props

    const existingOptions: string[] = React.useMemo(() =>

        utils().dedup(initial.map(r => r[detailsType].vesselType).filter(p => !!p).sort())

        // eslint-disable-next-line
        , [initial])

    const state = useFilterState<string[]>(context)

    const active = state.get(contextKey) ?? existingOptions

    const setActive = state.set(contextKey)

    const VesselTypeFilter = (

        <OptionMenu key={existingOptions.length} active={active} setActive={setActive} label={<Label icon={<VesselTypeIcon />} title={t('rec.vesseltype_col')} />}>
            {existingOptions.map(opt =>

                <OptionMenu.Option
                    key={opt}
                    disabled={!existingOptions.includes(opt)}
                    value={opt}
                    title={<TagLabel bare tag={opt} />} />
            )}
        </OptionMenu>
    )

    const vesselfilter = React.useCallback(

        (r: DetailsPatch) => !r[detailsType].vesselType || active.some(a => r[detailsType].vesselType === a)

        // eslint-disable-next-line
        , [active])  // recompute when selection changes


    const filteredData = React.useMemo(

        () => data.filter(vesselfilter),

        [data, vesselfilter])

    return { VesselTypeFilter, vesselfilter, filteredData, active, setActive }
}