import { useT } from 'apprise-frontend-core/intl/language'
import { useL } from 'apprise-frontend-core/intl/multilang'
import capitalize from 'lodash/capitalize'
import { TagCategory } from './category/model'
import { useCategoryStore } from './category/store'
import { useTagStore } from './tag/store'


export const useTagValidation = () => {

    const catstore = useCategoryStore()
    const store = useTagStore()

    const t = useT()
    const l = useL()

    const self = {

        validateTags: (tagIds: string[]) => {


            const validate = (...catIds: (TagCategory | string)[]) => {
      
              const tags = tagIds.map(store.safeLookupTag)
      
              const cats : TagCategory[] = catIds.map(c => typeof c === 'string' ? catstore.safeLookupCategory(c) : c)

              const isActive = (cat:TagCategory) =>  cat.lifecycle.state === 'active' && store.allTagsOfCategory(cat.id).some(t=>t.lifecycle.state==='active')
      
              // { category.id -> validation}
              return cats.filter(isActive).map(cat => {

                const tagsincat = tags.filter(t => t.category === cat.id)
      
                const { min, max } = cat.cardinality
      
                const enabledField = cat.properties.field?.enabled 
                const field = capitalize(l(cat.properties.field?.title ?? cat.name))
                const choice =  enabledField ?  t('tag.validation_choice'): t('tag.validation_choice_field',{field})
                const choice_atleast = enabledField ? t('tag.validation_atleast',{min}) : t('tag.validation_atleast_field',{min, field})
                const choice_one = enabledField ? t('tag.validation_one') : t('tag.validation_one_field',{field})
                const choice_atmost = enabledField ? t('tag.validation_atmost',{max}) : t('tag.validation_atmost_field',{max,field})  
                
                //console.log("category",cat.id,"card",cat.cardinality,tagsincat)

                // tailore cardinality failure message .
                const failure = 
                
                    (min && tagsincat.length < min && ( min === 1 ? choice :choice_atleast))

                  || 
                  
                  (max && tagsincat.length > max && (max === 1 ? choice_one :choice_atmost))
      
                return { [cat.id]: failure ? {status:'error',msg:failure} : { status: "success" } }
      
      
               }).reduce((acc, next) => ({ ...acc, ...next }), {}) // merges objects
      
            }
      
            return { 
              for: (type: string) => validate(...catstore.allCategoriesOf(type)), 
              include: validate 
            }
      
      
          }

    }


    return self
}