import { RecordValidator, useGenericValidator } from '#record/validator';
import { RecordIssue } from '#submission/validator';
import { useT } from 'apprise-frontend-core/intl/language';
import { authorizationType } from './constants';
import { AuthorizationPatch } from './model';


export const useAuthorizationValidation = (): RecordValidator<AuthorizationPatch> => {

    const validator = useAuthorizationValidator()

    return useGenericValidator(authorizationType, validator)
}


export const useAuthorizationValidator = (): RecordValidator<AuthorizationPatch> => {

    const t = useT()

    return data => data.reduce((acc, { patch }) => {

        const issues: RecordIssue[] = []

        const { from, to } = patch[authorizationType]

        const parsedFrom = Date.parse(from)
        const parsedTo = Date.parse(to)

        if (!from)
            issues.push({ message: t('valid.missing_from_date'), type: 'error', location: 'from' })

        if (!to)
            issues.push({ message: t('valid.missing_to_date'), type: 'error', location: 'to' })


        if (from && to && parsedFrom >=parsedTo)
            issues.push({ message: t('valid.invalid_auth_range'), type: 'error', location: 'range' })

        if (to && parsedTo <= Date.now())
            issues.push({ message: t('valid.expired_auth_range'), type: 'warning', location: 'range' })

        return { ...acc, [patch.id]: issues }

    }, {})

}


