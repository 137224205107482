import { utils } from 'apprise-frontend-core/utils/common';
import { useContext } from "react";
import { useBytestreams } from "../api";
import { Bytestream, BytestreamRef } from '../model';
import { StreamContext } from './state';
import { saveAs } from "file-saver"



/**
 *  helps tracking bystream/blob bindings until they can be uploaded together.
 * 
 *  intended to help in form-based contexts, where bytestreams are properties of domain objects and blobs are user files.
 *  tracking the bindings allows files to be uploaded when the objects are saved, at the end of editing sessions, rather than as they're selected.
 *  typically, a successful upload is a precondition to a successful save. 
 *  
 */
export const useBytestreamTracker = () => {

    const state = useContext(StreamContext)

    const streams = useBytestreams()

    const self = {


        all: () => state.get().bindings

        ,

        lookup: (id: string) => self.all().find(b => b.stream === id)

        ,

        linkOf: (id: string) => {
            const bs = self.lookup(id)

            if (!bs) return;

            return URL.createObjectURL(bs.file);
        }

        ,

        add: (stream: BytestreamRef, file: File) => state.set(s => s.bindings.push({ stream, file }))

        ,

        download: (stream: Bytestream) => {

            const match = self.all().find(s => s.stream === stream.id)


            if (match)

                saveAs(match.file, stream.name)

            else

                streams.download(stream.id)
        }

        ,

        upload: (...bytestreams: Bytestream[]) => {

            const resourceMap = utils().index(state.get().bindings).by(b => b.stream)

            const streamsAndBlobs = bytestreams.filter(r => resourceMap[r.id]!).map(r => [r, resourceMap[r.id].file] as [Bytestream, File])

            // clear bindings for uploaded streams in case of partial failure.
            // if we didn't, later retries would fail with duplication errors.
            const reportUploadErrors = (settled: Bytestream[]) =>  state.set(s => {

                const ids = settled.map(s => s.id)
                s.bindings = s.bindings.filter(b => !ids.includes(b.stream))
                
            })

            return streams.upload(streamsAndBlobs, { reportUploadErrors })


        }

        ,

        //  joins streams to the file bound to them in context.
        joinWith: (streams: Bytestream[] = []) => {

            const map = utils().index(streams).by(s => s.id)

            return self.all().filter(b => map[b.stream]).reduce((acc, b) =>

                [...acc, { ...map[b.stream], file: b.file }]

                , [] as (Bytestream & { file: Blob })[])
        }

        ,

        reset: () => state.set(s => s.bindings = [])
    }

    return self


}