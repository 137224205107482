
import { PatchAndCurrent } from '#submission/records'
import { DateLabel } from '#utils/datelabel'
import { useT } from 'apprise-frontend-core/intl/language'
import { Label } from 'apprise-ui/label/label'
import { useTableUtils } from 'apprise-ui/table/utils'
import { ChangeLabel } from 'apprise-ui/utils/changelabel'
import { DateIcon } from 'apprise-ui/utils/icons'
import format from 'date-fns/format'
import { AuthorizationPatch } from './model'

export const useAuthorizationColumns = () => {

    const t = useT()

    const { Column, compareNumbersOf } = useTableUtils<PatchAndCurrent<AuthorizationPatch>>()


    return <>

        <Column defaultLayout name="from" width={140} title={<Label icon={<DateIcon />} title={t('authorization.from_col')} />}
            text={patch => patch.authorization.from && format(new Date(patch.authorization.from), 'PPP')} 
            sort={compareNumbersOf(m => Date.parse(m.authorization.from))}
            render={m => <ChangeLabel before={m.current?.authorization?.from} after={m.authorization.from} render={d=> <DateLabel noIcon date={d} />} />} />


        <Column defaultLayout name="to" width={140} title={<Label icon={<DateIcon />} title={t('authorization.to_col')} />}
            text={patch => patch.authorization.to && format(new Date(patch.authorization.to), 'PPP')} 
            sort={compareNumbersOf(m => Date.parse(m.authorization.to))}
            render={m => <ChangeLabel before={m.current?.authorization?.to} after={m.authorization.to} render={d=> <DateLabel noIcon date={d} />} />} />



    </>
}