import { Select } from 'antd'
import { useT } from 'apprise-frontend-core/intl/language'
import { Sized, Tall, Wide } from 'apprise-ui/component/model'
import { useChangeHelper } from 'apprise-ui/field/changehelper'
import { Field, useFieldProps } from 'apprise-ui/field/field'
import { ChangeTracked, Fielded, Uncontrolled } from 'apprise-ui/field/model'
import { useReadonlyHelper } from 'apprise-ui/field/readonlyhelper'
import { useResetHelper } from 'apprise-ui/field/resethelper'
import { RemoveItemIcon } from 'apprise-ui/utils/icons'
import noop from 'lodash/noop'
import "./styles.scss"

export type TokeBoxProps = Fielded<string[]> & ChangeTracked & Sized & Wide & Tall & Uncontrolled & Partial<{

    children: string[]

    noClear: boolean
    separators: string[]
}>

export const TokenBox = (clientprops: TokeBoxProps) => {

    const props = useFieldProps({ ...clientprops, width: clientprops.width ?? '100%' })

    useReadonlyHelper(props)

    useResetHelper(props)

    const {
        children,
        onChange,
        width,
        disabled,
        innerClassName,
        innerStyle,
        noClear,
        placeholder:clientplaceholder,
        defaultValue,
        separators = [',', ';'],
        ...rest } = props


    const t = useT()
    
    const placeholder = clientplaceholder === true ? t('ui.field_placeholder') : clientplaceholder
   
    const currentValue = children ?? defaultValue

    const { pastMode, pastValue } = useChangeHelper(props)

    const latestValue = pastMode ? pastValue : currentValue


    const select = (v: string[] | undefined) => onChange?.(v)

    props.debug && console.log({ children, currentValue, pastValue, defaultValue, readonly:props.readonly  })

    return <Field name="tokenbox" {...rest}  >
        <Select placeholder={placeholder} mode="tags" open={false} disabled={disabled} tokenSeparators={separators}
            value={latestValue}
            className={innerClassName}
            style={innerStyle}
            allowClear={!noClear && !props.readonly}
            onChange={props.readonly ? noop : select}
            removeIcon={props.readonly ? null : <RemoveItemIcon />}
            clearIcon={props.readonly ? null : <RemoveItemIcon />}>
        </Select>
    </Field>


}


