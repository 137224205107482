import { delistingReasonScrapped, delistingType } from '#delisting/constants';
import { useExtensions } from '#extension/state';
import { DetailContext } from '#submission/context';
import { Submission, SubmissionType } from '#submission/model';
import { RecordIssue } from '#submission/validator';
import { trxauthzType } from '#trxauth/constants';
import { useVesselOracle } from '#vessel/oracle';
import { VidExtension } from '#vid/ext';
import { useVID } from '#vid/model';
import { useT } from 'apprise-frontend-core/intl/language';
import { useL } from 'apprise-frontend-core/intl/multilang';
import { utils } from 'apprise-frontend-core/utils/common';
import { useTenantStore } from 'apprise-frontend-iam/tenant/store';
import { useContext } from 'react';
import { GenericRecord, RavRecord, RecordIdentifier } from './model';
import { useRecordOracle } from './oracle';


export type RecordValidationData<T extends GenericRecord> = {

    patch: T
    current?: RavRecord

    record?: RavRecord

}

// the parsing contract: take an file and extract records for a submission.
export type RecordValidator<T extends GenericRecord> = (data: RecordValidationData<T>[], submission: Submission<T>) => Record<RecordIdentifier, RecordIssue[]>

export const useGenericValidator = <T extends GenericRecord>(type: SubmissionType, validator: RecordValidator<T>): RecordValidator<T> => {

    const t = useT()
    const l = useL()
    const tenants = useTenantStore()
    const vids = useVID()
    const ext = useExtensions<VidExtension>().get()

    const { sameRecords } = useContext(DetailContext)

    const vessels = useVesselOracle()

    const oracle = useRecordOracle()


    return (data, submission) => {


        const uvimap = utils().index(data.map(d => d.patch)).byGroup(t => t.uvi)

        const { matchStatusOf, isExcluded } = oracle.given(submission)

        const generic = data.reduce((acc, { patch, current }) => {

            const issues: RecordIssue[] = []

            if (sameRecords.includes(patch.id))
                issues.push({ message: t('valid.unchanged_patch'), type: 'warning' })

            const currentlyDelisted = current?.patchedSlots.includes(delistingType)

            if (currentlyDelisted && current?.[delistingType]?.reason === delistingReasonScrapped)
                issues.push({ message: t('valid.scrapped_vessel'), type: 'error', location: 'uvi' })

             // authz is required to claim
             if (current && currentlyDelisted && !patch.authorization)
                issues.push({ message: t('valid.claim_noauthz', { uvi: vids.valueOf(current.uvi) }), type: 'error', location: 'uvi' })


            // only deflagged vessels can be claimed by other member states.
            if (current && current.tenant !== patch.tenant && submission.type !== trxauthzType && !vessels.canClaim(current))
                issues.push({ message: t('valid.otherflag_uvi', { uvi: vids.valueOf(current.uvi), state: l(tenants.safeLookup(current.tenant)?.name) }), type: 'error', location: 'uvi' })

           
            if (matchStatusOf(patch) === 'pending' && patch.lifecycle.state !== 'ignored')
                issues.push({ message: t('valid.unmatched_uvi'), type: 'error', location: 'uvi' })

            if (type !== 'base' && !patch.uvi)
                issues.push({ message: t('valid.missing_uvi'), type: 'error', location: 'uvi' })

            if (patch.uvi && !ext.isValidPrimaryUvi(vids.parse(patch.uvi).value!))
                issues.push({ message: t('valid.invalid_uvi', { uvi: vids.valueOf(patch.uvi) }), type: 'error', location: 'uvi' })

            if (patch.uvi && ext.isValidPrimaryUvi(vids.parse(patch.uvi).value!) && !current?.uvi)
                issues.push({ message: t('valid.unknown_uvi', { uvi: vids.valueOf(patch.uvi) }), type: 'error', location: 'uvi' })

            if (patch.uvi && uvimap[patch.uvi].filter(patch => !isExcluded(patch)).length > 1)
                issues.push({ message: t('valid.duplicate_uvi', { uvi: vids.valueOf(patch.uvi), length: uvimap[patch.uvi].length }), type: 'error', location: 'uvi' })

            

            return { ...acc, [patch.id]: issues }

        }, {})

        return Object.entries(validator(data, submission)).reduce((acc, [id, issues]) => ({

            ...acc,
            [id]: [...(acc[id] ?? []), ...issues]

        }), generic)
    }
}
