import { State } from 'apprise-frontend-core/state/api';
import { StateProvider } from 'apprise-frontend-core/state/provider';
import { utils } from 'apprise-frontend-core/utils/common';
import { createContext, PropsWithChildren } from 'react';
import { BytestreamRef } from '../model';


export type StreamBinding = {

    stream: BytestreamRef
    file: Blob
}


/**
 * holds temporary bindings between streams and files, 
 * and optionally the target and tenant common to all the streams.
 * 
 * this is all the contextual information required to upload streams and along with the bound files.
 */
export type BindingState = {

    bindings: StreamBinding[]

}


export const StreamContext = createContext<State<BindingState>>(undefined!)

export const initialStreamState: BindingState = {

    bindings: []

}

export const StreamBindingProvider = (props: PropsWithChildren<Partial<BindingState>>) => {

    const { children, ...rest } = props

    const initialState = utils().merge(initialStreamState, rest)

    return <StateProvider initialState={initialState} context={StreamContext}>
        {children}
    </StateProvider>

}





