import { useAuthorizationValidator } from '#authorization/validator';
import { useDetailValidator } from '#details/validator';
import { usePhotographValidator } from '#photographs/validator';
import { RecordIdentifier } from '#record/model';
import { RecordValidator, useGenericValidator } from '#record/validator';
import { RecordIssue } from '#submission/validator';
import { BaseComboPatch, baseComboType } from './mode';



export const useBaseComboValidation = (): RecordValidator<BaseComboPatch> => {

    const detailIValidator = useDetailValidator()
    const authValidator = useAuthorizationValidator()
    const photoValidator = usePhotographValidator()

    const combinedValidator = (data,submission) => {

        const detailIssues = detailIValidator(data,submission)
        const photoIssues = photoValidator(data,submission)
        const authIssues = authValidator(data,submission)

        return Object.keys(detailIssues).reduce((acc, key) => ({

            ...acc, [key]: [...detailIssues[key], ...photoIssues[key], ...authIssues[key]]

        }), {} as Record<RecordIdentifier, RecordIssue[]>)

    }


    return useGenericValidator(baseComboType, combinedValidator)


}
