import { SlotType } from '#record/model'
import { CategoryLabel, CategoryLabelProps } from 'apprise-frontend-tags/category/label'
import { CSSProperties } from 'react'
import { IconBaseProps } from 'react-icons'
import { BiBlock, BiLayer } from 'react-icons/bi'
import { RiForbidFill } from 'react-icons/ri'
import { SiNintendoswitch } from 'react-icons/si'
import { TiFlowMerge } from "react-icons/ti";




export const delistingType = 'delisting'

export const delistingPatchedTypes : SlotType[]  = [delistingType]

export const DelistingIcon = BiBlock
export const DelistingFullIcon = RiForbidFill

export const delistColor: CSSProperties['color'] = 'orangered'

export const delistingReasonCategory = 'TGC-delisting-reason'
export const delistingReasonAuto = 'TG-reason-auto'
export const delistingReasonScrapped= 'TG-reason-scrapped'
export const delistingReasonDeflagged= 'TG-reason-deflagged'

export const DelistingReasonIcon= BiLayer

export const DelistingReasonLabel = (_: CategoryLabelProps) => <CategoryLabel bare icon={<DelistingReasonIcon />} category={delistingReasonCategory} {..._} />

export const ClaimIcon = (_: IconBaseProps) =>  <SiNintendoswitch color={claimColor} {..._} />

export const claimColor: CSSProperties['color'] = 'lightseagreen'

export const MergeIcon = TiFlowMerge