import { useSubmissionRouting } from '#submission/routing'
import { recordRoute } from '#vessel/routing'
import { useT } from 'apprise-frontend-core/intl/language'
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant'
import { Button } from 'apprise-ui/button/button'
import { useRoutableDrawer } from 'apprise-ui/drawer/drawer'
import { Page } from 'apprise-ui/page/page'
import { SearchIcon, UploadIcon } from 'apprise-ui/utils/icons'
import './home.scss'
import { useToolBridge } from 'apprise-frontend-core/utils/toolbridge'


export const Home = () => {

    const t = useT()

    const logged = useTenancyOracle()
    const submissions = useSubmissionRouting()

    const { absoluteOf } = useToolBridge()

    const { openSet } = useRoutableDrawer('help');

    return <Page className='rav-home custom-scrollbar dark-custom-scrollbar' fullBleed >

        <div className="home-title">
            <img className="home-ico" alt="logo" src={ absoluteOf('/images/logo.png') } />
        </div>

        <div className='home-blurb'>
            <div className='home-resolution'>
                {t('rav.home_resolution')}
             </div>
            <div className='home-btns'>
                <Button type='primary' size='large' iconPlacement='left' icon={<SearchIcon color='cyan' />}  linkTo={recordRoute}>{t('rav.home_search')}</Button>
                {logged.isGuest() || 
                    <Button type='primary'  size='large' iconPlacement='left' icon={<UploadIcon color='cyan' />} onClick={() => submissions.routeToNewDetail()}>{t('rav.home_submissions')}</Button>
                }
            </div>

            <Button type='ghost' className='home-help' onClick={() => openSet(true)}>{t('rav.home_help')}</Button> 
        
        </div>

    </Page>
}
