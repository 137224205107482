import { Card } from '#record/card';
import { Asset } from '#submission/model';
import { useValidationIssueIndex } from '#submission/validator';
import { useT } from 'apprise-frontend-core/intl/language';
import { useL } from 'apprise-frontend-core/intl/multilang';
import { Lifecycle } from 'apprise-frontend-core/utils/lifecycle';
import { ImageLoader } from 'apprise-frontend-streams/imageloader/imageloader';
import { isBytestream } from 'apprise-frontend-streams/model';
import { Tag } from 'apprise-frontend-tags/tag/model';
import { useTagStore } from 'apprise-frontend-tags/tag/store';
import { Tall, Wide } from 'apprise-ui/component/model';
import { Grid } from 'apprise-ui/grid/grid';
import { Label } from 'apprise-ui/label/label';
import { FileSizeLabel } from 'apprise-ui/utils/filesizelabel';
import { NamedProperty } from 'apprise-ui/utils/namedproperty';
import { NoPhotoIcon } from './constants';
import { Photo } from './model';
import "./photocard.scss";



export type PhotoCardProps = Wide & Tall & {

    tag?: Tag

    photo?: Photo

    pastphoto?: Photo

    showMetadata?: boolean
}

export const PhotoCard = (props: PhotoCardProps) => {

    const t = useT()
    const l = useL()

    const tags = useTagStore()

    const { photo = { type: '', ref: '' }, pastphoto, width, height = 220, showMetadata } = props

    const { type, ref } = photo

    const tag = props.tag || tags.safeLookupTag(type)

    const unknownPlaceholder = <span style={{ color: 'lightgrey' }}>{t('photograph.img_unknown')}</span> as any

    const unknownAsset = ref => ({

        id: 'unknown', lifecycle: {} as Lifecycle, type: unknownPlaceholder, size: 0, properties: { archive: undefined, path: typeof ref === 'string' ? ref : '' }

    })

    const image = (isBytestream(ref) ? ref : unknownAsset(ref)) as Asset

    const pastImage = (isBytestream(pastphoto?.ref) ? pastphoto!.ref : unknownAsset(pastphoto?.ref)) as Asset

    const issues = useValidationIssueIndex()

    const issue = issues[tag.id]

    // replace with full photo card (picture and metadat)
    return <Card className='photo-card'>
        <Grid rowGap={5}>

            <Grid.Row>
                <NamedProperty previously={image.id === 'unknown' ? undefined : pastImage?.size} currently={image.size}>
                    {l(tag.name)}
                </NamedProperty>
            </Grid.Row>

            <Grid.Row columnGap={10} >

                <Grid.Col className='photo-box' width="auto">
                    <ImageLoader stream={ref} width={width} height={height} placeholder={<NoPhotoIcon className='photo-placeholder' />} />
                </Grid.Col>

                {showMetadata && <Grid.Col rowGap={3}>

                    <Grid.Row>
                        <NamedProperty status={issue} noFixedHeight name={t('photograph.img_name')}>
                            <Label noIcon unknown title={image.name ?? image.properties.path} />
                        </NamedProperty>
                    </Grid.Row>
                    <Grid.Row>
                        <NamedProperty name={t('photograph.img_type')}>
                            {image.type}
                        </NamedProperty>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Col>
                            <NamedProperty noFixedHeight name={t('photograph.img_archive')}>
                                {image.properties.archive || unknownPlaceholder}
                            </NamedProperty>
                        </Grid.Col>
                        {image.properties.archive && <Grid.Col>
                            <NamedProperty noFixedHeight name={t('photograph.img_path')}>
                                {image.properties.path}
                            </NamedProperty>
                        </Grid.Col>}
                    </Grid.Row>
                    <Grid.Row>
                        <NamedProperty status={issues[`${tag.id}-quality`]} previously={image.id === 'unknown' ? undefined : pastImage?.size} currently={image.size} name={t('photograph.img_size')}>
                            {image.size ? <FileSizeLabel size={image.size} /> : unknownPlaceholder}
                        </NamedProperty>
                    </Grid.Row>
                    <Grid.Row>
                        <NamedProperty status={issues[`${tag.id}-quality`]} previously={`${pastImage?.properties.width}${pastImage?.properties.height}`} currently={`${image?.properties.width}${image?.properties.height}`} name={t('photograph.img_resolution')}>
                            {image.properties.width ? `${image?.properties.width}x${image?.properties.height}` : unknownPlaceholder}
                        </NamedProperty>
                    </Grid.Row>
                </Grid.Col>
                }
            </Grid.Row>
        </Grid>
    </Card >
}
