
import { Empty } from 'antd'
import { useChangeHelper } from 'apprise-ui/field/changehelper'
import { Field, useFieldProps } from 'apprise-ui/field/field'
import { ChangeTracked, Fielded, FieldInfo } from 'apprise-ui/field/model'
import { useReadonlyHelper } from 'apprise-ui/field/readonlyhelper'
import { useResetHelper } from 'apprise-ui/field/resethelper'
import { Fields } from 'apprise-ui/field/validation'
import React, { FC } from 'react'
import "./styles.scss"

export type ValueField<T> = FC<Fielded<T> & { children: T }>

type Validators<R extends Record<string, T>,T> = Record<string, (value: T, record: R) => FieldInfo>

export type RecordBoxProps<R extends Record<string, T>, T =any> = Fielded<R> & ChangeTracked<R> & Partial<{

    children: R

    keys: string[] | ((_:T) => string[])

    renderKey: (_: string) => React.ReactNode
    renderValue: (value: T, key: string) => JSX.Element
    validate: Validators<R,T>

    field: ValueField<T> | Record<string, ValueField<T>>

}>


export const useRecordFields = <R extends Record<string, T>, T =any> (validators: Validators<R,T>) => (record: R = {} as R) => {

    const fieldInfo = Object.keys(validators).reduce((acc, key) => ({ ...acc, [key]: validators[key](record[key],record) ?? { status: 'success' } }), {} as Record<string, FieldInfo>)
    
    const keysWithIssues = Object.keys(fieldInfo).filter(key=> fieldInfo[key]?.status ==='error' || fieldInfo[key]?.status ==='warning')

    const valueOf = (info: FieldInfo) => info.status === 'warning' ? 1 : 2
   
    const orderedKeys = keysWithIssues.sort((key1, key2) => valueOf(fieldInfo[key2]) - valueOf(fieldInfo[key1]))
    
    return orderedKeys.reduce((acc, key) =>  ({...acc, [key]: fieldInfo[key]}), {} as Fields )
    
}

export const RecordBox = <R extends Record<string, T>, T extends any>(clientprops: RecordBoxProps<R, T>) => {

    const props = useFieldProps(clientprops)

    const { children, defaultValue, keys, renderKey, field : fieldComponent = (() => null) as ValueField<T>, renderValue, validate = {} } = props

    const currentValue = children ?? defaultValue ?? {} as R

    const { pastMode, pastValue, } = useChangeHelper(props)
    
    useReadonlyHelper(props)

    useResetHelper(props)


    const { innerStyle, innerClassName, disabled, onChange, info,  ...rest } = props

    const latestValue = pastMode ? pastValue : currentValue

    const onValueChange = (key: string) => (v: T | undefined) => onChange?.({ ...currentValue, [key]: v! })

    const recordKeys = keys ? (typeof keys === 'function'  ? keys(latestValue) : keys) : Object.keys(currentValue)

    const fieldInfo = Object.keys(validate).reduce((acc, key) => ({ ...acc, [key]: validate[key]?.(latestValue[key],latestValue) ?? { status: 'success' } }), {} as Record<string, FieldInfo>)
    
    const fieldsWithIssues =  useRecordFields(validate)(latestValue)

    const firstIssue =  Object.entries(fieldsWithIssues)[0]?.[1]

    const recordInfo = {  ...info, ...firstIssue }

    const valueprops = (key, val) => ({

        className: innerClassName,
        style: innerStyle,
        disabled,
        noReadonly: props.noReadonly || !props.readonly,
        readonly: props.readonly,
        onChange: onValueChange(key),
        defaultValue: defaultValue?.[key],
        children: val,
        info: { status: fieldInfo[key]?.status  }
    })

    clientprops.debug && console.log({ defaultValue, latestValue, pastValue })

    return <Field name='recordbox' {...rest} {...recordInfo} >{   // spread computed info to override to-level client props.

        recordKeys.length === 0 ?

            <Empty description={null} style={{ margin: "15px 0px 5px" }} image={Empty.PRESENTED_IMAGE_SIMPLE} />

            :

            recordKeys.map((key, idx) => {

                const value = latestValue[key]

                let field: JSX.Element

                if (renderValue) {

                    const element = renderValue(value, key)

                    // injects only props that haven't been already set by client.
                    const filteredProps = Object.entries(valueprops(key, value)).reduce((acc, [k, v]) => element.props[k] ? acc : { ...acc, [k]: v }, {})

                    field = React.cloneElement(element, filteredProps)

                }
                else {
                    
                    // A special case is when components are wrapped with forwardRef (cf. TextBox)
                    // in this case the component is not a function but an object with a special type and a render function.
                    const isForwardRefComponent = (fieldComponent as any)['$$typeof']===Symbol.for("react.forward_ref")

                    const Component =  (typeof fieldComponent === 'function' || isForwardRefComponent ? fieldComponent :  fieldComponent[key]) as FC<any>


                    field = <Component {...valueprops(key, value)} />                
                }

                const renderedKey = renderKey?.(key)

                return [

                    <div key={`name-${idx}`} className='map-key'>
                        {(renderedKey as any)?.props ? renderedKey : <div className='default-key'>{renderedKey ?? key}</div>}
                    </div>
               
                    ,
                    
                    <div key={`value-${idx}`} className='map-value'>
                        {field}
                    </div>
                ]
            })

    }

    </Field>

}
