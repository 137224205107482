import { RavConfig } from '#config';
import { useAppSettings } from '#settings/settings';
import { useConfig } from 'apprise-frontend-core/config/api';
import { useT } from 'apprise-frontend-core/intl/language';
import { useCategoryStore } from 'apprise-frontend-tags/category/store';
import { TagReference } from 'apprise-frontend-tags/tag/model';
import { useTagStore } from 'apprise-frontend-tags/tag/store';




/** a vessel identifier, namespaced by its scheme. */
export type Vid = string

export const vidSchemeCategory = 'TGC-vid-scheme'


const schemeSeparator = '://'
const IMORegexp = /^[0-9]{7}$/

export const useVIDUtils = () => {

    const t = useT()

    const settings = useAppSettings()

    const specialValues = [t('vid.pending_value'), t('vid.ne_value')/* , t('vid.na_value') */]

    const self = {

        stringify: (scheme: TagReference, value?: string) => scheme ? `${scheme}${schemeSeparator}${value ?? ''}` : value ?? ''

        ,

        parse: (vid?: Vid) => {


            // splits where possible, otherwise fallbacks to vid=value
            const [scheme, value] = (vid?.includes(schemeSeparator) ? vid?.split(schemeSeparator) : [undefined, vid]) ?? [undefined, undefined]

            return { scheme, value }
        }

        ,

        isSpecialValue: (vid?: Vid) => specialValues.includes(self.parse(vid).value ?? '')

        ,

        isPending: (vid?: Vid) => self.parse(vid).value === t('vid.pending')

        ,

        isNotEligible: (vid?: Vid) => self.parse(vid).value === t('vid.ne')

        ,

        isNotApplicable: (vid?: Vid) => self.parse(vid).value === t('vid.na')

        ,

        valueOf: (vid?: Vid) => self.parse(vid).value

        ,


        find: (scheme: TagReference, vids: Vid[] | undefined) => self.parse(vids?.find(v => self.parse(v).scheme === scheme))?.value

        ,

        isValidIMO: (imo: string) => {


            if (!IMORegexp.test(imo))
                return false

            if (settings.validateIMOChecksum) {
            
                const sum = `${Array.from({ length: 6 }).map((_, i) => i).reduce((sum, i) => sum + (parseInt(imo.charAt(i)!) * (7 - i)), 0)}`

                return sum.charAt(sum.length - 1) === imo.charAt(6)
            }

            return true
        }

    }

    return self
}

export const useVID = () => {

    const utils = useVIDUtils()
    const config = useConfig<RavConfig>()

    const cats = useCategoryStore()
    const tags = useTagStore()

    const primaryScheme = useAppSettings().primaryScheme

    const self = {

        ...utils

        ,

        primaryOf: (value: string) => self.stringify(primaryScheme, value)

        ,

        schemeOf: (vid?: Vid) => tags.lookupTag(self.parse(vid).scheme)

        ,

        schemeCategory: () => cats.safeLookupCategory(vidSchemeCategory)

        ,

        allSchemes: (includePrimary?: boolean) => tags.allTagsOfCategory(vidSchemeCategory).filter(t => t.lifecycle.state === 'active' && (includePrimary ? t : t.id !== config.primaryUVI))

    }


    return self
}
