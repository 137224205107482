import { detailsType } from '#details/constants';
import { RecordValidator, useGenericValidator } from '#record/validator';
import { useAppSettings } from '#settings/settings';
import { RecordIssue } from '#submission/validator';
import { useT } from 'apprise-frontend-core/intl/language';
import { useL } from 'apprise-frontend-core/intl/multilang';
import { utils } from 'apprise-frontend-core/utils/common';
import { isBytestream } from 'apprise-frontend-streams/model';
import { Tag } from 'apprise-frontend-tags/tag/model';
import { useTagStore } from 'apprise-frontend-tags/tag/store';
import { photographsTypeCategory, photographType } from './constants';
import { PhotographsPatch, usePhotoQualityHelper } from './model';



export const usePhotographValidation = (): RecordValidator<PhotographsPatch> => {

    const validator = usePhotographValidator()

    return useGenericValidator(photographType, validator)
}


export const usePhotographValidator = (): RecordValidator<PhotographsPatch> => {

    const l = useL()
    const t = useT()

    const lowQualityMessage = usePhotoQualityHelper()

    const { photosRequired } = useAppSettings()

    const severity = photosRequired ? 'error' : 'warning'

    const tags =  useTagStore()

    return data => data.reduce((acc, { patch, current }) => {

        const issues: RecordIssue[] = []

        const { photos } = patch[photographType]
        const { photos:previousPhotos } = current?.[photographType] ?? {}

        // we expect photos if we have none to fallback to or key details have changed (name)
        const expectPhotos = previousPhotos && ( patch[detailsType] && [
            [patch.tenant, current?.tenant],
            [patch[detailsType]?.name, current?.[detailsType]?.name],
            [patch[detailsType]?.identifiers, current?.[detailsType]?.identifiers],
            [patch[detailsType]?.port, current?.[detailsType]?.port]

        ].find(([p, c]) => !utils().deepequals(p, c)))

        const phototags = tags.allTagsOfCategory(photographsTypeCategory, true)

        const validate = (tag: Tag) => {

            const photoref = photos.find(p => p.type === tag.id)?.ref

            // we have at least a reference.
            if (photoref) {

                // resolved image, check quality.
                if (isBytestream(photoref)){

                    const message = lowQualityMessage(photoref, tag.id)

                    if (message)
                        issues.push({ message, type: 'warning', location: `${tag.id}-quality` })

                }
                    
                // unresolved reference.
                else  

                    issues.push({ message: t('valid.unresolved_photo', { ref: photoref }), type: severity, location: tag.id })


            }

            else
                 issues.push({ message: t( expectPhotos ? 'valid.expected_photo' : 'valid.missing_photo' , { type: l(tag.name) }), type: severity, location: tag.id })



        }

        // settings-driven global check in case there are no photos (missing or unresolved) 
        // AND we have no previous photo to fallback to.
        if (
            !photos.some(p => isBytestream(p.ref)) 
            && (!previousPhotos || !previousPhotos?.some(p => isBytestream(p.ref)))
        )
            issues.push({ message: t('valid.missing_photos'), type:  severity })
        else
            phototags.forEach(validate)



        return { ...acc, [patch.id]: issues }

    }, {})
}
