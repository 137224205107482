import { useT } from 'apprise-frontend-core/intl/language';
import { elementProxyRole } from 'apprise-frontend-core/utils/common';
import { Label } from 'apprise-ui/label/label';
import { ColumnProps, TableElement } from 'apprise-ui/table/table';
import { useTableUtils } from 'apprise-ui/table/utils';
import { TenantIcon, tenantSingular } from './constants';
import { TenantLabel, TenantLabelProps } from './label';
import { TenantReference } from './model';
import { useTenantUtils } from './utils';



export const TenantColumn = <T extends TableElement> (props: ColumnProps<T> & {

    renderProps?: Partial<TenantLabelProps>,
    tenantOf: (_:T) => TenantReference


}) => {

    const t = useT()
    const model = useTenantUtils()

    const {tenantOf, renderProps, ...rest} = props

    const { Column, applyComparator} = useTableUtils<T>()

    const text = (t:T) => t && model.textOf(tenantOf(t))
    const sort = applyComparator(model.refComparator).to(tenantOf)
    const render = (t:T) => <TenantLabel bare tenant={tenantOf(t)} {...renderProps} />
   
    return <Column width={180} name='tenant' text={text} sort={sort} title={<Label icon={<TenantIcon />} title={t(tenantSingular)} />} render={render} {...rest}/>

}

TenantColumn[elementProxyRole] = true