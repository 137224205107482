
import { RavConfig } from '#config'
import { searchSettingsModule, systemSettingsModule, useDevSettings, useValidationSettings } from '#modules/settings'
import { useConfig } from 'apprise-frontend-core/config/api'
import { useSettings } from 'apprise-frontend-streams/settings/api'
import { TagReference } from 'apprise-frontend-tags/tag/model'
import { ApproveCycle } from './approvecyclebox'

export type DateFormatOption = {
    name: string
    format: string
}

export type ImageSizeOption = {
    name: string
    val: number
}

export type AppSettings = {

    primaryScheme: TagReference
    secondaryScheme: TagReference
    approveCycle?: ApproveCycle    
    autodelisting: boolean,
    autodelistThreshold: number | undefined,
    autodelistMessage: string | undefined
    dateFormat: string
    defaultColumns: string[]
    minPhotoQuality: number
    validatePhotoQuality: boolean
    check: number
    photosRequired: boolean
    excludedFlags: string[] | undefined
    validateIMOChecksum: boolean
    exportEnabled: boolean
    
}

export const useAppSettings = () => {

    const { dateFormats } = useConfig<RavConfig>()

    const systemsettings = useSettings<AppSettings>(systemSettingsModule.type)
    const searchsettings = useSettings<AppSettings>(searchSettingsModule.type)
    const validationSettings = useSettings<AppSettings>(useValidationSettings().type)
    const devsettings = useSettings<AppSettings>(useDevSettings().type)

    const self = {

        ...systemsettings

        ,

        ...searchsettings

        ,

        ...validationSettings

        ,

        ...devsettings

        ,

        resolveFormat: () => dateFormats[systemsettings.dateFormat]?.format,

    }


    return self

}

