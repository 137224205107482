import { useValidationIssueIndex } from '#submission/validator';
import { DateLabel } from '#utils/datelabel';
import { SlotCardProps } from '#vessel/overview';
import { useT } from 'apprise-frontend-core/intl/language';
import { Grid } from 'apprise-ui/grid/grid';
import { NamedProperty } from 'apprise-ui/utils/namedproperty';
import { authorizationType } from './constants';
import { AuthorizationPatch } from './model';


export const AuthorizationOverview = (props: SlotCardProps<AuthorizationPatch>) => {

    const t = useT()

    const { showChange, isCurrent,  record } = props

    const previous = showChange ? props.previous?.authorization : undefined

    const authorization = record[authorizationType]

    const issues = useValidationIssueIndex()

    return <Grid rowGap={8} columnGap={10} >
        <Grid.Row height='auto'>
            <Grid.Col>
                <NamedProperty status={issues.from} previously={previous?.from} currently={authorization.from} name={t('authorization.from_col')}>
                    <DateLabel noIcon noTip date={authorization.from} />
                </NamedProperty>
            </Grid.Col>
            <Grid.Col>
                <NamedProperty status={issues.to} previously={previous?.to} currently={authorization.to} name={t('authorization.to_col')}>
                    <DateLabel debug noTip displayMode={ isCurrent ? 'expiry' : 'normal' } noIcon date={authorization.to} />
                </NamedProperty>
            </Grid.Col>
        </Grid.Row>

    </Grid>
}
