import { detailsType } from '#details/constants'
import { coreSlotTypes, GenericRecord, RavRecord, SlotType, slotTypes } from '#record/model'
import { useRecordPlugin } from '#record/plugin'
import { trxauthzType } from '#trxauth/constants'
import { useT } from 'apprise-frontend-core/intl/language'
import { utils } from 'apprise-frontend-core/utils/common'
import { FormState } from 'apprise-frontend-core/utils/form'
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant'
import { Button } from 'apprise-ui/button/button'
import { classname, Styled } from 'apprise-ui/component/model'
import { useRoutableDrawer } from 'apprise-ui/drawer/drawer'
import { useValidation } from 'apprise-ui/field/validation'
import { Form } from 'apprise-ui/form/form'
import { Topbar } from 'apprise-ui/topbar/topbar'
import { EditIcon, RevertIcon } from 'apprise-ui/utils/icons'
import { Placeholder } from 'apprise-ui/utils/placeholder'
import React, { useContext } from 'react'
import { SlotViewer } from '../record/slotviewer'
import { editModeParam } from './constants'
import { DetailContext } from './context'
import { useVesselOracle } from './oracle'
import { useVesselRouting } from './routing'




export type SlotCardProps<T extends GenericRecord> = Styled & {

    showChange: boolean
    record: T
    isCurrent: boolean

    previous: T
}


export type SlotFormProps = FormState<RavRecord> & {

    isNew:boolean
    current: RavRecord
}


export const Overview = () => {

    const { age, vessel, historySlot, current } = useContext(DetailContext)

    const routing = useVesselRouting()
    const logged = useTenancyOracle()

    const slots = coreSlotTypes.filter(type => logged.isGuest() ? current[type] : type)

    const typedHistories = React.useMemo(() =>

        slotTypes.reduce((acc, type) => ({ ...acc, [type]: vessel.history.filter(record => record[type]) }), {} as Record<SlotType, RavRecord[]>)


        , [vessel])


    const toggleMode = (type: SlotType) => () => routing.routeToInnerDetail(age, historySlot === type ? undefined : type)


    return <div className="vessel-overview ">

        {slots.map(type =>

            <SlotOverview key={type} age={age} historyForType={typedHistories[type]} historyMode={historySlot === type} onModeToggle={toggleMode(type)} type={type} />
        )}

    </div>

}


export const TranshipmentOverview = () => {

    const { age, vessel, historySlot } = useContext(DetailContext)

    const routing = useVesselRouting()
    const typedHistory = vessel.history.filter(record => record[trxauthzType])

    const toggleMode = () => routing.routeToInnerDetail(age, historySlot === trxauthzType ? undefined : trxauthzType)

    const { record } = useContext(DetailContext)

    const { Overview } = useRecordPlugin(trxauthzType)
    

    // no overview => no form, convince typechecker both exist below.
    if (!Overview)
        return null

    return <div className="vessel-overview ">

        <SlotViewer noTitle current={record} historyForType={typedHistory} historyMode={historySlot === trxauthzType} onModeToggle={toggleMode} type={trxauthzType}
            onRecordClick={age => historySlot && routing.routeToInnerDetail(age)}
            trackMode='history'

            render={({ showChange, record, previous, age }) => {

                return <Overview showChange={showChange} isCurrent={age === 0} record={record} previous={previous!} />

            }}>

        </SlotViewer>

    </div>

}


type TypeProps = {

    type: SlotType
    historyMode: boolean
    onModeToggle: () => any
    age?: number
    historyForType: RavRecord[]
}



const SlotOverview = (props: TypeProps) => {

    const t = useT()

    const validation = useValidation()
    const oracle = {...useTenancyOracle(), ...useVesselOracle()}

    const { isNew, current, form, record, groupedfields } = useContext(DetailContext)

    const { edited, initial, dirty, set, reset } = form

    const { type, historyMode } = props

    const routing = useVesselRouting()
    const logged = useTenancyOracle()

    const { Overview, Icon, Form: SlotForm, plural, newSlot } = useRecordPlugin(type)

    const editParam = `${editModeParam}-${type}`

    const { Drawer: EditDrawer, openSet } = useRoutableDrawer(editParam)

    // no overview => no form, convince typechecker both exist below.
    if (!Overview || !SlotForm)
        return null

    const editSlot = () => openSet(true)

    // escape hatch to claim vessels.
    const canEditDetailsOnReadonly = type===detailsType && oracle.hasNoTenant() &&  oracle.canClaim(current)

    const editBtn = <Button key='edit' noReadonly={canEditDetailsOnReadonly} tipPlacement='left' tip={t('vessel.edit_tip', { plural })} type='ghost' noLabel icon={<EditIcon />} onClick={editSlot} />

    // revert is local to slot, but when we claim we change also the tenant outside the slot. in that case we do a full reset.
    const revert = () => type === detailsType && initial.tenant !== edited.tenant ? reset.toInitial.quietly() : set.using(m => { m[type] = initial[type] as any })

    const revertBtn = <Button key='revert' enabled={dirty} icon={<RevertIcon />} onClick={revert}>
        {t('vessel.revert_slot_btn')}
    </Button>


    const slotErrors = (dirty || isNew) && validation.reportOf(groupedfields[type]).hasErrors()
    const slotWarnings = (dirty || isNew) && validation.reportOf(groupedfields[type]).hasWarnings()


    return <SlotViewer className={classname(slotErrors ? 'slot-has-errors' : slotWarnings && 'slot-has-warnings')} current={record} {...props}

        extraControls={logged.isGuest() ? [] : [editBtn]}
        onRecordClick={age => historyMode && routing.routeToInnerDetail(age)}
        trackMode='history'

        render={({ showChange, record, previous, age }) => {

            // while it's convenient to prefill 'blank' slots, we want to detect them and render the placeholder.
            // we detect blank slot by comparison (but without generated id). 
            const { id: recordId, timestamp: rts, ...slot } = record[type] ?? {}
            const { id: slotId, timestamp: bts, ...blankSlot } = newSlot()

            // showing current record and slot is initial.
            const showPlaceholder = age === 0 && (utils().deepequals(slot, blankSlot))

            return showPlaceholder ?

                <Placeholder>
                    <Button type='link' iconPlacement='left' icon={<EditIcon />} onClick={(e) => {
                        e?.stopPropagation()
                        editSlot()
                        
                        }}>
                        {t('vessel.edit_placeholder', { plural })}
                    </Button>
                </Placeholder>

                :

                <div onDoubleClick={() => age === 0 && editSlot()}>
                    <Overview showChange={showChange} isCurrent={age === 0} record={record} previous={previous!} />
                </div>

        }}>

        <EditDrawer width={700} icon={<Icon />} title={t('vessel.edit_drawer', { plural })}>
            <Topbar>
                {revertBtn}
            </Topbar>
            <Form>
                <SlotForm isNew={isNew} current={current} {...form} />
            </Form>
        </EditDrawer>

    </SlotViewer>

}
