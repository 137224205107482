import { useLanguage, useT } from "apprise-frontend-core/intl/language"
import { useAccounts } from 'apprise-frontend-iam/account/accounts'
import { AccountLabel } from 'apprise-frontend-iam/account/label'
import { useTenancyOracle } from "apprise-frontend-iam/authz/tenant"
import { useTenantRouting } from 'apprise-frontend-iam/tenant/routing'
import { useTenantStore } from "apprise-frontend-iam/tenant/store"
import { TenantColumn } from 'apprise-frontend-iam/tenant/tenantcolumn'
import { useCategoryStore } from "apprise-frontend-tags/category/store"
import { useTagStore } from "apprise-frontend-tags/tag/store"
import { TagViewColumn } from "apprise-frontend-tags/tag/tagcolumn"
import { Label } from "apprise-ui/label/label"
import { LanguageLabel } from 'apprise-ui/language/label'
import { useTableUtils } from "apprise-ui/table/utils"
import { userType } from "./constants"
import { UserLabel } from "./label"
import { UserTableProps } from './list'
import { User, useUserModel } from "./model"
import { useUserPreferencesModules } from './modules'


export const useUserColumns = (props: UserTableProps) => {

    const t = useT()

    const { tenant } = props

    const routing = useTenantRouting()
    const model = useUserModel()

    const tagStore = useTagStore()

    const accounts = useAccounts()

    const oracle = useTenancyOracle()

    const tenants = useTenantStore()

    const categories = useCategoryStore()

    const preferences = useUserPreferencesModules().all()

    const lang = useLanguage()

    const { compareStringsOf, columnsOf, Column } = useTableUtils<User>()

    return columnsOf(

        <Column name="name" defaultLayout pinned width={250} title={t('user.name_col')} text={model.fullName} sort={model.comparator} render={u =>

            tenant ?

                <UserLabel linkTo={routing.userInTenantRoute(tenant.id, u.username)} user={u} />

                :

                <UserLabel user={u} />


        } />,

        oracle.managesMultipleTenants() && tenants.all().length &&

        <TenantColumn<User> tenantOf={u => u.tenant} defaultLayout />,

        <Column defaultLayout name="email" width={300} title={t('user.email_col')} text={u => u.details.email} sort={compareStringsOf(u => u.details.email)} render={u => <Label noIcon title={u.details.email} />} />,

        // categories of type user get a column each.
        ...categories.allCategoriesOf(userType).map(cat => <TagViewColumn name={cat.id} filter={cat.id} category={cat} />),

        ...preferences.flatMap(p => p.columns ?? []),

        lang.supported.length > 1 &&

        <Column name="lang" title={t('user.lang_col')} sort={compareStringsOf(u => u.details.preferences.language)} render={u => <LanguageLabel displayMode='code' language={u.details.preferences.language ?? model.defaultLanguage(u)} />} />,

        // category-less tags of type user are lumped in a single column.       
        tagStore.allTagsOf(userType).length && <TagViewColumn />

        ,
    
        <Column defaultLayout width={200}  name="account" title={t('user.account_col')} sort={compareStringsOf(u => u.details.preferences.accountState)} 
                
            render={u => <AccountLabel displayMode='short' account={{...accounts.accountFor(u), state: u.details.preferences.accountState ?? 'unknown'}} user={u} />}
            
        />


    )


}