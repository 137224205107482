import { useRavRecordMocks } from '#record/mockery'
import { RavRecord } from '#record/model'
import { vesselApi } from '#vessel/calls'
import { Vessel } from '#vessel/model'
import { Vid } from '#vid/model'
import { useUserMocks } from 'apprise-frontend-iam/user/mockery'
import MockAdapter from 'axios-mock-adapter/types'


export const useVesselMockery = () => {

    const regapi = RegExp(`${vesselApi}/.+$`)
    const users = useUserMocks()

    const records = useRavRecordMocks()

    return (mock: MockAdapter) => {

        console.log("mocking vessels...")

        mock.onGet(regapi).reply(({ url }) => {

            const uvi = decodeURIComponent(url!.split('/').pop() ?? '') as Vid

            const history = records.findRecords().historical().for(uvi).now()

            if (history.length===0)
                return [404, { message: `no such vessel '${uvi}'`}]

            return [200, {uvi,history} as Vessel]
        })


        mock.onPost(regapi).reply(({ data }) => {

            const record = JSON.parse(data) as RavRecord

            record.lifecycle.state='submitted'
            record.lifecycle.created= Date.now()
            record.lifecycle.lastModified = record.lifecycle.created
            record.lifecycle.lastModifiedBy = users.logged().username

            const added = records.store().add(record)

            console.log(`added record for ${record.uvi}`,{record})

            return [200, added]
        })

        
        mock.onDelete(RegExp(`${vesselApi}/.+/.+$`)).reply(({ url }) => {

            const id = decodeURIComponent(url!.split('/').pop() ?? '')
 
            records.store().delete(id)

            return [200]
        })


    }


}