import { imoscheme, ircsscheme, regnoscheme } from '#iotc/constants'
import { ExcelRecordWriter, useGenericExcelSerializer } from '#record/excelserializer'
import { useVID } from '#vid/model'
import { useT } from 'apprise-frontend-core/intl/language'
import { useTagStore } from 'apprise-frontend-tags/tag/store'
import { contactTypeCategory, grtTag, loaTag, operatingCompanyTag, specificationCategory, tonnageTag, totalVolumeTag, totalVolumeTonnesTag } from './constants'
import { DetailsPatch } from './model'


export const useDetailsExcelSerializer = () => useGenericExcelSerializer(useDetailsExcelWriter())

export const useDetailsExcelWriter = () => {

    const t = useT()
    const vids = useVID()

    const tags = useTagStore()

    const serializer: ExcelRecordWriter<DetailsPatch> = (props) => {

        const { output, mode,
            writeTenant, writeTenantCode, writeTenantName, 
            writeInternalTag, 
            writeDomainTag, writeDomainTagCode, writeDomainTagName, 
            writePort, writePortCode, writePortName } = props

        const specs = tags.allTagsOfCategory(specificationCategory)

        const regnoVid = vids.allSchemes().find(vid => vid.id === regnoscheme)!
        const ircsVid = vids.allSchemes().find(vid => vid.id === ircsscheme)!
        const imoVid = vids.allSchemes().find(vid => vid.id === imoscheme)!

        const loaSpec = specs.find(spec => spec.id === loaTag)
        const tonnageSpec = specs.find(spec => spec.id === tonnageTag)
        const grtSpec = specs.find(spec => spec.id === grtTag)
        const totVolumeSpec = specs.find(spec => spec.id === totalVolumeTag)
        const totVolumeTonnesSpec = specs.find(spec => spec.id === totalVolumeTonnesTag)


        if (mode === 'codes' || mode === 'names') {
            output.text(t('search.vesseltype_col')).render(r =>writeDomainTag(r.details.vesselType))
            .text(t('search.vesselname_col')).render(r => r.details.name)
            .text(t('search.tenant_col')).render(r => writeTenant(r.tenant))
            

            output.text(t('search.vesselrange_col')).render(r => writeDomainTag(r.details.range))
            .text(writeInternalTag(vids.valueOf(regnoVid.id))).render(r => vids.find(regnoVid.id, r.details.identifiers))
            .text(writeInternalTag(vids.valueOf(imoVid.id))).render(r => vids.find(imoVid.id, r.details.identifiers))
            .text(writeInternalTag(vids.valueOf(ircsVid.id))).render(r => vids.find(ircsVid.id, r.details.identifiers))
            .text(t('search.vesselport_col')).render(r => writePort(r.details.port))
            .text(t('search.vesselkind_col')).render(r =>writeDomainTag(r.details.vesselKind))
            .text(t('search.vesselgear_col')).render(r => r.details.gears.map(gear => writeDomainTag(gear)).join(';'))

            loaSpec && output.num(writeInternalTag(loaSpec.id)).render(r => r.details.specifications[loaSpec.id])
            tonnageSpec && output.num(writeInternalTag(tonnageSpec.id)).render(r => r.details.specifications[tonnageSpec.id])
            grtSpec && output.num(writeInternalTag(grtSpec.id)).render(r => r.details.specifications[grtSpec.id])
            totVolumeSpec && output.num(writeInternalTag(totVolumeSpec.id)).render(r => r.details.specifications[totVolumeSpec.id])
            totVolumeTonnesSpec && output.num(writeInternalTag(totVolumeTonnesSpec.id)).render(r => r.details.specifications[totVolumeTonnesSpec.id])

        } else {

            output.text(t('search.vesseltype_code_col')).render(r =>writeDomainTagCode(r.details.vesselType))
            .text(t('search.vesseltype_name_col')).render(r =>writeDomainTagName(r.details.vesselType))
            .text(t('search.vesselname_col')).render(r => r.details.name)
            
            .text(t('search.tenant_code_col')).render(r => writeTenantCode(r.tenant))
            .text(t('search.tenant_name_col')).render(r => writeTenantName(r.tenant))
            
            .text(t('search.vesselrange_code_col')).render(r => writeDomainTagCode(r.details.range))
            .text(t('search.vesselrange_name_col')).render(r => writeDomainTagName(r.details.range))

            .text(writeInternalTag(vids.valueOf(regnoVid.id))).render(r => vids.find(regnoVid.id, r.details.identifiers))
            .text(writeInternalTag(vids.valueOf(imoVid.id))).render(r => vids.find(imoVid.id, r.details.identifiers))
            .text(writeInternalTag(vids.valueOf(ircsVid.id))).render(r => vids.find(ircsVid.id, r.details.identifiers))

            .text(t('search.vesselport_code_col')).render(r => writePortCode(r.details.port))
            .text(t('search.vesselport_name_col')).render(r => writePortName(r.details.port))

            .text(t('search.vesselkind_code_col')).render(r =>writeDomainTagCode(r.details.vesselKind))
            .text(t('search.vesselkind_name_col')).render(r =>writeDomainTagName(r.details.vesselKind))

            .text(t('search.vesselgear_code_col')).render(r => r.details.gears.map(gear => writeDomainTagCode(gear)).join(';'))
            .text(t('search.vesselgear_name_col')).render(r => r.details.gears.map(gear => writeDomainTagName(gear)).join(';'))

            loaSpec && output.num(writeInternalTag(loaSpec.id)).render(r => r.details.specifications[loaSpec.id])
            tonnageSpec && output.num(writeInternalTag(tonnageSpec.id)).render(r => r.details.specifications[tonnageSpec.id])
            grtSpec && output.num(writeInternalTag(grtSpec.id)).render(r => r.details.specifications[grtSpec.id])
            totVolumeSpec && output.num(writeInternalTag(totVolumeSpec.id)).render(r => r.details.specifications[totVolumeSpec.id])
            totVolumeTonnesSpec && output.num(writeInternalTag(totVolumeTonnesSpec.id)).render(r => r.details.specifications[totVolumeTonnesSpec.id])
  
        }
        


        // output.text(t('search.vesselname_col')).render(r => r.details.name)

        // if (mode === 'codes' || mode === 'names') {
        //     output.text(t('search.tenant_col')).render(r => writeTenant(r.tenant))
        // } else {
        //     output.text(t('search.tenant_code_col')).render(r => writeTenantCode(r.tenant))
        //     output.text(t('search.tenant_name_col')).render(r => writeTenantName(r.tenant))
        // }

        // vids.allSchemes().forEach(vid =>
        //     output.text(writeInternalTag(vids.valueOf(vid.id))).render(r => vids.find(vid.id, r.details.identifiers))
        // )

        // if (mode === 'codes' || mode === 'names') {
        //     output.text(t('search.vesseltype_col')).render(r =>writeDomainTag(r.details.vesselType))
        //     .text(t('search.vesselkind_col')).render(r =>writeDomainTag(r.details.vesselKind))
        //     .text(t('search.vesselgear_col')).render(r => r.details.gears.map(gear => writeDomainTag(gear)).join(';'))
        //     .text(t('search.vesselrange_col')).render(r => writeDomainTag(r.details.range))
        //     .text(t('search.vesselport_col')).render(r => writePort(r.details.port))
        // } else {
        //     output.text(t('search.vesseltype_code_col')).render(r =>writeDomainTagCode(r.details.vesselType))
        //     .text(t('search.vesseltype_name_col')).render(r =>writeDomainTagName(r.details.vesselType))
        //     .text(t('search.vesselkind_code_col')).render(r =>writeDomainTagCode(r.details.vesselKind))
        //     .text(t('search.vesselkind_name_col')).render(r =>writeDomainTagName(r.details.vesselKind))
        //     .text(t('search.vesselgear_code_col')).render(r => r.details.gears.map(gear => writeDomainTagCode(gear)).join(';'))
        //     .text(t('search.vesselgear_name_col')).render(r => r.details.gears.map(gear => writeDomainTagName(gear)).join(';'))
        //     .text(t('search.vesselrange_code_col')).render(r => writeDomainTagCode(r.details.range))
        //     .text(t('search.vesselrange_name_col')).render(r => writeDomainTagName(r.details.range))
        //     .text(t('search.vesselport_code_col')).render(r => writePortCode(r.details.port))
        //     .text(t('search.vesselport_name_col')).render(r => writePortName(r.details.port))
        // }

        // tags.allTagsOfCategory(specificationCategory).forEach(t =>
        //     output.num(writeInternalTag(t.id)).render(r => r.details.specifications[t.id])
        // )

        tags.allTagsOfCategory(contactTypeCategory).forEach(tag => {
                output.text(writeInternalTag(tag.id)).render(r => r.details.contacts.find(c => c.type === tag.id)?.name ?? '')
                output.text(`${writeInternalTag(tag.id)} ${t('details.contact_address')}`).render(r => r.details.contacts.find(c => c.type === tag.id)?.address ?? '')
                if (tag.id === operatingCompanyTag)
                    output.text(`${writeInternalTag(tag.id)} ${t('details.contact_regno')}`).render(r => r.details.contacts.find(c => c.type === tag.id)?.['regno'] ?? '')
            }
        )

    }


    return serializer
}