import { useT } from 'apprise-frontend-core/intl/language'
import { AskConsentProps, useFeedback } from 'apprise-ui/utils/feedback'


export type ReloadOnEventProps = Partial<AskConsentProps> & {

    singular?: string
    allowCancel?: boolean
    type?: 'change' | 'remove'

}

export const useReloadOnEvent = (props: ReloadOnEventProps) => {

    const t = useT()
    const fb = useFeedback()


    const { type='change', allowCancel, singular=t('bus.consent_reload_body_default_singular'), ...rest } = props

    

    return fb.ask({

        title: t('bus.consent_reload_title'),
        body: t( allowCancel ? `bus.consent_reload_body_${type}` : 'bus.consent_reload_body_nocancel', { singular: t(singular).toLowerCase() }),
        noValediction: true ,
        okText: t('bus.consent_reload_ok'),
        noCancel: !allowCancel || type==='remove',
        ...rest

    })
}