
import { MultiSelectProps, PolySelectBox, PolySelectProps, SingleSelectProps } from 'apprise-ui/selectbox/selectbox'
import { useMemo } from 'react'
import { NoTenantLabel, TenantLabel } from './label'
import { noTenant, Tenant, useTenantModel } from "./model"
import { useTenantStore } from './store'
import { useTenantUtils } from './utils'

/* 
    single or multiple tenant id selection box, intended primarily for tenant resource forms.
    handles the mapping undefined => noTenant.
*/


type TenantBoxProps = Partial<{

    // all sorted, if not provided
    tenants: (Tenant | string)[]

}>

export const TenantBox = (props: SingleSelectProps<string> & TenantBoxProps) => <PolyTenantBox {...props} />
export const MultiTenantBox = (props: MultiSelectProps<string> & TenantBoxProps) => <PolyTenantBox multi {...props} />

export const PolyTenantBox = (props: PolySelectProps<string> & TenantBoxProps) => {

    const store = useTenantStore()
    const model = useTenantModel()
    const utils = useTenantUtils()


    const allTenants = store.all()
    
    // eslint-disable-next-line
    const allSortedTenants = useMemo(()=> [...allTenants].sort(model.comparator),[allTenants])

    const { tenants = allSortedTenants, onChange, placeholder=<NoTenantLabel />, placeholderAsOption=true, ...rest } = props

    const tenantIds = tenants.map( t => typeof t === 'string' ? t : t.id)

    const onChangeProxy = rest.multi ? onChange : (t:string) => onChange?.(t ? t : noTenant)

    return <PolySelectBox key={`tenant-boxset`}
        options={tenantIds}
        textOf={utils.nameOf}
        render={t => t ? <TenantLabel bare noIcon tenant={t} /> : <NoTenantLabel />}
        placeholder={placeholder} placeholderAsOption={placeholderAsOption} 
        onChange = {onChangeProxy}
        {...rest} />
}