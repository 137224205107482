
import { GenericRecord, RecordSlot, SlotType } from '#record/model'
import { useRecordPlugin } from '#record/plugin'
import { DateLabel } from '#utils/datelabel'
import { useVesselModel } from '#vessel/model'
import { useMode } from 'apprise-frontend-core/config/api'
import { useT } from 'apprise-frontend-core/intl/language'
import { Button } from 'apprise-ui/button/button'
import { classname, Styled } from 'apprise-ui/component/model'
import { Grid } from 'apprise-ui/grid/grid'
import { Label } from 'apprise-ui/label/label'
import { MoreIcon } from 'apprise-ui/utils/icons'
import { NamedProperty } from 'apprise-ui/utils/namedproperty'
import React, { useEffect } from 'react'
import { HistoryDeactivateIcon, HistoryIcon } from '../vessel/constants'
import './slotviewer.scss'
import { usePrevious } from 'apprise-frontend-core/utils/hooks'
import { useScroller } from 'apprise-ui/page/scroller'


type TypeProps<T extends GenericRecord = GenericRecord> = React.PropsWithChildren<{

    type: SlotType
    noTitle?: boolean

    historyMode: boolean
    onModeToggle: () => any

    onRecordClick?: (age: number) => any
    trackMode?: 'always' | 'history'

    current: T

    historyForType: T[]

    controls?: JSX.Element[]
    extraControls?: JSX.Element[]
    noControls?: boolean

    render: (_: { showChange: boolean, record: T, previous: T | undefined, age: number }) => JSX.Element | null
}>

const expand = (div: HTMLDivElement, state: boolean) => {

    let start = 0, end = 0

    if (state) {

        start = div.offsetHeight

        div.classList.remove('closed')

        end = div.scrollHeight

    } else {

        const first = div.firstElementChild as HTMLElement

        start = div.offsetHeight
        end = first.offsetHeight
    }

    div.animate([

        { height: `${ start }px`, overflow: 'hidden' },

        { height: `${ end }px`, overflow: 'hidden' }

    ], {

        duration: 350,

        easing: state ? 'ease-in' : 'ease-out'

    }).addEventListener('finish', () => {

        if (!state)
            div.classList.add('closed');
        else
            div.querySelector('.current-record')?.scrollIntoView({
                behavior: 'smooth',
                inline: 'center',
                block: 'center'
            });
    });
}

export const SlotViewer = <T extends GenericRecord = GenericRecord>(props: Styled & TypeProps<T>) => {

    const t = useT()

    const { className, type, historyMode, onModeToggle, onRecordClick, noTitle, current, historyForType, noControls, controls, extraControls = [], children, render, trackMode = 'always' } = props

    const prev = usePrevious(current)
    const mode = useMode()

    const [state, stateSet] = React.useState<{ history: boolean, height: number, transition: 'open' | 'close' | undefined }>({

        history: false,
        height: 0,
        transition: undefined

    })

    const { history } = state

    const { Icon, plural } = useRecordPlugin(type)

    const infoBtn = <Button noReadonly type='ghost' noLabel icon={<MoreIcon />}
        tipPlacement='left' tipWidth={300} tipMode='light' tip={<InfoTooltip slot={current[type]!} />} />

    const historyBtn = <Button enabled={historyForType.length > 1} noReadonly type='ghost' noLabel icon={history ? <HistoryDeactivateIcon /> : <HistoryIcon />}
        tipPlacement='left' tip={t(history ? 'vessel.history_hide_tip' : 'vessel.history_show_tip', { plural: plural.toLocaleLowerCase() })}
        onClick={onModeToggle} />


    const bodyref = React.createRef<HTMLDivElement>()


    const recordRefs: Record<string, React.MutableRefObject<HTMLDivElement>> = React.useMemo(() =>

        historyForType.reduce((acc, rec) => ({ ...acc, [rec.id]: React.createRef<HTMLDivElement>() }), {})

        , [historyForType])


    const paddingTop = 20

    const paddingBottom = 20

    const model = useVesselModel()

    const delisted = (record:GenericRecord)  => model.delisted(record)

    React.useEffect(() => {

        if (history === historyMode)
            return

        expand(bodyref.current!, historyMode);

        stateSet(s => ({ ...s,  history: historyMode}));

        // eslint-disable-next-line
    }, [history, historyMode])

    useEffect(() => {

        if (!historyMode)

            bodyref.current?.classList.add('closed');
 
        // eslint-disable-next-line
    }, []);

    const scroller = useScroller()

    // reset scroll when current change
    useEffect(() => {

        if (current?.id === prev?.id || !bodyref.current) return;

        scroller.element?.scrollTo({
            //behavior: 'smooth',
            top: 0
        });

        // eslint-disable-next-line
    }, [ current, prev ])

    return <div className={classname('slot-card', history && 'card-history-mode', className)}>

        {noTitle || <Label className='card-header-element card-title' icon={<Icon />} title={t(plural)} />}


        {<div ref={bodyref} className={`overview-body`} style={{ overflow: 'visible' }}>

            {historyForType.map((record, age, all) =>

                <div key={age} className={ current === record ? 'current-record' : '' }>

                    <div className={'record-separator' + (age === 0 || (current === record && !history) ? ' first' : '')}>
                        <DateLabel date={record.timestamp} />
                    </div>

                    <div style={{ paddingTop, paddingBottom }} onClick={() => onRecordClick?.(age)} ref={recordRefs[record.id]} className={classname('historical-record', record.id === current.id && (onRecordClick && history ? 'history-active' : 'active'), `age-${age}`, delisted(record) && 'record-delisted')}>
                        {render({ showChange: history || trackMode !== 'history', record, previous: age < all.length - 1 ? all[age + 1] : undefined, age })}
                    </div>

                </div>

            )}

        </div>}

        {noControls ||
            <div className='card-header-element card-bar'>
                {controls ??
                    <React.Fragment>
                        {historyBtn}
                        {extraControls}
                        {mode.development && infoBtn}
                    </React.Fragment>
                }
            </div>
        }

        {children}

    </div >


}

const InfoTooltip = (props: { slot: RecordSlot }) => {

    const t = useT()
    const { slot } = props

    return <Grid rowGap={5}>

        <Grid.Row>
            <NamedProperty name={t('vessel.info_id')} valueAlign='right'>
                {slot.id}
            </NamedProperty>
        </Grid.Row>

        <Grid.Row>
            <NamedProperty name={t('vessel.info_timestamp')} valueAlign='right'>
                <DateLabel noIcon bare date={slot.timestamp} />
            </NamedProperty>
        </Grid.Row>
    </Grid>
}
