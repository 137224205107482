import { useT } from 'apprise-frontend-core/intl/language'
import { LazyBusyGuard, useBusyFlag } from 'apprise-frontend-core/utils/busyguard'
import { UserIcon } from 'apprise-frontend-iam/user/constants'
import { User } from 'apprise-frontend-iam/user/model'
import { useUserStore } from 'apprise-frontend-iam/user/store'
import { classname } from 'apprise-ui/component/model'
import { Label, LabelProps } from 'apprise-ui/label/label'
import { IoMdRefresh } from 'react-icons/io'
import { useAccounts } from './accounts'
import { accountColors } from './constants'
import './label.scss'
import { Account } from './model'

export type Props = LabelProps &{

    user: User
    account: Account

    displayMode?: 'default' | 'short'

    onRefresh?: (account: Account) => void
}

export const AccountLabel = (props: Props) => {

   
    return <LazyBusyGuard>
            <InnerLabel {...props} />
            </LazyBusyGuard>
}

export const InnerLabel = (props: Props) => {

    const t = useT()

    const accounts = useAccounts()
    const store = useUserStore()

    const busy = useBusyFlag()

    const { user, account, displayMode = 'default', decorations, onRefresh  } = props

    const extendedAccount = accounts.extendedStateOf(user, account)

    const title = t(`account.state_${extendedAccount}_label${displayMode === 'short' ? '_short' : ''}`)

    const refresh =  async () => {

        const fetched = await accounts.customFetchAccount(config => config.minimumDuration(500))(user)

        onRefresh?.(fetched) ?? store.updateAccountData(user, fetched)

    }

    const refreshBtn =  <IoMdRefresh  className={classname(`refresh-icon`, busy && 'refresh-busy')} onClick={refresh} />

    const augmentedDecorations = ['unknown', 'pending'].includes(extendedAccount) ? [refreshBtn, ...decorations ?? []] : decorations

    return <Label className='account-label' fill={accountColors[extendedAccount]} icon={<UserIcon />} title={title} tip={t(`account.state_${extendedAccount}_tip`)} mode='tag' decorations={augmentedDecorations} {...props} />

}