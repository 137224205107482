import { useRavPreferences } from '#modules/users'
import { useT } from 'apprise-frontend-core/intl/language'
import { useForm } from 'apprise-frontend-core/utils/form'
import { Button } from 'apprise-ui/button/button'
import { FieldInfo } from 'apprise-ui/field/model'
import { useValidation } from 'apprise-ui/field/validation'
import { Form } from 'apprise-ui/form/form'
import { RouteGuard } from 'apprise-ui/link/routeguard'
import { TextBox } from 'apprise-ui/textbox/textbox'
import { Explainer, ExplainerPanel } from 'apprise-ui/utils/explainer'
import { SaveIcon } from 'apprise-ui/utils/icons'
import React from 'react'
import { SearchIcon } from './constants'
import { SavedQuery } from './model'


export type SaveQueryPanelProps = {

  query: SavedQuery
  close: () => any

}


export const SaveQueryPanel = (props: SaveQueryPanelProps) => {

  const { query, close } = props

  const t = useT()


  const preferences = useRavPreferences()

  const { search: { queries } } = preferences.get()

  const { edited, set, dirty, reset, initial } = useForm(query)

  const otherQueries = queries!.filter(q => q.name !== initial.name)

  const saveQuery = () => {

    preferences.saveWith(prefs => {

        prefs.search = { ...prefs.search, queries : query.name ? queries!.map(q => q.name === initial.name ? { ...q, name: edited.name } : q) : [edited, ...queries!]}
        
    })
        

    reset.to(edited).quietly()

    close()

  }

  const { check, is } = useValidation()

  const nameInfo: FieldInfo = {

    label: t('search.query_name'),
    msg: t('search.query_msg'),

    ...check(is.duplicateWith(otherQueries, q => q.name)).on(edited)

  }

  const disabled = !edited.name || nameInfo.status === 'error'

  const saveBtn = <Button type='primary' icon={<SaveIcon />} enabled={dirty} disabled={disabled} onClick={saveQuery}>
    {t("search.query_save")}
  </Button>


  return <React.Fragment>


    <ExplainerPanel>

      <Explainer icon={<SearchIcon />} iconFill action={saveBtn}>
        {t('search.query_save_explainer')}
      </Explainer>

      <Form style={{ marginTop: 40 }}>

        <TextBox width={300} info={nameInfo} onChange={set.with((m, n) => m.name = n)}>{edited.name}</TextBox>

      </Form>

    </ExplainerPanel>

    <RouteGuard when={dirty} />

  </React.Fragment>
}
