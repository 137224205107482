import { useT } from 'apprise-frontend-core/intl/language';
import { useL } from 'apprise-frontend-core/intl/multilang';
import { Optional } from 'apprise-frontend-core/utils/common';
import { useLogged } from 'apprise-frontend-iam/login/logged';
import { Label, LabelProps } from 'apprise-ui/label/label';
import { Tip } from 'apprise-ui/tooltip/tip';
import { AiOutlineBook } from 'react-icons/ai';
import { TenantIcon, tenantType } from './constants';
import { noTenant, Tenant, TenantReference, unknownTenantId } from './model';
import { useTenantRouting } from './routing';
import { useTenantStore } from './store';

export type TenantLabelProps = LabelProps & {

    tenant: Optional<TenantReference | Tenant>
}


export const NoTenantLabel = (props: Pick<TenantLabelProps,'mode'>) => {

    const t = useT()

    return <Label mode={props.mode} icon={<TenantIcon />} title={t('tenant.no_tenant')} />
}


export const TenantLabel = (props:TenantLabelProps) => {

    const store = useTenantStore()
    const routing = useTenantRouting()

    const  logged = useLogged()

    const {tenant, decorations=[], tip, title: titleOverride, unknown: unknownOverride, ...rest} = props

    const l = useL()

     // have nothing, render nothing. 
    if (!tenant)
        return null

    if (tenant===noTenant)
        return <NoTenantLabel />

    // resolve reference, failure is modelled as unknown tenant to pass-through next steps. 
    const resolved = typeof tenant === 'string' ? store.safeLookup(tenant) : tenant

    const note = resolved.preferences?.note?.[logged.tenant] 
   
    if (note) 
        decorations.push(<Tip tip={note}>{<AiOutlineBook />}</Tip>)

    const inactive = resolved.lifecycle.state === 'inactive'

    const route = routing.detailRoute(resolved?.id)

    const description = resolved.description ? l(resolved.description)?.trim() !== '' ? l(resolved.description) : undefined : undefined

    const computedTip = tip ?? description

    // unresolved reference: show it, unless clients overrides.
    const unknown = resolved.id === unknownTenantId ? unknownOverride ?? (typeof tenant === 'string' ? tenant : tenant.name.en) : false

    // resolved reference: show name, unless client overrides.
    const title = unknown ? undefined : titleOverride ?? resolved.name


    return <Label unknown={unknown} tip={computedTip} decorations={decorations} readonly={resolved.guarded} inactive={inactive} icon={<TenantIcon />} title={title} linkTarget={tenantType} linkTo={route} {...rest} />

}