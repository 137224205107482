import { SlotType } from '#record/model';
import { useRecordPluginDescriptors } from '#record/plugin';
import { SubmissionTypeIcon } from '#submission/constants';
import { SubmissionType } from '#submission/model';
import { useT } from 'apprise-frontend-core/intl/language';
import { utils } from 'apprise-frontend-core/utils/common';
import { Label } from 'apprise-ui/label/label';
import { MultiSelectBox, MultiSelectProps } from 'apprise-ui/selectbox/selectbox';

export type ApproveCycle = 'all' | 'none' | SubmissionType[]

export type ApproveCycleProps = Omit<MultiSelectProps<any>, 'children' | 'onChange' | 'defaultValue'> & Partial<{
    children: ApproveCycle
    onChange: (_:ApproveCycle | undefined) => any
    defaultValue: ApproveCycle
}>

export const ApproveCycleBox = (props: ApproveCycleProps) => {

    const t = useT();

    const plugins = useRecordPluginDescriptors();

    const { onChange, children, defaultValue: clientDefaultValue, noClear=true, ...rest } = props;

    const defaultValue = clientDefaultValue === 'all' ? ['all' as const] : clientDefaultValue === 'none'  ? ['none' as const] : clientDefaultValue;

    const value = children

    const approvalOptions = ['all', 'none', ...plugins.allTypes()] as ('all' | 'none' | SlotType)[];

    return <MultiSelectBox<SubmissionType | 'all' | 'none'> 
        options={approvalOptions}
        defaultValue={defaultValue}
        noClear={noClear}
        render={id => {

            if (id === 'all')
                return <Label icon={<SubmissionTypeIcon />} title={t('rav.approve_all')} />

            if (id === 'none')
                return <Label icon={<SubmissionTypeIcon />} title={t('rav.approve_none')} />

            const type = plugins.lookup(id);

            return <Label icon={<type.Icon />} title={type.name} />
        
        }}
        onChange={ids => {
            
            const last = ids?.[ids?.length-1] 
            const filteredIds =  ids?.filter(id => !['all','none'].includes(id)) as SlotType[]
            onChange?.(last === 'all' || last ==='none' ? last : filteredIds)
        
        }}
        
        {...rest}>

        {value ? utils().arrayOf(value as SlotType[]) : value}

    </MultiSelectBox>;
};
