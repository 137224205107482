
import { useAuthorizationColumns } from '#authorization/columns'
import { useDetailsColumns, usePreviousDetailsColumns } from '#details/columns'
import { usePhotographsColumns } from '#photographs/columns'


export const useBaseComboColumns = () => {

    const detailsColumns = useDetailsColumns()
    const authzColumns = useAuthorizationColumns()
    const photoColumns = usePhotographsColumns()
    const previousDetailsColumns = usePreviousDetailsColumns()


    return <>

        {detailsColumns}
        {authzColumns}
        {photoColumns}
        {previousDetailsColumns}

    </>
}