import { JsonSerializationProps, useGenericJsonSerializer } from '#record/jsonserializer'
import { useAppSettings } from '#settings/settings'
import { utils } from 'apprise-frontend-core/utils/common'
import { useTagStore } from 'apprise-frontend-tags/tag/store'
import format from 'date-fns/format'
import { delistingReasonCategory, delistingType } from './constants'
import { DelistingPatch } from './model'



export type DelistingJsonSerialization = Partial<{

        uvi: string;
        reason: string
        note: string
        date: string
  }>


export const useDelistingJsonSerializer = () => {

    const formatString = useAppSettings().resolveFormat()

    const reasons = utils().index(useTagStore().allTagsOfCategory(delistingReasonCategory)).mappingBy( t=>t.id, t=>t.code)

    const serializer = (props: JsonSerializationProps<DelistingPatch>): DelistingJsonSerialization => {
       
        const { patch, mode } = props

        const row = patch[delistingType]

        return { 
            
            date:mode === 'full' ? new Date(row.date).toISOString() : format(new Date(row.date),formatString),
            reason: reasons[row.reason] ?? row.reason,
            note: row.note

        }
    }

    return useGenericJsonSerializer(serializer)
     
}