import { MergeIcon } from '#delisting/constants';
import { useExtensions } from '#extension/state';
import { RavRecord } from '#record/model';
import { SearchIcon } from '#search/constants';
import { VidExtension } from '#vid/ext';
import { useVID } from '#vid/model';
import { useT } from 'apprise-frontend-core/intl/language';
import { utils } from 'apprise-frontend-core/utils/common';
import { useForm } from 'apprise-frontend-core/utils/form';
import { useLogged } from 'apprise-frontend-iam/login/logged';
import { Button } from 'apprise-ui/button/button';
import { classname } from 'apprise-ui/component/model';
import { Form } from 'apprise-ui/form/form';
import { Label, LabelRow } from 'apprise-ui/label/label';
import { TextBox } from 'apprise-ui/textbox/textbox';
import { useAsyncTask } from 'apprise-ui/utils/asynctask';
import { Explainer } from 'apprise-ui/utils/explainer';
import { DotIcon } from 'apprise-ui/utils/icons';
import { Fragment, useContext, useMemo, useState } from 'react';
import { FaCheck, FaQuestion } from "react-icons/fa6";
import Autosizer from 'react-virtualized-auto-sizer';
import { useVesselCalls } from './calls';
import { DetailContext } from './context';
import { useEffects } from './effects';
import { HistoryEntryLabel } from './historyentry';
import "./mergepanel.scss";
import { Vessel } from './model';
import { useVesselCache } from './cache';


export const MergePanel = () => {

    const t = useT()

    const { mergeKit } = useContext(DetailContext)

    const { Drawer } = mergeKit

    return <Drawer innerClassName='vessel-merge' icon={<MergeIcon />} title={t('merge.drawer_title')} width={500}>

        <Inner />

    </Drawer>
}

const Inner = () => {

    const t = useT()

    const logged = useLogged()

    const calls = useVesselCalls()
    const cache = useVesselCache()

    const vid = useVID()

    const { mergeKit, record, history, setHistory } = useContext(DetailContext)

    const [loading, setLoading] = useState(false)

    const { merge } = useEffects()

    const task = useAsyncTask()

    const { postProcessValue } = useExtensions<VidExtension>().get()

    const { edited, set, reset } = useForm({

        uvi: undefined as string | undefined,
        target: undefined as Vessel | undefined,
        comment: undefined! as string

    })


    const fetchVesselTask = task.make(calls.fetchVesselQuietly)
        .with($ => $.minimumDuration(150)
            .noBusyWait())
        .done()

    const fetchTarget = async (clientuvi?: string) => {

        const uvi = clientuvi?.trim()

        set.using(m => m.uvi = uvi)

        if (!uvi)
            return

        const normalised = vid.primaryOf(postProcessValue(uvi)!)

        if (normalised === record.uvi) {  // current source is no-op.

            set.using(m => {
                m.target = undefined

            })
            return
        }


        try {

            setLoading(true)

            const vessel = await fetchVesselTask(normalised)

            if (vessel)
                set.using(m => {
    
                    m.target=vessel

                })

        }
        finally {

            setLoading(false)

        }

    }

    const submitForm = task.make(async () => {

        const target = edited.target!

        const targetRevisions = mergedHistory.filter(e => e.type === 'target').map(e => e.record.id)

        const mergedHistoryWithAliases = mergedHistory.map(({ type, record:r }) => type === 'source' ? r :

            {
                ...r, uvi: record.uvi, aliases: [{

                    uvi: r.uvi,
                    comment: edited.comment,
                    since: new Date().toISOString(),
                    aliasedBy: logged.username

                }, ...r.aliases ?? []]
            }
        )

        await merge( target, mergedHistoryWithAliases.filter(r => targetRevisions.includes(r.id)))

        cache.resetForUvis([record.uvi])

        setHistory(mergedHistoryWithAliases)

        reset.to(edited).quietly()

        mergeKit.toggle()

    })
        .with($ => 
            $.show(t('merge.wait_msg'))
            .throw(t('merge.failure_msg'))
        )
        .withConsent({
            okChallenge: postProcessValue(edited.uvi)!,
            body: t('merge.consent_msg', { uvi: postProcessValue(edited.uvi) })
        })
        .done()

    const delistBtn = <Button type='primary' enabled={!!edited.target?.history.length} onClick={submitForm}>
        {t('merge.form_btn')}
    </Button>

    type Entry = { type: 'source' | 'target', record: RavRecord }

    const mergedHistory: Entry[] = useMemo(() => {

        return [
            ...history.map(r => ({ type: 'source' as const, record: r })),
            ...(edited.target?.history ?? []).map(r => ({ type: 'target' as const, record: r }))]
            .sort((r1, r2) => utils().compareDatesOf((r: any) => r.record.timestamp)(r2, r1))

    }, [edited, history])


    const match = !!edited.target?.history[0]?.details.name

    return <Fragment >

        <Explainer icon={<MergeIcon />}>
            <div>{t('merge.drawer_explainer')}</div>
        </Explainer>

        <br />

        <Form centered className={classname('merge-form', loading && 'preview-loading')}  >

            <TextBox delay={500}
                prefix={match ? <FaCheck color={'lightseagreen'} /> : edited.uvi ? <FaQuestion color='darkgray' /> : <SearchIcon color='darkgray' />}
                label={t('merge.target_uvi_lbl')}
                msg={<span className={classname(match && 'vessel-highlight')}>{match ? t('merge.target_uvi_msg_found', { name: edited.target?.history[0]?.details.name }) : edited.uvi ? <span style={{color:'red'}}>{t('merge.target_uvi_unknown')}</span> :  t('merge.target_uvi_msg')}</span>} 
                onChange={fetchTarget}>
                {edited.uvi}
            </TextBox>

            <div className='merge-preview'>
                <div className='preview-title'>{t('merge.preview_title')}</div>
                <div className={classname('preview-list', loading && 'preview-loading')}  >
                    <Autosizer >{

                        ({ height, width }) =>

                            <div style={{ height, width }}>

                                {mergedHistory.map((r, age) =>

                                    <HistoryEntryLabel noLink key={age} className={`item-${r.type}`} current={record} age={-1} record={r.record} previous={mergedHistory[age + 1]?.record} />


                                )}


                            </div>

                    }</Autosizer>
                </div>
                <LabelRow className='preview-msg'>
                    <Fragment>
                        <Label icon={<DotIcon color='lightslategray' />} title={t('merge.preview_source_lbl')} />
                        <Label icon={<DotIcon color='dodgerblue' />} title={t('merge.preview_target_lbl')} />
                    </Fragment>
                </LabelRow>
            </div>


            <TextBox label={t('merge.comment_lbl')} msg={t('merge.comment_msg')} multi={{ start: 4, end: 8 }} onChange={set.with((m, v) => m.comment = v)}>
                {edited.comment}
            </TextBox>


        </Form>



        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 25 }}>
            {delistBtn}
        </div>

    </Fragment>


}
