import Title from "antd/lib/typography/Title";
import { useT } from 'apprise-frontend-core/intl/language';
import { Button } from "apprise-ui/button/button";
import * as React from "react";
import { useHistory } from "react-router";


type Props =                                  {
    backTo: string

}

export const NoSuchRoute = (props:Partial<Props>) => {

    const history = useHistory()

    const {backTo } = props
    const t = useT()

    return <div className="nosuchroute">

        <Title>{t("ui.nosuchroute")}</Title>
        <div style={{ "marginTop":10, display:"flex", flexDirection:"row" }} >
            {backTo ?
                <Button size="large" linkTo={backTo}>{t("ui.back")}</Button> :
                <Button size="large" onClick={history.goBack}>{t("ui.back")}</Button>}
                &nbsp;&nbsp;
                    <Button size="large" linkTo="/" >{t("ui.home")}</Button>
        </div>

    </div>

}
